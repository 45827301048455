import { IconColorVariants } from "app/icons/icons";
import { h2qAccount } from "app/core/H2QContext";
import { userSidebarData } from "entities/sideBar";
import { useNavigate } from "react-router-dom";
import styles from "./SideBarLogout.module.scss";

interface ISideBarLogout {
  isOpen: boolean;
};

export const SideBarLogout = ({ isOpen }: ISideBarLogout) => {
  const item = userSidebarData[userSidebarData.length - 1];

  const navigate = useNavigate();
  const iconColor = IconColorVariants.red;

  const logout = () => {
    h2qAccount.logoutFromAccount();
    navigate("/login-page");
  };

  return (
    <div className={styles["menu-item"]} onClick={logout} >
      <div className={styles["item-body"]}>
        <div
          className={styles["item-logo"]}
        >
          <item.icon fill={iconColor} stroke={iconColor} />
        </div>
        <div className={`${styles["item-text"]} ${isOpen ? "" : styles.closed}`}>
          {item.text}
        </div>
      </div>
    </div>
  )
};