import { GeneratorChangeBlockingReason } from "features/modalWindows";
import { Button } from "shared/ui";
import styles from "./BlockedGeneratorWarning.module.scss";

interface IBlockedGeneratorWarning {
  accept: () => void;
  blockingReason: GeneratorChangeBlockingReason;
};

export function BlockedGeneratorWarning({ accept, blockingReason }: IBlockedGeneratorWarning) {

  const buttonOkMessage = <p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>OK</p>;

  return (
    <div>
      {blockingReason === "noAvatar" &&
        < p className={styles['modal-items__text']}>You currently have NO avatar to enter to this generator!</p>
      }
      {blockingReason === "noItems" &&
        <p className={styles['modal-items__text']}>You must have at lest 7 different items in your Collection to enter to this generator!</p>
      }
      <div className={styles['modal-items__buttons']}>
        <Button
          children={buttonOkMessage}
          widthValue={"small"}
          colorValue={"green"}
          action={accept}
        />
      </div>
    </div>
  )
}