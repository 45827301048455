import { ReactNode, useState } from "react";
import selectFrame from "./images/ramka_select_ITEM.png";
import { GeneratorParams } from "entities/generatorSelector";
import styles from "./GeneratorSelectorGalleryItem.module.scss";

interface IGeneratorItemFront {
  item: GeneratorParams,
  avatarsCount: number;
  opensideBar: (id: number) => void,
};

export const GeneratorItemFront = ({ item, avatarsCount, opensideBar }: IGeneratorItemFront) => {
  const [isHovered, setHovered] = useState<boolean>(false);

  return (
    <div
      className={styles["generator-link"]}
      onClick={(e) => opensideBar(item.id)}
      onMouseOver={() => { setHovered(true) }}
      onMouseLeave={() => { setHovered(false) }}
    >
      <div className={styles["generator-image"]}>
        {isHovered &&
          <img
            src={selectFrame}
            alt="Hover frame"
            className={styles["hover-frame"]} />
        }
        <img
          className={styles["image-banner"]}
          src={item.banner}
          alt="NFT Hero"
        />
        <img className={styles["image-icon"]} src={item.icon} alt="Generator Logo" />
        <p className={styles.count}>{avatarsCount}<span>AVATARs</span></p>
      </div>
    </div>
  )
};

interface IGeneratorItemBack {
  gen: GeneratorParams;
  itemCount: number;
  rewardPool: number;
};

export const GeneratorItemBack = ({ gen, itemCount, rewardPool }: IGeneratorItemBack) => {
  return (
    <div className={styles["generator-back-item"]}>
      <h3>{gen.longName}</h3>
      <ul>
        <li>Owner: <span>{gen.owner}</span></li>
        <li>NFT collection: <span>OpenSea</span></li>
        <li>Total assets: <span>{itemCount}</span></li>
        <li>Rewards pool: <span>{rewardPool}$</span></li>
      </ul>
    </div>
  )
};

interface IGeneratorSelectorItem {
  flipButton?: ReactNode;
  topChild: ReactNode;
  bottomChild: ReactNode;
};

export const GeneratorSelectorItem = ({ topChild, bottomChild, flipButton }: IGeneratorSelectorItem) => {
  return (
    <div className={styles["generator-item"]}>
      {flipButton}
      {topChild}
      {bottomChild}
    </div>
  )
};