import { h2qAccount } from "app/core/H2QContext";
import { Balance } from "entities/Balance";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { delay } from "shared/lib";
import { RotatingCircleCenter } from "shared/ui";
import styles from "./WrapperMainComponent.module.scss";
import { assetList } from "app/model/assets";
import { TopBar } from "widgets";
import { LoadingImages, EnterPINCode } from "features/modalWindows";
import { ModalWrapper } from "entities/ModalWrapper";
import { checkPhraseForValidity, complexPin, decryptPhrase } from "shared/lib/encryptPhrase";

export const WrapperMainComponent = observer(function () {
  const [isLoggedIn, setIsloggedIn] = useState<boolean>(false);
  const [percent, setPercentLoaded] = useState<number | null>(null);
  const [isLoadingModal, setLoadingModal] = useState<boolean>(false);

  const [isPinModalOpen, togglePinModalOPen] = useState<boolean>(false);

  const loginPath = "/login-page";
  const errorPath = "/error-login";

  const navigate = useNavigate();

  const closeLoadingModal = () => {
    setLoadingModal(prev => false);
  };

  const enterPinForExistedAccount = async (): Promise<boolean> => {
    togglePinModalOPen(true);
    //waiting for user to enter the pin code:
    while (h2qAccount.pin === null) {
      await delay(1000);
    }
    // if user enter the pin code check it
    console.log("Checking pin code...");
    try {
      const phrase = decryptPhrase(localStorage.cipher, complexPin(h2qAccount.pin.split("")));

      if (checkPhraseForValidity(phrase)) {
        console.log("Pin is OK");
        return true;
      }
      else {
        console.error("Pin is NOT OK");
        return false;
      }

    } catch (error) {
      console.error("Error during entering PIN code", error);
      return false;
    }
  };

  useEffect(() => {

    const isUserHasAnAccount = (): boolean => {
      //if user has an account
      if (localStorage.cipher) {
        console.log("Already have an account.");
        return true;
      }

      console.log("Do not have an account.");
      return false;
    }

    const getLoginStatus = async (): Promise<boolean> => {
      const cipher = localStorage.cipher;

      if (h2qAccount.contracts && h2qAccount.data.m_h2qBalance) {
        console.log("Already logged in");
        return true;
      };

      if (cipher && h2qAccount.pin && h2qAccount.data.m_h2qBalance === undefined) {
        console.log("User is already has an account");
        if (h2qAccount.loginToAccount.state !== "pending" || h2qAccount.createAccount.state !== "pending") {
          console.log("Starting log in to account...");
          const phrase = decryptPhrase(cipher, complexPin(h2qAccount.pin.split("")));
          await h2qAccount.loginToAccount(phrase);
          console.log("Succesfully logged in");
          return true;
        }
      }

      if (h2qAccount.loginToAccount.state === "pending" || h2qAccount.createAccount.state === "pending") {
        console.log("Login to account in progress...");

        while (h2qAccount.loginToAccount.state === "pending" || h2qAccount.createAccount.state === "pending") {
          console.log("Waiting for account to logged in, 1000ms");
          await delay(1000);
        }

        console.log("Succesfully logged in.");
        return true;
      }

      else {
        console.log("Not logged in");
        return false;
      }
    };

    const deleteOldCache = async () => {
      if (localStorage.cacheV === "0.2.b1") return;

      if ('caches' in window) {
        const chacheName = "h2qImagesCache";

        const imageCache = await caches.open(chacheName);

        if (imageCache) {
          console.log("Clear previous cache...");

          const currentKeys = await imageCache.keys();

          for (let start = 0, end = 10, chunkSize = 10; start < currentKeys.length; start += chunkSize, end += chunkSize) {
            const chunk = currentKeys.slice(start, end);
            await Promise.all(chunk.map(async (item) => {
              await imageCache.delete(item);
            }))
          }
        }
      }
    };

    const loadAssets = async () => {
      localStorage.setItem("cacheV", "0.2.b1");
      console.log("Checking assets loaded status...");

      if (h2qAccount.loginToAccount.state === "pending" || h2qAccount.createAccount.state === "pending") {
        while (h2qAccount.loginToAccount.state === "pending" || h2qAccount.createAccount.state === "pending") {
          await delay(1000);
          console.log("Waiting for account to logged in (being created), 1000ms");
        }
      };

      if ('caches' in window) {
        const chacheName = "h2qImagesCache";

        const imageCache = await caches.open(chacheName);

        if (imageCache) {
          console.log("Succesfully opened cache:", chacheName);

          const totalKeys = (await imageCache.matchAll()).length;
          console.log("Total keys in cache:", totalKeys);
          // console.log("Total keys:", assetList.length);

          if (totalKeys >= assetList.length) {
            console.log("Assets already loaded before");
            setPercentLoaded(prev => 100);
            return;
          }

          try {
            setLoadingModal(prev => true);
            setPercentLoaded(prev => 0);
            for (let start = 0, end = 10, chunkSize = 10; start < assetList.length; start += chunkSize, end += chunkSize) {
              const chunk = assetList.slice(start, end);
              await Promise.all(chunk.map(async (item) => {
                if (!(await imageCache.match(item))) {
                  await imageCache.add(item)
                }
              }))
              const newTotalKeys = (await imageCache.matchAll()).length;
              if (newTotalKeys % 100 === 0) {
                console.log("New keys count:", newTotalKeys);
              }
              const newCompletePercent = Math.min(Math.ceil(newTotalKeys * 100 / assetList.length), 100);
              setPercentLoaded(prev => newCompletePercent);
            }

            const updatedKeys = (await imageCache.matchAll()).length;
            console.log("Total keys in cache:", updatedKeys);
            setPercentLoaded(prev => 100);
          }
          catch (error) {
            console.log("Error on adding to cache", error);
            setPercentLoaded(prev => 100);
          }
        }

        else {
          console.log("Cache is not available");
        }
      }
      console.log("All assets are loaded!");
      setLoadingModal(prev => false);
    };

    const startApp = async () => {
      // temp--
      localStorage.removeItem("currentSeed");
      // --temp

      const hasAccount = isUserHasAnAccount();

      // if user has not account or not logged in:
      if (!hasAccount) {
        navigate(loginPath);
        return;
      };

      const checkPin = await enterPinForExistedAccount();

      // if user entered not valid pin code:
      if (!checkPin) {
        console.log("Need to enter the correct PIN code");
        h2qAccount.setPin(null);
        navigate(errorPath);
        return;
      }

      // if user enter valid pin proceed:
      const loggedInStatus = await getLoginStatus();

      if (!loggedInStatus) {
        console.log("Access denied");
        navigate(loginPath);
        return;
      }

      setIsloggedIn(true);
      console.log("Go to load assets");

      // TODO Delete in a future ->
      await deleteOldCache();
      // -<

      await loadAssets();
    };

    startApp();
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {

  //   const checkAndUpdateLastEnteredTime = async () => {
  //     const now = Date.now();
  //     const pinTime = h2qAccount.lastEnter;

  //     // if  less then 1 hour passed after last user's enter, update lasEnteredTime:
  //     if (now - pinTime < (1 * 60 * 1000)) {
  //       h2qAccount.setLastEnteredTime(now);
  //       return;
  //     }

  //     else {
  //       h2qAccount.setLastEnteredTime(0);
  //       h2qAccount.resetActiveSession();
  //       const checkPin = await enterPinForExistedAccount();

  //       // if user entered not valid pin code:
  //       if (!checkPin) {
  //         console.log("Need to enter the correct PIN code");
  //         h2qAccount.setPin(null);
  //         navigate(errorPath);
  //         return;
  //       }

  //       try {
  //         const cipher = localStorage.cipher;
  //         const phrase = decryptPhrase(cipher, complexPin(h2qAccount.pin!.split("")));
  //         await h2qAccount.init(phrase);

  //       } catch (error) {
  //         console.warn("Error during entering to account", error);
  //         navigate(errorPath);
  //       }
  //     }
  //   };

  //   checkAndUpdateLastEnteredTime();
  // });

  return (
    <div className={styles['wrapper-main']}>
      <TopBar />
      {isLoggedIn
        ? <div className={`${styles["outlet-wrapper"]} ${h2qAccount.uiStore.isRightSidebarOpen && !h2qAccount.uiStore.isRightSidebarSeparate ? styles["with-sidebar"] : ""}`}>
          <Outlet />
        </div>
        : <RotatingCircleCenter />
      }
      <Balance />

      {!!percent && percent !== 100 && isLoadingModal &&
        <ModalWrapper
          isTransparent={false}
          close={() => { }}>
          <LoadingImages currentPercent={percent} action={closeLoadingModal} />
        </ModalWrapper>
      }

      {isPinModalOpen &&
        <ModalWrapper
          children={<EnterPINCode accept={() => togglePinModalOPen(prev => false)} />}
          isTransparent={false}
          close={() => { }}
        />
      }

    </div>
  )
});