import { Balance } from "entities/Balance";
import { Outlet } from "react-router-dom"
import { TopBar } from "widgets";
import styles from "./WrapperOpenedComponent.module.scss";

export default function WrapperOpenedComponent() {
  return (
    <div className={styles["wrapper-open-main"]}>
      <TopBar />
      <div style={{
        marginTop: "8rem",
        height: "100%"
      }}>
        <Outlet />
      </div>
      <Balance />
    </div>
  )
};