import styles from "./FilterButton.module.scss";

interface IFilterBtn {
  name: string;
  isSelected: boolean;
  clickAction: () => void;
};

export const FilterButton = ({ name, isSelected, clickAction }: IFilterBtn) => {
  const onClickBtn = () => {
    clickAction();
  };

  return (
    <button
      className={`${styles["item-btn"]} ${isSelected ? styles.selected : ""}`}
      onClick={onClickBtn}
    >
      {name}
    </button>
  )
}