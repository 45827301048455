import { ReactComponent as EditSeasonIcon } from "app/icons/icon-quest-manager.svg";
import { ReactComponent as ChooseModeIcon } from "app/icons/icon-select-mode.svg";
import { ReactComponent as ChooseRandomIcon } from "app/icons/icons-select-random.svg";
import { ReactComponent as ChooseAllIcon } from "app/icons/icon-select-all.svg";
import { ReactComponent as ParamsSeasonIcon } from "app/icons/icon-details-more-gray.svg";
import { ReactComponent as CalcSeasonIcon } from "app/icons/icon-calculate.svg";

import styles from "./SeasonControlBtns.module.scss";
import { IconColorVariants } from "app/icons/icons";

interface ISeasonControlBtns {
  isEditModeEnabled: boolean;
  isParamsOpened: boolean;
  isSelectModeEnabled: boolean;
  isAllSelected: boolean;
  isCalculateEnabled: boolean;
  onEditSeason: () => void;
  onEnableSelectMode: () => void;
  onSelectAll: () => void;
  onSelectRandom: () => void;
  onParamsSeason: () => void;
  onCalculateSeason: () => void;
};

export const SeasonControlBtns = ({ isEditModeEnabled, isParamsOpened, isSelectModeEnabled, isAllSelected, isCalculateEnabled, onEditSeason, onEnableSelectMode, onSelectAll, onSelectRandom, onParamsSeason, onCalculateSeason }: ISeasonControlBtns) => {

  const chooseIconColor = isSelectModeEnabled
    ? IconColorVariants.yellow
    : IconColorVariants.blue;

  const chooseAllIconColor = isSelectModeEnabled
    ? isAllSelected
      ? IconColorVariants.yellow
      : IconColorVariants.blue
    : IconColorVariants.gray;

  const chooseRandomIconColor = isSelectModeEnabled
    ? IconColorVariants.blue
    : IconColorVariants.gray;


  const editIconColor = isEditModeEnabled
    ? IconColorVariants.yellow
    : IconColorVariants.blue;

  const paramsIconColor = isParamsOpened
    ? IconColorVariants.yellow
    : IconColorVariants.blue;

  const calculateIconColor = isCalculateEnabled
    ? IconColorVariants.blue
    : IconColorVariants.gray;

  return (
    <div className={styles.btns}>
      <EditSeasonIcon onClick={onEditSeason} className={`${styles.btn} ${styles.acitve}`} fill={editIconColor} />
      <CalcSeasonIcon onClick={onCalculateSeason} className={`${styles.btn} ${isCalculateEnabled ? styles.acitve : ""}`} stroke={calculateIconColor} />
      <ChooseModeIcon onClick={onEnableSelectMode} className={`${styles.btn} ${styles.acitve}`} fill={chooseIconColor} />
      <ChooseAllIcon onClick={onSelectAll} className={`${styles.btn} ${isSelectModeEnabled ? styles.acitve : ""}`} fill={chooseAllIconColor} />
      <ChooseRandomIcon onClick={onSelectRandom} className={`${styles.btn} ${isSelectModeEnabled ? styles.acitve : ""}`} fill={chooseRandomIconColor} />
      <ParamsSeasonIcon onClick={onParamsSeason} className={`${styles.btn} ${styles.acitve}`} style={{ gridColumn: 7 }} fill={paramsIconColor} />
    </div>
  )
};