import { IconColorVariants } from "app/icons/icons";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useEffect, useMemo } from "react";
import { ReactComponent as IconGetReward } from "app/icons/icon-diamond-yellow.svg";
import styles from "./UnstakeAllButton.module.scss";

interface IUnstakeAllButton {
  action: () => void;
};

export const UnstakeAllButton = observer(function ({ action }: IUnstakeAllButton) {
  const { h2qAccount } = useContext(H2QContext);

  const stakedNfts = useMemo(() => h2qAccount.nfts.filter(item => item?.pseudoNFTdata?.m_lockedInQuest), [h2qAccount.nfts]);

  const showRewardsWindow = (e: React.SyntheticEvent) => {
    if (h2qAccount.hasRewardsToGet === false) {
      return
    };
    action();
    e.stopPropagation();
  };

  const checkIsQuestFinished = (finishTimeString: number) => {
    const questTimeLeft = Date.now() - (finishTimeString * 1000);
    return questTimeLeft > 0;
  };

  const getFinishedNfts = () => {
    return stakedNfts.filter(item => checkIsQuestFinished(item.pseudoNFTdata.m_lockedInQuest?.finishQuest || 0));
  };

  useEffect(() => {
    const finishedNfts = getFinishedNfts();
    if (finishedNfts.length === 0) {
      h2qAccount.toggleRewardsToGet(false);
    } else {
      h2qAccount.toggleRewardsToGet(true);
    }

    // eslint-disable-next-line
  }, [h2qAccount.nfts]);

  return (
    <div className={`
    ${styles["unstake-wrapper"]}
    ${h2qAccount.hasRewardsToGet ? styles.active : ""}
    ${h2qAccount.q2hero.state === "pending" ? styles.waiting : ""}
    `}>
      <IconGetReward
        fill={h2qAccount.hasRewardsToGet ? IconColorVariants.yellow : IconColorVariants.blue}
        stroke={h2qAccount.hasRewardsToGet ? IconColorVariants.yellow : IconColorVariants.blue}
        onClick={showRewardsWindow}
      />
    </div>
  )
})