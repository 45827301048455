import { generatorsData } from "shared/model";

export type GeneratorParams = {
  shortName: string;
  longName: string;
  id: number;
  icon: string;
  banner: string;
  owner: string;
};

export type TGenStats = {
  assetsCount: number;
  heroCount: number;
  questCount: number;
  h2qRewardsPool: number;
};

export const newGeneratorsList: GeneratorParams[] = Object.entries(generatorsData.generators).map(([genKey, genValue]) => {
  return {
    id: genValue.gen_id,
    shortName: genValue.short_name,
    longName: genValue.long_name,
    owner: genValue.owner,
    banner: genValue.banner,
    icon: genValue.icon
  }
});

export const getGeneratorTitleByGenId = (genId: number): string => {
  return newGeneratorsList.find(gen => gen.id === genId)!.shortName;
};