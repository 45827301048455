import { InitialAvatarElement } from "entities/avatar";
import { useEffect, useRef, useState } from "react";
import loadingIcon from "app/icons/icon-refresh-white.svg";
import styles from "./AvatarImage.module.scss";

interface IAvatarImage {
  avatarImg: InitialAvatarElement;
  isImgMoved?: boolean;
  onClick?: () => void,
  className?: string
};

export function AvatarImageSVG({ className, onClick, avatarImg, isImgMoved = false }: IAvatarImage) {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const rotatingSize = 50;

  const totalImagesInAvatar = 1 // compulsory torso image
    + (!(avatarImg.headThumbLink === "null" || avatarImg.headThumbLink === null) ? 1 : 0); // has separate head image?

  type ImagesForAvatar = {
    Torso: boolean,
    TorsoHead?: boolean
  };

  const loadedImages = useRef({} as ImagesForAvatar);

  const onLoadImage = () => {
    if (totalImagesInAvatar === Object.values(loadedImages.current).filter(i => i).length) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (totalImagesInAvatar === Object.values(loadedImages.current).filter(i => i).length) {
      setLoaded(true);
    }
  }, [totalImagesInAvatar]);

  return <svg viewBox="0 0 512 512"
    width={512}
    height={512}
    onClick={onClick}
    onLoad={onLoadImage}
    className={className}
    style={{
      position: "relative",
      display: "block",
      width: "100%",
      height: "100%",
      zIndex: 1
    }}
  >
    {/* Loading image */}
    {
      !isLoaded &&
      <image className={styles.loading} href={loadingIcon} width={rotatingSize} height={rotatingSize} x={256 - rotatingSize / 2} y={256 - rotatingSize / 2} />
    }
    <image style={{ "display": isLoaded ? "inherit" : "none" }} onLoad={() => loadedImages.current.Torso = true} href={avatarImg.imageThumbLink} width={512} height={512} x={isImgMoved ? "-14%" : 0} y={0} />
    {avatarImg.headThumbLink &&
      <svg viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} onLoad={() => loadedImages.current.TorsoHead = true} href={avatarImg.headThumbLink}
          x={`${isImgMoved
            ? avatarImg.headPos.X * 100 - 14
            : avatarImg.headPos.X * 100
            }%`}
          y={`${avatarImg.headPos.Y * 100}%`}
        />
      </svg>
    }
  </svg >
}
