import { Container, Sprite } from "@saitonakamura/react-pixi";
import { cellBorder } from "entities/generator";
import { ReactNode } from "react";

interface ISlotgenerateBtn {
  x: number;
  y: number;
  btnIcon: ReactNode;
  bottomCount: ReactNode;
  tooltips?: ReactNode
};

export const SlotGenerateBtn = ({ x, y, btnIcon, bottomCount, tooltips }: ISlotgenerateBtn) => {
  const genBtnZindex = 3;

  return (
    <>
      <Container
        x={x}
        y={y}
        zIndex={genBtnZindex}
      >
        {/* Frame for create button */}
        <Sprite
          image={"/res/Generator/select_lock-item-only-ramka.png"}
          anchor={[0, 0]}
          alpha={0.5}
        />
        {/* Create NFT button image within frame */}
        <Sprite
          image={"/res/Generator/btt-create-hero_non-date.png"}
          x={cellBorder}
          y={cellBorder}
          anchor={[0, 0]}
        />
        {/* Create NFT button icon */}
        {btnIcon}

        {/* Create NFT Hero Price */}
        {bottomCount}

      </Container>
      {tooltips &&
        <Container zIndex={10}>
          {tooltips}
        </Container>
      }
    </>
  )
};