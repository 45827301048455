export type collectionsElement = {
  id: number;
  name: string;
  nameId: string;
  icon?: string;
  link: string;
};

// temporary unused:
export const nftCollections: collectionsElement[] = [
  {
    id: 0,
    name: "NFT HERO",
    nameId: "hero",
    link: "nft",
  },
  // {
  //   id: 3,
  //   name: "NFT from other collections",
  //   nameId: "other",
  //   link: "other",
  // },
  // {
  //   id: 4,
  //   name: "Bookmarks",
  //   nameId: "bookmarks",
  //   icon: "/Icons/icon-favorit.png",
  //   link: "bookmarks",
  // },
];

export const assetCollections: collectionsElement[] = [
  {
    id: 1,
    name: "Avatar",
    nameId: "avatar",
    link: "avatar",
  },
  {
    id: 2,
    name: "Item",
    nameId: "item",
    link: "item",
  },
];