import { Button, ReloadButton } from "shared/ui";
import { InfoStatisticsBlock } from "entities/InfoStatisticsBlock";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NftTopInfo.module.scss";
import { getNftInfo, NftElement } from "entities/nft";

interface INftTopInfo {
  nft: NftElement;
};

export const NftTopInfo = observer(function ({ nft }: INftTopInfo) {
  const { h2qAccount } = useContext(H2QContext);

  const navigate = useNavigate();

  const getAvailableQuests = async () => {
    if (h2qAccount.filterQuestsByHero.state === "pending") {
      return;
    };

    // Do not load quests for staked nft:
    // if (nft?.pseudoNftData?.m_lockedInQuest) {
    //   return;
    // }

    if (nft !== null) {
      const nftInfo = getNftInfo(nft);
      await h2qAccount.filterQuestsByHero(nftInfo);
    }
  }

  const showAvailableQuests = () => {
    navigate(`/h2q/quests/for-nft-id/${nft!.pseudoNFTAddr.slice(2)}`)
  };

  useEffect(() => {
    if (nft !== null) {
      getAvailableQuests();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["top-info-wrapper"]}>

      <InfoStatisticsBlock
        title="Creation Costs"
        value={Number(nft.pseudoNftData?.m_h2qMetaCharacter.genPrice || 0) + Number(nft.pseudoNftData?.m_h2qMetaCharacter.valPrice || 0)}
        children={<span className={styles.h2q}>H2Q</span>}
      />

      <InfoStatisticsBlock
        title="NFT Generator"
        value={nft.pseudoNftData?.m_h2qMetaCharacter.generator_id.toUpperCase() || ""}
      />

      <InfoStatisticsBlock
        title="Quest Available"
        value={h2qAccount.filterQuestsByHero.state === "pending"
          ? "..."
          : h2qAccount.questsForHero.valid.length}
        children={
          <>
            <ReloadButton
              reloadAction={getAvailableQuests}
              isWaiting={h2qAccount.filterQuestsByHero.state === "pending"}
            />
            <Button
              children={<p style={{ "color": "white", "fontSize": "1.6rem", "padding": "0 2rem" }}>Show QUESTS</p>}
              colorValue={"green"}
              isActive={h2qAccount.filterQuestsByHero.state !== "pending" && (h2qAccount.questsForHero.valid.length > 0 || !!h2qAccount.questsForHero.active)}
              action={showAvailableQuests}
            />
          </>
        }
      />
    </div>
  )
})