import { H2QContext } from "app/core/H2QContext";
import { getRarityName, ItemElement } from "entities/item";
import { ItemRewardElement } from "entities/ItemReward";
import { useContext } from "react";
import styles from "./UserRewards.module.scss";

interface IUserRewards {
  items: ItemElement[]
};

export function UserRewards({ items }: IUserRewards) {
  const { h2qAccount } = useContext(H2QContext);
  return (
    <div className={styles["reward-items-block"]}>
      <h3>Total items gained: {items.reduce((total, item) => total += item.amount, 0)}</h3>
      {items.length &&
        <ul>
          {items.map(item => {
            return (
              <ItemRewardElement
                key={item.iconThumbLink}
                item={item}
                value={h2qAccount.inventoryInfoFromBC![item.itemId].value}
                rarity={getRarityName(h2qAccount.inventoryInfoFromBC![item.itemId].rarity)}
              />
            )
          })}
        </ul>
      }
    </div>
  )
}
