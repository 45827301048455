import { H2QuestResponse } from "app/core/H2QQuestManager";

const calcInventory = (mask?: string) => {
  if (!mask) return 0;
  mask = mask.slice(2);
  let sum = 0
  for (let i = 0; i < mask.length; i += 4) {
    sum += (parseInt(mask.slice(i, i + 4), 16) > 0 ? 1 : 0);
  }
  return sum;
};

export const calcQuestTotalStats = (quests: H2QuestResponse[]) => {

  return quests.reduce((result, i) => {
    const max = Number(i.m_maxParticipants) > 0 ? Number(i.m_maxParticipants) : 1;
    const leftParticipants = max - parseInt(i.m_totalClientsLocked, 16);

    result.totalQuests = result.totalQuests + 1;
    result.totalHeroesLocked = result.totalHeroesLocked + parseInt(i.m_totalClientsLocked, 16);

    result.tokensTotalH2Q = result.tokensTotalH2Q + Number(i.m_totalH2QReward);
    result.tokensPoolH2Q = result.tokensPoolH2Q + Math.floor(Number(i.m_totalH2QReward) * leftParticipants / max);

    result.tokensTotalQST = result.tokensTotalQST + Number(i.m_totalQstReward);
    result.tokensPoolQST = result.tokensPoolQST + Math.floor(Number(i.m_totalQstReward) * leftParticipants / max);

    result.totalInventoryLocked = result.totalInventoryLocked + calcInventory(i.m_inventoryReward) * leftParticipants;
    result.totalInventory = result.totalInventory + calcInventory(i.m_inventoryReward) * max;

    return result;
  }, {
    totalQuests: 0,
    totalHeroesLocked: 0,
    tokensPoolH2Q: 0,
    tokensTotalH2Q: 0,
    tokensPoolQST: 0,
    tokensTotalQST: 0,
    totalInventoryLocked: 0,
    totalInventory: 0
  });
};