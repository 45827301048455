import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeViewModeForAssets } from "features/ChangeViewModeForAssets";
import styles from "./AssetsCollectionControlBlock.module.scss";
import { PageSelector } from "features/PageSelector";
import { PageSelectorWrapper } from "entities/PageSelectorWrapper";
import { assetCollections, collectionsElement } from "entities/collections";

export function AssetsCollectionControlBlock() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")[3];
  const [currentGallery, setCurrentGallery] = useState<collectionsElement>(() => {
    return assetCollections.filter(item => item.link === currentPath)[0] || assetCollections[0]
  });

  const changeActiveCollection = (link: string) => {
    const selectedColl = assetCollections.filter((item) => item.link === link)[0];
    setCurrentGallery(selectedColl);
    navigate(link);
  };

  return (
    <PageSelectorWrapper>
      {assetCollections.map((item) => {
        return (
          <PageSelector
            key={item.id}
            item={item}
            isActive={item.nameId === currentGallery.nameId}
            action={changeActiveCollection}
          />
        );
      })}

      {currentPath === "item" &&
        <div className={styles["control-item"]}>
          <ChangeViewModeForAssets modes={[4, 6, 8]} type={"items"} isFavButton={true} />
        </div>
      }

      {currentPath === "avatar" &&
        <div className={styles["control-item"]}>
          <ChangeViewModeForAssets modes={[4, 6, 8]} type={"avatars"} isFavButton={true} />
        </div>
      }

    </PageSelectorWrapper>
  );
}
