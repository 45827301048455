// const zeroString = "00000000";

// export const IdNumberDecToHex = (id: number): string => {
//   return (zeroString + id.toString(16)).slice(-8);
// };

export const IdNumberHexToDec = (id: string): number => {
  return parseInt(id, 16);
};

export const ItemHexMasktoItemArray = (mask: string): string[] => {
  const maskArray = mask.slice(2).match(/.{1,8}/g);
  maskArray!.shift();
  return maskArray!.reverse().map(mask => (IdNumberHexToDec(mask) - 1).toString());
};

export const ItemHexMasktoAvatarIdNumber = (mask: string): number => {
  const avatarIdHexNumber = mask.slice(2, 10);

  return IdNumberHexToDec(avatarIdHexNumber) - 1;
};

export const hexToDecNumber = (mask: string): number => {
  const returnNumber = mask.slice(2);

  return IdNumberHexToDec(returnNumber);
};

export const ItemHexMaskToInventoryItemArray = (mask: string): number[] => {
  if (mask.length !== 66) return [0, 0, 0, 0, 0, 0, 0];

  const maskArray = mask.slice(2).match(/.{1,8}/g);
  const itemsFromMask = maskArray!.reverse().map(mask => IdNumberHexToDec(mask) - 1)

  return itemsFromMask
};