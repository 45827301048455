import { ReactNode } from "react";
import styles from "./FadeAndGlowWrapper.module.scss";

interface IFadeAndGlowWrapper {
  children?: ReactNode;
  fade?: boolean;
  glow?: boolean;
};

export const FadeAndGlowWrapper: React.FC<IFadeAndGlowWrapper> = ({ children, fade = true, glow = true }) => {
  return (
    <div className={`${styles["fade-and-glow"]} ${fade ? styles.fade : ""} ${glow ? styles.glow : ""}`}>
      {children}
    </div>
  )
}
