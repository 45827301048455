import React, { useContext, useEffect, useState } from 'react'
import { Container, Sprite } from '@saitonakamura/react-pixi';
import { H2QContext } from 'app/core/H2QContext';
import { observer } from 'mobx-react-lite';
import { cellOutSize, ExcludedItems, GeneratorTooltip, getApplyBtnCoords, getSlotItemsCoords, getSlotSizeAndCoord } from 'entities/generator';
import { ItemImageSVG, itemList, makeInitialItemElement, parts } from "entities/item";
import { ItemInfoMainTab, ItemTags, NftItems } from "entities/nft";
import { ItemElementWithColor } from "entities/itemColors";
import { ItemSidebarContainer, TSidebarInfo } from "entities/rigthSideBar";
import { ClickOnItem, CreateNft, ReloadItems } from "features/generatorActions";

const slotZindex = [3, 2, 1, 0, 4, 5, 6];

interface IGeneratorSlots {
  isVertical: boolean;
  update: (excluded: ExcludedItems) => void;
  generate: () => void;
  isAvatarSelected: boolean;
  isTrianglesEnabled: boolean;
  isTooltipsActive: boolean;
  slotColors: NftItems<ItemElementWithColor>;
  isLocksReset: boolean;
  // setModalMessage: (type: ModalTypes) => void;
};

export const GeneratorSlots = observer(function ({
  isVertical = false,
  update,
  generate,
  isAvatarSelected,
  isTrianglesEnabled,
  isTooltipsActive,
  slotColors,
  isLocksReset
}: IGeneratorSlots) {
  const [locks, setLocks] = useState<boolean[]>(() => [false, false, false, false, false, false, false]);
  const [slotHovered, setSlotHovered] = useState<number | null>(null);
  const { h2qAccount } = useContext(H2QContext);
  const itemCoords = getSlotItemsCoords(isVertical);
  const slotSizeAndCoord = getSlotSizeAndCoord(isVertical);
  const applyBtnCoords = getApplyBtnCoords(isVertical);

  const onExcludeItems = (id: number) => {
    const locksAmount = locks.filter((item) => item === true).length;
    if (locksAmount < 3) {
      locks[id] = !locks[id];
    } else {
      locks[id] = false;
    }
    setLocks((prevLocks) => [...prevLocks]);
  };

  const onUpdateItems = () => {
    if (!isAvatarSelected) return;

    const excluded = locks.map((item, index) => item ? index : -1).filter(item => item !== -1);
    update(excluded);
  };

  const onGenerateHero = () => {
    if (h2qAccount.data.m_generatedHero) {
      generate();
    }
  };

  const onThreeDots = async (catId: number, itemId: number) => {
    const item = makeInitialItemElement(itemId, catId);

    if (item === null) return;

    const newSelectedKey = `${item!.categoryId}-${item!.itemId}`;

    // if sidebar already opened and show the same item do nothing
    if (newSelectedKey === h2qAccount.uiStore.rightSidebarChild.key) {
      h2qAccount.uiStore.toggleRightSidebar(!h2qAccount.uiStore.isRightSidebarOpen, true);
      return;
    }

    if (h2qAccount.inventoryInfoFromBC === null) {
      await h2qAccount.getInventoryDetails();
    };

    const selectedItem = {} as TSidebarInfo;
    const nftItemInfo = h2qAccount.inventoryInfoFromBC![item!.itemId];

    selectedItem.image = <ItemImageSVG iconLink={item!.iconThumbLink} />;
    selectedItem.info = < ItemInfoMainTab itemInfo={nftItemInfo} categoryId={item!.categoryId} itemId={item!.itemId} title={parts[item!.categoryId]} showTags={false} />;
    selectedItem.tags = <ItemTags categoryId={item!.categoryId} itemId={item!.itemId} itemInfo={nftItemInfo} />

    h2qAccount.uiStore.setRightSidebarChild(
      newSelectedKey,
      <ItemSidebarContainer
        image={selectedItem.image}
        info={selectedItem.info}
        tags={selectedItem.tags}
      />
    );

    if (!h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(true, true)
    }
  };

  useEffect(() => {
    if (isLocksReset) {
      setLocks(prev => [false, false, false, false, false, false, false])
    };

    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    }
    // eslint-disable-next-line
  }, [isLocksReset])

  return (
    <>
      <Container
        position={[slotSizeAndCoord.Slots!.x, slotSizeAndCoord.Slots!.y]}
        sortableChildren={true}
      >
        {/* Reload button */}
        <ReloadItems
          isAvatarSelected={isAvatarSelected}
          isTooltipsActive={isTooltipsActive}
          isVertical={isVertical}
          onUpdateItems={onUpdateItems}
        />

        {/* Create button */}
        <CreateNft
          x={applyBtnCoords.x}
          y={applyBtnCoords.y}
          isTooltipsActive={isTooltipsActive}
          isVertical={isVertical}
          onGenerate={onGenerateHero}
        />

        {/* When Items exist */}
        {
          h2qAccount.data.m_generatedHero?.inventory &&
          h2qAccount.data.m_generatedHero?.inventory.map((item, index) => {
            const amountOfCurrentItem = h2qAccount.data.m_inventories
              ? h2qAccount.data.m_inventories[index][item]
              : 0;

            return (
              <ClickOnItem
                key={itemList[index][item].iconLink}
                x={itemCoords[`${parts[index]}`].x}
                y={itemCoords[`${parts[index]}`].y}
                isTooltipsActive={isTooltipsActive}
                isTrianglesEnabled={isTrianglesEnabled}
                zIndex={slotZindex[index]}
                isLocked={locks[index]}
                itemAmount={amountOfCurrentItem}
                itemIndex={index}
                itemLink={itemList[index][item].iconThumbLink}
                onDotsClick={() => onThreeDots(index, item)}
                onItemClick={() => onExcludeItems(index)}
                color={`${slotColors[parts[index]]!.color}`}
              />
            )
          })
        }

        {/* When there are NO items */}
        {!h2qAccount.data.m_generatedHero &&
          [0, 1, 2, 3, 4, 5, 6].map((item, index) => {
            return (
              <React.Fragment key={item}>
                <Container
                  zIndex={slotZindex[index]}
                  x={itemCoords[`${parts[index]}`].x}
                  y={itemCoords[`${parts[index]}`].y}
                  interactive={true}
                  mouseover={() => setSlotHovered(index)}
                  mouseout={() => setSlotHovered(null)}
                >
                  {/* Frame for item */}
                  <Sprite
                    image={"/res/Generator/select_lock-item-only-ramka.png"}
                    alpha={0.5}
                  />

                  {/* Random image icon */}
                  <Sprite
                    image={"/res/Generator/icon-random-black.svg"}
                    alpha={0.15}
                    width={cellOutSize * 3 / 5}
                    height={cellOutSize * 3 / 5}
                    anchor={0.5}
                    x={cellOutSize / 2}
                    y={cellOutSize / 2}
                  />

                  {/* Tooltip for empty slot */}
                  {isTooltipsActive && slotHovered === index &&
                    <GeneratorTooltip
                      type="emptySlot"
                    />
                  }
                </Container>
              </React.Fragment>
            )
          })
        }
      </Container>
    </>
  )
});