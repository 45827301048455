import { ItemElement, ItemImageSVG } from "entities/item";
import styles from "./ItemRewardElement.module.scss";

type ItemRewardType = {
  item: ItemElement;
  value: string;
  rarity: string;
};

export const ItemRewardElement = ({ item, value, rarity }: ItemRewardType) => {
  return (
    <li>
      <div className={styles["item-info"]}>
        <div>
          <ItemImageSVG iconLink={item.iconThumbLink} />
        </div>
        <span>&#10005; {item.amount}pcs
          (Value: {value}, Rarity: {rarity})
        </span>
      </div>
    </li>

  )
};