const H2QuestPlatformContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "superRoot",
                        "type": "address"
                    },
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "name": "levelMin",
                                        "type": "uint8"
                                    },
                                    {
                                        "name": "levelMax",
                                        "type": "uint8"
                                    },
                                    {
                                        "name": "inventoryMask",
                                        "type": "uint256"
                                    }
                                ],
                                "name": "essentialParams",
                                "type": "tuple"
                            },
                            {
                                "components": [
                                    {
                                        "name": "startTime",
                                        "type": "uint32"
                                    },
                                    {
                                        "name": "finishTime",
                                        "type": "uint32"
                                    },
                                    {
                                        "name": "name",
                                        "type": "string"
                                    },
                                    {
                                        "name": "worldTag",
                                        "type": "string"
                                    },
                                    {
                                        "name": "actionTag",
                                        "type": "string"
                                    },
                                    {
                                        "name": "questNarrative",
                                        "type": "string"
                                    },
                                    {
                                        "name": "qstAmount",
                                        "type": "uint128"
                                    }
                                ],
                                "name": "mandatoryParams",
                                "type": "tuple"
                            },
                            {
                                "name": "paramsScheme",
                                "type": "uint256"
                            },
                            {
                                "name": "additionalParams",
                                "type": "cell"
                            }
                        ],
                        "name": "questParams",
                        "type": "tuple"
                    },
                    {
                        "name": "questCode",
                        "type": "cell"
                    },
                    {
                        "name": "questGetterCode",
                        "type": "cell"
                    },
                    {
                        "name": "questClientPlatformCode",
                        "type": "cell"
                    },
                    {
                        "name": "questIndexCode",
                        "type": "cell"
                    }
                ],
                "outputs": []
            },
            {
                "name": "additinalParamsCompatible",
                "inputs": [
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "name": "levelMin",
                                        "type": "uint8"
                                    },
                                    {
                                        "name": "levelMax",
                                        "type": "uint8"
                                    },
                                    {
                                        "name": "inventoryMask",
                                        "type": "uint256"
                                    }
                                ],
                                "name": "essentialParams",
                                "type": "tuple"
                            },
                            {
                                "components": [
                                    {
                                        "name": "startTime",
                                        "type": "uint32"
                                    },
                                    {
                                        "name": "finishTime",
                                        "type": "uint32"
                                    },
                                    {
                                        "name": "name",
                                        "type": "string"
                                    },
                                    {
                                        "name": "worldTag",
                                        "type": "string"
                                    },
                                    {
                                        "name": "actionTag",
                                        "type": "string"
                                    },
                                    {
                                        "name": "questNarrative",
                                        "type": "string"
                                    },
                                    {
                                        "name": "qstAmount",
                                        "type": "uint128"
                                    }
                                ],
                                "name": "mandatoryParams",
                                "type": "tuple"
                            },
                            {
                                "name": "paramsScheme",
                                "type": "uint256"
                            },
                            {
                                "name": "additionalParams",
                                "type": "cell"
                            }
                        ],
                        "name": "questParams",
                        "type": "tuple"
                    }
                ],
                "outputs": [
                    {
                        "name": "value0",
                        "type": "bool"
                    }
                ]
            },
            {
                "name": "m_h2questManager",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_h2questManager",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_nonce",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nonce",
                        "type": "uint256"
                    }
                ]
            },
            {
                "name": "m_superRoot",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_superRoot",
                        "type": "address"
                    }
                ]
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "m_h2questManager",
                "type": "address"
            },
            {
                "key": 2,
                "name": "m_nonce",
                "type": "uint256"
            }
        ],
        "events": [
            {
                "name": "AddressInRangeFailed",
                "inputs": [],
                "outputs": []
            },
            {
                "name": "AdditionalParamsNotCompatible",
                "inputs": [],
                "outputs": []
            }
        ],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "m_h2questManager",
                "type": "address"
            },
            {
                "name": "m_nonce",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "components": [
                            {
                                "name": "levelMin",
                                "type": "uint8"
                            },
                            {
                                "name": "levelMax",
                                "type": "uint8"
                            },
                            {
                                "name": "inventoryMask",
                                "type": "uint256"
                            }
                        ],
                        "name": "essentialParams",
                        "type": "tuple"
                    },
                    {
                        "components": [
                            {
                                "name": "startTime",
                                "type": "uint32"
                            },
                            {
                                "name": "finishTime",
                                "type": "uint32"
                            },
                            {
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "name": "worldTag",
                                "type": "string"
                            },
                            {
                                "name": "actionTag",
                                "type": "string"
                            },
                            {
                                "name": "questNarrative",
                                "type": "string"
                            },
                            {
                                "name": "qstAmount",
                                "type": "uint128"
                            }
                        ],
                        "name": "mandatoryParams",
                        "type": "tuple"
                    },
                    {
                        "name": "paramsScheme",
                        "type": "uint256"
                    },
                    {
                        "name": "additionalParams",
                        "type": "cell"
                    }
                ],
                "name": "m_questParams",
                "type": "tuple"
            },
            {
                "name": "m_questCode",
                "type": "cell"
            },
            {
                "name": "m_questGetterCode",
                "type": "cell"
            },
            {
                "name": "m_questClientPlatformCode",
                "type": "cell"
            },
            {
                "name": "m_questIndexCode",
                "type": "cell"
            },
            {
                "name": "m_superRoot",
                "type": "address"
            }
        ]
    },
    tvc: "te6ccgECIAEABTwAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gscBQQZA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8GhUGA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPBsbBgIoIIIQZrm2v7vjAiCCEHOJJg+64wIIBwFOMNHbPPhKIY4bjQRwAAAAAAAAAAAAAAAAPOJJg+DIzs7JcPsA3vIAFgRQIIIQLpdPFLrjAiCCED07dQu64wIgghBQx/JiuuMCIIIQZrm2v7rjAg0LCgkBTjDR2zz4USGOG40EcAAAAAAAAAAAAAAAADmuba/gyM7OyXD7AN7yABYBUDDR2zz4SyGOHI0EcAAAAAAAAAAAAAAAADQx/JigyM7L/8lw+wDe8gAWAr4w+Eby4Ewhl9MH0wfU0dCU0wfTB+LT/1UgbwMB0x/TH9TU1NTR0NTTf1VgbwcB0//UVTBvBAHR2zwhjhwj0NMB+kAwMcjPhyDOghC9O3ULzwuBygDJcPsAkTDi4wDyABIMACjtRNDT/9M/MfhDWMjL/8s/zsntVAOMMPhCbuMA+EbycyGT1NHQ3vpA0wfTB9P/VSBvAwHTH9Mf1NTU1NHQ1NN/VWBvBwHT/9RVMG8EAdTU0dDU1NTRjoDY2zzyABUOHwSW+Er4SccF8uCH+CdvEIIRKgXyALzy4Gskb7SBASygtR8lb7W78uCL+AAkbxDbPI6XiHD7APhKyM+FCM6Ab89AyYEAoPsAXwbhJNs8FBMSDwJejpeIcPsA+ErIz4UIzoBvz0DJgQCg+wBfBuFVA/hsVQL4bVj4bgH4bwH4cfhw2zwREABk+E37BPhN0CCLOK2zWMcFk9dN0N7XTNDtHu1T+FH4SvhM+Ev4TvhP+FCCEBM2qLHtQ9gAIsAAAAAAAAAAAAAAAAAxZmeUAMpvEiB0sMMAIYAQsMMAIniwwwAjgCCwwwBdsJNfBXDgXLCTXwVw4FMwsJNfBXDgI7Mjs7CTXwVw4CGzIbOwk18FcOAkgECwwwBVBIMGsMMAVQRVA7FYs7CTXwNw4FmxAbOwb5DcfwAiwAAAAAAAAAAAAAAAAF/W0lYATPgo+kJvE9cL/6v3tQchbxC6+Cj6Qm8T1wv/q++EB7C1B1hvEbqwAhbtRNDXScIBjoDjDRcWAKjtRNDT/9M/0wAx+kDU0dDT/9MH0wfT/1UgbwMB0x/TH9TU1NTR0NTTf1VgbwcB0//UVTBvBAHU1NHQ1NTU+kDR+HH4cPhv+G74bfhs+Gv4avhj+GIEXHDtRND0BXEhgED0Do6Bid9yIoBA9A5vkZPXC//ecF8gbwNwIIhfMHBvB3CIbwQaGRkYAkSIXzCJ+HH4cPhv+G74bfhs+Gv4aoBA9A7yvdcL//hicPhjGRoAAABDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAK+Eby4EwCEPSkIPS98sBOHh0AFHNvbCAwLjY2LjABGqATNqixXwfbPPgP8gAfAKj4UfhQ+E/4TvhN+Ez4S/hK+EP4QsjL/8s/z4POVWDIy/8BbyReMAFvI14gywfLB8v/AW8nXmDLH8sfzMzMVYDIzMt/y//MzFUwyMzMzM7Nzc3J7VQ=",
    code: "te6ccgECHQEABQ8ABCSK7VMg4wMgwP/jAiDA/uMC8gsZAgEWA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8FxIDA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPBgYAwIoIIIQZrm2v7vjAiCCEHOJJg+64wIFBAFOMNHbPPhKIY4bjQRwAAAAAAAAAAAAAAAAPOJJg+DIzs7JcPsA3vIAEwRQIIIQLpdPFLrjAiCCED07dQu64wIgghBQx/JiuuMCIIIQZrm2v7rjAgoIBwYBTjDR2zz4USGOG40EcAAAAAAAAAAAAAAAADmuba/gyM7OyXD7AN7yABMBUDDR2zz4SyGOHI0EcAAAAAAAAAAAAAAAADQx/JigyM7L/8lw+wDe8gATAr4w+Eby4Ewhl9MH0wfU0dCU0wfTB+LT/1UgbwMB0x/TH9TU1NTR0NTTf1VgbwcB0//UVTBvBAHR2zwhjhwj0NMB+kAwMcjPhyDOghC9O3ULzwuBygDJcPsAkTDi4wDyAA8JACjtRNDT/9M/MfhDWMjL/8s/zsntVAOMMPhCbuMA+EbycyGT1NHQ3vpA0wfTB9P/VSBvAwHTH9Mf1NTU1NHQ1NN/VWBvBwHT/9RVMG8EAdTU0dDU1NTRjoDY2zzyABILHASW+Er4SccF8uCH+CdvEIIRKgXyALzy4Gskb7SBASygtR8lb7W78uCL+AAkbxDbPI6XiHD7APhKyM+FCM6Ab89AyYEAoPsAXwbhJNs8ERAPDAJejpeIcPsA+ErIz4UIzoBvz0DJgQCg+wBfBuFVA/hsVQL4bVj4bgH4bwH4cfhw2zwODQBk+E37BPhN0CCLOK2zWMcFk9dN0N7XTNDtHu1T+FH4SvhM+Ev4TvhP+FCCEBM2qLHtQ9gAIsAAAAAAAAAAAAAAAAAxZmeUAMpvEiB0sMMAIYAQsMMAIniwwwAjgCCwwwBdsJNfBXDgXLCTXwVw4FMwsJNfBXDgI7Mjs7CTXwVw4CGzIbOwk18FcOAkgECwwwBVBIMGsMMAVQRVA7FYs7CTXwNw4FmxAbOwb5DcfwAiwAAAAAAAAAAAAAAAAF/W0lYATPgo+kJvE9cL/6v3tQchbxC6+Cj6Qm8T1wv/q++EB7C1B1hvEbqwAhbtRNDXScIBjoDjDRQTAKjtRNDT/9M/0wAx+kDU0dDT/9MH0wfT/1UgbwMB0x/TH9TU1NTR0NTTf1VgbwcB0//UVTBvBAHU1NHQ1NTU+kDR+HH4cPhv+G74bfhs+Gv4avhj+GIEXHDtRND0BXEhgED0Do6Bid9yIoBA9A5vkZPXC//ecF8gbwNwIIhfMHBvB3CIbwQXFhYVAkSIXzCJ+HH4cPhv+G74bfhs+Gv4aoBA9A7yvdcL//hicPhjFhcAAABDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAK+Eby4EwCEPSkIPS98sBOGxoAFHNvbCAwLjY2LjABGqATNqixXwfbPPgP8gAcAKj4UfhQ+E/4TvhN+Ez4S/hK+EP4QsjL/8s/z4POVWDIy/8BbyReMAFvI14gywfLB8v/AW8nXmDLH8sfzMzMVYDIzMt/y//MzFUwyMzMzM7Nzc3J7VQ=",
    codeHash: "6bad574dfe316d2e649141333d20d44bb7388c29508cca998dec97732de3b447",
};
module.exports = { H2QuestPlatformContract };