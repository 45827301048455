import { useNavigate } from "react-router-dom";
import styles from "./GeneratorSelectorGallery.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { FlipWrapper } from "entities/FlipWrapper";
import { observer } from "mobx-react";
import { H2QContext } from "app/core/H2QContext";
import { ModalWrapper } from "entities/ModalWrapper";
import { BlockedGeneratorWarning, ChangeAvatarWarningFree, GeneratorChangeBlockingReason } from "features/modalWindows";
import { GeneratorItemBack, GeneratorItemFront, GeneratorSelectorItem, newGeneratorsList } from "entities/generatorSelector";
import { GeneratorGalleryItemAddNew, GeneratorItemButtons, getGeneratorStats } from "features/generatorSelector";
import { ReactComponent as MenuIcon } from "app/icons/icon-details-more-gray.svg";
import { GeneratorSidebarContainer } from "entities/rigthSideBar";
import { Button } from "shared/ui";
import { delay } from "shared/lib";

export const GeneratorSelectorGallery = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();
  const [flippedItem, setFlippedItem] = useState<number[]>([]);

  const [modalType, setModalType] = useState<GeneratorChangeBlockingReason | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);

  const selectedGenerator = useRef<number | null>(null);

  const currentAvatarInGenerator = h2qAccount.data.m_generatedHero?.avatar_id !== undefined
    ? h2qAccount.data.m_generatedHero.avatar_id
    : null;

  const userAvatars = h2qAccount.data.m_avatar_amount?.map((avatar, index) => avatar !== 0 ? index : -1).filter(item => item !== -1);


  const openGenerator = (link: number) => {
    // TODO: only one generator active now
    if (link !== 1) {
      setModalType("noAvatar");
      return;
    }

    // if (currentAvatarInGenerator && currentAvatarInGenerator !== link) {
    //   selectedGenerator.current = link;
    //   setModalType("changeActiveAvatar");
    //   console.log("Already active avatar:", currentAvatarInGenerator);
    //   console.log("selected:", selectedGenerator.current);
    //   return;
    // }

    if (link === 1 && h2qAccount.data.m_inventories) {
      const itemsCount = h2qAccount.data.m_inventories
        .map(itemsArray => {
          return itemsArray.reduce((acc, item) => {
            return acc + Number(item)
          }, 0)
        })
        .filter(item => item > 0)
        .length;

      if (itemsCount < 7) {
        setModalType("noItems");
        return;
      }

      if (itemsCount >= 7 && userAvatars && userAvatars.length > 0) {
        if (currentAvatarInGenerator) {
          navigate(`/h2q/generator/${currentAvatarInGenerator}`);
          localStorage.setItem("activeGen", link.toString())
          console.log("Open currently active avatar in generator:", currentAvatarInGenerator);
          return;
        }
        const firstAvailableAvatar = userAvatars[0];
        navigate(`/h2q/generator/${firstAvailableAvatar}`);
        localStorage.setItem("activeGen", link.toString())
        console.log("Open first available avatar:", firstAvailableAvatar);
      }
    }
  };

  const resetGenerator = async (id: number) => {
    setIsPending(true);
    try {
      // disabled after adding changeAvatar free function
      // await h2qAccount.discardCharacter();
      await h2qAccount.changeAvatar({
        av: id
      });
      setIsPending(false);
      setModalType(null);
      navigate(`/h2q/generator/${id}`);
    }
    catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setModalType(null);
  };

  const setActiveGenerator = (genId: number) => {
    if (genId !== 1) {
      setModalType("noAvatar");
    }
    else {
      localStorage.setItem("activeGen", genId.toString());
    }
    h2qAccount.uiStore.toggleRightSidebar(false);
    h2qAccount.uiStore.setRightSidebarChild(null, null);
  };

  const flipGeneratorItem = (id: number) => {
    if (flippedItem.includes(id)) {
      const newFlipped = [...flippedItem].filter(item => item !== id);
      setFlippedItem(prev => newFlipped);
      return;
    }
    else {
      const newFlipped = [...flippedItem];
      newFlipped.push(id);
      setFlippedItem(prev => newFlipped);
    }
  };

  const openSidebarPanel = async (id: number) => {
    const genKey = `Generator-${id}`

    if (h2qAccount.uiStore.rightSidebarChild.key === genKey) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      await delay(500);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
      return;
    };

    const selectedGenerator = newGeneratorsList.find(gen => gen.id === id)!;
    const genRewards = getGeneratorStats(id);
    const sideBarRightChild = <GeneratorSidebarContainer
      gen={selectedGenerator}
      stats={genRewards}
      button={
        <Button
          colorValue={id === 1 ? "green" : "red"}
          widthValue={"full"}
          action={() => { setActiveGenerator(id) }}
        >
          <p className={styles["select-btn-text"]}>
            {id === 1
              ? "Select to play"
              : "Unavailabe"
            }
          </p>
        </Button>
      }
    />;

    h2qAccount.uiStore.setRightSidebarChild(genKey, sideBarRightChild);
    h2qAccount.uiStore.toggleRightSidebar(true, true);
  };

  useEffect(() => {
    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles['generators-gallery']}>
        {newGeneratorsList.map(item => {
          const generatorStats = getGeneratorStats(item.id);

          return (
            <GeneratorSelectorItem key={item.id}
              flipButton={(
                <MenuIcon
                  width={25}
                  className={styles["flip-icon"]}
                  onClick={() => flipGeneratorItem(item.id)}
                />
              )}
              topChild={(
                <FlipWrapper
                  frontChild={(<GeneratorItemFront
                    item={item}
                    opensideBar={openSidebarPanel}
                    // TODO: Only first generator now can work
                    avatarsCount={userAvatars && item.id === 1 ? userAvatars.length : 0}
                  />)}
                  backChild={(<GeneratorItemBack
                    gen={item}
                    itemCount={generatorStats.assetsCount}
                    rewardPool={generatorStats.h2qRewardsPool}
                  />)}
                  ratio={740 / 600}
                  flipped={flippedItem.includes(item.id)}
                />
              )}
              bottomChild={(
                <GeneratorItemButtons
                  genId={item.id}
                  title={item.longName}
                  // TODO: Only first generator now can work
                  isGeneratorActive={item.id === 1}
                  isPlayBtnActive={userAvatars && userAvatars.length > 0 && item.id <= 1}
                  playBtnAction={() => openGenerator(item.id)}
                />
              )}
            />
          )
        })}
        <GeneratorGalleryItemAddNew />
      </div>
      {
        (modalType === "noAvatar" || modalType === "noItems") &&
        <ModalWrapper close={closeModal}>
          <BlockedGeneratorWarning
            accept={closeModal}
            blockingReason={modalType}
          />
        </ModalWrapper>
      }
      {
        modalType === "changeActiveAvatar" &&
        <ModalWrapper close={closeModal}>
          {/* <ChangeAvatarWarning
            accept={() => resetGenerator(selectedGenerator!)}
            discard={closeModal}
            isPending={isPending} /> */}
          <ChangeAvatarWarningFree
            accept={() => resetGenerator(selectedGenerator.current!)}
            discard={closeModal}
            isPending={isPending} />
        </ModalWrapper>
      }
    </>
  )
});