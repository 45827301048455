import { InventoryItemData } from "app/core/H2QAccount";
import { H2QContext } from "app/core/H2QContext";
import { ORIGINAL_NFT_TYPE } from "entities/nft";
import { Selector } from "entities/Selector";
import { observer } from "mobx-react";
import { useContext } from "react";

export const FilterNftOriginalHero = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const selectedOriginalHero = h2qAccount.uiStore.nftFilter.nftOriginType;

  const select = (hero: string) => {
    if (selectedOriginalHero.includes(hero)) {
      return;
    }

    h2qAccount.uiStore.changeNftFilterOriginType(hero);
  };

  const unselect = (hero: string) => {
    h2qAccount.uiStore.changeNftFilterOriginType(hero);
  };

  const originalHeroList = Object.entries(h2qAccount.inventoryInfoFromBC!).map(([id, hero]: [string, InventoryItemData]) => {
    return hero.originalHero
  });
  originalHeroList.unshift(ORIGINAL_NFT_TYPE);

  return (
    <div>
      <Selector
        list={originalHeroList}
        selected={selectedOriginalHero}
        select={select}
        unselect={unselect}
      />
    </div>
  );
})