import { BackLink } from "shared/ui/BackLink";
import { AddToFavButton, FoldButton, RotatingCircleCenter, ShowLockedQuestsButton } from "shared/ui";
import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./QuestsForNftPage.module.scss";
import { makeNftObject, NftImageSVG, NFTInfo, NftInfoMainTab } from "entities/nft";
import { QuestListForNft } from "widgets";
import { StakeHeroBlockForOneNft } from "features/quest";

export const QuestsForNftPage = observer(function () {
  const { id } = useParams(); //nftId
  const { h2qAccount } = useContext(H2QContext);
  const [selectedQuest, setSelectedQuest] = useState<H2QuestResponse | null>(null);
  const [isQuestsFold, toggleQuestsFold] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(true);

  const nftViewQuestParams = id ? h2qAccount.uiStore.favQuestsForNfts[id] : null;

  const questsForNft = h2qAccount.questsForHero;
  const selectedNft = useRef<NFTInfo | null>(null);

  const chooseQuest = (id: string) => {
    const chosenQuest = questsForNft.valid.find(q => q.id === id);
    if (chosenQuest && chosenQuest !== selectedQuest) {
      setSelectedQuest(prev => chosenQuest);
    } else {
      setSelectedQuest(prev => null);
      h2qAccount.setCurrentHeroStakeDuration(0);
    }
  };

  const showFavQuestsForNft = () => {
    if (nftViewQuestParams && nftViewQuestParams.quests.length > 0) {
      h2qAccount.uiStore.changeNftPageFavQuestMode(id!, !nftViewQuestParams.isFavModeEnabled)
    }
  };

  const showLockedQuests = () => {
    h2qAccount.uiStore.changeQuestFilterNotValidMode(!h2qAccount.uiStore.questFilter.showNotValidMode);
  };

  useEffect(() => {
    const checkData = async () => {
      setIsWaiting(true);

      if (h2qAccount.nfts.length === 0 && h2qAccount.fetchNTFs.state !== "pending") {
        console.log("No nfts are availalbe. Loading nfts...");
        await h2qAccount.fetchNTFs();
      }

      selectedNft.current = h2qAccount.nfts.find(item => item.pseudoNFTAddr.slice(2) === id) || null;

      if (selectedNft.current === null) {
        console.log("Nft is not found");
        setIsWaiting(false);
        return;
      }

      console.log("Nft is found", selectedNft.current.pseudoNFTAddr);

      if (h2qAccount.questsForHero.nftId === selectedNft.current.pseudoNFTAddr) {
        console.log("Quests for selected Nft already loaded...");
        setIsWaiting(false);
        return;
      }

      console.log("Loading quests for selected Nft...");
      await h2qAccount.filterQuestsByHero(selectedNft.current);
      setIsWaiting(false);
    };

    h2qAccount.uiStore.changeQuestFilterNotValidMode(false);
    checkData();

    return () => {
      h2qAccount.clearQuestsForHero();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateQuests = async () => {
      setIsWaiting(true);

      if (h2qAccount.nfts.length) {
        selectedNft.current = h2qAccount.nfts.filter(item => item.pseudoNFTAddr.slice(2) === id)[0];
        if (selectedNft) {
          console.log("Updating quests for selected Nft...");
          await h2qAccount.filterQuestsByHero(selectedNft.current);
        }
      }
      setIsWaiting(false);
    };

    updateQuests();
    // eslint-disable-next-line
  }, [h2qAccount.nfts]);

  return (
    <div className={styles["page-wrapper"]}>
      {(h2qAccount.fetchNTFs.state === "pending" || h2qAccount.filterQuestsByHero.state === "pending") &&
        <RotatingCircleCenter />
      }
      {selectedNft.current &&
        <>
          <div className={styles["on-top"]}>
            <BackLink />
            <h1 className={styles["page-title"]}>
              Quests For {selectedNft.current ? selectedNft.current.trueNFTInfo.name : "..."}
              <span>
                (Available: {questsForNft.valid.length ? questsForNft.valid.length : 0} /
                Locked: {questsForNft.invalid.length ? questsForNft.invalid.length : 0})
              </span>
            </h1>
            <div className={styles["nft-info-block"]}>
              <div style={{ width: "100%", height: "100%" }}>
                <NftImageSVG item={makeNftObject(selectedNft.current)} />
              </div>
              <NftInfoMainTab nft={makeNftObject(selectedNft.current)} />
              <div className={styles["control-item"]}>
                <h3 className={styles["buttons-title"]}>Show options</h3>
                <div className={styles.buttons}>
                  <ShowLockedQuestsButton
                    action={showLockedQuests}
                    isActive={questsForNft.invalid.length > 0}
                    isSelected={h2qAccount.uiStore.questFilter.showNotValidMode}
                    size={25}
                  />
                  <FoldButton
                    action={() => toggleQuestsFold(prev => !prev)}
                    isActive={questsForNft.valid.length > 0 || questsForNft.invalid.length > 0}
                    isFold={isQuestsFold}
                    size={28}
                  />
                  <AddToFavButton
                    action={showFavQuestsForNft}
                    isActive={!!id && h2qAccount.uiStore.favQuestsForNfts[id] && h2qAccount.uiStore.favQuestsForNfts[id].quests.length > 0}
                    isAddedToFav={!!id && h2qAccount.uiStore.favQuestsForNfts[id] && h2qAccount.uiStore.favQuestsForNfts[id].isFavModeEnabled}
                    size={28}
                  />
                </div>
                <div className={styles["stake-block"]}>
                  <StakeHeroBlockForOneNft
                    nftToSend={selectedNft.current}
                    selectedQuests={selectedQuest}
                    clearSelectedQuest={() => setSelectedQuest(prev => null)}
                    isWaiting={isWaiting}
                    setIsWaiting={setIsWaiting}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["quest-list"]}>
            <QuestListForNft
              activeQuest={questsForNft.active}
              validQuests={nftViewQuestParams?.isFavModeEnabled
                ? questsForNft.valid.filter(q => nftViewQuestParams.quests.includes(q.id))
                : questsForNft.valid
              }
              invalidQuests={h2qAccount.uiStore.questFilter.showNotValidMode
                ? nftViewQuestParams?.isFavModeEnabled ?
                  questsForNft.invalid.filter(q => nftViewQuestParams.quests.includes(q.id))
                  : questsForNft.invalid
                : []
              }
              isQuestsFold={isQuestsFold}
              selectedNft={selectedNft.current}
              selectedQuest={selectedQuest}
              setSelectedQuest={chooseQuest}
              isWaiting={isWaiting}
            />
          </div>
        </>
      }
    </div>
  )
})