const DataChunkContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "chunk",
                        "type": "bytes"
                    }
                ],
                "outputs": []
            },
            {
                "name": "getInfo",
                "inputs": [],
                "outputs": [
                    {
                        "name": "addrData",
                        "type": "address"
                    },
                    {
                        "name": "chunkNumber",
                        "type": "uint128"
                    },
                    {
                        "name": "chunk",
                        "type": "bytes"
                    }
                ]
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "_code",
                "type": "cell"
            },
            {
                "key": 2,
                "name": "_addrData",
                "type": "address"
            },
            {
                "key": 3,
                "name": "_chunkNumber",
                "type": "uint128"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "_code",
                "type": "cell"
            },
            {
                "name": "_addrData",
                "type": "address"
            },
            {
                "name": "_chunkNumber",
                "type": "uint128"
            },
            {
                "name": "_chunk",
                "type": "bytes"
            }
        ]
    },
    tvc: "te6ccgECEwEAAjkAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsQBQQSA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPA4MBgNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwPDwYCKCCCEAvaThW64wIgghA8HMKmuuMCCgcDeDD4RvLgTPhCbuMA0ds8I44jJdDTAfpAMDHIz4cgznHPC2FeIMjPkvBzCprOy3/Mzclw+wCSXwPi4wDyAA0JCAAo7UTQ0//TPzH4Q1jIy//LP87J7VQADPhL+Ez4TQI6MPhCbuMA+Ebyc9TR+En4S8cF8uBk+AD4bds88gAMCwA8+E34TPhL+Er4Q/hCyMv/yz/Pg8zOWcjLf8zNye1UBJLtRNDXScIBj75w7UTQ9AVxIYBA9A+OgYjfciKAQPQOjoGJ33MjgED0Dm+Rk9cLf96I+G34bPhr+GqAQPQO8r3XC//4YnD4Y+MNEg4SDQBA7UTQ0//TP9MAMdT6QNTR0NN/1NH4bfhs+Gv4avhj+GIAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAACvhG8uBMAhD0pCD0vfLAThIRABRzb2wgMC42Ni4wAAA=",
    code: "te6ccgECEAEAAgwABCSK7VMg4wMgwP/jAiDA/uMC8gsNAgEPA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPAsJAwNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwMDAMCKCCCEAvaThW64wIgghA8HMKmuuMCBwQDeDD4RvLgTPhCbuMA0ds8I44jJdDTAfpAMDHIz4cgznHPC2FeIMjPkvBzCprOy3/Mzclw+wCSXwPi4wDyAAoGBQAo7UTQ0//TPzH4Q1jIy//LP87J7VQADPhL+Ez4TQI6MPhCbuMA+Ebyc9TR+En4S8cF8uBk+AD4bds88gAJCAA8+E34TPhL+Er4Q/hCyMv/yz/Pg8zOWcjLf8zNye1UBJLtRNDXScIBj75w7UTQ9AVxIYBA9A+OgYjfciKAQPQOjoGJ33MjgED0Dm+Rk9cLf96I+G34bPhr+GqAQPQO8r3XC//4YnD4Y+MNDwsPCgBA7UTQ0//TP9MAMdT6QNTR0NN/1NH4bfhs+Gv4avhj+GIAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAACvhG8uBMAhD0pCD0vfLATg8OABRzb2wgMC42Ni4wAAA=",
    codeHash: "d2000c09b4d0cd67c6cdd3640ffbc4cd9885092da11e2a1601d66fb1e6e3689f",
};
module.exports = { DataChunkContract };