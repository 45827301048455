import styles from "./PageSelector.module.scss";

interface IItem {
  id: number;
  name: string;
  nameId: string;
  link: string;
  icon?: string;
};

interface IPageSelector {
  item: IItem;
  isActive: boolean;
  action: (itemLink: string) => void;
};

export const PageSelector = ({ item, isActive, action }: IPageSelector) => {
  return (
    <div
      className={`${styles["col-selector-item"]} ${isActive ? styles.active : ""}`}
      onClick={() => action(item.link)}
    >
      {item.icon && <img className={styles["col-selector-img"]} src={item.icon} alt="Favourite items"></img>}
      {item.name}
    </div>
  )
}