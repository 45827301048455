import styles from "./CostAndRewardTitle.module.scss";
import { ReactComponent as IconInfo } from "app/icons/icon-info-blue.svg";
import { InitialItemElement } from "entities/item";
import { IconColorVariants } from "app/icons/icons";
import { CostAndRewardType, IQuestPrice, QuestSelectedItem, QuestTitleCostData } from "entities/quest";

interface ICostAndRewardTitle {
  type: CostAndRewardType;
  cost: IQuestPrice;
  action: (item: QuestSelectedItem) => void;
};

export function CostAndRewardTitle({ cost, action, type }: ICostAndRewardTitle) {
  const itemsCount = cost.items
    ? Object.values(cost.items).reduce((acc, item: InitialItemElement) => { return item ? acc + 1 : acc }, 0)
    : 0;

  const avatarName = cost.avatar?.avatarName
    ? cost.avatar.avatarName
    : "Any avatar";

  const costData: QuestTitleCostData = {
    avatarName,
    itemsCount: itemsCount !== 0
      ? itemsCount + " specified items"
      : "No mandatory items",
    qst: cost.qstAmount,
    h2q: cost.h2qAmount !== undefined ? cost.h2qAmount : null,
    minLevel: cost.levelMin !== undefined ? cost.levelMin : null,
    maxLevel: cost.levelMax !== undefined ? cost.levelMax : null,
    maxParticipants: cost.maxParticipants !== undefined ? cost.maxParticipants : null
  };

  const costSelectedElement: QuestSelectedItem = {
    cost: costData,
    type
  };

  return (
    <div className={styles['title-wrapper']}>
      <p className={`${type === "cost" ? styles['entrance-title'] : styles["reward-title"]}`}>
        {type === "cost" ? "Entrance" : "Reward"}
      </p>
      <IconInfo
        fill={IconColorVariants.gray}
        strokeWidth={0.5}
        className={styles.icon}
        onClick={() => action(costSelectedElement)}
      />
    </div>)
}
