import { PageSelectorWrapper } from "entities/PageSelectorWrapper";
import { PageSelector } from "features/PageSelector";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface pageItem {
  id: number;
  name: string;
  nameId: string;
  link: string;
};

const adminPages: pageItem[] = [
  {
    id: 0,
    name: "Add Inventory",
    nameId: "addInv",
    link: "add-inventory"
  },
  {
    id: 1,
    name: "Add Quest",
    nameId: "addQst",
    link: "add-quest"
  },
  {
    id: 2,
    name: "Add Seasons",
    nameId: "addQstSeason",
    link: "add-quest-seasons"
  },
];

export default function AdminPageSelector() {
  const [currentPage, setPage] = useState<pageItem>(() => adminPages[0]);

  const navigate = useNavigate();

  const changeActivePage = (link: string) => {
    const selectedPage = adminPages.filter(
      (item) => item.link === link)[0];

    setPage(selectedPage);
    navigate(link);
  };

  return (
    <PageSelectorWrapper>
      {adminPages.map((item) => {
        return (
          <PageSelector
            key={item.id}
            item={item}
            isActive={item.id === currentPage.id}
            action={changeActivePage}
          />
        );
      })}
    </PageSelectorWrapper >
  )
}
