const TokenRootOwnerContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "tokenRootCode",
                        "type": "cell"
                    },
                    {
                        "name": "tokenWalletCode",
                        "type": "cell"
                    }
                ],
                "outputs": []
            },
            {
                "name": "createProjectCurrencies",
                "inputs": [],
                "outputs": []
            },
            {
                "name": "deployRoot",
                "inputs": [
                    {
                        "name": "initialSupplyTo",
                        "type": "address"
                    },
                    {
                        "name": "initialSupply",
                        "type": "uint128"
                    },
                    {
                        "name": "deployWalletValue",
                        "type": "uint128"
                    },
                    {
                        "name": "mintDisabled",
                        "type": "bool"
                    },
                    {
                        "name": "burnByRootDisabled",
                        "type": "bool"
                    },
                    {
                        "name": "burnPaused",
                        "type": "bool"
                    },
                    {
                        "name": "remainingGasTo",
                        "type": "address"
                    },
                    {
                        "name": "randomNonce",
                        "type": "uint256"
                    }
                ],
                "outputs": [
                    {
                        "name": "value0",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "mint",
                "inputs": [
                    {
                        "name": "tokenRoot",
                        "type": "address"
                    },
                    {
                        "name": "amount",
                        "type": "uint128"
                    },
                    {
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "name": "deployWalletValue",
                        "type": "uint128"
                    },
                    {
                        "name": "remainingGasTo",
                        "type": "address"
                    },
                    {
                        "name": "notify",
                        "type": "bool"
                    },
                    {
                        "name": "payload",
                        "type": "cell"
                    }
                ],
                "outputs": []
            },
            {
                "name": "projectTokens",
                "inputs": [],
                "outputs": [
                    {
                        "components": [
                            {
                                "name": "ProjectTokenRoot",
                                "type": "address"
                            },
                            {
                                "name": "ReverueTokenRoot",
                                "type": "address"
                            },
                            {
                                "name": "RevenueTokenWallet",
                                "type": "address"
                            }
                        ],
                        "name": "projectTokens",
                        "type": "map(address,tuple)"
                    }
                ]
            }
        ],
        "data": [],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "m_tokenWalletCode",
                "type": "cell"
            },
            {
                "name": "m_tokenRootCode",
                "type": "cell"
            },
            {
                "components": [
                    {
                        "name": "ProjectTokenRoot",
                        "type": "address"
                    },
                    {
                        "name": "ReverueTokenRoot",
                        "type": "address"
                    },
                    {
                        "name": "RevenueTokenWallet",
                        "type": "address"
                    }
                ],
                "name": "projectTokens",
                "type": "map(address,tuple)"
            }
        ]
    },
    tvc: "te6ccgECHAEABDoAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsZBwQbAQAFAvztRNDXScMB+GaNCGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAT4aSHbPNMAAY4igwjXGCD4KMjOzsn5AAHTAAGU0/8DAZMC+ELiIPhl+RDyqJXTAAHyeuLTPwH4QyG58rQg+COBA+iogggbd0CgufK0+GPTHwEQBgEY+CO88rnTHwHbPPI8CANS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwYGAgCKCCCEEouT9a74wIgghBzPx49uuMCDQkDoDD4RvLgTPhCbuMAIZPU0dDe+kDTf9N/0gDSANIA1NHQ+kDT/9HbPCGOHyPQ0wH6QDAxyM+HIM5xzwthAcjPk8z8ePbOzclw+wCRMOLjAPIAFwoVA/74RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+ABeYHDIy/9wbYBA9ENVB8jL/3ZYgED0Q/god1iAQPQWiHFYgED0F4hyWIBA9BfIz4QGc1iAQPRD+Ch0WIBA9Bb4SnVYgED0F8j0AMn4S8jPhID0APQAz4HJIPkAyM+KAEDL/8nQGxsLAYZVcCjIz4WIzoKIKBKgXyAAAAAAAAAAAAAAAAAAA88LliHbPMzPg1VgyM+QKI+acs7Lf8t/ygDKAMoAAcjOzc3JcPsADAA00NIAAZPSBDHe0gABk9IBMd70BPQE9ATRXwMEUCCCEA3uyTW64wIgghATnXJAuuMCIIIQLUs2abrjAiCCEEouT9a64wIUExEOAmIw+EJu4wD4RvJz1NTR+EUgbpIwcN7y4GT4QvhFIG6SMHDeuvLgZPgAAfhr+GrbPPIAEA8AMPhM+Ev4SvhD+ELIy//LP8+DzMz0AMntVAJQ7UTQ10nCAY6dcO1E0PQFiCBt+Gz4a/hqgED0DvK91wv/+GJw+GPjDRsXA1Yw+Eby4Ez4Qm7jACGT1NHQ3vpA03/U0dD6QNN/1NHQ+kDSANTR2zzjAPIAFxIVAKj4RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+ABeUMjPhYjOgoAjuaygAAAAAAAAAAAAAAAAAAHPC45VUMjPkIL+zuLLf87Lf1UgyM7KAMzNzclw+wABUDDR2zz4TCGOHI0EcAAAAAAAAAAAAAAAACTnXJAgyM70AMlw+wDe8gAXAyQw+Eby4Ez4Qm7jANHbPOMA8gAXFhUAKO1E0NP/0z8x+ENYyMv/yz/Oye1UADb4RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+AAAMu1E0NP/0z/TADHU1PQE0fhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLAThsaABRzb2wgMC42Ni4wAAA=",
    code: "te6ccgECGQEABA0ABCSK7VMg4wMgwP/jAiDA/uMC8gsWBAEYAQACAvztRNDXScMB+GaNCGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAT4aSHbPNMAAY4igwjXGCD4KMjOzsn5AAHTAAGU0/8DAZMC+ELiIPhl+RDyqJXTAAHyeuLTPwH4QyG58rQg+COBA+iogggbd0CgufK0+GPTHwENAwEY+CO88rnTHwHbPPI8BQNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwVFQUCKCCCEEouT9a74wIgghBzPx49uuMCCgYDoDD4RvLgTPhCbuMAIZPU0dDe+kDTf9N/0gDSANIA1NHQ+kDT/9HbPCGOHyPQ0wH6QDAxyM+HIM5xzwthAcjPk8z8ePbOzclw+wCRMOLjAPIAFAcSA/74RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+ABeYHDIy/9wbYBA9ENVB8jL/3ZYgED0Q/god1iAQPQWiHFYgED0F4hyWIBA9BfIz4QGc1iAQPRD+Ch0WIBA9Bb4SnVYgED0F8j0AMn4S8jPhID0APQAz4HJIPkAyM+KAEDL/8nQGBgIAYZVcCjIz4WIzoKIKBKgXyAAAAAAAAAAAAAAAAAAA88LliHbPMzPg1VgyM+QKI+acs7Lf8t/ygDKAMoAAcjOzc3JcPsACQA00NIAAZPSBDHe0gABk9IBMd70BPQE9ATRXwMEUCCCEA3uyTW64wIgghATnXJAuuMCIIIQLUs2abrjAiCCEEouT9a64wIREA4LAmIw+EJu4wD4RvJz1NTR+EUgbpIwcN7y4GT4QvhFIG6SMHDeuvLgZPgAAfhr+GrbPPIADQwAMPhM+Ev4SvhD+ELIy//LP8+DzMz0AMntVAJQ7UTQ10nCAY6dcO1E0PQFiCBt+Gz4a/hqgED0DvK91wv/+GJw+GPjDRgUA1Yw+Eby4Ez4Qm7jACGT1NHQ3vpA03/U0dD6QNN/1NHQ+kDSANTR2zzjAPIAFA8SAKj4RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+ABeUMjPhYjOgoAjuaygAAAAAAAAAAAAAAAAAAHPC45VUMjPkIL+zuLLf87Lf1UgyM7KAMzNzclw+wABUDDR2zz4TCGOHI0EcAAAAAAAAAAAAAAAACTnXJAgyM70AMlw+wDe8gAUAyQw+Eby4Ez4Qm7jANHbPOMA8gAUExIAKO1E0NP/0z8x+ENYyMv/yz/Oye1UADb4RSBukjBw3vLgZPhC+EUgbpIwcN668uBk+AAAMu1E0NP/0z/TADHU1PQE0fhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLAThgXABRzb2wgMC42Ni4wAAA=",
    codeHash: "2e2792752ab8c4e832b8a62176c030806b8ae243068250ad8524d6c833e1804d",
};
module.exports = { TokenRootOwnerContract };