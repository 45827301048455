import { useContext } from "react";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";

import iconRefresh from "app/icons/icon-refresh-white.svg";
import styles from "./RefreshButton.module.scss";

export const RefreshButton = observer(function () {
    const { h2qAccount } = useContext(H2QContext);

    const reload = async () => {
        if (h2qAccount.loadData.state !== "pending") {
            h2qAccount.loadData();
        }
        if (h2qAccount.loadAllQuests.state !== "pending") {
            h2qAccount.loadAllQuests();
        }
        if (h2qAccount.fetchNTFs.state !== "pending") {
            h2qAccount.fetchNTFs();
        }

    }
    return (
        <div className={styles["refresh-icon__wrapper"]} onClick={reload}>

            <img
                className={`${styles["refresh-icon"]} ${h2qAccount.loadData.state === "pending" || h2qAccount.loadAllQuests.state === 'pending' || h2qAccount.fetchNTFs.state === "pending" ? styles.rotating : ""}`}
                src={iconRefresh} alt="Refresh" />
        </div>
    );
});