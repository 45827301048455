import styles from "./SelectAvatar.module.scss"
import { AvatarImageSVG } from "entities/avatar/ui/AvatarImage/AvatarImage";
import { useState } from "react";
import { InitialItemElement, itemList } from "entities/item";
import { avatarsList, InitialAvatarElement } from "entities/avatar";

interface SelectAvatarProps {
    heroId?: number | null;
    onSelect?: (hero: InitialAvatarElement | null) => void
}

export const SelectAvatar = (props: SelectAvatarProps) => {

    const [heroListShown, showHeroList] = useState(false)

    const hero = avatarsList.find(({ itemId }) => {
        return itemId === props.heroId
    }) || null

    return <div className={styles.root}>

        <button className={styles.empty} onClick={() => showHeroList(true)}>
            {
                hero ?
                    <div>
                        <p className={styles.clear} onClick={(e) => {
                            e.stopPropagation();
                            if (props.onSelect) {
                                props.onSelect(null);
                            }
                        }}><span>x</span>Id: {hero.itemId}</p>
                        <AvatarImageSVG avatarImg={hero} className={styles.avatarSvg} />
                    </div> :
                    "Choose hero"
            }
        </button>


        {heroListShown &&
            <div className={styles.avatarsList}>
                {avatarsList.map((hero) => <div className={styles.hero} key={hero.itemId}>
                    <AvatarImageSVG avatarImg={hero} className={styles.avatarSvg} onClick={() => {
                        showHeroList(false);
                        if (props.onSelect) {
                            props.onSelect(hero);
                        }
                    }} />
                </div>)}
            </div>}
    </div>
}


interface SelectInventoryProps {
    type: number
    itemId?: number | null
    onSelect?: (item: InitialItemElement | null) => void

}
export const SelectInventory = (props: SelectInventoryProps) => {


    const [inventoryListShown, showInventoryList] = useState(false)

    const inventory = itemList[props.type].find(({ itemId }) => {
        return itemId === props.itemId
    }) || null

    return <div className={styles.root}>

        <button className={styles.empty} onClick={() => showInventoryList(true)}>
            {(inventory)
                ? <div>
                    <p className={styles.clear} onClick={(e) => {
                        e.stopPropagation();
                        if (props.onSelect) {
                            props.onSelect(null);
                        }
                    }}><span>X</span>ItemId: {props.itemId}</p>

                    <img src={inventory.iconThumbLink}
                        width={inventory.imageThumbSize?.width}
                        height={inventory.imageThumbSize?.height}
                        alt="item" />
                </div>
                : "Choose inventory"
            }
        </button>
        {inventoryListShown &&
            <div className={styles.inventoryList}>
                {itemList[props.type].map((item) => <div
                    // style={{ backgroundImage: `url(${item.imageLink})` }}
                    className={styles.inventory}
                    key={item.itemId}
                    onClick={
                        () => {
                            showInventoryList(false)
                            if (props.onSelect) {
                                props.onSelect(item);
                            }
                        }
                    }>
                    <img src={item.iconThumbLink} alt="Item preview" style={{ width: "100%" }} />
                </div>
                )}
            </div>}
    </div>
}


interface InventoriesProps {
    avatar_id?: number | null,
    inv0?: number | null,
    inv1?: number | null,
    inv2?: number | null,
    inv3?: number | null,
    inv4?: number | null,
    inv5?: number | null,
    inv6?: number | null,
}
interface AvatarAndInventoriesProps {
    inventories: InventoriesProps,
    className?: string,
    onSelect: (inventories: InventoriesProps) => void
}
export const AvatarAndInventories = (props: AvatarAndInventoriesProps) => {

    const InventorySelector = ({ type }: { type: number }) => {
        const key = `inv${type}`
        return <div>
            <SelectInventory
                type={type}
                itemId={props.inventories[key as keyof typeof props.inventories]}
                onSelect={(item) => {
                    props.onSelect({ ...props.inventories, [key]: (item ? item.itemId : null) })
                }}
            />
        </div>

    }
    return <fieldset className={props.className}>
        <div>
            <SelectAvatar
                heroId={props.inventories.avatar_id}
                onSelect={(hero) => {
                    props.onSelect({ ...props.inventories, avatar_id: (hero && hero.itemId >= 0) ? hero.itemId : null })
                }} />
        </div>
        <InventorySelector type={0} />
        <InventorySelector type={1} />
        <InventorySelector type={2} />
        <InventorySelector type={3} />
        <InventorySelector type={4} />
        <InventorySelector type={5} />
        <InventorySelector type={6} />
    </fieldset>

}