import { Container, PixiComponent, Text } from "@saitonakamura/react-pixi";
import { ItemBlockColorVariants, ItemBlockColorKeys } from "entities/itemColors";
import { Graphics, TextStyle } from "pixi.js";
import { fieldLabelFontFamily, rarityFontColor, rarityFontSize, squareColorBlockAlpha, squareGap, squareSize } from "entities/generator";

interface IRarityText {
  rarity: string;
  width: number;
};

const RarityLabel = (props: IRarityText) => {
  return (
    <Text
      anchor={[0.5, 0]}
      x={props.width / 2}
      text={props.rarity}
      style={
        new TextStyle({
          fontFamily: fieldLabelFontFamily,
          fontSize: rarityFontSize,
          fill: rarityFontColor,
          align: "center",
        })
      }
    />
  )
};

interface ISquareColorBlock {
  color: ItemBlockColorKeys;
  x: number;
  y: number;
};

const SquareColorBlock = PixiComponent<ISquareColorBlock, Graphics>("SquareColorBlock", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(+`0x${ItemBlockColorVariants[props.color].slice(1)}`, squareColorBlockAlpha);
    ins.drawRoundedRect(props.x, props.y, squareSize, squareSize, 5);
    ins.endFill();
  }
});

interface IColorAndRarityBlock {
  colors: ItemBlockColorKeys[];
  x: number;
  y: number;
  width: number;
  rarity: string;
};

export const ColorAndRarityBlock = ({ x, y, colors, rarity, width }: IColorAndRarityBlock) => {
  return (
    <Container
      position={[x, y]}
    >
      <RarityLabel
        rarity={rarity}
        width={width}
      />
      {colors.map((color, index) => {
        return (
          <SquareColorBlock
            key={index}
            color={color}
            x={index * (squareSize + squareGap)}
            y={squareSize + squareGap}
          />
        )
      })}
    </Container>
  )
}
