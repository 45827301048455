import { VerticalDivider } from "shared/ui";
import { AddressStringWithLabel } from "entities/AddressStringWithLabel";
import { UserAccPageHeader } from "entities/UserAccPageHeader";
import { AddressString } from "features/AddressString";
import { ReactComponent as IconSettings } from "app/icons/Title_SETTINGS_white.svg";
import { getAccountData } from "app/core/fetchGraphQL";
import { H2QContext } from "app/core/H2QContext";
import { useContext, useEffect, useState } from "react";
import styles from "./Settings.module.scss";

export default function Settings() {
  const accountId = localStorage.getItem("currentAddress") || "";
  const [superRootAddr, setSuperRootAddr] = useState<string>("");
  const [superRootPubKey, setSuperRootPubKey] = useState<string>("");
  const { h2qAccount } = useContext(H2QContext);

  useEffect(() => {
    const getSuperRootParams = async () => {
      if (h2qAccount.contracts.superRoot) {
        const result = await getAccountData(h2qAccount.contracts.superRoot);
        const srAddr = await h2qAccount.contracts.superRoot.getAddress();
        setSuperRootPubKey(prev => result._pubkey);
        setSuperRootAddr(prev => srAddr)
      }
    };

    getSuperRootParams();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconSettings />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>
        <h3>User Account Address</h3>

        <div style={{ width: "74rem" }}>
          <AddressString
            value={accountId}
            valueColor="white"
            bg="transparent"
            padding={false}
            isLink={true}
          />
        </div>

        <VerticalDivider value={8} />

        <div style={{ width: "75.5rem" }}>
          <AddressStringWithLabel label="Public key">
            <AddressString
              value={accountId}
              bg={"darker"}
              valueColor={"grey"}
            />
          </AddressStringWithLabel>
        </div>

        <VerticalDivider value={38} />

        <div className={styles["two-address-block"]}>
          <h2>Super Root</h2>
          <div className={styles["two-address"]}
            style={{ backgroundColor: "#591E27" }}
          >
            <AddressStringWithLabel label="Address">
              <AddressString
                value={superRootAddr}
                bg={"dark"}
                valueColor={"white"}
                isLink={true}
              />
            </AddressStringWithLabel>
            <VerticalDivider value={18} />
            <AddressStringWithLabel label="Public key">
              <AddressString
                value={superRootPubKey}
                bg={"dark"}
                valueColor={"grey"}
              />
            </AddressStringWithLabel>
          </div>
        </div>

        <div className={styles["two-address-block"]}>
          <h2>H2Q-ROOT</h2>
          <div className={styles["two-address"]}
            style={{ backgroundColor: "#182254" }}
          >
            <AddressStringWithLabel label="Address">
              <AddressString
                value={h2qAccount.data.m_h2qWallet!}
                bg={"dark"}
                valueColor={"white"}
                isLink={true}
              />
            </AddressStringWithLabel>
            {/* <VerticalDivider value={25} />
            <AddressStringWithLabel
              label="Public key"
              value={h2qAccount.data.m_h2qWallet!}
              bg="dark"
              valueColor="grey"
            /> */}
          </div>
        </div>

        <div className={styles["two-address-block"]}>
          <h2>QST-ROOT</h2>
          <div className={`${styles["two-address"]} ${styles["bottom-shadow"]}`}
            style={{ backgroundColor: "#463720" }}
          >
            <AddressStringWithLabel
              label="Address"
            >
              <AddressString
                value={h2qAccount.data.m_qstWallet!}
                bg={"dark"}
                valueColor={"white"}
                isLink={true}
              />
            </AddressStringWithLabel>
            {/* <VerticalDivider value={25} />
            <AddressStringWithLabel
              label="Public key"
              value={h2qAccount.data.m_qstWallet!}
              bg="dark"
              valueColor="grey"
            /> */}
          </div>
        </div>

      </div>
    </div >)
}
