import { H2QuestResponse } from "app/core/H2QQuestManager";

type QuestInfo = {
  startTime: number;
  finishTime: number;
  maxParticipants: number;
  coinsLeftH2Q: number;
  coinsPoolH2Q: number;
  coinsLeftQST: number;
  coinsPoolQST: number;
};

export const calcQuestInfo = (questData: H2QuestResponse): QuestInfo => {
  const result = {} as QuestInfo;

  result.startTime = questData.m_questParams.mandatoryParams.startTime * 1000;

  result.finishTime = questData.m_questParams.mandatoryParams.finishTime * 1000;

  result.maxParticipants = Number(questData.m_maxParticipants);

  result.coinsPoolH2Q = Number(questData.m_totalH2QReward);
  result.coinsLeftH2Q = result.maxParticipants > 0
    ? (Math.floor(result.coinsPoolH2Q * (result.maxParticipants - parseInt(questData.m_totalClientsLocked, 16))
      / result.maxParticipants
    ))
    : result.coinsPoolH2Q;

  result.coinsPoolQST = Number(questData.m_totalQstReward);
  result.coinsLeftQST = result.maxParticipants > 0
    ? (Math.floor(result.coinsPoolQST * (result.maxParticipants - parseInt(questData.m_totalClientsLocked, 16))
      / result.maxParticipants
    ))
    : result.coinsPoolQST;

  return result;
}