import { useNavigate } from "react-router-dom";
import styles from "./BackLink.module.scss";

interface IBackLink {
  marBottom?: number;
  backLink?: string;
};

export const BackLink = ({ marBottom = 10, backLink }: IBackLink) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (backLink) {
      navigate(backLink);
      return;
    }

    navigate(-1);
  };

  return (
    <p
      style={{ marginBottom: `${marBottom}px` }}
      className={styles["back-link"]}
      onClick={goBack}>&#10094; Back</p>
  )
}
