import { ReactNode } from "react";
import styles from "./ItemSidebarContainer.module.scss";

interface IItemSidebarContainer {
  image: ReactNode;
  info: ReactNode;
  tags?: ReactNode;
};

export const ItemSidebarContainer = ({ image, info, tags }: IItemSidebarContainer) => {
  return (
    <div className={styles["side-asset-container"]}>
      <div className={styles.image}>
        {image}
      </div>
      <div className={styles.info}>
        {info}
      </div>
      {tags &&
        <div className={styles.tags}>
          {tags}
        </div>
      }
    </div>
  )
};