import { H2QContext } from 'app/core/H2QContext';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react'
import CountUp from 'react-countup';

interface IWaitingPercent {
  maxValue: number;
};

export const WaitingPercent = observer(function ({ maxValue }: IWaitingPercent) {
  const { h2qAccount } = useContext(H2QContext);

  const messagesLength = h2qAccount.logMessages.length;

  const minimunValue = 0;

  return (
    <CountUp
      start={minimunValue}
      end={Math.min(Math.ceil(messagesLength * 100 / maxValue), 100)}
      preserveValue={true}
      delay={0}
      duration={0.5}
      suffix="%"
    >
      {({ countUpRef }) => (
        <p style={{ fontWeight: 400, margin: 0 }}>
          <span ref={countUpRef} />
        </p>
      )}
    </CountUp>
  )
});