import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useEffect, useRef } from "react";
import CountUp from "react-countup";
import styles from "./Balances.module.scss";

export const Balances = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const currentH2qVal = (Number(h2qAccount.data.m_h2qBalance) - Number(h2qAccount.data.m_lockedH2QTokens));
  const prevH2qVal = useRef((Number(h2qAccount.data.m_h2qBalance) - Number(h2qAccount.data.m_lockedH2QTokens)));

  const currentQstVal = (Number(h2qAccount.data.m_qstBalance) - Number(h2qAccount.data.m_lockedQSTokens));
  const prevQstVal = useRef((Number(h2qAccount.data.m_qstBalance) - Number(h2qAccount.data.m_lockedQSTokens)));

  useEffect(() => {
    prevH2qVal.current = currentH2qVal;
    prevQstVal.current = currentQstVal;
  }, [h2qAccount.data, currentH2qVal, currentQstVal]);

  return (
    <>
      <div className={styles["top-bar__stats"]}>
        <span className={styles.h2q}>H2Q</span>
        {/* {h2qAccount.loadData.match({
          pending: () => <span className={styles["stats-value"]} >...</span>,
          resolved: () => <span className={styles["stats-value"]}>{
            (Number(h2qAccount.data.m_h2qBalance) - Number(h2qAccount.data.m_lockedH2QTokens)) || "---"}</span>,
        })} */}

        <CountUp
          start={prevH2qVal.current}
          end={currentH2qVal}
          preserveValue={true}
          delay={0}
          duration={2}
          separator=""
        >
          {({ countUpRef }) => (
            <span ref={countUpRef} className={styles["stats-value"]} ></span>
          )}
        </CountUp>
      </div>
      <div className={styles["top-bar__stats"]}>
        <span className={styles.qst}>QST</span>
        <CountUp
          start={prevQstVal.current}
          end={currentQstVal}
          preserveValue={true}
          delay={0}
          duration={2}
          separator=""
        >
          {({ countUpRef }) => (
            <span ref={countUpRef} className={styles["stats-value"]} ></span>
          )}
        </CountUp>
        {/* {h2qAccount.loadData.match({
          pending: () => <span className={styles["stats-value"]}>...</span>,
          resolved: () => <span className={styles["stats-value"]}>{
            (Number(h2qAccount.data.m_qstBalance) - Number(h2qAccount.data.m_lockedQSTokens))
            || "---"}</span>,
        })} */}
      </div>
      {/* <button className={styles["top-bar__connect"]}>WALLET CONNECT</button> */}
    </>
  )
});
