import { CSSProperties, useEffect, useState } from 'react'
import { delay, getTimeFromMilliSeconds, timeToDdHhMmSs } from "shared/lib";
import styles from "./Timer.module.scss";

interface ITimer {
  finishTime: number; //ms
  size?: number;
  color?: "white" | "gold";
  onFinishAction?: () => void;
  messageType?: "quest" | "heroInCurrentQuest" | "heroInOtherQuest";
  style?: CSSProperties;
};

export const Timer = ({ finishTime, size = 14, color = "gold", onFinishAction, messageType = "quest", style }: ITimer) => {
  const [time, setTime] = useState(Date.now());

  const timeEndString = timeToDdHhMmSs(getTimeFromMilliSeconds(finishTime - Date.now()), true);

  const white = "#dadada";
  const gold = "#fec771";

  const textColor = color === "gold" ? gold : white;

  useEffect(() => {
    const changeTimeEnd = async () => {
      if (finishTime && (finishTime - Date.now() > 0)) {
        await delay(1000);
        setTime(Date.now());
      }
      if (finishTime && (finishTime - Date.now() < 0)) {
        // console.log("Complete");
        if (onFinishAction) onFinishAction();
      }
    };

    changeTimeEnd();
    // eslint-disable-next-line
  }, [time]);

  const finishMessage = messageType === "heroInCurrentQuest"
    ? "Quest completed"
    : messageType === "heroInOtherQuest"
      ? "Another quest completed"
      : "Quest expired";

  const styleProps = style ?
    { ...style, fontSize: `${size / 10}rem`, color: textColor }
    : { fontSize: `${size / 10}rem`, color: textColor };

  return (
    <p className={styles["timer-value"]}
      style={styleProps}
    >
      {finishTime - Date.now() > 0 ? timeEndString : finishMessage}
    </p>
  )
}