const PseudoNftPlatformContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "pk",
                        "type": "uint256"
                    },
                    {
                        "name": "nonce",
                        "type": "uint256"
                    },
                    {
                        "name": "code",
                        "type": "cell"
                    },
                    {
                        "components": [
                            {
                                "name": "imageHash",
                                "type": "uint256"
                            },
                            {
                                "name": "genPrice",
                                "type": "uint128"
                            },
                            {
                                "name": "valPrice",
                                "type": "uint128"
                            },
                            {
                                "name": "generation",
                                "type": "uint32"
                            },
                            {
                                "name": "clone_id",
                                "type": "uint32"
                            },
                            {
                                "name": "experience",
                                "type": "uint256"
                            },
                            {
                                "name": "level",
                                "type": "uint8"
                            },
                            {
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "name": "generator_id",
                                "type": "string"
                            }
                        ],
                        "name": "metaChar",
                        "type": "tuple"
                    },
                    {
                        "name": "price",
                        "type": "uint128"
                    }
                ],
                "outputs": []
            },
            {
                "name": "updateCode",
                "inputs": [],
                "outputs": []
            },
            {
                "name": "m_h2qTrueCharacter",
                "inputs": [],
                "outputs": [
                    {
                        "components": [
                            {
                                "name": "avatar_id",
                                "type": "uint8"
                            },
                            {
                                "name": "inventory",
                                "type": "uint8[]"
                            }
                        ],
                        "name": "m_h2qTrueCharacter",
                        "type": "tuple"
                    }
                ]
            },
            {
                "name": "m_nftDeployer",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nftDeployer",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_h2qMetaCharacter",
                "inputs": [],
                "outputs": [
                    {
                        "components": [
                            {
                                "name": "imageHash",
                                "type": "uint256"
                            },
                            {
                                "name": "genPrice",
                                "type": "uint128"
                            },
                            {
                                "name": "valPrice",
                                "type": "uint128"
                            },
                            {
                                "name": "generation",
                                "type": "uint32"
                            },
                            {
                                "name": "clone_id",
                                "type": "uint32"
                            },
                            {
                                "name": "experience",
                                "type": "uint256"
                            },
                            {
                                "name": "level",
                                "type": "uint8"
                            },
                            {
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "name": "generator_id",
                                "type": "string"
                            }
                        ],
                        "name": "m_h2qMetaCharacter",
                        "type": "tuple"
                    }
                ]
            },
            {
                "name": "m_pk",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_pk",
                        "type": "uint256"
                    }
                ]
            },
            {
                "name": "m_nonce",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nonce",
                        "type": "uint256"
                    }
                ]
            },
            {
                "name": "m_code",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_code",
                        "type": "cell"
                    }
                ]
            },
            {
                "name": "m_price",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_price",
                        "type": "uint128"
                    }
                ]
            }
        ],
        "data": [
            {
                "components": [
                    {
                        "name": "avatar_id",
                        "type": "uint8"
                    },
                    {
                        "name": "inventory",
                        "type": "uint8[]"
                    }
                ],
                "key": 1,
                "name": "m_h2qTrueCharacter",
                "type": "tuple"
            },
            {
                "key": 2,
                "name": "m_nftDeployer",
                "type": "address"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "components": [
                    {
                        "name": "avatar_id",
                        "type": "uint8"
                    },
                    {
                        "name": "inventory",
                        "type": "uint8[]"
                    }
                ],
                "name": "m_h2qTrueCharacter",
                "type": "tuple"
            },
            {
                "name": "m_nftDeployer",
                "type": "address"
            },
            {
                "components": [
                    {
                        "name": "imageHash",
                        "type": "uint256"
                    },
                    {
                        "name": "genPrice",
                        "type": "uint128"
                    },
                    {
                        "name": "valPrice",
                        "type": "uint128"
                    },
                    {
                        "name": "generation",
                        "type": "uint32"
                    },
                    {
                        "name": "clone_id",
                        "type": "uint32"
                    },
                    {
                        "name": "experience",
                        "type": "uint256"
                    },
                    {
                        "name": "level",
                        "type": "uint8"
                    },
                    {
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "name": "generator_id",
                        "type": "string"
                    }
                ],
                "name": "m_h2qMetaCharacter",
                "type": "tuple"
            },
            {
                "name": "m_pk",
                "type": "uint256"
            },
            {
                "name": "m_nonce",
                "type": "uint256"
            },
            {
                "name": "m_code",
                "type": "cell"
            },
            {
                "name": "m_price",
                "type": "uint128"
            }
        ]
    },
    tvc: "te6ccgECJQEABTsAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gshBQQeA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8DQoGA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPCAgBgM8IIIQToxnC7vjAiCCEGRUqhK74wIgghB2okbKuuMCEwgHAU4w0ds8+E8hjhuNBHAAAAAAAAAAAAAAAAA9qJGyoMjOzMlw+wDe8gAfBFAgghBPld2iuuMCIIIQUMfyYrrjAiCCEF1OIFC64wIgghBkVKoSuuMCEhEQCQOmMPhCbuMA+EbycyGT1NHQ3tP/0//U0//Tf9TR0NN/0x/TH9P/0wfU1FWAbwkB03/R+CdvEIIQdzWUALzy4Gv4AFj4b/hwWPhtAfhu+GzbPNs88gAKGSQCFu1E0NdJwgGOgOMNCx8ENnDtRND0BXEhgED0DuMPciKAQPQOjoGJ33BfYA8ODQwCSIggbwlwIIhw+HD4b/hu+G34bPhr+GqAQPQO8r3XC//4YnD4Yx4eAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA5wIG1vAm8CABrTB9Mf9ARZbwIB0W8CAVAw0ds8+E0hjhyNBHAAAAAAAAAAAAAAAAA3U4gUIMjOy//JcPsA3vIAHwFQMNHbPPhOIY4cjQRwAAAAAAAAAAAAAAAANDH8mKDIzsv/yXD7AN7yAB8BaDDR2zz4SiGOKI0EcAAAAAAAAAAAAAAAADPld2igyM4BbyICywcBbyICyx/0AMlw+wDe8gAfBFAgghAMeu4duuMCIIIQDOVBg7rjAiCCEEDfFYC64wIgghBOjGcLuuMCFxYVFAF2MNHbPPhMIY4vjQRwAAAAAAAAAAAAAAAAM6MZwuDIzgFvKV6Ay//Lf8t/yx/LH8v/ywfMzMlw+wDe8gAfAU4w0ds8+EshjhuNBHAAAAAAAAAAAAAAAAAwN8VgIMjOzslw+wDe8gAfAVAw0ds8+FAhjhyNBHAAAAAAAAAAAAAAAAAjOVBg4MjOy3/JcPsA3vIAHwMkMPhG8uBM+EJu4wDR2zzjAPIAHxkYACjtRNDT/9M/MfhDWMjL/8s/zsntVAF8+AD4ScjO+E/QAcnbPCD7BNAgizits1jHBZPXTdDe10zQ7R7tU/hP+En4TfhO+Ev4SvhM+FCCEGqejVvtQ9gaAhYhizits1jHBYqK4hwbAQgB2zzJHQEmAdTUMBLQ2zzIz44rbNYSzM8RyR0BdtWLL0pA1yb0BNMJMSDXSpHUjoKIAeKLX0vfLATo1yYwAcjPi9KQ9ACAIM8LCc+X0vfLATrMEszIzxHOHgAAAJztRNDT/9M/0wAx0wfTH/QEWW8CEm8CAfpA1NHQ0//Tf9N/0x/TH9P/0wfU1FWAbwkB1NHQ0//T/9TTf9H4cPhv+G74bfhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLATiMiABRzb2wgMC42Ni4wARqgap6NW18I2zz4D/IAJACe+FD4T/hO+E34TPhL+Er4Q/hCyMv/yz/PgwFvIgLLBwFvIgLLH/QAzgFvKV6AVcDIy//Lf8t/yx/LH8v/ywfMzFUwyMv/y//My3/NzcntVA==",
    code: "te6ccgECIgEABQ4ABCSK7VMg4wMgwP/jAiDA/uMC8gseAgEbA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8CgcDA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPB0dAwM8IIIQToxnC7vjAiCCEGRUqhK74wIgghB2okbKuuMCEAUEAU4w0ds8+E8hjhuNBHAAAAAAAAAAAAAAAAA9qJGyoMjOzMlw+wDe8gAcBFAgghBPld2iuuMCIIIQUMfyYrrjAiCCEF1OIFC64wIgghBkVKoSuuMCDw4NBgOmMPhCbuMA+EbycyGT1NHQ3tP/0//U0//Tf9TR0NN/0x/TH9P/0wfU1FWAbwkB03/R+CdvEIIQdzWUALzy4Gv4AFj4b/hwWPhtAfhu+GzbPNs88gAHFiECFu1E0NdJwgGOgOMNCBwENnDtRND0BXEhgED0DuMPciKAQPQOjoGJ33BfYAwLCgkCSIggbwlwIIhw+HD4b/hu+G34bPhr+GqAQPQO8r3XC//4YnD4YxsbAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA5wIG1vAm8CABrTB9Mf9ARZbwIB0W8CAVAw0ds8+E0hjhyNBHAAAAAAAAAAAAAAAAA3U4gUIMjOy//JcPsA3vIAHAFQMNHbPPhOIY4cjQRwAAAAAAAAAAAAAAAANDH8mKDIzsv/yXD7AN7yABwBaDDR2zz4SiGOKI0EcAAAAAAAAAAAAAAAADPld2igyM4BbyICywcBbyICyx/0AMlw+wDe8gAcBFAgghAMeu4duuMCIIIQDOVBg7rjAiCCEEDfFYC64wIgghBOjGcLuuMCFBMSEQF2MNHbPPhMIY4vjQRwAAAAAAAAAAAAAAAAM6MZwuDIzgFvKV6Ay//Lf8t/yx/LH8v/ywfMzMlw+wDe8gAcAU4w0ds8+EshjhuNBHAAAAAAAAAAAAAAAAAwN8VgIMjOzslw+wDe8gAcAVAw0ds8+FAhjhyNBHAAAAAAAAAAAAAAAAAjOVBg4MjOy3/JcPsA3vIAHAMkMPhG8uBM+EJu4wDR2zzjAPIAHBYVACjtRNDT/9M/MfhDWMjL/8s/zsntVAF8+AD4ScjO+E/QAcnbPCD7BNAgizits1jHBZPXTdDe10zQ7R7tU/hP+En4TfhO+Ev4SvhM+FCCEGqejVvtQ9gXAhYhizits1jHBYqK4hkYAQgB2zzJGgEmAdTUMBLQ2zzIz44rbNYSzM8RyRoBdtWLL0pA1yb0BNMJMSDXSpHUjoKIAeKLX0vfLATo1yYwAcjPi9KQ9ACAIM8LCc+X0vfLATrMEszIzxHOGwAAAJztRNDT/9M/0wAx0wfTH/QEWW8CEm8CAfpA1NHQ0//Tf9N/0x/TH9P/0wfU1FWAbwkB1NHQ0//T/9TTf9H4cPhv+G74bfhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLATiAfABRzb2wgMC42Ni4wARqgap6NW18I2zz4D/IAIQCe+FD4T/hO+E34TPhL+Er4Q/hCyMv/yz/PgwFvIgLLBwFvIgLLH/QAzgFvKV6AVcDIy//Lf8t/yx/LH8v/ywfMzFUwyMv/y//My3/NzcntVA==",
    codeHash: "242b5b0ec78b655f2147af8e2fb81c0e8ce494f33aa69576b8263339548589a5",
};
module.exports = { PseudoNftPlatformContract };