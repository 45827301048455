import { FC, PropsWithChildren } from "react";
import styles from "./AddressStringWithLabel.module.scss";

interface IAddressStringWithLabel {
  label: string;
};

export const AddressStringWithLabel: FC<PropsWithChildren<IAddressStringWithLabel>> = ({ label, children }) => {
  return (
    <div className={styles["block-wrapper"]}>
      <label>{label}</label>
      {children}
    </div>
  )
};