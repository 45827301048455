import { AvatarImageSVG } from 'entities/avatar/ui/AvatarImage/AvatarImage';
import { AvatarElement } from "entities/avatar";
import styles from "./AvatarCard.module.scss";

interface IAvatarCard {
  item: AvatarElement;
  process?: (id: number) => void;
  isImgMoved?: boolean;
  isFadeActive?: boolean;
};

export const AvatarCard = ({ item, process = () => { }, isImgMoved = false, isFadeActive = false }: IAvatarCard) => {
  return (
    <>
      <div
        className={`${styles["item-link"]} ${isFadeActive ? styles.fade : ""}`}
        onClick={() => process(item.itemId)}
      >
        <AvatarImageSVG avatarImg={item} isImgMoved={isImgMoved} />
      </div>
    </>
  )
}
