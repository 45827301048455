import styles from "./FilterCloseButton.module.scss";
import { ReactComponent as IconClose } from "app/icons/icon-cancel-white.svg";

interface IFilterCloseButton {
  name: string;
  clickAction: () => void;
};

export default function FilterCloseButton({ name, clickAction }: IFilterCloseButton) {
  const onClickBtn = () => {
    clickAction();
  };

  return (
    <button
      className={styles["close-item-btn"]}
      onClick={onClickBtn}
    >
      <p>
        {name}
      </p>
      <IconClose />
    </button>
  )
}