import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { H2QContext } from "app/core/H2QContext";
import styles from "./LoginSetPin.module.scss";
import { Fade } from "react-awesome-reveal";
import { Button, RotatingCircle } from "shared/ui";
import { InputPIN } from "features/LoginAndCreateAccount";

export const LoginSetPin = observer(function () {
  const [pin, setPin] = useState<string[]>(() => new Array(6).fill(null).map(p => ""));

  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();

  // todo
  const createPinPhrase = (h2qAccount.loginToAccount.state === "pending" ?
    <RotatingCircle size={31} thick={3} />
    :
    <p style={{ textTransform: "uppercase", padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>
      Create PIN
    </p>);

  const login = async () => {
    try {
      const phrase = localStorage.tempSeed;
      h2qAccount.setPin(pin.join(""));
      await h2qAccount.loginToAccount(phrase);
      navigate("/h2q/generator");
    }
    catch (error) {
      navigate("/error-login")
    }
  };

  const isPinEntered = pin.filter(p => p !== "").length === 6;

  return (
    <div className={styles["create-account__wrapper"]}>
      <Link to={".."}>
        <span>&#8592;</span>Go back
      </Link>
      <h2>Set PIN code for your account</h2>

      <InputPIN
        pin={pin}
        setPin={setPin}
      />

      {isPinEntered &&
        <Fade
          direction="up"
          duration={500}
        >
          <Button
            colorValue="green"
            widthValue="medium"
            children={createPinPhrase}
            isActive={isPinEntered}
            action={login}
          />
        </Fade>
      }
    </div>
  );
});
