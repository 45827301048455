import { H2QContext } from "app/core/H2QContext";
import { EpochsList } from "entities/questMaker";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";

export const AddQuestNew = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  useEffect(() => {
    if (!h2qAccount.inventoryInfoFromBC) {
      h2qAccount.getInventoryDetails();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <EpochsList />
  )
});
