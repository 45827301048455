import { H2QContext } from "app/core/H2QContext";
import { ItemElement, ItemImageSVG, ItemListElement, parts } from "entities/item";
import { ItemInfoMainTab, ItemTags } from "entities/nft";
import { ItemSidebarContainer, TSidebarInfo } from "entities/rigthSideBar";
import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { RotatingCircleCenter } from "shared/ui";
import styles from "./ItemList.module.scss";

export const ItemList = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const items = h2qAccount.uiStore.itemsToShow;
  const isLoading = h2qAccount.loadData.state === "pending";

  const onFavClick = (item: ItemElement) => {
    h2qAccount.uiStore.changeItemFilterFavourites(item.categoryId, item.itemId);
  };

  const openSideBarInfo = async (item: ItemElement) => {
    const newSelectedKey = `${item.categoryId}-${item.itemId}`;

    // if sidebar already opened and show the same item then close it:
    if (newSelectedKey === h2qAccount.uiStore.rightSidebarChild.key && h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      return;
    }

    if (h2qAccount.inventoryInfoFromBC === null) {
      await h2qAccount.getInventoryDetails();
    };

    const selectedItem = {} as TSidebarInfo;
    const nftItemInfo = h2qAccount.inventoryInfoFromBC![item.itemId];

    selectedItem.image = <ItemImageSVG iconLink={item.iconThumbLink} />;
    selectedItem.info = < ItemInfoMainTab itemInfo={nftItemInfo} categoryId={item.categoryId} itemId={item.itemId} title={parts[item.categoryId]} showTags={false} />;
    selectedItem.tags = <ItemTags categoryId={item.categoryId} itemId={item.itemId} itemInfo={nftItemInfo} />

    h2qAccount.uiStore.setRightSidebarChild(
      newSelectedKey,
      <ItemSidebarContainer
        image={selectedItem.image}
        info={selectedItem.info}
        tags={selectedItem.tags}
      />
    );

    if (!h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(true);
    };
  };

  useEffect(() => {
    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && <RotatingCircleCenter />}
      {items.length &&
        <div
          className={styles["item-gallery__wrapper"]}
          style={{
            gridTemplateColumns: `repeat(${h2qAccount.uiStore.itemsInRowCount}, 1fr)`
          }}
        >
          {items.map((item, index) => {
            const key = `${item.categoryId}-${item.itemId}`;
            return (
              <React.Fragment
                key={key}
              >
                <Fade
                  direction="up"
                  delay={75 * (index % 4)}
                  fraction={0.1}
                  triggerOnce
                  duration={800}
                >
                  <ItemListElement
                    item={item}
                    isFav={h2qAccount.uiStore.itemFilter.favItems[item.categoryId].includes(item.itemId)}
                    onFavClick={onFavClick}
                    onItemClick={openSideBarInfo}
                    isSelected={h2qAccount.uiStore.rightSidebarChild.key === key}
                  />
                </Fade>
              </React.Fragment>
            );
          })}
        </div>}
      {items && items.length === 0 && (
        <div className={styles["empty-gallery"]}>
          <p>You have no items yet.</p>
        </div>
      )}
    </>
  )
});