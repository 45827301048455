// db.ts
import Dexie, { Table } from 'dexie';
import { TrueCharacter } from "entities/nft";

interface GeneratedPendingNFT extends TrueCharacter {
    id?: number;
    pseudoNFTAddr: string;
}

export class H2QDexie extends Dexie {
    nfts!: Table<GeneratedPendingNFT>;

    constructor() {
        super('h2q');
        this.version(1).stores({
            nfts: '++id, pseudoNFTAddr' // Primary key and indexed props
        });
    }
}

export const h2qDB = new H2QDexie();