import { QuestFloatingStats } from "widgets";
import { ReloadButton, RotatingCircleCenter } from "shared/ui";
import { QuestList } from "widgets";
import { QuestsTotalStats } from "widgets";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import styles from "./QuestsPage.module.scss";
import { ReactComponent as IconQuest } from "app/icons/Logo_QUEST_white.svg";
import { PageTitle } from "entities/PageTitle";
import { QuestViewControls } from "features/questFilter";

export const QuestsPage = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const [isQuestsFold, toggleQuestsFold] = useState<boolean>(false);
  const quests = h2qAccount.uiStore.questsToShow;

  useEffect(() => {
    const loadQuests = () => {
      console.log("Load all quests");
      if (h2qAccount.loadAllQuests.state !== "pending") {
        h2qAccount.loadAllQuests();
      }
    };

    h2qAccount.uiStore.changeQuestFilterExpectedMode(false);
    loadQuests();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["page-wrapper"]}>
      {h2qAccount.loadAllQuests.state === "pending" &&
        <RotatingCircleCenter />
      }
      <PageTitle>
        <IconQuest style={{
          marginRight: "2rem",
        }} />
        <ReloadButton
          reloadAction={() => {
            h2qAccount.loadAllQuests();
            h2qAccount.getTotalNfts();
          }}
          isWaiting={h2qAccount.loadAllQuests.state === "pending"}
        />
      </PageTitle>
      <QuestFloatingStats quests={quests} />
      <QuestsTotalStats quests={quests}>
        <QuestViewControls
          isQuestsFold={isQuestsFold}
          toggleQuestsFold={() => toggleQuestsFold(prev => !prev)}
        />
      </QuestsTotalStats>
      {quests.length !== 0
        ? <QuestList quests={quests} isAllQuestsFold={isQuestsFold} isStakedMode={h2qAccount.uiStore.questFilter.stakedMode} />
        : <p className={styles["no-data"]}>
          No quests are available
        </p>}
    </div>
  )
})