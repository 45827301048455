import { H2QContext } from "app/core/H2QContext";
import { getNftInProgress, NftListElement, NftListTempElement } from "entities/nft";
import { observer } from "mobx-react";
import { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { RotatingCircleCenter } from "shared/ui";
import styles from "./NftList.module.scss";

export const NFTList = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const nftItems = h2qAccount.uiStore.nftsToShow;
  const nftInProgress = getNftInProgress(h2qAccount.nftsInProgress);
  const navigate = useNavigate();

  const onFavClick = (id: string) => {
    h2qAccount.uiStore.changeNftFilterFavourites(id);
  };

  const goToNftAvailableQuests = (nftId: string) => {
    navigate(`/h2q/quests/for-nft-id/${nftId.slice(2)}`);
  };

  return (
    <>
      {h2qAccount.fetchNTFs.state === "pending" && (
        <RotatingCircleCenter />
      )}
      <div
        className={styles["nft-items"]}
        style={{
          gridTemplateColumns: `repeat(${h2qAccount.uiStore.nftsInRowCount}, 1fr)`
        }}
      >
        {nftInProgress !== null && nftInProgress.length > 0 &&
          nftInProgress.map((nft, index) => {
            return (
              <Fade
                key={nft.pseudoNFTAddr}
                direction="up"
                delay={75 * (index % 4)}
                fraction={0.1}
                triggerOnce
                duration={800}
              >
                <NftListTempElement
                  nft={nft}
                />
              </Fade>
            )
          })
        }
        {nftItems && nftItems.length > 0 &&
          nftItems.map((nft, index) => {
            return (
              <Fade
                key={nft.pseudoNFTAddr}
                direction="up"
                delay={75 * (nftInProgress.length + index % 4)}
                fraction={0.1}
                triggerOnce
                duration={800}
              >
                <NftListElement
                  nft={nft}
                  isFav={h2qAccount.uiStore.nftFilter.favNfts.includes(nft.pseudoNFTAddr)}
                  onChooseClick={goToNftAvailableQuests}
                  onFavClick={onFavClick}
                />
              </Fade>
            );
          })}
      </div>
      {nftItems && nftItems.length === 0 &&
        <div className={styles["empty-gallery"]}>
          <p>
            {nftInProgress.length === 0
              ? "You have no NFTs yet."
              : "Your NFTs are being generated"
            }
          </p>
          <p>Try to reload NFTs' information.</p>
        </div>
      }
    </>
  )
});