import { parts } from "entities/item";
import { NftElement } from "entities/nft";
import { useEffect, useRef, useState } from "react";
import styles from "./NftImage.module.scss";
import loadingIcon from "app/icons/icon-refresh-white.svg";

const rightMargin = 14;

interface INftSvgImage {
  item: NftElement;
  hasBgColor?: boolean;
};

export function NftImageSVG({ item, hasBgColor = false }: INftSvgImage) {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const rotatingSize = 50;

  const totalImagesInNft = Object.values(item).filter(i => i).length // item images
    + 1 // compulsory torso image
    + (!(item.avatar.headThumbLink === "null" || item.avatar.headThumbLink === null) ? 1 : 0); // has separate head image?

  type ImagesForNft = {
    [K in typeof parts[number]]: boolean;
  } & { Torso: boolean, TorsoHead?: boolean };

  const loadedImages = useRef({} as ImagesForNft);

  const onLoadImage = () => {
    if (totalImagesInNft === Object.values(loadedImages.current).filter(i => i).length) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (totalImagesInNft === Object.values(loadedImages.current).filter(i => i).length) {
      setLoaded(true);
    }
  }, [totalImagesInNft]);

  return (
    <svg viewBox="0 0 512 512"
      onLoad={onLoadImage}
      width={512}
      height={512}
      style={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "100%",
        zIndex: 1,
        backgroundColor: `${hasBgColor ? "#1a1d2e" : ""}`
      }}
    >
      {/* Loading image */}
      {
        !isLoaded &&
        <image className={styles.loading} href={loadingIcon} width={rotatingSize} height={rotatingSize} x={256 - rotatingSize / 2} y={256 - rotatingSize / 2} />
      }
      {/* Torso */}
      <image style={{ "display": isLoaded ? "inherit" : "none" }} onLoad={() => loadedImages.current.Torso = true} href={item.avatar.imageThumbLink} width={512} height={512} x={`-${rightMargin}%`} y={0} />
      {/* Head */}
      {item.avatar.headThumbLink &&
        <svg onLoad={() => loadedImages.current.TorsoHead = true} viewBox="0 0 512 512">
          <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.avatar.headThumbLink}
            x={`${item.avatar.headPos.X * 100 - rightMargin}%`}
            y={`${item.avatar.headPos.Y * 100}%`}
          />
        </svg>
      }
      {/* Legs */}
      <svg onLoad={() => loadedImages.current.Legs = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Legs?.imageThumbLink}
          x={`${item.items.Legs?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Legs?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Hips */}
      <svg onLoad={() => loadedImages.current.Hip = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Hip?.imageThumbLink}
          x={`${item.items.Hip?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Hip?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Body Suit */}
      <svg onLoad={() => loadedImages.current.Body = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Body?.imageThumbLink}
          x={`${item.items.Body?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Body?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Hat */}
      <svg onLoad={() => loadedImages.current.Head = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Head?.imageThumbLink}
          x={`${item.items.Head?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Head?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Accessory 1 */}
      <svg onLoad={() => loadedImages.current.Accessory1 = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Accessory1?.imageThumbLink}
          x={`${item.items.Accessory1?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Accessory1?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Accessory 2 */}
      <svg onLoad={() => loadedImages.current.Accessory2 = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Accessory2?.imageThumbLink}
          x={`${item.items.Accessory2?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Accessory2?.imagePos?.Y! * 100}%`}
        />
      </svg>
      {/* Accessory 3 */}
      <svg onLoad={() => loadedImages.current.Accessory3 = true} viewBox="0 0 512 512">
        <image style={{ "display": isLoaded ? "inherit" : "none" }} href={item.items.Accessory3?.imageThumbLink}
          x={`${item.items.Accessory3?.imagePos?.X! * 100 - rightMargin}%`}
          y={`${item.items.Accessory3?.imagePos?.Y! * 100}%`}
        />
      </svg>
    </svg >
  )
};

interface INFTItem {
  item: NftElement;
  hasBgColor?: boolean;
  isFadeActive?: boolean;
};

export const NftDivImage = ({ item, hasBgColor = false, isFadeActive = false }: INFTItem) => {
  return (
    <div
      className={`${styles['nft-image']} ${isFadeActive ? styles.fade : ""}`}
    >
      <NftImageSVG item={item} hasBgColor={hasBgColor} />
    </div>
  )
}