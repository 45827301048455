import { UserAccPageHeader } from "entities/UserAccPageHeader/UserAccPageHeader"
import { useState } from "react";
import { NotificationItem, notificationList } from "./notificationList";
import { ReactComponent as IconNotifcation } from "app/icons/Title_NOTIFICATION_white.svg";
import styles from "./Notifications.module.scss";
import { UserNotificationSelector } from "features/UserNotificationSelector";

export default function Notifications() {
  const [notifications, setNotifications] = useState<NotificationItem[]>(() => notificationList);

  const selectNotification = (id: number) => {
    const newNotifications = [...notifications].map(item => {
      item.isSelected = item.id === id ? !item.isSelected : item.isSelected
      return item;
    });

    setNotifications(prev => newNotifications);
  };

  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconNotifcation />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>
        <h3>Select wich notifications you would like to receive for ...</h3>

        {notifications.map(item => {
          return (
            <UserNotificationSelector
              key={item.id}
              title={item.title}
              description={item.description}
              isChecked={item.isSelected}
              action={() => selectNotification(item.id)}
            />
          )
        })}

      </div>
    </div>
  )
}