import { h2qAccount } from "app/core/H2QContext";
import { AvatarElement } from "entities/avatar";

export const filterAvatars = (avatars: AvatarElement[]): AvatarElement[] => {
  const avFilterByFav = filterAvatarByFav(avatars);
  const avFilterByGenType = filterAvatarByGenId(avFilterByFav);
  const avFilterByName = filterAvatarByName(avFilterByGenType);
  return avFilterByName;
};

const filterAvatarByFav = (avatars: AvatarElement[]): AvatarElement[] => {
  const favAvatars = h2qAccount.uiStore.avatarFilter.favAvatars;
  const favMode = h2qAccount.uiStore.avatarFilter.favMode;

  return !favMode
    ? avatars
    : favAvatars.length === 0
      ? avatars
      : avatars.filter(a => favAvatars.includes(a.itemId))
};

const filterAvatarByGenId = (avatars: AvatarElement[]): AvatarElement[] => {
  const genTypes = h2qAccount.uiStore.avatarFilter.genId;

  return genTypes.length === 0
    ? avatars
    : avatars.filter(a => genTypes.includes(a.genId!))
};

const filterAvatarByName = (avatars: AvatarElement[]): AvatarElement[] => {
  const avatarNames = h2qAccount.uiStore.avatarFilter.avatarName;

  return avatarNames.length === 0
    ? avatars
    : avatars.filter(a => avatarNames.includes(a.avatarName))
};