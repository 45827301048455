import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CopyPasteIcon } from "app/icons/icon-copy-black.svg";
import { observer } from "mobx-react";
import { Seed } from "./Seed";
import { Link } from "react-router-dom";
import { H2QContext } from "app/core/H2QContext";
import styles from "./CreateNewAccount.module.scss";
import { RotatingCircle } from "shared/ui";
import { Fade } from "react-awesome-reveal";
import { Button, CheckBox } from "shared/ui";

export const CreateNewAccountPage = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();
  const seedPhrase = h2qAccount.lastGeneratedSeed || "";
  const [isChecked, setChecked] = useState<boolean>(false);
  const [isSaved, setSaved] = useState<boolean>(false);

  const setCheckboxChecked = () => {
    if (isSaved) setChecked(!isChecked);
  };

  useEffect(() => {
    const generatePhrase = async () => {
      await h2qAccount.generateSeed();

      // if already existed phrase is equal to lastGeneratedSeed => generate the new one 
      if (h2qAccount.lastGeneratedSeed === h2qAccount.phrase) {
        h2qAccount.generateSeed();
      };
    };

    generatePhrase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CopySeedPhrase = (
    <p
      style={{ fontSize: "1.8rem", letterSpacing: "0.015rem", lineHeight: "2.1rem", padding: "0.5rem 0" }}
    >
      {!isSaved &&
        <CopyPasteIcon
          stroke="#dadada"
          fill="#dadada"
          style={{ verticalAlign: "-0.5rem", height: "2.1rem", marginRight: "1rem" }}
        />
      }
      {isSaved ? "Seed phrase saved!" : "Save seed phrase on disk!"}

    </p>
  );

  const LoginPhrase = (h2qAccount.createAccount.state === "pending" ?
    <RotatingCircle size={31} thick={3} /> :
    <p
      style={{ fontSize: "1.8rem", letterSpacing: "0.015rem", lineHeight: "2.1rem", padding: "0.5rem 0" }}
    >
      {isChecked ? "Create new account" : "Did you save your seed phrase?"}
    </p>
  );

  // const goToSetPinPhrase = <p style={{ fontSize: "1.8rem", letterSpacing: "0.015rem", lineHeight: "2.1rem", padding: "0.5rem 0" }}>Set your PIN</p>;

  const login = async () => {
    localStorage.setItem("tempSeed", seedPhrase);
    navigate("/login-page/setpin");

  };

  const saveSeed = () => {
    const blob = new Blob([seedPhrase], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = "hero2quest-seed-phrase.txt";

    if (window.webkitURL != null) {
      link.href = window.webkitURL.createObjectURL(blob);
    }
    else {
      link.href = window.URL.createObjectURL(blob);
    }

    link.click();
    URL.revokeObjectURL(link.href);
    setSaved(true);
  };

  return (
    <div className={styles["create-account__wrapper"]}>
      <Link to={"/login-page"}>
        <span>&#8592;</span>Go back
      </Link>
      <h2>Create new account</h2>
      <p>Write down this seed phrase on paper to keep access to the wallet</p>
      {
        h2qAccount.generateSeed.state === "pending"
          ? <div
            style={{
              width: "100%",
              height: "15rem",
              marginBottom: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <RotatingCircle />
          </div>
          : <Seed
            seed={seedPhrase}
            inProgress={h2qAccount.createAccount.state === "pending"}
          />
      }
      <Button
        colorValue={`${isSaved ? "darkGrey" : "red"}`}
        widthValue="medium"
        children={CopySeedPhrase}
        isActive={true}
        action={saveSeed}
      />
      {isSaved &&
        <Fade
          direction="up"
          duration={500}
        >
          <div className={styles["create-account__warning"]}>
            <div className={styles["check-box__wrapper"]}>
              <CheckBox
                action={setCheckboxChecked}
                isChecked={isChecked}
                size={23}
              />
              <div className={styles["check-box__text"]}>I saved the copy of my seed phrase on my disk</div>
            </div>
          </div>
        </Fade>
      }
      {isSaved && isChecked &&
        <Fade
          direction="up"
          duration={500}
        >
          <Button
            colorValue="green"
            widthValue="medium"
            children={LoginPhrase}
            isActive={isChecked && isSaved && h2qAccount.createAccount.state !== "pending"}
            action={login}
          />
        </Fade>
      }
      {/* <Button
        colorValue="green"
        widthValue="medium"
        children={goToSetPinPhrase}
        isActive={true}
        action={login}
      /> */}
    </div>
  );
});
