import { H2QContext } from "app/core/H2QContext";
import { INftPageTab, NftElement, NftInfoMainTab, NftInfoQuestTab, NftInfoStatsTab } from "entities/nft";
import { NftPageViewer } from "features/nftPageViewer";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import styles from "./NftPageNftTabs.module.scss";

interface INftPageNftTabs {
  nft: NftElement;
};

export const NftPageNftTabs = observer(function ({ nft }: INftPageNftTabs) {
  const { h2qAccount } = useContext(H2QContext);

  useEffect(() => {
    const getNftRewards = async () => {
      console.count("get rewards");
      await h2qAccount.calculateHeroQuestsRewards(nft?.pseudoNFTAddr!);
    };

    getNftRewards();
    // eslint-disable-next-line
  }, [nft.pseudoNftData?.m_h2qMetaCharacter.experience, nft.pseudoNftData?.m_lockedInQuest]);

  const nftTabs: INftPageTab[] = nft
    ? [
      {
        title: "NFT Hero",
        id: 0,
        tab: <NftInfoMainTab nft={nft} />,
      },
      {
        title: "Quests",
        id: 1,
        tab: <NftInfoQuestTab nft={nft} />
      },
      {
        title: "Statistics",
        id: 2,
        tab: <NftInfoStatsTab nft={nft} />
      },
      {
        title: "Story",
        id: 3,
        tab: <>
          <h3 className={styles['info-title']}>Story</h3>
          <p className={styles['info-value']}><span className={styles['info-param']}>Title:</span>Story Title</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>Other:</span>Story info</p>
        </>,
        isActive: false
      },
      {
        title: "Markets",
        id: 4,
        tab: <>
          <h3 className={styles['info-title']}>Markets</h3>
          <p className={styles['info-value']}><span className={styles['info-param']}>Title:</span>Markets Title</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>Other:</span>Markets info</p>
        </>,
        isActive: false
      },
    ]
    : [];

  return (
    <>
      {nft &&
        <NftPageViewer
          tabs={nftTabs}
          nftElement={nft}
        />
      }
    </>
  )
});