import { ISideBarBlock, sidebarHelpData, SideBarLink } from "entities/sideBar";
import styles from "./SideBarHelpLinks.module.scss";

export const SideBarHelpLinks = ({ isOpen, isLoggedIn }: ISideBarBlock) => {
  const sideHelpData = isLoggedIn ? sidebarHelpData : sidebarHelpData.filter(item => item.isActiveForGuest);

  return (
    <div className={`${styles["side-nav__links-block"]} ${styles.bottom}`}>
      {sideHelpData.map((item) => {
        return (
          <SideBarLink
            key={item.link}
            item={item}
            isOpen={isOpen}
          />
        );
      })}
    </div>
  )
};