import { InfoStatisticsBlock } from "entities/InfoStatisticsBlock";
import { RotatingCircleCenter } from "shared/ui";
import { VerticalDivider } from "shared/ui";
import { UserAccPageHeader } from "entities/UserAccPageHeader";
import { ReactComponent as IconStat } from "app/icons/Title_STATISTICS_white.svg";
import { AsyncReturnType } from "app/core/H2QAccount";
import { H2QContext } from "app/core/H2QContext";
import { getItemsFromArray } from "entities/item";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { getTimeFromMilliSeconds, timeToDdHhMmSs } from "shared/lib";
import styles from "./GameStat.module.scss";
import { UserGameStatBlock } from "entities/UserGameStatBlock";
import { UserRewards } from "widgets";

export const GameStat = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const nftTotalValue = h2qAccount.nfts.reduce((acc, item) => { return acc + +item.pseudoNFTdata.m_h2qMetaCharacter.valPrice }, 0);

  const h2qTotalSpent = h2qAccount.nfts.reduce((acc, item) => { return acc + +item.pseudoNFTdata.m_h2qMetaCharacter.valPrice + +item.pseudoNFTdata.m_h2qMetaCharacter.genPrice }, 0);
  type Stats = AsyncReturnType<typeof h2qAccount.calculateQuestsStats>
  const [stats, setStats] = useState<Stats | null>(null);

  const questDuration = stats
    ? getTimeFromMilliSeconds(stats.duration * 1000)
    : null;

  const questDurationLabel = questDuration
    ? timeToDdHhMmSs(questDuration)
    : "00d 00h 00m";

  useEffect(() => {
    const getQuestStats = async () => {
      const result = await h2qAccount.calculateQuestsStats();
      setStats(result);
    };

    getQuestStats();
    // eslint-disable-next-line 
  }, []);

  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconStat />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>
        <h2>HERO2QUEST</h2>
        <div className={styles["top-stats"]}>
          <InfoStatisticsBlock
            title="Avatars available"
            value={h2qAccount.data.m_avatar_amount
              ? h2qAccount.data.m_avatar_amount.reduce((acc, item) => acc + item)
              : 0}
          />

          <InfoStatisticsBlock
            title="Items available"
            value={h2qAccount.data.m_inventories
              ? h2qAccount.data.m_inventories.flat(2).reduce((acc, item) => acc + item)
              : 0}
          />

          <InfoStatisticsBlock
            title="NFT Generators"
            value={1}
          />
        </div>

        <UserGameStatBlock
          title="NFT Hero"
          key1="Created"
          key2="Value (h2q)"
          key3="Spend (h2q)"
          key4="Earned (h2q)"
          val1={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : h2qAccount.nfts.length}
          val2={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : nftTotalValue}
          val3={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : h2qTotalSpent}
          val4={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : stats?.h2qReward || "0"}
        />
        <VerticalDivider value={39} />

        <div className={styles["bottom-shadow"]} >
          <UserGameStatBlock
            title="Quests"
            key1="Completed"
            key2="Time cost"
            key3="Spend (qst)"
            key4="Earned (qst)"
            val1={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : stats?.completed || "0"}
            val2={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : questDurationLabel}
            val3={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : stats?.qstAmount || "0"}
            val4={h2qAccount.calculateQuestsStats.state === "pending" ? "..." : stats?.qstReward || "0"}
          />
        </div>
        <VerticalDivider value={39} />

        {stats?.itemsRewards && stats.itemsRewards.length && h2qAccount.calculateQuestsStats.state !== "pending" &&
          <UserRewards
            items={getItemsFromArray(stats.itemsRewards)}
          />
        }
      </div>

      {h2qAccount.calculateQuestsStats.state === "pending" &&
        <RotatingCircleCenter />
      }
    </div>
  )
})