import { useState } from 'react'
import styles from "./FlipWrapper.module.scss";

interface IFlipWrapper {
  frontChild: JSX.Element;
  backChild: JSX.Element;
  ratio: number;
  flipped?: boolean;
};

export const FlipWrapper = ({ frontChild, backChild, ratio, flipped }: IFlipWrapper) => {
  const [flip, toggleFlip] = useState<boolean>(false);

  const toggleView = () => {
    if (flipped !== undefined) return;

    toggleFlip(prev => !prev)
  };

  const paddingBottom = ratio * 100;
  return (
    <div
      style={{ paddingBottom: paddingBottom + "%" }}
      className={`${styles["flip-wrapper"]} ${flip || (flipped === true) ? styles.flipped : ""}`}
    >
      <div className={styles.front} onClick={toggleView}>
        {frontChild}
      </div>

      <div className={styles.back} onClick={toggleView}>
        {backChild}
      </div>
    </div>
  )
}
