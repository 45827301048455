import { Container, PixiComponent, Text } from "@saitonakamura/react-pixi";
import { Graphics, TextStyle } from "pixi.js";
import { useEffect, useState } from "react";
import { ArrowTo, getGeneratorTooltips, GenTooltipsType, tooltipBg, tooltipBgAlpha, tooltipBorder, tooltipBorderAlpha, tooltipBorderWidth, tooltipFont, tooltipFontColor, tooltipFontOffset, tooltipFontSize, tooltipLineHeight, tooltipRadius } from "entities/generator";

interface ITooltipText {
  text: string;
  height: number;
  width: number;
  x: number;
  y: number;
};

const TooltipText = (props: ITooltipText) => {
  return (
    <Text
      isSprite={true}
      anchor={[0, 0]}
      text={props.text}
      x={props.x}
      y={props.y}
      style={
        new TextStyle({
          fontFamily: tooltipFont,
          fontSize: tooltipFontSize,
          fill: tooltipFontColor,
          align: "left",
          wordWrap: true,
          wordWrapWidth: props.width,
          lineHeight: tooltipLineHeight
        })
      }
    />
  )
};

interface ITooltipBubble {
  width: number;
  height: number;
  arrowTo: ArrowTo;
};

const handleHeight = 66;
const handleWidth = 62;

const handleRightHeight = 30;
const handleRightWidth = 50;

const TooltipBubbleBottom = PixiComponent<ITooltipBubble, Graphics>("TooltipBubbleBottom", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {

    const handleBottom = {
      //top-left corner
      x1: props.arrowTo === "b-left" ? tooltipRadius : props.width - tooltipRadius - handleWidth,
      y1: props.height,
      //bottom corner
      x2: props.arrowTo === "b-left" ? tooltipRadius : props.width - tooltipRadius,
      y2: props.height + handleHeight,
      //top-right corner
      x3: props.arrowTo === "b-left" ? tooltipRadius + handleWidth : props.width - tooltipRadius,
      y3: props.height
    };

    const handleRight = {
      //top-left corner
      x1: props.width,
      y1: tooltipRadius,
      //top-right corner
      x2: props.width + handleRightWidth,
      y2: tooltipRadius,
      //bottom-left corner
      x3: props.width,
      y3: tooltipRadius + handleRightHeight
    };

    ins.clear();
    ins.beginFill(tooltipBg, tooltipBgAlpha);
    ins.lineStyle(tooltipBorderWidth, tooltipBorder, tooltipBorderAlpha, 0);

    //top-left-corner
    ins.moveTo(0, 0 + tooltipRadius);
    ins.quadraticCurveTo(0, 0, 0 + tooltipRadius, 0);

    //top and top-right corner
    ins.lineTo(props.width - tooltipRadius, 0);
    ins.quadraticCurveTo(props.width, 0, props.width, 0 + tooltipRadius);

    if (props.arrowTo !== "right") {
      // right and bottom-right corner
      ins.lineTo(props.width, 0 + props.height - tooltipRadius);
      ins.quadraticCurveTo(props.width, props.height, props.width - tooltipRadius, props.height);

      // bottom
      if (props.arrowTo === "b-left") ins.lineTo(handleBottom.x3, handleBottom.y3);

      //handle
      ins.lineTo(handleBottom.x2, handleBottom.y2);
      ins.lineTo(handleBottom.x1, handleBottom.y1);

      if (props.arrowTo === "b-right") ins.lineTo(tooltipRadius, handleBottom.y3);

      //bottom-left corner and left
      ins.quadraticCurveTo(0, props.height, 0, props.height - tooltipRadius);
      ins.lineTo(0, 0 + tooltipRadius)
    }

    if (props.arrowTo === "right") {
      //handle
      ins.lineTo(handleRight.x2, handleRight.y2);
      ins.lineTo(handleRight.x3, handleRight.y3);

      // right and bottom-right corner
      ins.lineTo(props.width, 0 + props.height - tooltipRadius);
      ins.quadraticCurveTo(props.width, props.height, props.width - tooltipRadius, props.height);

      // bottom
      ins.lineTo(tooltipRadius, props.height);

      //bottom-left corner and left
      ins.quadraticCurveTo(0, props.height, 0, props.height - tooltipRadius);
      ins.lineTo(0, 0 + tooltipRadius);
    }

    ins.endFill();
  }
});

interface IGeneratorTooltip {
  type: GenTooltipsType;
  isVertical?: boolean;
  isSlotNumber?: number | null;
};

export const GeneratorTooltip = ({ type, isVertical = false, isSlotNumber = null }: IGeneratorTooltip) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generatorTooltips = isSlotNumber
    ? getGeneratorTooltips(isVertical, isSlotNumber)
    : getGeneratorTooltips(isVertical);

  const text = generatorTooltips[type].text;

  const arrowTo = generatorTooltips[type].arrowTo;
  const width = generatorTooltips[type].width;
  const height = generatorTooltips[type].height;
  const x = generatorTooltips[type].x;
  const y = generatorTooltips[type].y;

  const xPos = arrowTo === "right"
    ? x - width - handleRightWidth
    : arrowTo === "b-left"
      ? x - tooltipRadius
      : x - width + tooltipRadius;

  const yPos = arrowTo === "right"
    ? y
    : y - height - handleHeight;

  useEffect(() => {
    setIsLoading(true);
  }, [type]);

  return (
    <Container
      position={[xPos, yPos]}
    >
      {
        isLoading &&
        <>
          <TooltipBubbleBottom
            arrowTo={arrowTo}
            height={height}
            width={width}
          />
          <TooltipText
            text={text}
            width={width - tooltipFontOffset * 2}
            height={height - tooltipFontOffset * 2}
            x={tooltipFontOffset}
            y={tooltipFontOffset}
          />
        </>
      }
    </Container>
  )
};