import { RotatingCircle, VerticalDivider } from "shared/ui";
import { ChangeEvent, useEffect, useState } from "react";
import styles from "./AddQuest.module.scss";
import { h2qAccount } from "../../../app/core/H2QContext";
import { observer } from "mobx-react";
import { AvatarAndInventories } from "../SelectAvatar/SelectAvatar";
import { delay } from "shared/lib";
import { toJS } from "mobx";
import { H2QuestParams, H2QuestResponse, RewardParams } from "app/core/H2QQuestManager";

// type TNewQuestMainParams = {
//   essentialParams: {
//     levelMin: number;
//     levelMax: number;
//     inventoryMask: string;
//   };
//   mandatoryParams: {
//     startTime: number;
//     finishTime: number;
//     name: string;
//     worldTag: string;
//     actionTag: string;
//     questNarrative: string;
//     qstAmount: number;
//   };
//   additionalParams: string;
// };

// type TNewQuestRestParams = {
//   rewardsMask: string;
//   qstTotalReward: number;
//   h2qTotalReward: number;
//   maxWinners: number;
//   experienceReward: number;
//   maxParticipants: number;
// };

type TNewQuest = {
  quest: H2QuestParams;
  questRestParams: RewardParams;
};

export const AddQuest = observer(function () {
  useEffect(() => {
    h2qAccount.loadAllQuests();
  }, []);

  const questLifeTime = 31 * 24 * 60 * 60;

  const [questForDeploy, setQuestsForDeploy] = useState<TNewQuest[] | null>(null);
  const [selectedForDeploy, setSelectedForDeploy] = useState<number[]>([]);

  const [addQuestFormVisible, setAddQuestFormVisible] = useState<boolean>(false);
  const [addingQuest, setAddingQuest] = useState<boolean>(false);
  const [levelMin, setLevelMin] = useState<number>(0);
  const [levelMax, setLevelMax] = useState<number>(99);
  const [startTime, setStartTime] = useState(Number(Date.now() / 1000) | 0);
  const [finishTime, setFinishTime] = useState(Number(Date.now() / 1000 + questLifeTime) | 0);
  const [name, setName] = useState<string>("First");
  const [worldTag, setWorldTag] = useState<string>("Tag");
  const [actionTag, setActionTag] = useState<string>("ActionTag");
  const [questNarrative, setQuestNarrative] = useState<string>("QuestNarrative");
  const [additionalParams, setAdditionalParams] = useState<string>("");
  const [qstTotalReward, setQstTotalReward] = useState(100)
  const [h2qTotalReward, setH2qTotalReward] = useState(1000)
  const [maxWinners, setMaxWinners] = useState(300)
  const [experienceReward, setExperienceReward] = useState(400)
  const [maxParticipants, setMaxParticipants] = useState(100)
  const [qstAmount, setQstAmount] = useState(0)

  const [inventoriesForMask, setInventoriesForMaskMask] = useState<any>({
    avatar_id: null,
    inv0: null,
    inv1: null,
    inv2: null,
    inv3: null,
    inv4: null,
    inv5: null,
    inv6: null,
  })

  const inventoryMask = '0x' + [
    inventoriesForMask.avatar_id === null ? 0 : inventoriesForMask.avatar_id + 1,
    inventoriesForMask.inv6 == null ? 0 : inventoriesForMask.inv6 + 1,
    inventoriesForMask.inv5 == null ? 0 : inventoriesForMask.inv5 + 1,
    inventoriesForMask.inv4 == null ? 0 : inventoriesForMask.inv4 + 1,
    inventoriesForMask.inv3 == null ? 0 : inventoriesForMask.inv3 + 1,
    inventoriesForMask.inv2 == null ? 0 : inventoriesForMask.inv2 + 1,
    inventoriesForMask.inv1 == null ? 0 : inventoriesForMask.inv1 + 1,
    inventoriesForMask.inv0 == null ? 0 : inventoriesForMask.inv0 + 1,
  ].map(
    x => ('00000000' + x.toString(16)).slice(-8)
  ).join('')

  const [inventoriesForRewards, setInventoriesForRewards] = useState<any>({
    avatar_id: null,
    inv0: null,
    inv1: null,
    inv2: null,
    inv3: null,
    inv4: null,
    inv5: null,
    inv6: null,
  })

  const rewardsMask = '0x' + [
    inventoriesForRewards.avatar_id === null ? 0 : inventoriesForRewards.avatar_id + 1,
    inventoriesForRewards.inv6 == null ? 0 : inventoriesForRewards.inv6 + 1,
    inventoriesForRewards.inv5 == null ? 0 : inventoriesForRewards.inv5 + 1,
    inventoriesForRewards.inv4 == null ? 0 : inventoriesForRewards.inv4 + 1,
    inventoriesForRewards.inv3 == null ? 0 : inventoriesForRewards.inv3 + 1,
    inventoriesForRewards.inv2 == null ? 0 : inventoriesForRewards.inv2 + 1,
    inventoriesForRewards.inv1 == null ? 0 : inventoriesForRewards.inv1 + 1,
    inventoriesForRewards.inv0 == null ? 0 : inventoriesForRewards.inv0 + 1,
  ].map(
    x => ('00000000' + x.toString(16)).slice(-8)
  ).join('')

  const gatherQuestDataForDeploy = (): TNewQuest => {
    const quest: H2QuestParams = {
      essentialParams: {
        levelMin,
        levelMax,
        inventoryMask,
      },
      mandatoryParams: {
        startTime,
        finishTime,
        name,
        worldTag,
        actionTag,
        questNarrative,
        qstAmount, // TODO: from input
      },
      additionalParams,
    };

    const questRestParams: RewardParams = {
      rewardsMask,
      qstTotalReward,
      h2qTotalReward,
      maxWinners,
      experienceReward,
      maxParticipants,
    };

    return { quest, questRestParams }
  };

  const convertH2QuestToNewQuestParams = (quest: H2QuestResponse): TNewQuest => {
    const questParams: H2QuestParams = {
      essentialParams: {
        ...quest.m_questParams.essentialParams,
        levelMax: quest.m_questParams.essentialParams.levelMax - 1
      },
      mandatoryParams: {
        ...quest.m_questParams.mandatoryParams,
        startTime: Math.round(Number(Date.now() / 1000)),
        finishTime: Math.round(Number(Date.now() / 1000 + questLifeTime))
      },
      additionalParams: ""
    };

    const questRestParams: RewardParams = {
      rewardsMask: quest.m_inventoryReward,
      qstTotalReward: Number(quest.m_totalQstReward),
      h2qTotalReward: Number(quest.m_totalQstReward),
      experienceReward: Number(quest.m_experienceUp),
      maxWinners: Number(quest.m_maxWinners),
      maxParticipants: Number(quest.m_maxParticipants)
    }

    return { quest: questParams, questRestParams }
  };

  const selectAllQuests = () => {
    if (selectedForDeploy.length) {
      setSelectedForDeploy([]);
      return;
    }
    setSelectedForDeploy(questForDeploy!.map((q, index) => index));
  };

  const selectQuests = (qIndex: number) => {
    if (selectedForDeploy.includes(qIndex)) {
      setSelectedForDeploy(prev => {
        return prev.filter(q => q !== qIndex)
      })
      return;
    }

    setSelectedForDeploy(prev => [...prev, qIndex])
  };

  const deploySelected = async () => {
    try {
      setAddingQuest(true);
      const selectedQuests = [...questForDeploy!].filter((q, index) => selectedForDeploy.includes(index));
      console.log(toJS(selectedQuests));

      for (let i = 0; i < selectedQuests.length; i++) {
        await h2qAccount.addQuestAdmin(selectedQuests[i].quest, { ...selectedQuests[i].questRestParams });
        console.log(i, "deployed");
      }

      // await Promise.all(selectedQuests.map(async (newQ, index) => {
      //   await h2qAccount.addQuestAdmin(newQ.quest, { ...newQ.questRestParams });
      //   console.log(index, "deployed:", newQ.quest.mandatoryParams.name);
      // }));
      console.log("Successfully deployed", selectedQuests.length, "quests.");


    } catch (error) {
      console.error(error);
    }
    finally {
      await delay(3000);
      await h2qAccount.loadAllQuests();
      setAddingQuest(false);
    }
  };

  const addQuest = async () => {
    try {
      setAddingQuest(true);

      const { quest, questRestParams } = gatherQuestDataForDeploy();
      await h2qAccount.addQuestAdmin(quest, { ...questRestParams });
    }
    finally {
      await delay(3000);
      await h2qAccount.loadAllQuests();
      setAddingQuest(false);
    }
  };

  const downloadFile = () => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(toJS(h2qAccount.quests), null, 4)));
    element.setAttribute('download', 'quests.json');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const loadQuestsFromJSON = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();

    if (!event.target.files || !event.target.value) return;

    fileReader.readAsText(event.target.files[0], "UTF-8");

    fileReader.onload = event => {
      const stringFromFile = event.target ? event.target.result : "null";
      if (typeof stringFromFile === "string") {
        const result: H2QuestResponse[] = JSON.parse(stringFromFile);

        if (result.every(quest => {
          if (!quest.id) return false;
          return true;
        })) {
          setQuestsForDeploy(prev => result.map(q => convertH2QuestToNewQuestParams(q)));
        }
      }
    };
  };

  const changeQuestLevel = (qIndex: number, minLevel: number, maxLevel: number) => {
    const updatedQuest = questForDeploy?.find((q, index) => qIndex === index);

    if (!updatedQuest) return;

    updatedQuest.quest.essentialParams.levelMin = minLevel;
    updatedQuest.quest.essentialParams.levelMax = maxLevel;

    const updated = [...questForDeploy!];
    updated[qIndex] = updatedQuest;
    setQuestsForDeploy(prev => updated);
  };

  const changeQuestTime = (qIndex: number, start: number, finish: number) => {
    const updatedQuest = questForDeploy?.find((q, index) => qIndex === index);

    if (!updatedQuest) return;

    updatedQuest.quest.mandatoryParams.startTime = start;
    updatedQuest.quest.mandatoryParams.finishTime = finish;

    const updated = [...questForDeploy!];
    updated[qIndex] = updatedQuest;
    setQuestsForDeploy(prev => updated);

  };

  return (
    <>
      <div className={styles["qst-btns"]}>
        <button className={styles["add-form-btn"]} onClick={() => setAddQuestFormVisible(prev => !prev)}>
          {addQuestFormVisible ? "Hide add Quest form" : "Show add Quest form"}
        </button>
        <label htmlFor="files" className={styles["load-btn"]}>
          <input id="files" type="file" accept="application/json, .json" value="" onChange={loadQuestsFromJSON} />
          Load from JSON
        </label>
        {h2qAccount.loadAllQuests.match({
          resolved: () => <button className={styles["save-btn"]} onClick={downloadFile}>Download deployed quests</button>
        })}
      </div>

      <div className={styles["add-quest-wrapper"]}>
        {addQuestFormVisible &&
          <>
            <h3>Add new Quest</h3>
            <fieldset>
              <label className={styles["value-title"]}>QST Amount:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={qstAmount}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setQstAmount(Number(val.target.value))
                }
              />
            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>Level Min:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={levelMin}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setLevelMin(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>Level Max:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={levelMax}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setLevelMax(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>Inventory mask:</label>
              <input
                className={`${styles["str-input"]} ${styles.long}`}
                disabled
                type="text"
                value={inventoryMask}
                onChange={(val: ChangeEvent<HTMLInputElement>) => 1}
              />
            </fieldset>

            <AvatarAndInventories
              className={styles.selectors}
              onSelect={setInventoriesForMaskMask}
              inventories={inventoriesForMask}
            />

            <fieldset>
              <label className={styles["value-title"]}>Start Time:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={startTime}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setStartTime(Number(val.target.value))
                }
              />
              <label className={styles["time-string"]} >{new Date(startTime * 1000).toISOString()}</label>
              <button onClick={() => setStartTime(prev => prev + 31 * 24 * 60 * 60)} >+1M</button>
              <button onClick={() => setStartTime(prev => prev + 7 * 24 * 60 * 60)} >+1W</button>
              <button onClick={() => setStartTime(prev => prev + 1 * 24 * 60 * 60)} >+1D</button>
              <button onClick={() => setStartTime(prev => prev + 1 * 60 * 60)} >+1h</button>
            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>Finish Time:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={finishTime}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setFinishTime(Number(val.target.value))
                }
              />
              <label className={styles["time-string"]} >{new Date(finishTime * 1000).toISOString()}</label>
              <button onClick={() => setFinishTime(prev => prev + 31 * 24 * 60 * 60)} >+1M</button>
              <button onClick={() => setFinishTime(prev => prev + 7 * 24 * 60 * 60)} >+1W</button>
              <button onClick={() => setFinishTime(prev => prev + 1 * 24 * 60 * 60)} >+1D</button>
              <button onClick={() => setFinishTime(prev => prev + 1 * 60 * 60)} >+1h</button>
            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>Name:</label>
              <input
                className={`${styles["str-input"]} ${styles.long}`}
                type="text"
                value={name}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setName(val.target.value)
                }
              />
            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>World Tag:</label>
              <input
                className={styles["str-input"]}
                type="text"
                value={worldTag}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setWorldTag(val.target.value)
                }
              />
            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>Action Tag:</label>
              <input
                className={styles["str-input"]}
                type="text"
                value={actionTag}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setActionTag(val.target.value)
                }
              />
            </fieldset>

            <fieldset className={styles.flex}>
              <label className={styles["value-title"]}>Quest Narrative:</label>
              <textarea
                className={styles["str-field-input"]}
                value={questNarrative}
                onChange={(val: ChangeEvent<HTMLTextAreaElement>) =>
                  setQuestNarrative(val.target.value)
                }
              />

            </fieldset>

            <fieldset>
              <label className={styles["value-title"]}>Additional Params:</label>
              <input
                className={styles["str-input"]}
                type="text"
                value={additionalParams}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setAdditionalParams(val.target.value)
                }
              />
            </fieldset>
            <VerticalDivider value={30} />
            <h3>Rewards:</h3>
            <fieldset>
              <label className={styles["value-title"]}>Reward mask:</label>
              <input
                className={`${styles["str-input"]} ${styles.long}`}
                disabled
                type="text"
                value={rewardsMask}
                onChange={(val: ChangeEvent<HTMLInputElement>) => 1}
              />
            </fieldset>

            <AvatarAndInventories
              className={styles.selectors}
              onSelect={setInventoriesForRewards}
              inventories={inventoriesForRewards}
            />

            <fieldset>
              <label className={styles["value-title"]}>QST Total Reward:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={qstTotalReward}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setQstTotalReward(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>H2Q Total Reward:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={h2qTotalReward}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setH2qTotalReward(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>Max Winners:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={maxWinners}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setMaxWinners(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>Experience reward:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={experienceReward}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setExperienceReward(Number(val.target.value))
                }
              />
            </fieldset>
            <fieldset>
              <label className={styles["value-title"]}>Max Participants:</label>
              <input
                className={styles["str-input"]}
                type="number"
                value={maxParticipants}
                min={0}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  setMaxParticipants(Number(val.target.value))
                }
              />
            </fieldset>

            <fieldset>
              <button className={styles["add-quest-btn"]} onClick={addQuest}>
                Add Quest
              </button>
            </fieldset>

            <VerticalDivider value={30} />
          </>
        }

        {
          addingQuest &&
          <div style={{ color: "#fff" }}>addingQuest....</div>
        }

        {questForDeploy && questForDeploy.length > 0 &&
          <>
            <div className={styles["table-header"]}>
              <h2 className={styles["q-title"]}>Loaded from JSON</h2>
              <button onClick={() => setQuestsForDeploy(prev => null)} className={styles["q-del"]}>&#10005;</button>
            </div>
            <table className={styles["q-table"]}>
              <thead>
                <tr>
                  <th><input type="checkbox" onChange={selectAllQuests} checked={selectedForDeploy.length === questForDeploy.length}></input></th>
                  <th>#</th>
                  <th>Name</th>
                  <th>World Tag</th>
                  <th>Action Tag</th>
                  <th>Quest Narrative</th>
                  <th>StartTime</th>
                  <th>FinishTime</th>
                  <th>LevelMin</th>
                  <th>LevelMax</th>
                  <th>QST Reward</th>
                  <th>H2Q Reward</th>
                  <th>Max participants</th>
                  <th>Max winners</th>
                  <th>Item Mask</th>
                  <th>Reward Mask</th>
                </tr>
              </thead>
              <tbody>
                {questForDeploy.map((q, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" onChange={() => selectQuests(index)} checked={selectedForDeploy?.includes(index)}></input></td>
                      <td>{index}</td>
                      <td>{q.quest.mandatoryParams.name}</td>
                      <td>{q.quest.mandatoryParams.worldTag}</td>
                      <td>{q.quest.mandatoryParams.actionTag}</td>
                      <td>{q.quest.mandatoryParams.questNarrative}</td>
                      <td>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime - 31 * 24 * 60 * 60, q.quest.mandatoryParams.finishTime)} >-1M</button>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime - 1 * 24 * 60 * 60, q.quest.mandatoryParams.finishTime)} >-1d</button>
                        {new Date(q.quest.mandatoryParams.startTime * 1000).toUTCString()}
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime + 1 * 24 * 60 * 60, q.quest.mandatoryParams.finishTime)} >+1d</button>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime + 31 * 24 * 60 * 60, q.quest.mandatoryParams.finishTime)} >+1M</button>
                      </td>
                      <td>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime, q.quest.mandatoryParams.finishTime - 31 * 24 * 60 * 60)} >-1M</button>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime, q.quest.mandatoryParams.finishTime - 1 * 24 * 60 * 60)} >-1d</button>
                        {new Date(q.quest.mandatoryParams.finishTime * 1000).toUTCString()}
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime, q.quest.mandatoryParams.finishTime + 1 * 24 * 60 * 60)} >+1d</button>
                        <button onClick={(e) => changeQuestTime(index, q.quest.mandatoryParams.startTime, q.quest.mandatoryParams.finishTime + 31 * 24 * 60 * 60)} >+1M</button>
                      </td>
                      <td><input min={0} max={99} className={styles.level} type="number" value={q.quest.essentialParams.levelMin} onChange={(e) => changeQuestLevel(index, Number(e.target.value), q.quest.essentialParams.levelMax)} /></td>
                      <td><input min={0} max={99} className={styles.level} type="number" value={q.quest.essentialParams.levelMax} onChange={(e) => changeQuestLevel(index, q.quest.essentialParams.levelMin, Number(e.target.value))} /></td>
                      <td>{q.questRestParams.qstTotalReward}</td>
                      <td>{q.questRestParams.h2qTotalReward}</td>
                      <td>{q.questRestParams.maxParticipants}</td>
                      <td>{q.questRestParams.maxWinners}</td>
                      <td>{q.quest.essentialParams.inventoryMask}</td>
                      <td>{q.questRestParams.rewardsMask}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {selectedForDeploy && selectedForDeploy.length &&
              <>
                {addingQuest
                  ? <RotatingCircle />
                  : <button className={styles["deploy-qsts"]} onClick={deploySelected}>
                    Deploy selected quests
                  </button>
                }
              </>
            }
          </>
        }

        <div>
          <h2 className={styles["q-title"]}>Currently deployed</h2>
          <table className={styles["q-table"]}>
            <thead>
              <tr>
                <th>#</th>
                <th>QST</th>
                <th>L0</th>
                <th>L1</th>
                <th>InventoryMask/rewardsMask</th>
                <th>StartTime / FinishTime</th>
                <th>Name</th>
                <th>WorldTag</th>
                <th>ActionTag</th>
                <th>QuestNarrative</th>
              </tr>
            </thead>
            <tbody>
              {h2qAccount.loadAllQuests.match({
                pending: () => (
                  <tr>
                    <td>loading....</td>
                  </tr>
                ),
                resolved: () => {
                  return h2qAccount.quests.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{index}</td>
                        <td>{item.m_questParams.mandatoryParams.qstAmount}</td>
                        <td>{item.m_questParams.essentialParams.levelMin}</td>
                        <td>{item.m_questParams.essentialParams.levelMax}</td>
                        <td width={"40%"}>
                          {item.m_questParams.essentialParams.inventoryMask} <br />
                          {item.m_inventoryReward}
                        </td>
                        <td>
                          {String(
                            new Date(
                              item.m_questParams.mandatoryParams.startTime * 1000
                            )
                          )}
                          <br />
                          {String(
                            new Date(
                              item.m_questParams.mandatoryParams.finishTime * 1000
                            )
                          )}
                        </td>
                        <td>{item.m_questParams.mandatoryParams.name}</td>
                        <td>{item.m_questParams.mandatoryParams.worldTag}</td>
                        <td>{item.m_questParams.mandatoryParams.actionTag}</td>
                        <td>
                          {item.m_questParams.mandatoryParams.questNarrative}
                        </td>
                      </tr>
                    );
                  });
                },
              })}
            </tbody>
          </table>
        </div>
      </div >
    </>
  );
});