import { IconColorVariants } from "app/icons/icons";
import { AddressString } from "features/AddressString";
import styles from "./UserWalletBlock.module.scss";

enum Wallet {
  h2q = "H2Q",
  qst = "QST",
  ever = "EVER"
};

export type WalletType = keyof typeof Wallet;

interface IWalletWrapper {
  type: WalletType;
  value: number;
  walletAddress: string;
  deposit?: () => void;
  withdraw?: () => void;
  convert?: () => void;
  buy?: () => void;
};

export function UserWalletBlock({
  type,
  value,
  walletAddress,
  deposit,
  withdraw,
  convert,
  buy
}: IWalletWrapper) {

  const typeColor = type === "h2q"
    ? IconColorVariants.blue
    : type === "qst"
      ? IconColorVariants.yellow
      : IconColorVariants.white;

  return (
    <div className={styles["wallet-wrapper"]}>
      <div className={styles["wallet-amount"]}>
        <h4 style={{ color: typeColor }}>{Wallet[type]}</h4>
        <div style={{ borderColor: typeColor }}>{value}</div>
      </div>
      <div className={styles["wallet-address-and-btns"]}>
        <div className={styles["wallet-btns"]}>
          {buy &&
            <button onClick={buy} style={{ color: IconColorVariants.yellow }}>Buy</button>
          }
          {deposit &&
            <button onClick={deposit} style={{ color: IconColorVariants.blue }}>Deposit</button>
          }
          {withdraw &&
            <button onClick={withdraw} style={{ color: type === "ever" ? IconColorVariants.gray : IconColorVariants.blue }}>{type === "ever" ? "Only for gas transactions" : "Withdraw"}</button>
          }
          {convert &&
            <button onClick={convert} style={{ color: IconColorVariants.blue }}>Convert</button>
          }
          <button style={{ opacity: 0, width: 0 }}>i</button>
        </div>
        <AddressString
          value={walletAddress}
          bg="dark"
          valueColor="grey"
        />
      </div>
    </div>
  )
}
