import { H2QContext } from "app/core/H2QContext";
import { FilterFoldableBlockWithTitle } from "entities/FilterFoldableBlockWithTitle";
import { cellColors, cellSuperColors, ChangeCellColor, ColorsAndCostumeIds, EditQuest, EditSeason, getRandomText, InputCell, inputSquareSize, QuestSeason, QuestSeasons, SeasonControlBtns, SelectQuests, TCellValue, TEpoch, TInputCell, TInputSquare, TSelectedQuests } from "entities/questMaker";
import { updateQuestWithRandomGeneratedValues } from "entities/questMaker/model/createSquare";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import styles from "./Epoch.module.scss";

interface IEpoch {
  epoch: TEpoch;
  eIndex: number;
  addNewSeason: (eIndex: number) => void;
  removeSeason: (eIndex: number, sIndex: number) => void;
  calculateSeason: (eIndex: number, sIndex: number, season: TInputSquare) => void;
  removeEpoch: (eIndex: number) => void;
  changeSeasonColor: (eIndex: number, sIndex: number, col: string) => void;
  changeCellValue: (eIndex: number, sIndex: number, row: number, col: number, value: TCellValue) => void;
  changeCellDiffColor: (eIndex: number, sIndex: number, row: number, col: number, color: string) => void;
  changeCellSuperColor: (eIndex: number, sIndex: number, row: number, col: number, color: string) => void;
  activeCell: { eIndex: number, sIndex: number, row: number, col: number } | null;
  setActiveCell: (eIndex: number | null, sIndex: number | null, row: number | null, col: number | null) => void;
  activeSeason: { eIndex: number; sIndex: number } | null;
  setActiveSeason: (eIndex: number | null, sIndex: number | null) => void;
  selectedQuests: TSelectedQuests | null;
  setSelectedQuests: (selQuests: TSelectedQuests | null) => void;
  deploySelectedQuests: () => void;
};

export const Epoch = observer(function ({ epoch, eIndex, addNewSeason, removeSeason, removeEpoch, changeSeasonColor, changeCellValue, changeCellDiffColor, changeCellSuperColor, setActiveCell, activeCell, activeSeason, setActiveSeason, selectedQuests, setSelectedQuests, calculateSeason, deploySelectedQuests }: IEpoch) {
  const { h2qAccount } = useContext(H2QContext);
  const zInitialValue = 5;


  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, sIndex: number, row: number, col: number) => {
    if (isNaN(Number(e.target.value))) return;

    const value = Number(e.target.value[e.target.value.length - 1]) as TCellValue;

    if (value > inputSquareSize || value < 1) return;

    changeCellValue(eIndex, sIndex, row, col, value);
  };


  const enableEditModeInSeason = (sIndex: number) => {
    if (activeSeason?.sIndex === sIndex) {
      setActiveSeason(null, null);
      h2qAccount.uiStore.setRightSidebarChildEditable(false);
      return;
    }

    h2qAccount.uiStore.setRightSidebarChildEditable(true);
    setActiveSeason(eIndex, sIndex);
  };


  const openParamsSeasonWindow = (sIndex: number) => {
    const seasonKey = `Epoch${eIndex}-Season${sIndex}`;

    // close sidebar when the selected season is clicked again
    // if (h2qAccount.uiStore.rightSidebarChild.key === seasonKey) {
    //   h2qAccount.uiStore.toggleRightSidebar(false);
    //   return;
    // }

    if (activeCell) {
      setActiveCell(null, null, null, null);
    }

    if (selectedQuests) {
      setSelectedQuests(null);
    }

    const isEditable = activeSeason?.eIndex === eIndex && activeSeason.sIndex === sIndex;
    h2qAccount.uiStore.setRightSidebarChild(seasonKey, <EditSeason season={epoch.seasons[sIndex]} />, isEditable);
    h2qAccount.uiStore.toggleRightSidebar(true);
  };


  const proceedClickOnCell = (sIndex: number, row: number, col: number) => {
    if (selectedQuests?.eIndex === eIndex && selectedQuests.sIndex === sIndex) {
      addQuestToSelected(sIndex, row, col);
      return;
    }

    openEditQuestWindow(sIndex, row, col);
  };


  const getNewSelectedQuests = (sIndex: number, row?: number, col?: number): TSelectedQuests | null => {
    if (sIndex === null) {
      return null;
    }

    if (selectedQuests === null || selectedQuests.eIndex !== eIndex || selectedQuests.sIndex !== sIndex) {
      return {
        eIndex,
        eTitle: epoch.title,
        sIndex,
        sTitle: epoch.seasons[sIndex].seasonTitle,
        quests: row && col ? [{ row, col }] : []
      }
    }

    if (row === undefined || col === undefined) return null;

    const updatedQuests = [...selectedQuests.quests];

    if (updatedQuests.length && updatedQuests.some(q => q.row === row && q.col === col)) {
      const filtered = updatedQuests.filter(q => !(q.col === col && q.row === row));
      return { ...selectedQuests, quests: filtered }
    }

    else {
      updatedQuests.push({ row, col });
      return { ...selectedQuests, quests: updatedQuests }
    }
  };


  const addQuestToSelected = (sIndex: number, row: number, col: number) => {
    const selectedKey = `Epoch${eIndex}-Season${sIndex}-Selected`;

    // Do not select alreay deployed quests
    if (epoch.seasons[sIndex].cells[row][col].isDeployed) {
      return;
    }

    const updated = getNewSelectedQuests(sIndex, row, col);
    setSelectedQuests(updated);
    const selQuests = updated?.quests.length ? updated.quests.map(quest => epoch.seasons[sIndex].cells[quest.row][quest.col]) : [];
    h2qAccount.uiStore.setRightSidebarChild(selectedKey, <SelectQuests epochIndex={eIndex} epochTitle={epoch.title} seasonIndex={sIndex} seasonTitle={epoch.seasons[sIndex].seasonTitle} quests={selQuests} deploySelectedQuests={deploySelectedQuests} />)
  };


  const openEditQuestWindow = (sIndex: number, row: number, col: number) => {
    const questKey = `Epoch${eIndex}-Season${sIndex}-Quest${row}-${col}`;

    // close sidebar when the selected quest is clicked again
    // if (h2qAccount.uiStore.rightSidebarChild.key === questKey) {
    //   h2qAccount.uiStore.toggleRightSidebar(false);
    //   return;
    // };

    setActiveCell(eIndex, sIndex, row, col);
    const isEditable = activeSeason?.eIndex === eIndex && activeSeason.sIndex === sIndex;
    h2qAccount.uiStore.setRightSidebarChild(questKey, <EditQuest quest={epoch.seasons[sIndex].cells[row][col]} />, isEditable);
    h2qAccount.uiStore.toggleRightSidebar(true);
  };


  const removeSeasonAndHideSideBar = (sIndex: number) => {
    h2qAccount.uiStore.toggleRightSidebar(false);
    // setActiveSeason(null, null);
    // setActiveCell(null, null, null, null);
    removeSeason(eIndex, sIndex);
  };


  const enableSelectModeInSeason = (sIndex: number) => {
    const selectedKey = `Epoch${eIndex}-Season${sIndex}-Selected`;

    // if (h2qAccount.uiStore.rightSidebarChild.key === selectedKey) {
    //   h2qAccount.uiStore.toggleRightSidebar(false);
    //   return;
    // }

    if (activeCell) {
      setActiveCell(null, null, null, null);
    }

    const newSelected = getNewSelectedQuests(sIndex);
    setSelectedQuests(newSelected);
    h2qAccount.uiStore.setRightSidebarChild(selectedKey, <SelectQuests epochIndex={eIndex} epochTitle={epoch.title} seasonIndex={sIndex} seasonTitle={epoch.seasons[sIndex].seasonTitle} quests={[]} deploySelectedQuests={deploySelectedQuests} />)
    h2qAccount.uiStore.toggleRightSidebar(true);
  };


  const updateSelectedQuests = (sIndex: number, newSelected: Array<{ row: number, col: number }>) => {
    const updated = { ...selectedQuests, quests: newSelected } as TSelectedQuests;
    setSelectedQuests(updated);
    const selQuests = updated?.quests ? updated.quests.map(quest => epoch.seasons[sIndex].cells[quest.row][quest.col]) : [];
    h2qAccount.uiStore.setRightSidebarChild(h2qAccount.uiStore.rightSidebarChild.key, <SelectQuests epochIndex={eIndex} epochTitle={epoch.title} seasonIndex={sIndex} seasonTitle={epoch.seasons[sIndex].seasonTitle} quests={selQuests} deploySelectedQuests={deploySelectedQuests} />);
  };


  const selectAllQuestInSeason = (sIndex: number) => {
    if (selectedQuests === null) return;

    let allSelected = [] as Array<{ row: number, col: number }>;

    // if (selectedQuests.quests.length === epoch.seasons[sIndex].cells.flat().length) {
    //   allSelected = [];
    // }

    if (selectedQuests.quests.length > 0) {
      allSelected = [];
    }

    else {
      allSelected = epoch.seasons[sIndex].cells.map((row, y) => {
        return row.map((cell, x) => {
          return cell.isChecked && !cell.isDeployed ? { "row": y, "col": x } : null
        })
      }).flat(2).filter(item => item) as Array<{ row: number, col: number }>;
    }

    updateSelectedQuests(sIndex, allSelected);
  };


  // select 10 random quests in the season
  const selectRandomQuestInSeason = (sIndex: number) => {
    if (selectedQuests === null) return;

    let randomSelected = [] as Array<{ row: number, col: number }>;

    while (randomSelected.length < 10) {
      const maxIndex = inputSquareSize - 1;
      const rowIndex = Math.floor(Math.random() * (maxIndex + 1));
      const colIndex = Math.floor(Math.random() * (maxIndex + 1));

      const newElement = { row: rowIndex, col: colIndex };
      if (epoch.seasons[sIndex].cells[rowIndex][colIndex].isChecked && !epoch.seasons[sIndex].cells[rowIndex][colIndex].isDeployed && !randomSelected.some((q) => q.col === newElement.col && q.row === newElement.col)) {
        randomSelected.push(newElement);
      }
    };

    updateSelectedQuests(sIndex, randomSelected);
  };

  const updateSeasonwithRandomInfo = (sIndex: number) => {
    if (activeSeason === null) return;

    const updatedSeason = { ...epoch.seasons[sIndex] };

    updatedSeason.cells.forEach((row, rowIndex) => {
      row.forEach((q, colIndex) => {
        q = updateQuestWithRandomGeneratedValues(q, rowIndex, colIndex);
      })
    });

    updatedSeason.seasonTitle = `Season-${sIndex}-${getRandomText(30)}`;
    updatedSeason.seasonDescr = `${getRandomText(150)}`;

    calculateSeason(eIndex, sIndex, updatedSeason);

    // if edit quest window is opened - update it
    if (activeCell) {
      const isEditable = activeSeason?.eIndex === eIndex && activeSeason.sIndex === sIndex;
      h2qAccount.uiStore.setRightSidebarChild(h2qAccount.uiStore.rightSidebarChild.key, <EditQuest quest={epoch.seasons[sIndex].cells[activeCell.row][activeCell.col]} />, isEditable);
      return;
    }

    // if edit season window is opened - update it
    const seasonKey = `Epoch${eIndex}-Season${sIndex}`;
    if (h2qAccount.uiStore.rightSidebarChild.key === seasonKey) {
      const isEditable = activeSeason?.eIndex === eIndex && activeSeason.sIndex === sIndex;
      h2qAccount.uiStore.setRightSidebarChild(seasonKey, <EditSeason season={epoch.seasons[sIndex]} />, isEditable);
    }

    // if select quests window is opened - update it
    const selectedKey = `Epoch${eIndex}-Season${sIndex}-Selected`;
    if (h2qAccount.uiStore.rightSidebarChild.key === selectedKey) {
      const newSelected = [] as Array<{ row: number, col: number }>;

      const selCells = selectedQuests?.quests.length
        ? selectedQuests.quests
          .map(quest => {
            if (updatedSeason.cells[quest.row][quest.col].isChecked && !updatedSeason.cells[quest.row][quest.col].isDeployed) {
              newSelected.push({ col: quest.col, row: quest.row })
              return updatedSeason.cells[quest.row][quest.col]
            }
            return null
          })
          .filter(q => q) as TInputCell[]
        : [];

      updateSelectedQuests(sIndex, newSelected);
      h2qAccount.uiStore.setRightSidebarChild(selectedKey, <SelectQuests epochIndex={eIndex} epochTitle={epoch.title} seasonIndex={sIndex} seasonTitle={updatedSeason.seasonTitle} quests={selCells} deploySelectedQuests={deploySelectedQuests} />)
    }
  };

  useEffect(() => {
    if (!h2qAccount.uiStore.isRightSidebarOpen) {
      setActiveSeason(null, null);
      setActiveCell(null, null, null, null);
      setSelectedQuests(null);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    };

    // eslint-disable-next-line
  }, [h2qAccount.uiStore.isRightSidebarOpen]);

  useEffect(() => {
    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
      setActiveSeason(null, null);
      setActiveCell(null, null, null, null);
      setSelectedQuests(null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className={styles["epoch-remove"]}>
        <button onClick={() => removeEpoch(eIndex)} className={styles["rem-epoch-btn"]}>&#x2715;</button>
      </div>
      <FilterFoldableBlockWithTitle title={epoch.title} >
        <ColorsAndCostumeIds />
        <QuestSeasons
          seasonsSlot={
            epoch.seasons.map((square, sIndex) => {
              const isSeasonParamsOpened = h2qAccount.uiStore.isRightSidebarOpen && h2qAccount.uiStore.rightSidebarChild.key === `Epoch${eIndex}-Season${sIndex}`;
              const isSeasonEditable = !!activeSeason && activeSeason.eIndex === eIndex && activeSeason.sIndex === sIndex;
              const isSeasonSelectable = !!selectedQuests && selectedQuests.eIndex === eIndex && selectedQuests.sIndex === sIndex;
              const isAllSelected = !!selectedQuests && selectedQuests.quests.length === epoch.seasons[sIndex].cells.flat(2).filter(c => !c.isDeployed).length;
              const isCalculateEnabled = epoch.seasons[sIndex].cells.flat(2).every(cell => cell.value && cell.value >= 0);

              return (
                <QuestSeason
                  key={sIndex}
                  squareColor={square.squareColor}
                  sIndex={sIndex}
                  isActive={isSeasonParamsOpened || isSeasonSelectable}
                  cellsSlot={
                    square.cells.map((row, y) => {
                      return (
                        <div key={y} className={styles.row}>
                          {row.map((cell, x) => {
                            const isCellActive = activeCell
                              ? activeCell.eIndex === eIndex && activeCell.sIndex === sIndex && activeCell.row === y && activeCell.col === x
                              : false;

                            const isCellSelected = selectedQuests
                              ? selectedQuests.eIndex === eIndex && selectedQuests.sIndex === sIndex && selectedQuests.quests.some(q => q.row === y && q.col === x)
                              : false;

                            return (
                              <InputCell
                                key={`${x}-${y}`}
                                isError={cell.isError}
                                isActive={isCellActive || isCellSelected}
                                diffColor={cell.diffColor}
                                superColor={cell.superColor}
                                onCellClick={() => proceedClickOnCell(sIndex, y, x)}
                                isDeployed={cell.isDeployed}
                                // calc zindex
                                z={1 + sIndex + zInitialValue + (inputSquareSize - x) * (inputSquareSize - y)}
                                inputSlot={
                                  <input
                                    className={`${styles["cell-input"]} ${!isSeasonEditable ? styles.disabled : ""} ${!!square.squareColor || !!cell.diffColor || !!cell.superColor ? styles.colored : ""}`}
                                    type="text"
                                    value={cell.value ? cell.value : ""}
                                    onChange={(event) => onInputChange(event, sIndex, y, x)}
                                    // onFocus={() => setActiveCell(eIndex, sIndex, y, x)}
                                    disabled={!isSeasonEditable}
                                  />
                                }
                                diffColorSlot={
                                  isSeasonEditable &&
                                  <ChangeCellColor colors={cellColors} onSelectColor={(colorKey) => changeCellDiffColor(eIndex, sIndex, y, x, colorKey)} selectedColor={cell.diffColor} />
                                }
                                superColorSlot={
                                  isSeasonEditable &&
                                  <ChangeCellColor
                                    colors={cellSuperColors}
                                    onSelectColor={(colorKey) => changeCellSuperColor(eIndex, sIndex, y, x, colorKey)}
                                    selectedColor={cell.superColor}
                                  />
                                }
                              />
                            )
                          })}
                        </div>
                      )
                    })
                  }
                  removeSeasonSlot={
                    isSeasonEditable &&
                    <button onClick={() => removeSeasonAndHideSideBar(sIndex)} className={styles["rem-season-btn"]}>&#x2715;</button>
                  }
                  changeSeasonColorSlot={
                    isSeasonEditable &&
                    <ChangeCellColor
                      colors={cellColors}
                      onSelectColor={(colorKey) => changeSeasonColor(eIndex, sIndex, colorKey)}
                      selectedColor={square.squareColor}
                    />

                  }
                  bottonBtnsSlot={< SeasonControlBtns
                    isParamsOpened={isSeasonParamsOpened}
                    isEditModeEnabled={isSeasonEditable}
                    isSelectModeEnabled={isSeasonSelectable}
                    isAllSelected={isAllSelected}
                    isCalculateEnabled={isSeasonEditable && isCalculateEnabled}
                    onEditSeason={() => enableEditModeInSeason(sIndex)}
                    onEnableSelectMode={() => enableSelectModeInSeason(sIndex)}
                    onSelectAll={() => selectAllQuestInSeason(sIndex)}
                    onSelectRandom={() => selectRandomQuestInSeason(sIndex)}
                    onParamsSeason={() => openParamsSeasonWindow(sIndex)}
                    onCalculateSeason={() => updateSeasonwithRandomInfo(sIndex)}
                  />}
                />
              )
            })
          }
          addSeasonSlot={epoch.seasons.length < 7
            ? <div className={styles["add-btn"]}>
              <button onClick={() => addNewSeason(eIndex)}>+</button>
            </div>
            : null
          }
        />
      </FilterFoldableBlockWithTitle>
    </div>
  )
});