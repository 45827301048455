import { h2qAccount } from "app/core/H2QContext";
import { itemList } from "entities/item";
import { inputSquareSize, TEpochColorIds, TEpochColorId, cellAllColors, TCellAllColor, TCostume } from "./squares";

export type TCostumeElement = {
  id: number;
  name: string;
  value: number;
};

export const getCoctumeList = () => {
  const costumeList: TCostumeElement[] = itemList[0].map(item => {
    const newCostumeElement = {} as TCostumeElement;
    newCostumeElement.id = item.itemId;
    newCostumeElement.name = item.iconLink.split("\\")[3].split("_")[2] || `hero-${item.itemId}`;
    newCostumeElement.value = h2qAccount.inventoryInfoFromBC
      ? Number(h2qAccount.inventoryInfoFromBC[item.itemId].value) * 7
      : 0;
    return newCostumeElement;
  })
    .sort((a, b) => a.value <= b.value ? -1 : 1)

  return costumeList;
};

export const getColorEqualityToCostumesBy7 = (): TEpochColorIds => {
  const costumeList = getCoctumeList();

  const costumeListBy7 = {} as TEpochColorIds;
  const colors = Object.keys(cellAllColors);

  for (let i = 0, key = 0; i < costumeList.length; i += inputSquareSize, key++) {
    const set = costumeList.slice(i, i + inputSquareSize);

    const epochColorId = {} as TEpochColorId;
    const costumeSetName = set.reduce((total, costume, index) => index === 0 ? costume.id.toString() : total + ":" + costume.id.toString(), "");
    const costumesSetIdValues = set.reduce((total: TCostume[], costume) => total.concat({ costumeId: costume.id, costumeName: costume.name, costumeValue: costume.value }), []);

    epochColorId.title = costumeSetName;
    epochColorId.costumeIds = costumesSetIdValues;

    const colorKey = colors[key];

    if (colorKey) {
      costumeListBy7[colorKey as TCellAllColor] = epochColorId;
    }
  };

  return costumeListBy7;
};