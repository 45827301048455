import { TInputCell } from "entities/questMaker";
import { Button } from "shared/ui";
import styles from "./SelectQuests.module.scss";

interface ISelectQuests {
  epochIndex: number;
  epochTitle: string;
  seasonIndex: number;
  seasonTitle: string;
  quests: TInputCell[];
  deploySelectedQuests: () => void;
};

export const SelectQuests = ({ epochIndex, epochTitle, seasonIndex, seasonTitle, quests, deploySelectedQuests }: ISelectQuests) => {

  return (
    <div className={styles["select-wrapper"]}>
      <h2 className={styles["select-header"]}>Selected quests for deploy:</h2>
      <p className={styles["quest-info"]}>[{epochIndex}]Epoch: {epochTitle}</p>
      <p className={styles["quest-info"]}>[{seasonIndex}]Season: {seasonTitle}</p>
      <p className={styles["quest-info"]}>Total quests for deploy: {quests.length}</p>

      <div className={styles["quest-list"]}>
        {quests.map((q, index) => {
          return (
            <div className={styles["qust-element"]} key={index}>
              <p>{q.questParams.mandatoryParams.name}</p>
              <p>Total H2Q/QST rewards: <span className={styles.h2q}>{q.rewardParams.h2qTotalReward}</span>/<span className={styles.qst}>{q.rewardParams.qstTotalReward}</span></p>
            </div>
          )
        })}
      </div>

      <div className={styles["btn-block"]}>
        {(quests.length === 0 || quests.some(q => q.isChecked === false))
          ? <p className={styles["error-message"]} >First select quests and press calculate icon</p>
          : <Button
            children={<p style={{ color: "white", fontSize: "1.8rem", padding: "0 2rem" }}>Deploy quests</p>}
            colorValue={"green"}
            widthValue={"full"}
            isActive={quests.length > 0 && quests.every(q => q.isChecked === true)}
            action={deploySelectedQuests}
          />
        }
      </div>
    </div>
  )
};