import { BottomMessage, ColorAndRarityBlock, FieldWithBg, GeneratorTooltip, GenTooltipsType, getMessageSizeAndCoord } from "entities/generator"
import { ItemBlockColorKeys } from "entities/itemColors";
import { getNftType } from "entities/nft";
import { useState } from "react";

interface IGeneratorMessageBlock {
  isVertical: boolean;
  items: [number, number, number, number, number, number, number] | never[];
  isTooltipsActive: boolean;
  itemsColorArray: ItemBlockColorKeys[] | null;
};

export const GeneratorMessageBlock = ({ isVertical, items, isTooltipsActive, itemsColorArray }: IGeneratorMessageBlock) => {
  const [hoveredItem, setHovered] = useState<GenTooltipsType | null>(null);

  const messageBlockSizeAndCoord = getMessageSizeAndCoord(isVertical);

  const { nftType, nftTypePercent } = getNftType(items.map(i => i.toString()));

  const bottomMessage = "Get avatars and items in quests, load them into slots to create a new NFT-HERO";

  return (
    <>
      {/* Message and information block under the slots */}
      <FieldWithBg
        x={messageBlockSizeAndCoord.ItemsBottomField!.x}
        y={messageBlockSizeAndCoord.ItemsBottomField!.y}
        width={messageBlockSizeAndCoord.ItemsBottomField!.width!}
        height={messageBlockSizeAndCoord.ItemsBottomField!.height!}
        onHoverOver={() => setHovered("colorsBlock")}
        onHoverOut={() => setHovered(null)}
      />
      {/* Items' rareness and colors block */}
      {items.length > 0 && itemsColorArray &&
        <ColorAndRarityBlock
          colors={itemsColorArray}
          x={messageBlockSizeAndCoord.BlockColors!.x}
          y={messageBlockSizeAndCoord.BlockColors!.y}
          width={messageBlockSizeAndCoord.BlockColors!.width!}
          rarity={`${nftType} ${nftTypePercent}%`}
        />
      }
      {/* Message or information */}
      {items.length === 0 &&
        <BottomMessage
          width={messageBlockSizeAndCoord.TextMessage!.width!}
          x={messageBlockSizeAndCoord.TextMessage!.x}
          y={messageBlockSizeAndCoord.TextMessage!.y}
          text={bottomMessage}
        />
      }
      {/* Tooltips for current stage and blocks: colorsBlock */}
      {isTooltipsActive &&
        (hoveredItem === "colorsBlock") &&
        < GeneratorTooltip
          type={hoveredItem}
        />
      }
    </>
  )
}
