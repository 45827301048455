import styles from "./RewardsModalWrapper.module.scss";
import { FC, PropsWithChildren, useRef } from "react"
import { useOutsideClick } from "shared/lib";

interface IRewardsModalWrapper {
  isShown: boolean;
  close: () => void;
};

export const RewardsModalWrapper: FC<PropsWithChildren<IRewardsModalWrapper>> = ({ children, close, isShown }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, () => {
    close();
  });

  return (
    <div className={`${styles["rewards-wrapper"]} ${isShown ? styles.shown : ""}`} ref={modalRef}>
      {children}
    </div>
  )
};