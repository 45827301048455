import styles from "./FilterMinMaxValue.module.scss";

interface IMinMaxFilterValue {
  min: number;
  max: number;
  minVal: number;
  maxVal: number;
  setMinVal: (val: number) => void;
  setMaxVal: (val: number) => void;
};

export const MinMaxFilterValue = ({ min, max, minVal, maxVal, setMinVal, setMaxVal }: IMinMaxFilterValue) => {
  const setMinimum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Math.min(Number(e.target.value), maxVal - 1);
    e.stopPropagation();
    setMinVal(currentValue);
  };

  const setMaximum = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const currentValue = Math.max(Number(e.target.value), minVal + 1);
    setMaxVal(currentValue);
  };
  return (
    <>
      <div className={styles["input-block"]}>
        <input type="number" min={min} max={maxVal - 1} value={minVal} onChange={setMinimum} />
      </div>
      <span className={styles.dash}>-</span>
      <div className={styles["input-block"]}>
        <input type="number" min={minVal + 1} max={max} value={maxVal} onChange={setMaximum} />
      </div>

    </>
  )
};