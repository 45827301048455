import { fillQuestWithDefaultValues, inputSquareSize, makeEmptyEpoch, makeEmptySeason, TActiveCell, TActiveSeason, TCellColor, TCellSuperColor, TCellValue, TEpoch, TInputCell, TInputSquare, TSelectedQuests, TSquareCellDiffColor, TSquareCellDiffSuperColor } from "entities/questMaker";
import { toJS } from "mobx";
import { useEffect, useState } from "react"
import { Epoch } from "../Epoch/Epoch";
import styles from "./EpochsList.module.scss";

export const EpochsList = () => {
  const [activeSeason, setSeasonToEdit] = useState<TActiveSeason | null>(null);
  const [epochs, setEpochs] = useState<TEpoch[] | []>([]);
  const [activeCell, setActiveCell] = useState<TActiveCell | null>(null);
  const [selectedQuests, setSelectedQuests] = useState<TSelectedQuests | null>(null);

  const checkIsEqual = (nextRowFirstValue: number | null, currentRowLastValue: number | null) => {
    if (nextRowFirstValue === null || currentRowLastValue === null) return false;
    return nextRowFirstValue === currentRowLastValue ? true : false;
  };

  const markActiveSeason = (eIndex: number | null, sIndex: number | null) => {
    if (eIndex === null || sIndex === null) {
      setSeasonToEdit(prev => null);
      return;
    }
    setSeasonToEdit(prev => { return { eIndex, sIndex } });
  };

  const changeSelectedQuests = (selQuests: TSelectedQuests | null) => {
    setSelectedQuests(prev => selQuests);
  };

  const markActiveCell = (eIndex: number | null, sIndex: number | null, row: number | null, col: number | null) => {
    if (eIndex === null || sIndex === null || row === null || col === null) {
      setActiveCell(null);
      return;
    }
    setActiveCell(prev => { return { eIndex, sIndex, row, col } });
  };

  const checkElements = (elements: TInputCell[]) => {
    const elementsToCheck = elements.map(el => el.value);

    const valueMap = {} as any;
    const errorValue: number[] = [];

    for (let i = 0; i < elementsToCheck.length; i++) {
      const value = elementsToCheck[i];
      if (!value) continue;

      if (!valueMap[value]) {
        valueMap[value] = [i];
      }
      else {
        valueMap[value].push(i);
        errorValue.push(value);
      }
    }
    return errorValue;
  };

  // const checkSquareSumm = (square: TInputSquare): boolean => {
  //   if (square.cells.length === 0) return false;
  //   const squareSize = Math.sqrt(square.cells.flat().length);
  //   const squareTotalValue = Math.floor(squareSize * (squareSize + 1) / 2) * squareSize;
  //   const squareCheckSumm = square.cells.flat().reduce((sum, el) => sum = el.value ? sum + el.value : sum, 0);

  //   if (squareCheckSumm === squareTotalValue) return true;
  //   else return false;
  // };

  const markErrors = (square: TInputSquare, row: number, col: number, rowErrors: number[], colErrors: number[]): TInputSquare => {
    square.cells[row].forEach(cell => cell.isError = cell.value && (cell.value > inputSquareSize || rowErrors.includes(cell.value)) ? true : false);
    square.cells.forEach(row => row[col].isError = row[col].value && (row[col].value! > inputSquareSize || colErrors.includes(row[col].value!)) ? true : false);

    // check the last element of row - it must not be equal to the next row's first value 
    if (col === inputSquareSize - 1) {
      const nextRowIndex = (row + 1) % inputSquareSize;

      const currentCell = square.cells[row][col];
      const nextRowCell = square.cells[nextRowIndex][0];

      const isLastValueError = checkIsEqual(nextRowCell.value, currentCell.value);

      if (isLastValueError) {
        currentCell.isError = true;
        nextRowCell.isError = true;
      }
      else {
        currentCell.isError = currentCell.value && (rowErrors.includes(currentCell.value) || colErrors.includes(currentCell.value)) ? true : false;

        nextRowCell.isError = nextRowCell.value && (rowErrors.includes(nextRowCell.value) || colErrors.includes(nextRowCell.value)) ? true : false;
      }
    }

    // check the first element of row - it must not be equal to the previous row's last value 
    if (col === 0) {
      const prevRowIndex = row === 0 ? inputSquareSize - 1 : row - 1;

      const currentCell = square.cells[row][col];
      const prevRowCell = square.cells[prevRowIndex][inputSquareSize - 1];

      const isLastValueError = checkIsEqual(prevRowCell.value, currentCell.value);

      if (isLastValueError) {
        currentCell.isError = true;
        prevRowCell.isError = true;
      }
      else {
        currentCell.isError = currentCell.value && (rowErrors.includes(currentCell.value) || colErrors.includes(currentCell.value)) ? true : false;

        prevRowCell.isError = prevRowCell.value && (rowErrors.includes(prevRowCell.value) || colErrors.includes(prevRowCell.value)) ? true : false;
      }
    }
    return square;
  };

  const checkSquareCell = (square: TInputSquare, row: number, col: number): TInputSquare => {
    const rowErrors = checkElements(square.cells[row]);

    const squareColumn = square.cells
      .map(row => row.map((value, index) => index === col ? value : null))
      .flat()
      .filter(value => value) as TInputCell[];
    const colErrors = checkElements(squareColumn);

    return markErrors(square, row, col, rowErrors, colErrors);
  };

  const changeCellValue = (eIndex: number, sIndex: number, row: number, col: number, value: TCellValue) => {
    const updatedEpochs = [...epochs];

    updatedEpochs[eIndex].seasons[sIndex].cells[row][col].value = value;
    updatedEpochs[eIndex].seasons[sIndex] = checkSquareCell(updatedEpochs[eIndex].seasons[sIndex], row, col);
    setEpochs(updatedEpochs);
  };

  const changeCellDiffColor = (eIndex: number, sIndex: number, row: number, col: number, color: string) => {
    const updatedEpochs = [...epochs];

    const newCellColorInfo = color === "null"
      ? {} as TSquareCellDiffColor
      : {
        coords: { col, row },
        color: color as TCellColor
      };

    // if in the same row there is selected diff color for call:
    const prevDiffColorInRowCellIndex = updatedEpochs[eIndex].seasons[sIndex].cellDiffColors.findIndex(info => info.coords ? info.coords.row === row : false);
    if (prevDiffColorInRowCellIndex >= 0) {
      const prevDiffColorInRowInfo = updatedEpochs[eIndex].seasons[sIndex].cellDiffColors[prevDiffColorInRowCellIndex];

      updatedEpochs[eIndex].seasons[sIndex].cells[prevDiffColorInRowInfo.coords.row][prevDiffColorInRowInfo.coords.col].diffColor = null;
      updatedEpochs[eIndex].seasons[sIndex].cellDiffColors[prevDiffColorInRowCellIndex] = {} as TSquareCellDiffColor;
    }

    // if in the same column there is a selected diff color for cell:
    const prevDiffColorInColInfo = epochs[eIndex].seasons[sIndex].cellDiffColors[col];
    if (prevDiffColorInColInfo.coords) {
      updatedEpochs[eIndex].seasons[sIndex].cells[prevDiffColorInColInfo.coords.row][prevDiffColorInColInfo.coords.col].diffColor = null;
    }

    updatedEpochs[eIndex].seasons[sIndex].cellDiffColors[col] = newCellColorInfo;
    updatedEpochs[eIndex].seasons[sIndex].cells[row][col].diffColor = color === "null" ? null : color as TCellColor;
    setEpochs(updatedEpochs);
  };

  const changeCellSuperColor = (eIndex: number, sIndex: number, row: number, col: number, color: string) => {
    const updatedEpochs = [...epochs];

    const newCellColorInfo = color === "null"
      ? {} as TSquareCellDiffSuperColor
      : {
        coords: { col, row },
        color: color as TCellSuperColor
      };

    // reset previous value to null
    const prevCellSuperColor = { ...updatedEpochs[eIndex].seasons[sIndex].cellSuperColors };
    if (prevCellSuperColor.coords) {
      updatedEpochs[eIndex].seasons[sIndex].cells[prevCellSuperColor.coords.row][prevCellSuperColor.coords.col].superColor = null;
    }

    updatedEpochs[eIndex].seasons[sIndex].cellSuperColors = newCellColorInfo;
    updatedEpochs[eIndex].seasons[sIndex].cells[row][col].superColor = color === "null" ? null : color as TCellSuperColor;
    setEpochs(updatedEpochs);
  };

  const changeSeasonColor = (eIndex: number, sIndex: number, color: string) => {
    const updatedEpochs = [...epochs];

    const newSquareColor = color === "null"
      ? null
      : color;

    updatedEpochs[eIndex].seasons[sIndex].squareColor = newSquareColor as TCellColor;
    setEpochs(updatedEpochs);
  };

  // const changeSeasonIds = (eIndex: number, color: string, value: string) => {
  //   const newEpochs = [...epochs];

  //   newEpochs[eIndex].colorIds[color as TCellAllColor].title = value === "null"
  //     ? null
  //     : value;

  //   newEpochs[eIndex].colorIds[color as TCellAllColor].costumeIds = value === "null"
  //     ? []
  //     : value.split(":").map(v => Number(v));

  //   setEpochs(newEpochs);
  // };

  const calculateSeason = (eIndex: number, sIndex: number, season: TInputSquare) => {
    const updated = [...epochs];
    updated[eIndex].seasons[sIndex] = season;

    setEpochs(prev => updated);
  };

  const addSeason = (eIndex: number) => {
    const newEpochs = [...epochs];
    const newSeason = makeEmptySeason();

    newEpochs[eIndex].seasons = [...newEpochs[eIndex].seasons, newSeason];
    setEpochs(newEpochs);
  };

  const removeSeason = (eIndex: number, sIndex: number) => {
    const newEpochs = [...epochs];
    const updatedSeasons = newEpochs[eIndex].seasons.filter((s, index) => index !== sIndex);
    newEpochs[eIndex].seasons = updatedSeasons;
    setEpochs(newEpochs);
  };

  const addEpoch = () => {
    const newEpoch = makeEmptyEpoch(epochs.length);
    setEpochs([...epochs, newEpoch]);
  };

  const removeEpoch = (eIndex: number) => {
    const newEpochs = [...epochs].filter((epoch, index) => index !== eIndex);
    setEpochs(newEpochs);
    localStorage.setItem("epochs", JSON.stringify(newEpochs));
  };

  const saveAllEpochs = () => {
    if (epochs.length === 0) return;

    const aElement = document.createElement("a");
    aElement.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(toJS(epochs), null, 4)));
    aElement.setAttribute("download", "quest-squares.json");
    aElement.style.display = "none";
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
  };

  const loadEpochs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();

    if (!event.target.files || !event.target.value) return;

    fileReader.readAsText(event.target.files[0], "UTF-8");

    fileReader.onload = event => {
      const stringFromFile = event.target ? event.target.result : "null";
      if (typeof stringFromFile === "string") {
        const result: TEpoch[] = JSON.parse(stringFromFile);

        if (result.every(epoch => {
          if (!epoch.title) return false;
          if (epoch.seasons.length === 0) return false;
          // if (Object.keys(epoch.colorIds).length !== Object.keys(cellAllColors).length) return false;
          return true;
        })) {
          console.log("success");
          setEpochs(prev => result);
        }
      }
    };
  };

  const clearAllEpochs = () => {
    setEpochs(prev => []);
    localStorage.setItem("epochs", JSON.stringify([]));
  };

  const deploySelectedQuests = () => {
  };

  const updateLSTempData = () => {
    if (epochs.length === 0) return;

    const tempData = JSON.stringify(epochs);
    localStorage.setItem("epochs", tempData);
    console.log("update epochs in localStorage");
  };

  updateLSTempData();

  useEffect(() => {
    const checkLSTempData = () => {
      const savedEpochs = localStorage.getItem("epochs");
      if (savedEpochs && JSON.parse(savedEpochs).length > 0) {
        setEpochs(prev => JSON.parse(savedEpochs));
        console.log("restored epochs from localStorage");
      }
    };

    checkLSTempData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkSquareCellValues = (square: TInputSquare): TInputSquare => {
      square.cells.forEach((row, rowIndex) => {
        const rowErrors = checkElements(row);

        row.forEach((el, elIndex) => {
          const columnn = square.cells
            .map(row => row.map((value, index) => index === elIndex ? value : null))
            .flat()
            .filter(value => value) as TInputCell[];
          const colErrors = checkElements(columnn);

          el.isError = el.value && (el.value > inputSquareSize || rowErrors.includes(el.value) || colErrors.includes(el.value)) ? true : false;

          if (elIndex === inputSquareSize - 1) {
            const nextRowIndex = (rowIndex + 1) % inputSquareSize;
            const nextRowCell = square.cells[nextRowIndex][0];

            const isLastValueError = checkIsEqual(nextRowCell.value!, el.value!);

            if (isLastValueError) {
              el.isError = true;
              nextRowCell.isError = true;
            }
            else {
              el.isError = el.value && (rowErrors.includes(el.value) || colErrors.includes(el.value)) ? true : false;
              nextRowCell.isError = nextRowCell.value && (rowErrors.includes(nextRowCell.value) || colErrors.includes(nextRowCell.value)) ? true : false;
            }
          }
        });
      });

      return square;
    };

    const pasteSquareFromClipboard = (event: ClipboardEvent) => {
      if (activeSeason === null) return;

      event.preventDefault();

      if (!event.clipboardData) return;
      // Assume that we paste squares from excel and between squares there is a gap in only one empty column

      const pasteData = event.clipboardData.getData("text")
        .split(/(\r\n)/gm)
        .filter(str => str !== "\r\n")
        // do not allow empty cells in input tables:
        // .map(str => str.split("\t\t").map(row => row.split("\t")));
        // allow empty cells in input tables:
        .map(str => {
          const splitStr = str.split("\t");
          const result: string[][] = [];
          for (let i = 0; i < splitStr.length; i += inputSquareSize) {
            const chunk = splitStr.slice(i, i + inputSquareSize);
            result.push(chunk);
            i++;
          }
          return result
        })
      pasteData.pop();

      const inputSquareCount = pasteData[0].length;
      const newSquares = new Array(inputSquareCount).fill(null).map(arr => { return { ...makeEmptySeason() } });

      pasteData.forEach((row, rowIndex) => row.forEach((squareRow, squareIndex) => squareRow.forEach((elem, elemIndex) => {
        const newCell = fillQuestWithDefaultValues(elemIndex, rowIndex);
        newCell.value = Number(elem) as TCellValue;
        newSquares[squareIndex].cells[rowIndex][elemIndex] = newCell;
      })));

      const checked = newSquares
        // do not allow wrong summ in input tables 
        // .filter(square => checkSquareSumm(square))
        .map(square => checkSquareCellValues(square))
        .slice(0, inputSquareSize - epochs[activeSeason.eIndex].seasons.length + 1);

      const newEpochs = [...epochs];

      newEpochs[activeSeason.eIndex].seasons.splice(activeSeason.sIndex, 1, ...checked);

      setEpochs(newEpochs)
    };
    document.addEventListener("paste", pasteSquareFromClipboard);

    return () => {
      document.removeEventListener("paste", pasteSquareFromClipboard);
    }
  }, [activeSeason, epochs]);

  return (
    <div className={styles["epoch-list"]}>
      {epochs.length > 0 &&
        epochs.map((epoch, eIndex) => {
          return (
            <Epoch
              key={epoch.title}
              epoch={epoch}
              eIndex={eIndex}
              addNewSeason={addSeason}
              removeSeason={removeSeason}
              calculateSeason={calculateSeason}
              removeEpoch={removeEpoch}
              changeSeasonColor={changeSeasonColor}
              changeCellValue={changeCellValue}
              changeCellDiffColor={changeCellDiffColor}
              changeCellSuperColor={changeCellSuperColor}
              setActiveCell={markActiveCell}
              activeCell={activeCell}
              activeSeason={activeSeason}
              setActiveSeason={markActiveSeason}
              selectedQuests={selectedQuests}
              setSelectedQuests={changeSelectedQuests}
              deploySelectedQuests={deploySelectedQuests}
            />
          )
        })
      }
      <div className={styles["epoch-btns"]}>
        <button className={styles["add-epoch"]} onClick={addEpoch}>Add new Epoch</button>
        <button className={`${styles["save-btn"]} ${epochs.length === 0 ? styles.disabled : ""}`} onClick={saveAllEpochs}>Save all data</button>
        <label htmlFor="files" className={styles["load-btn"]}>
          <input id="files" type="file" accept="application/json, .json" value="" onChange={loadEpochs} />
          Load
        </label>
        <button className={`${styles["reset-btn"]} ${epochs.length === 0 ? styles.disabled : ""}`} onClick={clearAllEpochs}>Clear all</button>
      </div>
    </div>
  )
}
