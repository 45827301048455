import { InfoStatisticsBlock } from "entities/InfoStatisticsBlock";
import { observer } from 'mobx-react';
import { useContext } from 'react'
import { ButtonWithLabel, ReloadButton } from "shared/ui";
import { H2QContext } from 'app/core/H2QContext';
import styles from "./NFTStats.module.scss";
import { ChangeViewModeForAssets } from "features/ChangeViewModeForAssets";
import { getNftInfo } from "entities/nft";

export const NFTStats = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const reloadNft = () => {
    h2qAccount.fetchNTFs();
  };

  const unstakeAllNft = async () => {
    console.log("Unstaking all Nft-Heroes");

    if (h2qAccount.q2hero.state === "pending") {
      return
    };

    if (stakedHeros) {

      if (stakedHeros.length === 0) {
        return;
      }

      h2qAccount.toggleUnstakingAll(true);
      try {
        await Promise.all(stakedHeros.map(async h => {
          const hero = getNftInfo(h);
          await h2qAccount.q2hero(hero);
        }));
        console.log("Successfully got rewards for all staked Nfts");
        h2qAccount.toggleRewardsToGet(false);
      }
      catch (error) {
        console.error("Error during unstaking:")
        console.error(error);
      }
      finally {
        h2qAccount.toggleUnstakingAll(false);
        await h2qAccount.fetchNTFs();
      }
    };

  };

  const totalValue = h2qAccount.uiStore.nftsToShow.reduce((acc, item) => { return acc + Number(item.pseudoNftData?.m_h2qMetaCharacter.valPrice) }, 0);
  const totalGenCost = h2qAccount.uiStore.nftsToShow.reduce((acc, item) => { return acc + Number(item.pseudoNftData?.m_h2qMetaCharacter.valPrice) + Number(item.pseudoNftData?.m_h2qMetaCharacter.genPrice) }, 0);

  const stakedHeros = h2qAccount.uiStore.nftsToShow.filter(nft => nft.pseudoNftData?.m_lockedInQuest !== null);

  return (
    <div className={styles['stats-wrapper']}>

      <InfoStatisticsBlock
        title="NFT-HERO Created"
        value={h2qAccount.fetchNTFs.state === "pending"
          ? "..."
          : h2qAccount.uiStore.nftsToShow.length
            ? h2qAccount.uiStore.nftsToShow.length
            : 0}
        children={<ReloadButton reloadAction={reloadNft} isWaiting={h2qAccount.fetchNTFs.state === "pending"} />
        }
      />

      <InfoStatisticsBlock
        title="NFT-HERO Staked"
        value={h2qAccount.fetchNTFs.state === "pending"
          ? "..."
          : stakedHeros.length
            ? stakedHeros.length
            : 0}
        children={<ButtonWithLabel
          action={unstakeAllNft}
          isActive={stakedHeros.length > 0 && h2qAccount.q2hero.state !== "pending"}
          isWaiting={h2qAccount.q2hero.state === "pending"}
          title="Unstake All"
        />}
      />

      <InfoStatisticsBlock
        title="Total Value"
        value={h2qAccount.fetchNTFs.state === "pending"
          ? "..."
          : totalValue
            ? totalValue
            : 0}
      />

      <InfoStatisticsBlock
        title="Total Generation Cost"
        value={h2qAccount.fetchNTFs.state === "pending"
          ? "..."
          : totalGenCost
            ? totalGenCost
            : 0}
      />

      <div className={styles["control-item"]}>
        {/* <p className={styles["buttons-title"]}>Show options</p> */}
        <ChangeViewModeForAssets
          modes={[4, 6, 8]}
          type={"nfts"}
          isFavButton={true}
          isStakedButton={true}
        />
      </div>
    </div>
  )
})
