import { Text } from '@saitonakamura/react-pixi';
import { TextStyle } from "pixi.js";
import { tooltipFont, tooltipFontSize, tooltipFontColor, tooltipLineHeight, tooltipFontOffset } from "entities/generator";

interface IBottomMessage {
  x: number;
  y: number;
  text: string;
  width: number;
};

export const BottomMessage = (props: IBottomMessage) => {
  return (
    <Text
      position={[props.x, props.y]}
      anchor={[0, 0]}
      text={props.text}
      x={props.x + 2 * tooltipFontOffset}
      y={props.y + tooltipFontOffset}
      style={
        new TextStyle({
          fontFamily: tooltipFont,
          fontSize: tooltipFontSize,
          fill: tooltipFontColor,
          align: "left",
          wordWrap: true,
          wordWrapWidth: props.width - tooltipFontOffset * 4,
          lineHeight: tooltipLineHeight
        })
      }
    />
  )
};