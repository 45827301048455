import { useContext, useEffect, useState } from "react";
import styles from "./SelectedQuestPage.module.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { H2QContext } from "app/core/H2QContext";
import { ProperNftGallery } from "widgets";
import { observer } from "mobx-react";
import { RotatingCircleCenter } from "shared/ui";
import { BackLink } from "shared/ui/BackLink";
import { getNftFromArray, getNfts, getNftsForQuest, NftElement } from "entities/nft";
import { getQuestPrice } from "entities/quest";
import { QuestElement } from "widgets";
import { StakeHeroBlock } from "features/quest";
import { QuestProperNftControlBlock } from "features/QuestProperNftControlBlock/QuestProperNftControlBlock";
import { ProperNft } from "widgets/ProperNftGallery/ProperNftGallery";

export const SelectedQuestPage = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const [selectedNfts, setSelectedNfts] = useState<string[]>([]);
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const [properNfts, setProperNfts] = useState<ProperNft[]>([]);
  const [isPropNftsGetting, setProperNftsGetting] = useState<boolean>(true);

  const nftId = queryParams.get("nft-id");

  const questData = h2qAccount.quests.find(item => item.id.slice(2) === id) || null;

  const questCost = questData
    ? getQuestPrice(questData.m_questParams)
    : null;

  const nftsToSend = properNfts.length > 0 && selectedNfts.length > 0
    ? properNfts.filter(nft => selectedNfts.includes(nft.nftEl.pseudoNFTAddr)).map(item => item.nftEl)
    : [];

  const selectNftForQuest = (nftId: string) => {
    if (selectedNfts.includes(nftId)) {
      console.log("Delete from selected nfts nft with id:", nftId);
      const newSelected = [...selectedNfts].filter(n => n !== nftId);
      setSelectedNfts(prev => newSelected);
    }
    else {
      console.log("Add to selected nfts for quest new nft with id:", nftId);

      const newSelected = [...selectedNfts];
      newSelected.push(nftId);
      setSelectedNfts(prev => newSelected);
    }
  };

  useEffect(() => {
    // to fix FireFox issue on load page (scrolling down)
    const scrollOnTop = () => {
      if (window.scroll) {
        window.scroll(0, 0);
      }
    };

    const checkData = async () => {
      console.log("Check data on first quest page load");

      if (nftId) {
        console.log("Quests are shown for selected nft:", nftId);
      }

      if (h2qAccount.quests.length === 0) {
        console.log("No quests are availalbe. Loading quests...");
        if (h2qAccount.loadAllQuests.state !== "pending") {
          await h2qAccount.loadAllQuests();
        }
      }

      if (h2qAccount.nfts.length === 0) {
        console.log("No nfts are availalbe. Loading nfts...");
        if (h2qAccount.fetchNTFs.state !== "pending") {
          await h2qAccount.fetchNTFs();
        }
      }

      if (h2qAccount.totalNfts.length === 0) {
        console.log("Total nfts are not loaded. Loading total nfts...");
        await h2qAccount.getTotalNfts();
      }
    };

    scrollOnTop();
    checkData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getProperNfts = async () => {
      if (questCost === null) return;

      setProperNftsGetting(true);
      console.log("Getting proper NFTS for quest:", questData?.id);
      let nfts = [] as NftElement[];

      if (nftId && h2qAccount.nfts.length) {
        const selectedNft = h2qAccount.nfts.filter(item => item.pseudoNFTAddr.slice(2) === nftId)[0];
        const nftIndex = h2qAccount.nfts.indexOf(selectedNft);
        const newNfts = [...h2qAccount.nfts];
        newNfts.splice(nftIndex, 1);
        newNfts.unshift(selectedNft);
        nfts = getNftFromArray(newNfts);
      }
      else {
        nfts = getNfts(h2qAccount.nfts);
      }

      const properNfts = getNftsForQuest(nfts, questCost);
      const properNftsData = (await Promise.all(properNfts.map(async n => {
        const questClientData = await h2qAccount.getQuestClientData(n.pseudoNFTAddr, questData!.id);
        return { nftEl: n, questClientData }
      })))

      setProperNfts(prev => properNftsData);
      setProperNftsGetting(false);
    };

    getProperNfts();
    // eslint-disable-next-line
  }, [h2qAccount.nfts])
  return (

    <div className={styles["quest-element"]}>
      {questData && questCost &&
        <div className={`${styles["quest-top-wrapper"]} ${styles["on-top"]}`}>
          <BackLink />
          <div className={styles["quest-block"]}>
            <QuestElement questData={questData}>
              <div className={styles["quest-inner-block"]} >
                <StakeHeroBlock
                  clearNftsToSend={() => setSelectedNfts(prev => [])}
                  questData={questData}
                  nftsToSend={nftsToSend}
                />
                <QuestProperNftControlBlock
                  questId={questData.id}
                />
              </div>
            </QuestElement>
          </div>
        </div>
      }

      {properNfts && questData &&
        <div className={styles["proper-nft-wrapper"]}>
          <ProperNftGallery
            questData={questData}
            nfts={properNfts}
            selectedNfts={selectedNfts}
            setSelectedNft={selectNftForQuest}
            nftFromGalleryId={nftId || ""}
          />
        </div>
      }

      {(isPropNftsGetting
        || h2qAccount.loadAllQuests.state === "pending"
        || h2qAccount.fetchNTFs.state === "pending"
        || h2qAccount.getInventoryDetails.state === "pending") &&
        <RotatingCircleCenter />
      }
    </div >
  )
});