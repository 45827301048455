import { H2QContext } from "app/core/H2QContext";
import { avatarNames } from "entities/avatar";
import { FilterButton } from "shared/ui/FilterButton";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterAvatarName.module.scss";

export const FilterAvatarName = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const onClickAvatarName = (name: string) => {
    h2qAccount.uiStore.changeAvatarFilterAvatarName(name);
  };

  return (
    <div className={styles["items-type"]}>
      {avatarNames.map(a => {
        return (
          <FilterButton
            key={a}
            name={a}
            clickAction={() => onClickAvatarName(a)}
            isSelected={h2qAccount.uiStore.avatarFilter.avatarName.includes(a)}
          />
        )
      })}
    </div>
  )
})