import { generatorsData, itemsData } from "shared/model";

const itemUrlList: string[] = Object.values(itemsData.inventories)
  .map((genVal) => {
    return Object.values(genVal).map((catVal) => {
      return Object.values(catVal).map((itemVal) => {
        return [itemVal.thumb_image_file, itemVal.thumb_slot_icon];
      })
    })
  }).flat(3);

const avatarUrlList: string[] = Object.values(itemsData.avatars)
  .map((genVal) => {
    return Object.values(genVal)
      .map(value => {
        if (value.heads.thumb_happy === "none") {
          return [value.thumb_body]
        }
        else return [value.thumb_body, value.heads.thumb_happy]
      })
  }).flat(2);

const generatorsUrlList: string[] = Object.values(generatorsData.generators).map(value => {
  return value.banner
});

export const assetList = [
  ...itemUrlList,
  ...avatarUrlList,
  ...generatorsUrlList,
  "/res/Generator/bg_animal_horizontal.png",
  "/res/Generator/bg_humaniod_horizontal.png",
  "res/UserGuide/bg_user-guide.jpg"
];