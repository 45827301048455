import { Timer } from "entities/Timer";
import styles from "./QuestTopInfo.module.scss";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { calcQuestInfo } from "../../model/questInfo";

interface IQuestTopInfo {
  questData: H2QuestResponse,
};

export function QuestTopInfo({ questData }: IQuestTopInfo) {

  const questInfo = calcQuestInfo(questData);

  return (
    <div className={styles["top-info-wrapper"]}>
      <div className={styles["top-info"]}>
        <h3>
          {questInfo.startTime > Date.now()
            ? "Opens in"
            : "Expires in"
          }
        </h3>
        <div className={styles["quest-info-wrapper"]}>
          <Timer finishTime={questInfo.startTime > Date.now() ? questInfo.startTime : questInfo.finishTime} size={16} />
        </div>
      </div>
      <div className={styles["top-info"]}>
        <h3>Played / Max Participants</h3>
        <div className={styles["quest-info-wrapper"]}>
          <p className={styles["top-info__value"]}>{parseInt(questData.m_totalClientsLocked, 16)} / {questInfo.maxParticipants}</p>
        </div>
      </div>
      {/* <div className={styles["top-info"]}>
        <h3>Heroes in the Quest</h3>
        <div className={styles["quest-info-wrapper"]}>
          <p className={styles["top-info__value"]}>{parseInt(questData.m_totalClientsLocked, 16)}</p>
        </div>
      </div> */}
      <div className={styles["top-info"]}>
        <h3>Token Pool / Total</h3>
        <div className={styles["quest-info-wrapper"]}>
          <p className={styles["top-info__value"]}>
            {questInfo.coinsLeftH2Q} / {questInfo.coinsPoolH2Q}
            <span className={styles.h2q}>H2Q</span>
          </p>
        </div>
      </div>
      <div className={styles["top-info"]}>
        <h3>Token Pool / Total</h3>
        <div className={styles["quest-info-wrapper"]}>
          <p className={styles["top-info__value"]}>
            {questInfo.coinsLeftQST} / {questInfo.coinsPoolQST}
            <span className={styles.qst}>QST</span>
          </p>
        </div>
      </div>
    </div>)
}