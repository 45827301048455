import { FilterFoldableBlockWithTitle } from "entities/FilterFoldableBlockWithTitle";
import { FilterNftAvatarName } from "features/nftFilter";
import { FilterNftGeneratorType } from "features/nftFilter";
import { FilterNftLevel } from "features/nftFilter";
import { FilterNftOriginalHero } from "features/nftFilter";
import { FilterNftOriginality } from "features/nftFilter";
import { FilterNftValue } from "features/nftFilter";

export const NftFilter = () => {
  return (
    <>
      <FilterFoldableBlockWithTitle
        title="Generator Nft"
      >
        <FilterNftGeneratorType />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Avatar Name"
      >
        <FilterNftAvatarName />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Nft-Hero Value"
      >
        <FilterNftValue />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Nft-Hero Original Name"
      >
        <FilterNftOriginalHero />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Nft-Hero Originalitiy"
      >
        <FilterNftOriginality />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Nft-Hero Level"
      >
        <FilterNftLevel />
      </FilterFoldableBlockWithTitle>
    </>
  )
};