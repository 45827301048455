import styles from "./GeneratorItemButtons.module.scss";
import { ReactComponent as AssetsIcon } from "app/icons/icon-collections-black.svg";
import { ReactComponent as NftIcon } from "app/icons/icon-nft-hero-black.svg";
import { ReactComponent as PlayIcon } from "app/icons/icon-play-button.svg";
import { IconColorVariants } from "app/icons/icons";
import { useContext } from "react";
import { observer } from "mobx-react";
import { H2QContext } from "app/core/H2QContext";
import { useNavigate } from "react-router-dom";

interface IGeneratorItemButtons {
  genId: number;
  title: string;
  isGeneratorActive?: boolean;
  isPlayBtnActive?: boolean;
  playBtnAction?: ((e: React.SyntheticEvent) => void);
};

export const GeneratorItemButtons = observer(function ({ genId, isGeneratorActive, title, isPlayBtnActive, playBtnAction }: IGeneratorItemButtons) {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();

  const assetsIconColor = IconColorVariants.gray;

  const nftsIconColor = IconColorVariants.gray;

  const playIconColor = isPlayBtnActive
    ? IconColorVariants.blue
    : IconColorVariants.gray;

  const showNftsByGenType = () => {
    h2qAccount.uiStore.changeNftFilterGenId(genId);
    navigate("/h2q/nft/");
  };

  const showAssetsByGenType = () => {
    h2qAccount.uiStore.changeAvatarFilterGenId(genId);
    navigate("/h2q/collections/avatar");
  };


  return (
    <div className={styles['generator-buttons']}>
      {isGeneratorActive !== undefined &&
        <>
          <AssetsIcon className={`${styles["assets-icon"]} ${styles.active}`} fill={assetsIconColor} onClick={showAssetsByGenType} />
          <NftIcon className={`${styles["nft-icon"]} ${styles.active}`} fill={nftsIconColor} onClick={showNftsByGenType} />
        </>
      }
      <p className={styles["info-title"]}>{title}</p>
      {playBtnAction &&
        <PlayIcon onClick={playBtnAction} className={`${styles["play-icon"]} ${isPlayBtnActive ? styles.active : ""}`} fill={playIconColor} />
      }
    </div>
  )
});