import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import styles from "./GeneratorSelectorStats.module.scss";

type GeneratorStat = {
  id: number;
  title: string;
  value: number;
  color: string;
};

export const GeneratorSelectorStats = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const [genTotalStats, setGenTotalStats] = useState({
    nftsCount: -1,
    h2qCount: -1,
    itemsCount: -1,
    avatarsCount: -1,
    generatorCount: -1
  });

  const genStats: GeneratorStat[] = [
    {
      id: 1,
      title: "HEROES CREATED",
      color: "#d97186",
      value: genTotalStats.nftsCount
    },
    {
      id: 2,
      title: "CONTRIBUTED h2q LIQIDITY",
      value: genTotalStats.h2qCount,
      color: "#5e96c9"
    },
    {
      id: 3,
      title: "ITEMS",
      value: genTotalStats.itemsCount,
      color: "#5ec9be"
    },
    {
      id: 4,
      title: "AVATARS",
      value: genTotalStats.avatarsCount,
      color: "#895ec9"
    },
    // {
    //   id: 5,
    //   title: "TOTAL GENERATORS NFT",
    //   value: genTotalStats.generatorCount,
    //   color: "#895ec9"
    // },
  ];


  useEffect(() => {
    const getNftsCount = () => {
      return h2qAccount.totalNfts.reduce((result, item) => {
        result.count += 1;
        result.price += Number(item.m_price);
        return result
      }, {
        count: 0,
        price: 0,
      })
    };

    const getStats = async () => {
      await h2qAccount.getTotalNfts();
      const { count, price } = getNftsCount();

      const newGenTotalStats = { ...genTotalStats };
      newGenTotalStats.nftsCount = count;
      newGenTotalStats.h2qCount = price;
      newGenTotalStats.itemsCount = Math.floor(Math.random() * 1000); // TODO
      newGenTotalStats.avatarsCount = Math.floor(Math.random() * 1000); // TODO
      // newGenTotalStats.generatorCount = 2;

      setGenTotalStats(prev => newGenTotalStats);
    };

    getStats();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["top-stats"]}>
      {genStats.map(item => {
        return (
          <div
            key={item.id}
            className={styles["gen-stat"]}
            style={{ border: `0.1rem solid ${item.color}` }}
          >
            <p className={styles["gen-title"]}>{item.title}</p>
            <p className={styles["gen-value"]}>{item.value !== -1 ? item.value : "..."}</p>
          </div>
        )
      })}
    </div>
  )
});