import { h2qAccount } from "app/core/H2QContext";
import { ReactComponent as CancelIcon } from "app/icons/icon-cancel-white.svg";
import { observer } from "mobx-react";
import { delay } from "shared/lib";
import styles from "./SideBarRight.module.scss";

export const SideBarRight = observer(function () {

  const closeSideBar = async () => {
    h2qAccount.uiStore.toggleRightSidebar(false);
    await delay(500);
    h2qAccount.uiStore.setRightSidebarChild(null, null);
  };

  return (
    <div
      className={`${styles["side-widget"]} ${h2qAccount.uiStore.isRightSidebarOpen ? styles.open : ""}`}
    >
      <CancelIcon onClick={closeSideBar} className={styles["close-btn"]} />
      {h2qAccount.uiStore.rightSidebarChild.child}
    </div>
  )
});