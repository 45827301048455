import styles from "./PlusButton.module.scss";
import { ReactComponent as IconAddGray } from "app/icons/icon-add-black.svg";
import { ReactComponent as ShowQuestIcon } from "app/icons/icon-quest-network-black.svg";

import { IconColorVariants } from "app/icons/icons";

interface IPlusButton {
  action: () => void;
  isActive: boolean;
  size?: number;
  isSelected: boolean;
  style?: React.CSSProperties;
  choseBtnType: "sendToQuest" | "showQuests"
};

export const PlusButton = ({ action, isActive, size = 24, isSelected, style, choseBtnType }: IPlusButton) => {
  const iconColor = isSelected
    ? IconColorVariants.yellow
    : IconColorVariants.gray;

  return (
    <button
      className={styles["choose-btn"]}
      style={{ ...style, width: `${size / 10}rem`, height: `${size / 10}rem` }}
      disabled={!isActive}
    >
      {choseBtnType === "sendToQuest" &&
        <IconAddGray
          className={styles["btn-image"]}
          fill={iconColor}
          onClick={action}
        />
      }

      {choseBtnType === "showQuests" &&
        <ShowQuestIcon
          className={styles["btn-image"]}
          fill={iconColor}
          onClick={action}
        />
      }
    </button>
  )
}
