import { PixiComponent } from "@saitonakamura/react-pixi";
import { triangleColorBlockAlpha, triangleSize } from "entities/generator";
import { ItemBlockColorKeys } from "entities/itemColors";
import { Graphics } from "pixi.js";

interface ITriangleColorBlock {
  color: ItemBlockColorKeys;
  x: number;
  y: number;
};

export const TriangleItemColoredBlock = PixiComponent<ITriangleColorBlock, Graphics>("TriangleItemColoredBlock", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(+`0x${props.color.slice(1)}`, triangleColorBlockAlpha);
    ins.moveTo(0 + props.x, props.y);
    ins.lineTo(0 + props.x, props.y - triangleSize);
    ins.lineTo(triangleSize + props.x, props.y);
    ins.endFill();
  }
});