import styles from "./ChooseQuestForHeroBlock.module.scss";
import { PlusButton as ChooseButton } from "shared/ui/PlusButton/PlusButton";
import { getTimeFromMilliSeconds, timeToDdHhMmSs } from "shared/lib";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { H2QuestParamsResult } from "app/core/H2QAccount";

interface IChooseQuestForHeroBlock {
  quest: H2QuestResponse;
  action: () => void;
  isActive: boolean;
  isSelected: boolean;
  isWaiting: boolean;
};

export const ChooseQuestForHeroBlock = observer(function ({ quest, action, isActive, isSelected, isWaiting }: IChooseQuestForHeroBlock) {
  const { h2qAccount } = useContext(H2QContext);
  const { id: nftId } = useParams();
  const nftInfo = h2qAccount.nfts.find(n => n.pseudoNFTAddr.slice(2) === nftId);
  const [questData, setQuestData] = useState<H2QuestParamsResult | null>(null);

  const choseQuest = () => {
    if (!isWaiting && questData) {
      h2qAccount.setCurrentHeroStakeDuration(Number(questData.duration) * 1000);
      action();
    }
  };

  useEffect(() => {
    const getQuestData = async () => {
      if (nftInfo) {
        const result = await h2qAccount.getQuestParams(nftInfo, quest);
        setQuestData(result);
      }
    };

    getQuestData();

    return () => {
      h2qAccount.setCurrentHeroStakeDuration(0);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["open-quest-block"]}>
      {isActive &&
        <>
          <p className={styles["open-quest-text"]} >Select QUEST</p>
          <ChooseButton
            action={choseQuest}
            isActive={!isWaiting}
            isSelected={isSelected}
            size={46}
            choseBtnType={"sendToQuest"}
          />
          {questData && <p className={styles["open-quest-timer"]} >{timeToDdHhMmSs(getTimeFromMilliSeconds(Number(questData.duration) * 1000 || 0))}</p>}
          {questData === null && <p className={styles["open-quest-timer"]} >Getting time...</p>}
        </>
      }
      {!isActive &&
        <p className={styles["disabled-quest-text"]} >The selected hero is not available</p>
      }
    </div>
  )
});