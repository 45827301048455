import { ReactNode } from "react";
import styles from "./BookmarkButtonsBlock.module.scss";

interface IBookmarkButtonsBlock {
  foldBlock?: ReactNode;
  addToFavBlock?: ReactNode;
};

export function BookmarkButtonsBlock({ addToFavBlock, foldBlock }: IBookmarkButtonsBlock) {
  return (
    <div className={styles["control-wrapper"]}>
      {addToFavBlock}
      {foldBlock}
    </div>)
};