import { client } from "app/model/tonClient";
import { Account } from "@eversdk/appkit";
import { H2QuestContract } from "app/contracts/H2QuestContract";
import { abiContract } from "@eversdk/core";
import { Abi } from "@eversdk/core/dist/modules";
import { H2QPseudoNftContract } from "app/contracts/H2QPseudoNftContract";

export async function getGraphQLQuery<R>(query: string): Promise<R> {
  return (await client.net.query({ query })).result.data;
}

export async function getAccountsData(addresses: string[], abi: Abi) {
  // TODO: pagination
  const { result } = await client.net.query({
    query: `{
      accounts(
        filter: {
          id: {
            in: ${JSON.stringify(addresses)}
          }
        }
      ) {
        id, 
        data
      }
    }`,
  });
  async function decodeData({ id, data }: { data?: string; id: string }) {
    if (!data) {
      return { id };
    }

    return {
      id,
      ...(
        await client.abi.decode_account_data({
          abi,
          data,
        })
      ).data,
    };
  }
  return Promise.all(result?.data?.accounts?.map(decodeData));
}

export async function getAccountData(account: Account) {
  const { data } = await account.getAccount()

  return (
    await client.abi.decode_account_data({
      abi: account.abi,
      data: data,
    })
  ).data;
}

(window as any).getAccountData = getAccountData;

export async function fetchGraphQL(codeHash: string): Promise<string[]> {
  // TODO: hardcode
  const getQuery = (limit: number, offset: string) => `
    {
      accounts(
        filter: {
          code_hash: {
            eq: "${codeHash.slice(2)}"
          },
          id: {
            gt: "${offset}"
          }
        },
        orderBy: {path: "id", direction: ASC},
        limit: ${limit}
      ) {
        id
      }
    }`;

  let allAccounts: Array<{ id: string }> = []
  for (let limit = 50, offset = '0'; ;) {
    const query = getQuery(limit, offset)
    const { accounts } = await getGraphQLQuery<{ accounts: Array<{ id: string }> }>(
      query
    );
    allAccounts = allAccounts.concat(accounts)
    if (accounts.length < limit) break

    offset = accounts.slice(-1)[0].id
  }


  return allAccounts.map((acc: any) => acc?.id);
}

export async function fetchByCodeHash(
  codeHash: string,
  abi: Abi = abiContract(H2QuestContract.abi)
) {
  const getQuery = (limit: number, offset: string) => `
    {
      accounts(
        filter: {
          code_hash: {
            eq: "${codeHash.slice(2)}"
          }
          id: {
            gt: "${offset}"
          }
        }
        
        orderBy: {path: "id", direction: ASC},
        
        limit: ${limit}
      ) {
        id, 
        data
      }
    }`;

  let allAccount: Array<{ id: string, data: string }> = [];

  for (let limit = 50, offset = '0:0'; ;) {
    const query = getQuery(limit, offset);
    const { accounts } = await getGraphQLQuery<{
      accounts: Array<{ id: string; data: string }>;
    }>(query);
    allAccount = allAccount.concat(accounts)
    if (accounts.length < limit) break;

    offset = accounts.slice(-1)[0].id
  }


  return await Promise.all(
    allAccount.map(async (acc) => {
      let data = null;
      try {
        data = (
          await client.abi.decode_account_data({
            abi,
            data: acc.data,
          })
        ).data;
      } catch (e) {
        console.log(e);
      }

      return { id: acc.id, ...data };
    })
  );
}


export async function fetchByInitCodeHash(
  initCodeHash: string,
  abi: Abi = abiContract(H2QPseudoNftContract.abi)
) {


  const getQuery = (limit: number, offset: string) => `
    {
      accounts(
        filter: {
          init_code_hash: {
            eq: "${initCodeHash.slice(2)}"
          }
          id: {
            gt: "${offset}"
          }
        }
        
        orderBy: {path: "id", direction: ASC},
        
        limit: ${limit}
      ) {
        id, 
        data
      }
    }`;

  let allAccount: Array<{ id: string, data: string }> = [];

  for (let limit = 50, offset = '0:0'; ;) {
    const query = getQuery(limit, offset);
    const { accounts } = await getGraphQLQuery<{
      accounts: Array<{ id: string; data: string }>;
    }>(query);
    allAccount = allAccount.concat(accounts)
    if (accounts.length < limit) {
      console.log('break', offset, accounts.length)
      break
    }

    offset = accounts.slice(-1)[0].id
  }


  return await Promise.all(
    allAccount.map(async (acc) => {
      let data = null;
      try {
        data = (
          await client.abi.decode_account_data({
            abi,
            data: acc.data,
          })
        ).data;
      } catch (e) {
        console.log(e);
      }

      return { id: acc.id, ...data };
    })
  );
}


(window as any).fetchByCodeHash = fetchByCodeHash;


(window as any).fetchByInitCodeHash = fetchByInitCodeHash;