import { ItemBlockColorVariants, ItemBlockColorKeys } from "entities/itemColors";
import styles from "./NftColoredSquaresGrid.module.scss";

interface INftColoredSquaresGrid {
  colors: ItemBlockColorKeys[];
};

export function NftColoredSquaresGrid({ colors }: INftColoredSquaresGrid) {
  return (
    <div className={styles["colors-grid"]}>
      {colors.map((item, index) => {
        return (
          <div
            key={index}
            className={styles["color-block"]}
            style={{
              backgroundColor: ItemBlockColorVariants[item]
            }}
          >
          </div>
        )
      })}
    </div>
  )
}
