interface IVDivider {
  value: number
}

export const VerticalDivider = ({ value }: IVDivider) => {
  return (
    <div style={{ "height": `${value / 10}rem` }}>
      {/* Just vertical divider */}
    </div>)
}
