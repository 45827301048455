import { ItemImageSVG } from "entities/item";
import styles from "./NftItemsGrid.module.scss";
import iconEmpty from "app/icons/icon-item-empty.svg";
import { NftColoredSquaresGrid } from "entities/nft";
import { NftElement } from "entities/nft";
import { getItemsBlocksColors, ImageColorBlock } from "entities/itemColors";

interface INftItemsGrid {
  nft: NftElement;
};

export const NftItemsGrid = ({ nft }: INftItemsGrid) => {
  const { result, colorArray } = getItemsBlocksColors(nft.items);

  return (
    <div className={styles["items-container"]}>
      <div className={styles["h-body"]}>
        <ItemImageSVG iconLink={result.Body?.iconLink ? result.Body.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Body?.color ? result.Body.color : "transparent"} />
      </div>
      <div className={styles["h-head"]}>
        <ItemImageSVG iconLink={result.Head?.iconLink ? result.Head.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Head?.color ? result.Head.color : "transparent"} />
      </div>
      <div className={styles["h-hips"]}>
        <ItemImageSVG iconLink={result.Hip?.iconLink ? result.Hip.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Hip?.color ? result.Hip.color : "transparent"} />
      </div>
      <div className={styles["h-boots"]}>
        <ItemImageSVG iconLink={result.Legs?.iconLink ? result.Legs.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Legs?.color ? result.Legs.color : "transparent"} />
      </div>
      <div className={styles["h-inv1"]}>
        <ItemImageSVG iconLink={result.Accessory1?.iconLink ? result.Accessory1.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Accessory1?.color ? result.Accessory1.color : "transparent"} />
      </div>
      <div className={styles["h-inv2"]}>
        <ItemImageSVG iconLink={result.Accessory2?.iconLink ? result.Accessory2.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Accessory2?.color ? result.Accessory2.color : "transparent"} />
      </div>
      <div className={styles["h-inv3"]}>
        <ItemImageSVG iconLink={result.Accessory3?.iconLink ? result.Accessory3.iconLink : iconEmpty} />
        <ImageColorBlock color={result.Accessory3?.color ? result.Accessory3.color : "transparent"} />
      </div>
      <div className={styles["statistic"]}>
        <p className={styles.title}>{nft.nftOriginType} {nft.nftOriginality}%</p>
        <NftColoredSquaresGrid colors={colorArray} />
      </div>
    </div>
  )
}
