import { FoldButton } from "shared/ui";

interface IFoldQuestBlock {
  fold: () => void;
  isFold: boolean;
};

export const FoldQuestBlock = ({ fold, isFold }: IFoldQuestBlock) => {
  return (
    <FoldButton
      action={fold}
      isActive={true}
      isFold={isFold}
      size={28}
    />
  )
};