import { Container, PixiComponent } from "@saitonakamura/react-pixi";
import { dotColor, dotGap, dotSize, threeDotsSize } from "entities/generator/model/generatorParams";
import { Graphics, Rectangle } from "pixi.js";
import { useState } from "react";

interface IDot {
  x: number;
  y: number;
  size: number;
  color: number;
};

const Dot = PixiComponent<IDot, Graphics>("Dot", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(props.color);
    ins.drawCircle(props.x + props.size / 2, props.y + props.size / 2, props.size / 2);
  }
});

interface IThreeDotsButton {
  x: number;
  y: number;
  action: () => void;
  onHoverOver: () => void;
  onHoverOut: () => void;
};

export const ThreeDotsButton = (props: IThreeDotsButton) => {
  const [isHovered, setHovered] = useState<boolean>(false);

  const onHoverOver = () => {
    setHovered(true);
    props.onHoverOver();
  };

  const onHoverOut = () => {
    setHovered(false)
    props.onHoverOut();
  };

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      buttonMode={true}
      alpha={isHovered ? 1 : 0.5}
      mousedown={props.action}
      mouseover={onHoverOver}
      mouseout={onHoverOut}
      hitArea={new Rectangle(0, 0, threeDotsSize, dotSize)}
    >
      <Dot x={0} y={0} size={dotSize} color={dotColor} />
      <Dot x={dotSize + dotGap} y={0} size={dotSize} color={dotColor} />
      <Dot x={2 * (dotSize + dotGap)} y={0} size={dotSize} color={dotColor} />
    </Container>
  )
}
