import { useContext } from "react";
import { observer } from "mobx-react";
import { H2QContext } from "app/core/H2QContext";

export const Balance = observer(() => {
    const { h2qAccount } = useContext(H2QContext);
    const date = "21.02.2024";
    const version = "0.2.b1";

    return <div style={{
        background: '#090914',
        color: "#6B798B",
        textAlign: "end",
        position: 'fixed',
        bottom: 0,
        right: 0,
        fontSize: "1.6rem",
        width: '100%',
        zIndex: 10
    }}>
        Version: {version} / {date} /
        ever:{h2qAccount.h2qBalance ? Math.round(h2qAccount.h2qBalance * 100) / 100 : "-"},
        root-ever: {h2qAccount.superRootBalance ? Math.round(h2qAccount.superRootBalance * 100) / 100 : "-"}
    </div>
})