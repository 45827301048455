import { InventoryItemData } from "app/core/H2QAccount";
import { getRarityName } from "entities/item";
import { KeyTagsBlock } from "entities/keyTags";
import { getItemKeyTags, getTagColor, KeyTagType } from "entities/keyTags/model/keyTags";
import styles from "./ItemInfoMainTab.module.scss";

interface IItemInfoMainTab {
  title: string | null;
  itemInfo: InventoryItemData;
  categoryId: number;
  itemId: number;
  showTitle?: boolean;
  showTags?: boolean;
};

export function ItemInfoMainTab({ title, itemInfo, categoryId, itemId, showTitle = true, showTags = true }: IItemInfoMainTab) {
  return (
    <>
      {showTitle &&
        <h3 className={styles['info-title']}>Item:<span className={styles["title-value"]}>{title ? title : "---"}</span></h3>
      }
      <div>
        <p className={styles['info-value']}><span className={styles['info-param']}>Inventory kind:</span>
          {categoryId !== null ? itemInfo.inventoryKind[categoryId] : "-"}
        </p>
        <p className={styles['info-value']}><span className={styles['info-param']}>Original hero:</span>{itemInfo.originalHero}</p>
        <p className={styles['info-value']}><span className={styles['info-param']}>Rarity:</span>{getRarityName(itemInfo.rarity)}</p>
        <p className={styles['info-value']}><span className={styles['info-param']}>value:</span>{itemInfo.value}</p>
      </div>
      {showTags &&
        <div>
          <ItemTags categoryId={categoryId} itemId={itemId} itemInfo={itemInfo} />
        </div>
      }
    </>
  )
};

interface IIitemTags {
  itemId: number;
  categoryId: number;
  itemInfo: InventoryItemData;
}
export const ItemTags = ({ itemId, categoryId, itemInfo }: IIitemTags) => {
  const itemTags = getItemKeyTags([itemId.toString()]);

  if (categoryId !== null) {
    const inventoryKindTag: KeyTagType =
    {
      text: itemInfo.inventoryKind[categoryId],
      color: getTagColor(itemId, categoryId),
    };
    itemTags.push(inventoryKindTag);
  };

  if (itemTags.length === 0) return null;

  return (
    <>
      <KeyTagsBlock
        nftKeyTags={itemTags}
      />
    </>
  )
}