import { useContext } from "react";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { FilterCloseButton } from "shared/ui";
import { getGeneratorTitleByGenId } from "entities/generatorSelector";

export const AvatarSelectedTopBar = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  return (
    <>
      {h2qAccount.uiStore.avatarFilter.genId.length > 0 &&
        h2qAccount.uiStore.avatarFilter.genId.map(g => {
          return (
            <FilterCloseButton key={g} name={getGeneratorTitleByGenId(g)} clickAction={() => h2qAccount.uiStore.changeAvatarFilterGenId(g)} />
          )
        })
      }
      {h2qAccount.uiStore.avatarFilter.avatarName.length > 0 &&
        h2qAccount.uiStore.avatarFilter.avatarName.map(n => {
          return (
            <FilterCloseButton key={n} name={n} clickAction={() => h2qAccount.uiStore.changeAvatarFilterAvatarName(n)} />
          )
        })
      }
    </>
  )
})