import { H2QContext } from "app/core/H2QContext";
import { InputPIN } from "features/LoginAndCreateAccount";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import styles from "./EnterPINCode.module.scss";

interface IEnterPINCode {
  accept: () => void;
};

export const EnterPINCode = observer(function ({ accept }: IEnterPINCode) {
  const { h2qAccount } = useContext(H2QContext);
  const [pin, setPin] = useState<string[]>(() => new Array(6).fill(null).map(p => ""));

  useEffect(() => {
    if (pin.every(item => item !== "" && Number(item) >= 0)) {
      h2qAccount.setPin(pin.join(""));
      accept();
    }
  });

  return (
    <div>
      <p className={styles['modal-items__text']}>Enter your PIN code</p>
      <InputPIN
        pin={pin}
        setPin={setPin}
      />
    </div>
  )
});