import { H2QContext } from "app/core/H2QContext";
import { FilterBarWrapper } from "features/FilterTopBarWrapper"
import { initialMaxItemValue, initialMinItemValue, ItemSelectedTopBar } from "features/itemFilter";
import { observer } from "mobx-react"
import { useContext } from "react";

interface IItemFilterTopBar {
  toggleFilter: () => void;
};

export const ItemFilterTopBar = observer(function ({ toggleFilter }: IItemFilterTopBar) {
  const { h2qAccount } = useContext(H2QContext);

  const isFiltersActive =
    h2qAccount.uiStore.itemFilter.itemMinValue !== initialMinItemValue ||
    h2qAccount.uiStore.itemFilter.itemMaxValue !== initialMaxItemValue ||
    h2qAccount.uiStore.itemFilter.itemRarity.length !== 0 ||
    h2qAccount.uiStore.itemFilter.itemType.length !== 0 ||
    h2qAccount.uiStore.itemFilter.originalHero.length !== 0;

  const clearFilter = () => {
    h2qAccount.uiStore.clearItemFilter();
  };

  return (
    <FilterBarWrapper
      isFiltersActive={isFiltersActive}
      clearFilter={clearFilter}
      toggleFilter={toggleFilter}
    >
      <ItemSelectedTopBar />
    </FilterBarWrapper>
  )
})