import { Account } from "@eversdk/appkit";
import { H2QuestClientContract } from "app/contracts/H2QuestClientContract";
import { H2QuestGetterContract } from "app/contracts/H2QuestGetterContract";
import { client } from "app/model/tonClient";
import { MetaCharacter, PseudoNFTData, TrueCharacter } from "entities/nft";
import { delay } from "shared/lib";

export interface H2QFullCharacter {
  trueCharacter: TrueCharacter;
  metaCharacter: MetaCharacter;
}

export class H2QuestClient {
  account: Account;
  constructor(private address: string) {
    // console.log("create H2QuestClient", address);
    this.account = new Account(
      {
        abi: H2QuestClientContract.abi,
      },
      {
        client,
        address,
      }
    );
  }

  async questGetter() {
    let questGetterAddress = "";
    for (let i = 0; i < 60; i++) {
      const { acc_type } = await this.account.getAccount();
      console.log("check acc_type", acc_type);
      if (acc_type === 1) {
        try {
          const {
            decoded: {
              output: { m_questGetter },
            },
          } = (await this.account.runLocal("m_questGetter", {})) as any;
          if (parseInt(m_questGetter.slice(2), 16) !== 0) {
            questGetterAddress = m_questGetter;
            break;
          } else {
            await delay(1000);
          }
        } catch (e) { }
      } else {
        await delay(1000);
      }
    }

    return new H2QuestGetter(questGetterAddress);
  }
}
export class H2QuestGetter {
  account: Account;
  constructor(private address: string) {
    // console.log("create H2QuestGetter", address);
    this.account = new Account(
      {
        abi: H2QuestGetterContract.abi,
      },
      {
        client,
        address,
      }
    );
  }

  // H2QFullCharacter
  async getQuestParams(pseudoNtfData: PseudoNFTData) {
    const fullCharacter: H2QFullCharacter = {
      trueCharacter: pseudoNtfData.m_h2qTrueCharacter,
      metaCharacter: pseudoNtfData.m_h2qMetaCharacter,
    };

    const input = JSON.parse(JSON.stringify({ hero: fullCharacter }));
    for (let i = 0; i < 10; i++) {
      try {
        const r = await this.account.runLocal(
          "_getQuestParams",
          input
        );
        const { decoded: output } = r;
        return output;
      } catch (e) {
        console.error(e)
        continue;
      }
    }

  }
}
