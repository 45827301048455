import { H2QContext } from "app/core/H2QContext";
import { getGeneratorTitleByGenId, newGeneratorsList } from "entities/generatorSelector";
import { GeneratorSidebarContainer } from "entities/rigthSideBar";
import { getGeneratorStats } from "features/generatorSelector";
import { observer } from "mobx-react"
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ActiveGeneratorTopButton.module.scss";

export const ActiveGeneratorTopButton = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const openSidebarPanel = (id: number) => {
    const genKey = `Generator-${id}`

    if (h2qAccount.uiStore.rightSidebarChild.key === genKey) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
      return;
    };

    const selectedGenerator = newGeneratorsList.find(gen => gen.id === id)!;
    const genRewards = getGeneratorStats(id);
    const sideBarRightChild = <GeneratorSidebarContainer
      stats={genRewards}
      gen={selectedGenerator}
    />;

    h2qAccount.uiStore.setRightSidebarChild(genKey, sideBarRightChild);
    h2qAccount.uiStore.toggleRightSidebar(true, true);
  };

  const openActiveGenerator = () => {
    if (h2qAccount.data.m_generatedHero === null) return

    navigate(`/h2q/generator/${h2qAccount.data.m_generatedHero?.avatar_id}`);
  };

  const clickOnBtn = () => {
    const isGenOpened = Number(pathname.split("/")[3]); // example - /h2q/generator/10

    if (!isGenOpened) {
      openActiveGenerator();
      return;
    }

    openSidebarPanel(isGenOpened);

  }

  if (!h2qAccount.data.m_generatedHero) return null;

  return (
    <button className={styles["open-gen-btn"]} onClick={clickOnBtn}>
      {/* TODO: Only one generator is active now */}
      {getGeneratorTitleByGenId(1)}
    </button>
  )
});