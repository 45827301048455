import { Container, PixiComponent, Sprite } from '@saitonakamura/react-pixi';
import { useEffect, useState } from 'react';
import { Graphics } from 'pixi.js';
import { bottomValBg, bottomValBgAlpha } from 'entities/generator';

interface ICancelBtn {
  x: number;
  y: number;
  width: number;
  height: number;
  action: () => void;
  isActive: boolean;
  onHoverOver?: () => void;
  onHoverOut?: () => void;
};

interface ICancelBtnBg {
  width: number;
  height: number;
};

const CancelBtnBg = PixiComponent<ICancelBtnBg, Graphics>("CancelBtnBg", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(bottomValBg, bottomValBgAlpha);
    ins.drawRect(0, 0, props.width, props.height);
  }
});

export const CancelBtn = (props: ICancelBtn) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true)
  }, [props]);

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      mouseover={props.onHoverOver}
      mouseout={props.onHoverOut}
    >
      {
        isLoading &&
        <>
          <CancelBtnBg height={props.height} width={props.width} />
          <Sprite
            image={"/res/Generator/cancel-white.svg"}
            position={[props.width / 2, props.height / 2]}
            anchor={0.5}
            interactive={props.isActive}
            buttonMode={props.isActive}
            pointerdown={props.action}
            alpha={props.isActive ? 1 : 0.5}
          />
        </>
      }
    </Container>
  )
};