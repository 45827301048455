import styles from "./QuestDescriptions.module.scss";

interface IQuestDescriptions {
  description: string;
}
export function QuestDescriptions({ description }: IQuestDescriptions) {
  return (
    <div className={styles["description-wrapper"]}>
      <p className={styles.text}>{description}</p>
    </div>
  )
}