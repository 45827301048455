import { useState } from "react";
import { NavLink } from 'react-router-dom';
import { IconColorVariants } from "app/icons/icons";
import { sidebarElement } from "entities/sideBar";
import styles from "./SideBarLink.module.scss";

interface INavLink {
  item: sidebarElement;
  isOpen: boolean;
};

export const SideBarLink = ({ item, isOpen }: INavLink) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const disabledIconColor = IconColorVariants.blue;
  const iconColor = IconColorVariants.blue;
  const iconHoverColor = IconColorVariants.white;

  if (item.isDisabled) {
    return (
      <>
        <div className={styles["side-nav__link"]}>
          <div className={styles['disabled-link__body']}>
            <div
              className={styles.link__logo}
              style={{
                "height": item.isSmall ? "2.3rem" : "2.6rem",
              }}
            >
              <item.icon fill={disabledIconColor} stroke={disabledIconColor} />
            </div>
            <div className={`${styles.link__text} ${isOpen ? "" : styles.closed}`}>
              {item.text}
            </div>
          </div>
        </div>
      </>
    )
  }

  if (item.isExternal) {
    return (
      <a
        className={styles["side-nav__link"]}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.link__body}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div
            className={styles.link__logo}
            style={{
              "height": item.isSmall ? "2.3rem" : "2.6rem"
            }}
          >
            <item.icon
              fill={isHover
                ? iconHoverColor
                : iconColor
              }
              stroke={isHover
                ? iconHoverColor
                : iconColor
              }
            />
          </div>
          <div className={`${styles.link__text} ${isOpen ? "" : styles.closed}`}>
            {item.text}
          </div>
        </div>
      </a>
    )
  }
  return (
    <>
      <NavLink
        className={({ isActive }) => (isActive
          ? `${styles["side-nav__link"]} ${styles.selected}`
          : styles["side-nav__link"]
        )}
        to={item.link}
      >
        <div className={styles.link__body}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div
            className={styles.link__logo}
            style={{
              "height":
                item.isSmall
                  ? "2.3rem"
                  : item.isBig
                    ? "3.1rem"
                    : "2.6rem"
            }}
          >
            <item.icon
              fill={isHover
                ? iconHoverColor
                : iconColor
              }
              stroke={isHover
                ? iconHoverColor
                : iconColor
              }
            />
          </div>
          <div className={`${styles.link__text} ${isOpen ? "" : styles.closed}`}>
            {item.text}
          </div>
        </div>
      </NavLink>
    </>
  )
}