// TODO: refactoring all
import { InitialAvatarElement } from "entities/avatar";
import { InitialItemElement } from "entities/item";
import { NftElement, NftItems } from "entities/nft";
import { IQuestPrice } from "entities/quest";

export const filterByAvatar = (nfts: NftElement[], avatar: InitialAvatarElement | null): NftElement[] => {
  const result = nfts.filter((item) => {
    if (avatar) {
      return item.avatar.itemId === avatar.itemId
    }
    else return true;
  });
  return result;
};

export const checkAllItemsExist = (nftItems: NftItems<InitialItemElement>, questItems: NftItems<InitialItemElement>): boolean => {
  const questItemsData = Object.values(questItems)
    .map((qItem: InitialItemElement | null) => {
      if (qItem) {
        return `${qItem.categoryId}-${qItem.itemId}`
      }
      else return null;
    })
    .filter(item => item);

  if (questItemsData.length === 0) {
    return true;
  }

  const nftItemsData = Object.values(nftItems).map((nItem: InitialItemElement) => {
    return `${nItem.categoryId}-${nItem.itemId}`
  });
  return questItemsData.every(value => nftItemsData.includes(value!));
};

export const filterByItem = (nfts: NftElement[], items: NftItems<InitialItemElement>): NftElement[] => {
  const result = nfts.filter((nft) => checkAllItemsExist(nft.items, items));
  return result;
};

export const filterByLevelMin = (nfts: NftElement[], levelMin: number): NftElement[] => {
  const result = nfts.filter(item => Number(item.pseudoNftData!.m_h2qMetaCharacter.level) >= levelMin);
  return result;
};

export const filterByLevelMax = (nfts: NftElement[], levelMax: number): NftElement[] => {
  const result = nfts.filter(item => Number(item.pseudoNftData!.m_h2qMetaCharacter.level) <= levelMax);
  return result;
};

export const getNftsForQuest = (nfts: NftElement[], cost: IQuestPrice): NftElement[] => {
  const nftsFilteredByAvatar = filterByAvatar(nfts, cost.avatar);
  const nftsFilteredByItems = filterByItem(nftsFilteredByAvatar, cost.items);
  const nftsFilteredByLevelMin = filterByLevelMin(nftsFilteredByItems, cost.levelMin!);
  const nftsFilteredByLevelMax = filterByLevelMax(nftsFilteredByLevelMin, cost.levelMax!);

  return nftsFilteredByLevelMax;
};