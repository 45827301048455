import { useState } from "react";
import styles from "./UserGuideBrandsBlock.module.scss";
import item1 from "./images/new/1-body-dred_item-top.png";
import item2 from "./images/new/2-head-dred_item-top.png";
import item3 from "./images/new/3-hip-dred_item-top.png";
import item4 from "./images/new/4-legs-dred_item-top.png";
import item5 from "./images/new/5-dred_item-top.png";
import item6 from "./images/new/6-dred_item-top.png";
import item7 from "./images/new/7-dred_item-top.png";
import avatarImg from "./images/new/Avatar-panda_item-top.png";
import { Button } from "shared/ui";
import { ReactComponent as IconArrow } from "app/icons/icon-arrow-down.svg";
import { ReactComponent as IconClose } from "app/icons/icon-cancel-white.svg";
import { SliderButton } from "@typeform/embed-react";

export function UserGuideBrandsBlock() {
  const [isOpen, toggleIsOpen] = useState<boolean>(false);

  return (
    <div className={styles["user-guide-block"]}>
      <button className={styles["toggle-btn"]} onClick={() => toggleIsOpen(prev => !prev)}>
        For Brands
        <IconArrow className={isOpen ? styles.open : ""} width={15} height={15} stroke="#dadada" />
      </button>
      <div className={styles["guide-wrapper"]}>
        <div className={styles["main-guide"]}>
          <h3>Loading <span className={styles["key-words"]}>ASSETS</span> - <span className={styles["key-words"]}>AVATARS</span> and <span className={styles["key-words"]}>ITEMS</span> from which players will create <span className={styles["key-words"]}>NFT-HEROES</span></h3>
          <p>Quest Network inc. works with well-known brands, multimedia content publishers, NFT artists and holders of rights for works that have stories and heroes; and offers the use of their NFT-HEROES and narratives in a customizeble quest game Hero2Quest for fans around the world.</p>
          <div className={`${styles["items-and-btn"]} ${isOpen ? styles.hidden : ""}`}>
            <div className={styles.btn}>
              <SliderButton id="Oum5tqYJ" style={{ fontSize: 0, background: 'none', border: 0, outline: 0, width: "30rem", minWidth: "30rem" }} className="my-button">
                <Button
                  children={<p style={{ margin: 0, padding: "0 2rem", fontSize: "2rem" }}>Loading ASSETS</p>}
                  widthValue={"full"}
                  colorValue="violet"
                />
              </SliderButton>
            </div>
            <div className={styles.avatar}><img src={avatarImg} alt="Avatar sample" /></div>
            <div className={styles.items}>
              <div className={styles.item}><img src={item1} alt="Item sample" /></div>
              <div className={styles.item}><img src={item2} alt="Item sample" /></div>
              <div className={styles.item}><img src={item3} alt="Item sample" /></div>
              <div className={styles.item}><img src={item4} alt="Item sample" /></div>
              <div className={styles.item}><img src={item5} alt="Item sample" /></div>
              <div className={styles.item}><img src={item6} alt="Item sample" /></div>
              <div className={styles.item}><img src={item7} alt="Item sample" /></div>
            </div>
          </div>
        </div>
        <div className={`${styles["second-guide"]} ${isOpen ? styles.shown : ""}`}>
          <div className={styles["second-guide-inner"]}>
            <h3>How to load <span className={styles["key-words"]}>ASSETS</span>?</h3>
            <button className={styles["close-btn"]} onClick={() => toggleIsOpen(prev => !prev)}>
              <IconClose />
            </button>
            <p>You can upload characters from your art or nft collection into the game - your resources in the form of items and avatars will be available in the hero generator and then everyone can generate NFT-HERO from your items and avatars. <br />Heroes created in HERO2QUEST will forever be stored on the blockchain as NFTs and will be available for resale or for HERO2QUEST. In order for your heroes to come to life in the game HERO2QUEST, please fill out a simple application form for participation in the partner program.</p>
            {/* <div className={styles.btn}>
              <Button
                children={<p style={{ margin: 0, padding: "0 2rem", fontSize: "2rem" }}>Loading ASSETS</p>}
                widthValue={"full"}
                colorValue={"violet"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
