import { NftElement, NftImageSVG } from "entities/nft";
import { Link } from "react-router-dom";
import styles from "./StakedNftGrid.module.scss";

interface IStakedNftGrid {
  nfts: NftElement[];
};

export const StakedNftGrid = ({ nfts }: IStakedNftGrid) => {
  return (
    <div className={styles["staked-block"]}>
      <h3 className={styles["staked-title"]}>Staked NFT in quest:</h3>
      <div className={styles["staked-nfts"]}>
        {nfts.map(nft => {
          return (
            <Link to={`/h2q/nft/${nft.pseudoNFTAddr.slice(2)}/main`}>
              <div
                className={styles["staked-nft"]}
                key={nft.pseudoNFTAddr}
              >
                <NftImageSVG item={nft} />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
};