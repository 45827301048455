import { AvatarElement, getAvatarsFromArray } from "entities/avatar";
import { getItemsFromArray, ItemElement } from "entities/item";
import { getNfts, NftElement, NFTInfo } from "entities/nft";
import { filterAvatars } from "features/avatarFilter";
import { filterItems, initialMaxItemValue, initialMinItemValue } from "features/itemFilter";
import { filterNfts, initialMinNftLevel, initialMinNftValue } from "features/nftFilter";
import { filterQuests } from "features/questFilter";
import { makeAutoObservable, runInAction } from "mobx";
import { ReactNode } from "react";
import { H2QAccountObservable } from "./H2QAccount";
import { H2QuestResponse } from "./H2QQuestManager";

const itemsInRow = [2, 4, 6, 8] as const;
export type ItemsInRow = typeof itemsInRow[number];
export type GalleryType = "nfts" | "nftsInQuest" | "avatars" | "items";


type TRightSideBarChild = {
  key: string | null;
  child: ReactNode | null;
  editable?: boolean;
};

type ItemFilterType = {
  genType: string[],
  itemType: string[],
  itemRarity: string[],
  itemMinValue: number,
  itemMaxValue: number,
  originalHero: string[],
  favItems: number[][],
  favMode: boolean
};

type AvatarFilterType = {
  genId: number[],
  avatarName: string[],
  favAvatars: number[],
  favMode: boolean
};

type NftFilterType = {
  genId: number[],
  avatarName: string[],
  nftMinValue: number,
  nftMaxValue: number,
  nftOriginType: string[],
  nftOriginality: number[];
  favNfts: string[];
  favMode: boolean;
  stakedMode: boolean;
  nftMinLevel: number,
  nftMaxLevel: number,
};

type QuestsWithStakedNft = {
  [key: string]: NFTInfo[]
};

type QuestFilterType = {
  favMode: boolean,
  stakedMode: boolean,
  expectedMode: boolean,
  showNotValidMode: boolean,
  favQuests: string[],
  stakedQuests: QuestsWithStakedNft,
};

type FavNftsForQuestParams = {
  favNfts: string[];
  isFavModeEnabled: boolean;
};

export interface QuestPageFavNftsParams {
  [k: string]: FavNftsForQuestParams
};

export type NftFavQuests = {
  [key: string]: {
    isFavModeEnabled: boolean;
    quests: string[];
  }
};

export class H2QUIParamsObservable {
  rootStore;

  constructor(rootStore: H2QAccountObservable) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  };

  // var to determine how much rows in item gallery user has
  itemsInRowCount = Number(localStorage.getItem("itemsInRow")) || 4;
  // var to determine how much rows in avatar gallery user has
  avatarsInRowCount = Number(localStorage.getItem("avatarsInRow")) || 4;
  // var to determine how much rows in nfts gallery user has
  nftsInRowCount = Number(localStorage.getItem("nftsInRow")) || 4;
  // var to determine how much rows in proper nft gallery (on quest page) user has
  nftsInQuestRowCount = Number(localStorage.getItem("nftsInQuestRow")) || 4;

  changeGalleryCellsInRowCount(count: ItemsInRow, type: GalleryType) {
    runInAction(() => {
      if (type === "items") {
        this.itemsInRowCount = count;
        localStorage.setItem("itemsInRow", count.toString());
      }
      if (type === "avatars") {
        this.avatarsInRowCount = count;
        localStorage.setItem("avatarsInRow", count.toString());
      }
      if (type === "nfts") {
        this.nftsInRowCount = count;
        localStorage.setItem("nftsInRow", count.toString());
      }
      if (type === "nftsInQuest") {
        this.nftsInQuestRowCount = count;
        localStorage.setItem("nftsInQuestRow", count.toString());
      }
    })
  };

  //var to control right sidebar visibility:
  isRightSidebarOpen = false;
  // var to determine right sidebar behavior:
  // true - other components not moved, sidebar on top of them
  // false - other components shrink, sidebar on the right side of them
  isRightSidebarSeparate = false;

  toggleRightSidebar(isOpened: boolean, isSeparate: boolean = false) {
    runInAction(() => {
      this.isRightSidebarOpen = isOpened;
      this.isRightSidebarSeparate = isSeparate;
    })
  };

  rightSidebarChild: TRightSideBarChild = {
    key: "",
    child: null,
    editable: false,
  };

  setRightSidebarChild(key: string | null, child: ReactNode | null, editable: boolean = false) {
    runInAction(() => {
      this.rightSidebarChild = {
        key,
        child,
        editable
      };
    })
  };

  setRightSidebarChildEditable(editable: boolean) {
    runInAction(() => {
      this.rightSidebarChild.editable = editable;
    })
  };

  //Item filter
  itemsToShow: ItemElement[] = [];

  filterItemsToShow() {
    runInAction(() => {
      if (this.rootStore.data.m_inventories) {
        const items = getItemsFromArray(this.rootStore.data.m_inventories);
        this.itemsToShow = filterItems(items);
      }
    })
  };

  initialItemFilter: ItemFilterType = {
    genType: [],
    itemType: [],
    itemRarity: [],
    itemMinValue: initialMinItemValue,
    itemMaxValue: initialMaxItemValue,
    originalHero: [],
    favItems: localStorage.getItem("favItems")
      ? JSON.parse(localStorage.getItem("favItems")!)
      : [[], [], [], [], [], [], []],
    favMode: false
  };

  itemFilter = { ...this.initialItemFilter };

  clearItemFilter() {
    runInAction(() => {
      this.itemFilter = { ...this.initialItemFilter };
      this.filterItemsToShow();
    })
  };

  changeItemFilterType(type: string) {
    runInAction(() => {
      if (this.itemFilter.itemType.includes(type)) {
        const newTypes = [...this.itemFilter.itemType].filter(t => t !== type);
        this.itemFilter.itemType = newTypes;
      }
      else {
        const newTypes = [...this.itemFilter.itemType];
        newTypes.push(type);
        this.itemFilter.itemType = newTypes;
      }
      this.filterItemsToShow();
    })
  };

  changeItemFilterRarity(rarity: string) {
    runInAction(() => {
      if (this.itemFilter.itemRarity.includes(rarity)) {
        const newRarities = [...this.itemFilter.itemRarity].filter(r => r !== rarity);
        this.itemFilter.itemRarity = newRarities;
      }
      else {
        const newRarities = [...this.itemFilter.itemRarity];
        newRarities.push(rarity);
        this.itemFilter.itemRarity = newRarities;
      }
      this.filterItemsToShow();
    })
  };

  changeItemFilterMinValue(min: number) {
    runInAction(() => {
      this.itemFilter.itemMinValue = min;
      this.filterItemsToShow();
    })
  };

  changeItemFilterMaxValue(max: number) {
    runInAction(() => {
      this.itemFilter.itemMaxValue = max;
      this.filterItemsToShow();
    })
  };

  changeItemFilterGenType(gen: string) {
    runInAction(() => {
      if (this.itemFilter.genType.includes(gen)) {
        const newGens = [...this.itemFilter.genType].filter(g => g !== gen);
        this.itemFilter.genType = newGens;
      }
      else {
        const newGens = [...this.itemFilter.genType];
        newGens.push(gen);
        this.itemFilter.genType = newGens;
      }
      this.filterItemsToShow();
    })
  };

  changeItemFilterOriginalHero(hero: string) {
    runInAction(() => {
      if (this.itemFilter.originalHero.includes(hero)) {
        const newHeroes = [...this.itemFilter.originalHero].filter(h => h !== hero);
        this.itemFilter.originalHero = newHeroes;
      }
      else {
        const newHeroes = [...this.itemFilter.originalHero];
        newHeroes.push(hero);
        this.itemFilter.originalHero = newHeroes;
      }
      this.filterItemsToShow();
    })
  };

  changeItemFilterFavourites(catId: number, itemId: number) {
    runInAction(() => {
      if (this.itemFilter.favItems[catId].includes(itemId)) {
        const updatedCategoryList = this.itemFilter.favItems[catId].filter(item => item !== itemId);
        const newFavItems = [...this.itemFilter.favItems];
        newFavItems[catId] = updatedCategoryList;
        localStorage.setItem("favItems", JSON.stringify(newFavItems));
        this.itemFilter.favItems = newFavItems;
      }
      else {
        const newFavItems = [...this.itemFilter.favItems];
        newFavItems[catId].push(itemId);
        localStorage.setItem("favItems", JSON.stringify(newFavItems));
        this.itemFilter.favItems = newFavItems;
      }
      if (this.itemFilter.favItems.flat().length === 0) this.itemFilter.favMode = false;
      this.filterItemsToShow();
    })
  };

  changeItemFilterFavMode = (mode: boolean) => {
    if (mode === this.itemFilter.favMode) return;

    runInAction(() => {
      if (mode) {
        this.itemFilter.favMode = true;
        this.filterItemsToShow();
        return;
      }
      if (!mode) {
        this.itemFilter.favMode = false;
        this.filterItemsToShow();
      }
    })
  };

  //Avatar filter
  avatarsToShow: AvatarElement[] = [];

  filterAvatarsToShow() {
    runInAction(() => {
      if (this.rootStore.data.m_avatar_amount) {
        const avatars = getAvatarsFromArray(this.rootStore.data.m_avatar_amount);
        this.avatarsToShow = filterAvatars(avatars);
      }
    })
  };

  initialAvatarFilter: AvatarFilterType = {
    avatarName: [],
    genId: [],
    favAvatars: localStorage.getItem("favAvatars")
      ? JSON.parse(localStorage.getItem("favAvatars")!)
      : [],
    favMode: false
  };

  avatarFilter = { ...this.initialAvatarFilter };

  clearAvatarFilter() {
    runInAction(() => {
      this.avatarFilter = { ...this.initialAvatarFilter };
      this.filterAvatarsToShow();
    })
  };

  changeAvatarFilterGenId(gen: number) {
    runInAction(() => {
      if (this.avatarFilter.genId.includes(gen)) {
        const newGens = [...this.avatarFilter.genId].filter(g => g !== gen);
        this.avatarFilter.genId = newGens;
      }
      else {
        const newGens = [...this.avatarFilter.genId];
        newGens.push(gen);
        this.avatarFilter.genId = newGens;
      }
      this.filterAvatarsToShow();
    })
  };

  changeAvatarFilterAvatarName(name: string) {
    runInAction(() => {
      if (this.avatarFilter.avatarName.includes(name)) {
        const newNames = [...this.avatarFilter.avatarName].filter(n => n !== name);
        this.avatarFilter.avatarName = newNames;
      }
      else {
        const newNames = [...this.avatarFilter.avatarName];
        newNames.push(name);
        this.avatarFilter.avatarName = newNames;
      }
      this.filterAvatarsToShow();
    })
  };

  changeAvatarFilterFavourites = (id: number) => {
    runInAction(() => {
      if (this.avatarFilter.favAvatars.includes(id)) {
        const newFavAvatars = [...this.avatarFilter.favAvatars].filter(a => a !== id);
        localStorage.setItem("favAvatars", JSON.stringify(newFavAvatars));
        this.avatarFilter.favAvatars = newFavAvatars;
      }
      else {
        const newFavAvatars = [...this.avatarFilter.favAvatars];
        newFavAvatars.push(id);
        localStorage.setItem("favAvatars", JSON.stringify(newFavAvatars));
        this.avatarFilter.favAvatars = newFavAvatars;
      }
      if (this.avatarFilter.favAvatars.length === 0) this.avatarFilter.favMode = false;
      this.filterAvatarsToShow();
    })
  };

  changeAvatarFilterFavMode = (mode: boolean) => {
    if (mode === this.avatarFilter.favMode) return;

    runInAction(() => {
      if (mode) {
        this.avatarFilter.favMode = true;
        this.filterAvatarsToShow();
        return;
      }
      if (!mode) {
        this.avatarFilter.favMode = false;
        this.filterAvatarsToShow();
      }
    })
  };

  //Nft filter
  //max level of available nfts (for filter)
  nftMaxLevel: number = 0;
  //max value of available nfts (for filter)
  nftMaxValue: number = 0;
  nftsToShow: NftElement[] = [];

  filterNftsToShow() {
    runInAction(() => {
      if (this.rootStore.nfts) {
        const nfts = getNfts(this.rootStore.nfts);
        this.nftsToShow = filterNfts(nfts);
      }
    })
  };

  initialNftFilter: NftFilterType = {
    avatarName: [],
    genId: [],
    nftOriginType: [],
    nftMinValue: initialMinNftValue,
    nftMaxValue: this.nftMaxValue,
    nftOriginality: [],
    nftMinLevel: initialMinNftLevel,
    nftMaxLevel: this.nftMaxLevel,
    favNfts: localStorage.getItem("favNfts")
      ? JSON.parse(localStorage.getItem("favNfts")!)
      : [],
    favMode: false,
    stakedMode: false
  };

  nftFilter = { ...this.initialNftFilter };

  getNftFilterMaxParams() {
    if (this.rootStore.nfts.length) {
      runInAction(() => {
        this.nftMaxLevel = Math.max(...this.rootStore.nfts.map(n => Number(n.pseudoNFTdata.m_h2qMetaCharacter.level)));
        this.nftFilter.nftMaxLevel = this.nftMaxLevel;
        // console.log("Set nftMaxLevel:", this.nftMaxLevel);
        this.nftMaxValue = Math.max(...this.rootStore.nfts.map(n => Number(n.pseudoNFTdata.m_h2qMetaCharacter.valPrice)));
        this.nftFilter.nftMaxValue = this.nftMaxValue;
        // console.log("Set nftMaxValue:", this.nftMaxValue);
      })
    }
  };

  clearNftFilter() {
    runInAction(() => {
      this.nftFilter = {
        ...this.initialNftFilter,
        nftMaxValue: this.nftMaxValue,
        nftMaxLevel: this.nftMaxLevel
      };
      this.filterNftsToShow();
    })
  };

  changeNftFilterGenId(gen: number) {
    runInAction(() => {
      if (this.nftFilter.genId.includes(gen)) {
        const newGens = [...this.nftFilter.genId].filter(g => g !== gen);
        this.nftFilter.genId = newGens;
      }
      else {
        const newGens = [...this.nftFilter.genId];
        newGens.push(gen);
        this.nftFilter.genId = newGens;
      }
      this.filterNftsToShow();
    })
  };

  changeNftFilterAvatarName(name: string) {
    runInAction(() => {
      if (this.nftFilter.avatarName.includes(name)) {
        const newNames = [...this.nftFilter.avatarName].filter(n => n !== name);
        this.nftFilter.avatarName = newNames;
      }
      else {
        const newNames = [...this.nftFilter.avatarName];
        newNames.push(name);
        this.nftFilter.avatarName = newNames;
      }
      this.filterNftsToShow();
    })
  };

  changeNftFilterMinValue(min: number) {
    runInAction(() => {
      this.nftFilter.nftMinValue = min;
      this.filterNftsToShow();
    })
  };

  changeNftFilterMaxValue(max: number) {
    runInAction(() => {
      this.nftFilter.nftMaxValue = max;
      this.filterNftsToShow();
    })
  };

  changeNftFilterOriginType(origin: string) {
    runInAction(() => {
      if (this.nftFilter.nftOriginType.includes(origin)) {
        const newOrigin = [...this.nftFilter.nftOriginType].filter(o => o !== origin);
        this.nftFilter.nftOriginType = newOrigin;
      }
      else {
        const newOrigin = [...this.nftFilter.nftOriginType];
        newOrigin.push(origin);
        this.nftFilter.nftOriginType = newOrigin;
      }
      this.filterNftsToShow();
    })
  };

  changeNftFilterOriginality(originality: number) {
    runInAction(() => {
      if (this.nftFilter.nftOriginality.includes(originality)) {
        const newOriginalities = [...this.nftFilter.nftOriginality].filter(o => o !== originality);
        this.nftFilter.nftOriginality = newOriginalities;
      }
      else {
        const newOriginalities = [...this.nftFilter.nftOriginality];
        newOriginalities.push(originality);
        this.nftFilter.nftOriginality = newOriginalities;
      }
      this.filterNftsToShow();
    })
  };

  changeNftFilterMinLevel(min: number) {
    runInAction(() => {
      this.nftFilter.nftMinLevel = min;
      this.filterNftsToShow();
    })
  };

  changeNftFilterMaxLevel(max: number) {
    runInAction(() => {
      this.nftFilter.nftMaxLevel = max;
      this.filterNftsToShow();
    })
  };

  changeNftFilterFavourites = (id: string) => {
    runInAction(() => {
      if (this.nftFilter.favNfts.includes(id)) {
        const newFavNfts = [...this.nftFilter.favNfts].filter(n => n !== id);
        localStorage.setItem("favNfts", JSON.stringify(newFavNfts));
        this.nftFilter.favNfts = newFavNfts;
      }
      else {
        const newFavNfts = [...this.nftFilter.favNfts];
        newFavNfts.push(id);
        localStorage.setItem("favNfts", JSON.stringify(newFavNfts));
        this.nftFilter.favNfts = newFavNfts;
      }
      if (this.nftFilter.favNfts.length === 0) this.nftFilter.favMode = false;
      this.filterNftsToShow();
    })
  };

  changeNftFilterFavMode = (mode: boolean) => {
    if (mode === this.nftFilter.favMode) return;

    runInAction(() => {
      if (mode) {
        this.nftFilter.favMode = true;
        this.filterNftsToShow();
        return;
      }
      if (!mode) {
        this.nftFilter.favMode = false;
        this.filterNftsToShow();
      }
    })
  };

  changeNftFilterStaked = (mode: boolean) => {
    if (mode === this.nftFilter.stakedMode) return;

    runInAction(() => {
      if (mode) {
        this.nftFilter.stakedMode = true;
        this.filterNftsToShow();
        return;
      }
      if (!mode) {
        this.nftFilter.stakedMode = false;
        this.filterNftsToShow();
        return;
      }
    })
  };

  //Quest filter
  questsToShow: H2QuestResponse[] = [];

  filterQuestsToShow() {
    runInAction(() => {
      if (this.rootStore.quests) {
        this.questsToShow = filterQuests(this.rootStore.quests);
      }
    })
  };

  initialQuestFilter: QuestFilterType = {
    favMode: false,
    favQuests: localStorage.getItem("favQuests")
      ? JSON.parse(localStorage.getItem("favQuests")!)
      : [],
    stakedMode: false,
    stakedQuests: {},
    expectedMode: false,
    showNotValidMode: false
  };

  questFilter = { ...this.initialQuestFilter };

  clearQuestFilter() {
    runInAction(() => {
      this.questFilter = { ...this.initialQuestFilter };
      this.filterQuestsToShow();
    })
  };

  changeQuestFilterFavMode = (mode: boolean) => {
    if (mode === this.questFilter.favMode) return;

    runInAction(() => {
      if (mode) {
        this.questFilter.favMode = true;
        this.filterQuestsToShow();
        return;
      }
      if (!mode) {
        this.questFilter.favMode = false;
        this.filterQuestsToShow();
      }
    })
  };

  changeQuestFilterStakedMode = (mode: boolean) => {
    if (mode === this.questFilter.stakedMode) return;

    runInAction(() => {
      if (mode) {
        this.questFilter.stakedMode = true;
        this.filterQuestsToShow();
        return;
      }
      if (!mode) {
        this.questFilter.stakedMode = false;
        this.filterQuestsToShow();
      }
    })
  };

  changeQuestFilterExpectedMode = (mode: boolean) => {
    if (mode === this.questFilter.expectedMode) return;

    runInAction(() => {
      if (mode) {
        this.questFilter.expectedMode = true;
        this.filterQuestsToShow();
        return;
      }
      if (!mode) {
        this.questFilter.expectedMode = false;
        this.filterQuestsToShow();
      }
    })
  };

  changeQuestFilterNotValidMode = (mode: boolean) => {
    if (mode === this.questFilter.showNotValidMode) return;

    runInAction(() => {
      if (mode) {
        this.questFilter.showNotValidMode = true;
        return;
      }
      if (!mode) {
        this.questFilter.showNotValidMode = false;
      }
    })
  };

  changeQuestFilterFavourites = (questId: string) => {
    runInAction(() => {
      if (this.questFilter.favQuests.includes(questId)) {
        const newFavQuests = this.questFilter.favQuests.filter(q => q !== questId);
        localStorage.setItem("favQuests", JSON.stringify(newFavQuests));
        this.questFilter.favQuests = newFavQuests;
      }
      else {
        const newFavQuests = [...this.questFilter.favQuests];
        newFavQuests.push(questId);
        localStorage.setItem("favQuests", JSON.stringify(newFavQuests));
        this.questFilter.favQuests = newFavQuests;
      }

      if (this.questFilter.favQuests.length === 0) this.questFilter.favMode = false;
      this.filterQuestsToShow();
    })
  };

  getStakedQuests() {
    const result = {} as QuestsWithStakedNft;

    if (this.rootStore.nfts.length === 0 || this.rootStore.quests.length === 0) {
      return {};
    };

    for (const nft of this.rootStore.nfts) {
      const questId = nft.pseudoNFTdata.m_lockedInQuest?.quest;
      if (questId) {
        if (result[questId]) {
          console.log("Add nft to quest's staked list");
          result[questId].push(nft);
        }
        else {
          console.log("Make new quest's staked list");
          result[questId] = [nft];
        }
      }
    }
    return result
  };

  //Favourite nfts for selected quest
  favNftsForQuests: QuestPageFavNftsParams = localStorage.getItem("favNftsForQuests")
    ? JSON.parse(localStorage.getItem("favNftsForQuests")!)
    : {};

  changeFavNftsForQuest(questId: string, nftId: string) {
    const questParams = this.favNftsForQuests[questId];

    runInAction(() => {
      if (!questParams) {
        const newQuestParams: FavNftsForQuestParams = {
          favNfts: [nftId],
          isFavModeEnabled: false
        };
        this.favNftsForQuests[questId] = newQuestParams;
        localStorage.setItem("favNftsForQuests", JSON.stringify(this.favNftsForQuests));
        // console.log("Added new fav nft for new quest:", questId);
        return;
      }
      else {
        const favNfts = [...this.favNftsForQuests[questId].favNfts];
        if (favNfts.includes(nftId)) {
          const updatedNfts = favNfts.filter(n => n !== nftId);
          this.favNftsForQuests[questId].favNfts = updatedNfts;
          console.log("Remove fav nft for old quest:", questId);
          if (updatedNfts.length === 0) this.changeQuestPageFavNftMode(questId, false);
          localStorage.setItem("favNftsForQuests", JSON.stringify(this.favNftsForQuests));
          return;
        }

        this.favNftsForQuests[questId].favNfts.push(nftId);
        localStorage.setItem("favNftsForQuests", JSON.stringify(this.favNftsForQuests));
        console.log("Add fav nft for old quest:", questId);
      }

    })
  };

  changeQuestPageFavNftMode(questId: string, isFavModeEnabled: boolean) {
    const questParams = this.favNftsForQuests[questId];
    runInAction(() => {
      if (!questParams) {
        return;
      };
      this.favNftsForQuests[questId].isFavModeEnabled = isFavModeEnabled;
      localStorage.setItem("favNftsForQuests", JSON.stringify(this.favNftsForQuests));
      console.log("Change fav mode for selected quest page's nfts", isFavModeEnabled);
    })
  };

  //Favourite quests for selected Nft
  favQuestsForNfts: NftFavQuests = localStorage.getItem("favQuestsForNfts")
    ? JSON.parse(localStorage.getItem("favQuestsForNfts")!)
    : {};

  changeFavQuestsForNft(nftId: string, questId: string) {
    const currentNftFavQuests = this.favQuestsForNfts[nftId];

    if (currentNftFavQuests && currentNftFavQuests.quests.includes(questId)) {
      console.log("Delete from nft's fav quests' list old quest:", questId);
      const newFavQuests = currentNftFavQuests.quests.filter(item => item !== questId);
      this.favQuestsForNfts[nftId].quests = newFavQuests;
      localStorage.setItem("favQuestsForNfts", JSON.stringify(this.favQuestsForNfts));
      return;
    }

    if (currentNftFavQuests && !currentNftFavQuests.quests.includes(questId)) {
      console.log("Add to nft's fav quests' list new quest:", questId);
      const newFavQuests = [...currentNftFavQuests.quests];
      newFavQuests.push(questId);
      this.favQuestsForNfts[nftId].quests = newFavQuests;
      localStorage.setItem("favQuestsForNfts", JSON.stringify(this.favQuestsForNfts));
      return;
    }

    else {
      console.log("Add new nft's fav quests' list with new quest:", questId);
      this.favQuestsForNfts[nftId] = {
        isFavModeEnabled: false,
        quests: [questId]
      };
      localStorage.setItem("favQuestsForNfts", JSON.stringify(this.favQuestsForNfts))
    }
  };

  changeNftPageFavQuestMode(nftId: string, mode: boolean) {
    runInAction(() => {
      const currentNftFavQuests = this.favQuestsForNfts[nftId];
      if (currentNftFavQuests) {
        console.log("Change fav quests for selected nft view mode to", mode);
        currentNftFavQuests.isFavModeEnabled = mode;
        localStorage.setItem("favQuestsForNfts", JSON.stringify(this.favQuestsForNfts))
      }
    })
  };

  //User params
  userNickname = "";
  setUserNickname = (name: string) => {
    console.log("User nickname:", name);
    runInAction(() => {
      this.userNickname = name;
    })
  };


  //logout
  clearUsersSettings() {
    localStorage.removeItem("avatarsInRow");
    localStorage.removeItem("itemsInRow");
    localStorage.removeItem("nftsInRow");
    localStorage.removeItem("nftsInQuestRow");
    localStorage.removeItem("userData");

    localStorage.removeItem("favQuests");
    localStorage.removeItem("favNfts");
    localStorage.removeItem("favQuestsForNfts");
    localStorage.removeItem("favNftsForQuests");

    this.clearAvatarFilter();
    this.clearItemFilter();
    this.clearNftFilter();
    this.clearQuestFilter();
    this.setUserNickname("");
  };
};