export interface TimeData {
  dd: number;
  hh: number;
  mm: number;
  ss: number;
};
export const getTimeFromMilliSeconds = (millisec: number): TimeData => {
  if (millisec < 0) return {
    dd: 0,
    hh: 0,
    mm: 0,
    ss: 0
  };

  const days = Math.floor((millisec) / (1000 * 60 * 60 * 24));
  const hours = Math.floor(((millisec) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor(((millisec) % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor(((millisec) % (1000 * 60)) / 1000);

  return {
    dd: days,
    hh: hours,
    mm: minutes,
    ss: seconds
  }
};

export const timeToDdHhMmSs = (timedata: TimeData, hasSeconds: boolean = false): string => {
  const hoursAndMinutes =
    (timedata.hh.toString().length < 2 ? "0" + timedata.hh : timedata.hh) + "h "
    + (timedata.mm.toString().length < 2 ? "0" + timedata.mm : timedata.mm) + "m "

  const days = timedata.dd ? timedata.dd + "d " : "";

  const seconds = (timedata.ss.toString().length < 2 ? "0" + timedata.ss : timedata.ss) + "s";

  const result = days.length ? days.concat(hoursAndMinutes) : hoursAndMinutes;

  return hasSeconds
    ? result.concat(seconds)
    : result.trim();
};