// параметры для модальных окон
export const fadeBg = 0xffffff;
export const fadeAlpha = 0.1;

export const modalFrontBg = 0xffffff;
export const modalFrontAlpha = 0.75;
export const modalTitleHeight = 135;
export const modalTitleGap = 15; // зазор между заголовком и основным полем с текстом
export const modalTextPadding = 50; // отступ текста сверху от края модального окна
export const modalTextColor = 0x000000;

export const modalTitleFontSize = 40;
export const modalTextFontSize = 35;
export const modalPriceFontSize = 50;
export const modalBtnFontSize = 30;

export const modalWidth = 700;
export const modalHeight = 700;

export const modalBtnWidth = 300;
export const modalBtnHeight = 70;
export const twoButtonsOffset = 175;

export type ModalTypes =
  "cancelItems"
  | "waiting"
  | "error"
  | "acceptChar"
  | "changeChar" // unused after adding changeAvatar function
  | "completeAcceptChar"
  | "nftAlredyExists"
  | "allItemsWereUsed"
  ;

export type ModalText = {
  [K in ModalTypes]: string
};

export const modalTitleVariants: ModalText = {
  "cancelItems": "Clearing slots of all items",
  "waiting": "Processing",
  "error": "Error",
  "acceptChar": "Generate new NFT",
  "completeAcceptChar": "Success",
  "changeChar": "Changing Avatar",
  "nftAlredyExists": "Oops...",
  "allItemsWereUsed": "Attention!"
};

export const modalTextVariants: ModalText = {
  "cancelItems": "You can start over by resetting all items.\nThe previous cost of requests to the blockchain will be deducted in H2Q tokens from your account.",
  "waiting": "Please wait.\nYour request is being proccessing...",
  "error": "There was an error during execution.",
  "acceptChar": "Do you really want to generate this NFT Hero?\nThis final cost will be deducted in H2Q tokens from your account.",
  "completeAcceptChar": "Your new NFT has been created!\nDo you want to go open its page or stay in generator?",
  "changeChar": "You can start over with the new avatar by resetting all items.\nThe previous cost of requests to the blockchain will be deducted in H2Q tokens from your account.",
  "nftAlredyExists": "Somebody already generated this NFT! Try to generate another one.",
  "allItemsWereUsed": "Resources for generating the new Hero are over. You can get them in quests."
};

export const modalButtonAcceptTextVariants: ModalText = {
  "cancelItems": "RESET ALL ITEMS",
  "waiting": "",
  "error": "CLOSE",
  "acceptChar": "GENERATE",
  "completeAcceptChar": "OPEN NFT",
  "changeChar": "CHANGE",
  "nftAlredyExists": "CLOSE",
  "allItemsWereUsed": "CLOSE"
};

export const modalButtonRejectTextVariants: ModalText = {
  "cancelItems": "CANCEL",
  "waiting": "",
  "error": "CLOSE",
  "acceptChar": "CANCEL",
  "completeAcceptChar": "STAY",
  "changeChar": "CANCEL",
  "nftAlredyExists": "CLOSE",
  "allItemsWereUsed": "CLOSE"
};