import { Container, Sprite } from "@saitonakamura/react-pixi"
import { cellBorder } from "entities/generator"
import { ReactNode } from "react"

interface ISlorReloadBtn {
  btnIcon: ReactNode;
  centerCount: ReactNode;
  bottomCount: ReactNode;
  tooltips?: ReactNode;
  x: number;
  y: number;
};

export const SlotReloadBtn = ({
  btnIcon,
  centerCount,
  bottomCount,
  tooltips,
  x,
  y,
}: ISlorReloadBtn) => {
  const reloadBtnZindex = 3;

  return (
    <>
      <Container
        x={x}
        y={y}
        zIndex={reloadBtnZindex}
      >

        {/* Frame for reload button */}
        <Sprite
          image={"/res/Generator/select_lock-item-only-ramka.png"}
          alpha={0.5}
          anchor={[0, 0]}
        />

        {/* Reload button image within frame */}
        <Sprite
          image={"/res/Generator/btt-in-hero-item-reload_non-date.png"}
          x={cellBorder}
          y={cellBorder}
          anchor={[0, 0]}
        />

        {/* Reload button icon within image */}
        {btnIcon}

        {/* Amount of different items */}
        {centerCount}

        {/* Items Value */}
        {bottomCount}

      </Container>
      {tooltips &&
        <Container zIndex={10}>
          {tooltips}
        </Container>
      }
    </>
  )
}
