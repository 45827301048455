import { cellColors, inputSquareSize, TCellColor } from "entities/questMaker";
import { ReactNode } from "react";
import styles from "./QuestSeason.module.scss";

interface IQuestSeason {
  squareColor: TCellColor | null;
  sIndex: number;
  cellsSlot: ReactNode;
  removeSeasonSlot: ReactNode;
  changeSeasonColorSlot: ReactNode;
  bottonBtnsSlot: ReactNode;
  isActive: boolean;
};

export const QuestSeason = ({
  squareColor,
  sIndex,
  cellsSlot,
  removeSeasonSlot,
  changeSeasonColorSlot,
  bottonBtnsSlot,
  isActive,
}: IQuestSeason) => {

  return (
    <div
      className={`${styles.square} ${isActive ? styles.active : ""}`}
      style={{
        zIndex: inputSquareSize - sIndex,
        backgroundColor: squareColor ? `RGB${cellColors[squareColor]}` : "transparent"
      }}
    >

      {cellsSlot}

      <div className={styles["square-remove"]}>
        {removeSeasonSlot}
      </div>

      <div className={styles["square-color"]}>
        {changeSeasonColorSlot}
      </div>

      {bottonBtnsSlot}
    </div>
  )
}