import { Container, Sprite } from "@saitonakamura/react-pixi"
import { GenTooltipsType } from "entities/generator";
import { ReactNode } from "react";

interface ISlotItem {
  x: number;
  y: number;
  zIndex: number;
  itemHovered: GenTooltipsType | null;
  item: ReactNode;
  triangle?: ReactNode;
  amountBlock: ReactNode;
  lockBlock?: ReactNode;
  threeDots: ReactNode;
  tooltips?: ReactNode;
};

export const SlotItem = ({
  x,
  y,
  zIndex,
  itemHovered,
  item,
  triangle,
  amountBlock,
  lockBlock,
  threeDots,
  tooltips
}: ISlotItem) => {

  return (
    <>
      <Container
        x={x}
        y={y}
        zIndex={zIndex}
      >

        {/* Frame for item */}
        <Sprite
          image={"/res/Generator/select_lock-item-only-ramka.png"}
          alpha={lockBlock ? 1 : 0.5}
        />

        {/* Gray frame with triangle in bottom right corner for locked item */}
        <Sprite
          image={"/res/Generator/select_lock-item-only-bgrd.png"}
          alpha={1}
          visible={lockBlock ? true : false}
        />

        {/* Colored triange in bottom left corner indicating item's "color type" */}
        {triangle}

        {/* Squre with amount of current items */}
        {amountBlock}

        {/* Item image */}
        {item}

        {/* Lock image in bottom right corner for locked items */}
        {lockBlock}

        {/* Hover frame image */}
        <Sprite
          image={"/res/Generator/ramka-hover-item.png"}
          alpha={1}
          visible={itemHovered === "itemSlot" ? true : false}
        />

        {/* Three round dots to control Right Sidebar panel */}
        {threeDots}
      </Container>

      {tooltips &&
        <Container
          zIndex={10}
        >
          {tooltips}
        </Container>
      }
    </>
  )
};