import { FC, PropsWithChildren } from "react";
import styles from "./ModalWrapper.module.scss";

interface IModalWrapper {
  isTransparent?: boolean;
  close: () => void;
}

export const ModalWrapper: FC<PropsWithChildren<IModalWrapper>> = ({ children, close, isTransparent = false }) => {

  return (
    <>
      <div className={styles.modal__fade} onClick={close}></div>
      <div className={`${styles.modal__children} ${isTransparent ? styles.modal_transparent : ""}`}>
        {children}
      </div>
    </>
  );
};