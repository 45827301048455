import { createRef, useEffect } from "react";
import styles from "./InputPIN.module.scss";

interface IInputPIN {
  pin: string[];
  setPin: (newPin: string[]) => void;
};

export const InputPIN = ({ pin, setPin }: IInputPIN) => {
  const inputRefs = new Array(6).fill(null).map(item => createRef<HTMLInputElement>());

  const inputPin = (digit: number, index: number) => {
    const newValue = Number(digit.toString().slice(-1));

    if (isNaN(newValue)) return;

    if (newValue > 9 || newValue < 0) return;

    const updatedPin = [...pin];

    updatedPin[index] = newValue.toString();

    setPin(updatedPin);
    inputRefs[(index + 1) % 6].current?.focus();
  };

  useEffect(() => {
    inputRefs[0].current?.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["pin-wrapper"]}>
      {pin.map((p, index) => {
        return (
          <div key={index} className={styles["pin-input"]}>
            <input
              ref={inputRefs[index]}
              value={p}
              maxLength={1}
              type="number"
              min={0}
              max={9}
              onChange={(e) => inputPin(Number(e.target.value), index)}
            />
          </div>
        )
      })}
    </div>
  )
}
