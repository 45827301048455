import styles from "./CostAndRewardBlock.module.scss";
import { ReactNode } from "react";

interface ICostAndRewardBlock {
  title: ReactNode;
  items: ReactNode;
  price: ReactNode;
};

export function CostAndRewardBlock({ title, items, price }: ICostAndRewardBlock) {

  return (
    <div className={styles['price-block-wrapper']}>
      {title}
      {items}
      {price}
    </div >
  )
};