import { h2qAccount } from "app/core/H2QContext";
import { NftElement } from "entities/nft";
import { initialMaxItemValue } from "features/itemFilter";

export const initialMinNftValue = 0;
export const initialMaxNftValue = initialMaxItemValue * 7;

export const initialMinNftLevel = 0;
export const initialMaxNftLevel = 255; // uint8: (2 ^ 8) - 1

export const filterNfts = (nfts: NftElement[]): NftElement[] => {
  const nftsByFav = filterNftByFav(nfts);
  const nftsByStaked = filterNftByStaked(nftsByFav);
  const nftsByGenType = filterNftByGenId(nftsByStaked);
  const nftsByAvatarName = filterNftByAvatarName(nftsByGenType);
  const nftsByValue = filterNftByValue(nftsByAvatarName);
  const nftsByOriginType = filterNftByOriginType(nftsByValue);
  const nftsByOriginality = filterNftByOriginality(nftsByOriginType);
  const nftsByLevel = filterNftByLevel(nftsByOriginality);
  return nftsByLevel;
};

const filterNftByFav = (nfts: NftElement[]): NftElement[] => {
  const favMode = h2qAccount.uiStore.nftFilter.favMode;
  const favNfts = h2qAccount.uiStore.nftFilter.favNfts;

  return !favMode
    ? nfts
    : favNfts.length === 0
      ? nfts
      : nfts.filter(n => favNfts.includes(n.pseudoNFTAddr))
};

const filterNftByStaked = (nfts: NftElement[]): NftElement[] => {
  const stakedMode = h2qAccount.uiStore.nftFilter.stakedMode;
  return !stakedMode
    ? nfts
    : nfts.filter(n => n.pseudoNftData?.m_lockedInQuest)
};

const filterNftByGenId = (nfts: NftElement[]): NftElement[] => {
  const genIds = h2qAccount.uiStore.nftFilter.genId;

  return genIds.length === 0
    ? nfts
    : nfts.filter(n => genIds.includes(n.avatar.genId!));
};

const filterNftByAvatarName = (nfts: NftElement[]): NftElement[] => {
  const names = h2qAccount.uiStore.nftFilter.avatarName;

  return names.length === 0
    ? nfts
    : nfts.filter(n => names.includes(n.avatar.avatarName));
};

const filterNftByValue = (nfts: NftElement[]): NftElement[] => {
  const minValue = h2qAccount.uiStore.nftFilter.nftMinValue;
  const maxValue = h2qAccount.uiStore.nftFilter.nftMaxValue;

  if (minValue === initialMinNftValue && maxValue === initialMaxNftValue) {
    return nfts;
  }
  else {
    const result = nfts.filter(n => {
      const nftValue = Number(n.pseudoNftData!.m_h2qMetaCharacter.valPrice);
      return nftValue >= minValue && nftValue <= maxValue;
    });

    return result;
  }
};

const filterNftByOriginType = (nfts: NftElement[]): NftElement[] => {
  const origins = h2qAccount.uiStore.nftFilter.nftOriginType;

  return origins.length === 0
    ? nfts
    : nfts.filter(n => origins.includes(n.nftOriginType));
};

const filterNftByOriginality = (nfts: NftElement[]): NftElement[] => {
  const originalities = h2qAccount.uiStore.nftFilter.nftOriginality;

  return originalities.length === 0
    ? nfts
    : nfts.filter(n => originalities.includes(n.nftOriginality));
};

const filterNftByLevel = (nfts: NftElement[]): NftElement[] => {
  const minLevel = h2qAccount.uiStore.nftFilter.nftMinLevel;
  const maxLevel = h2qAccount.uiStore.nftFilter.nftMaxLevel;

  if (minLevel === initialMinNftLevel && maxLevel === initialMaxNftLevel) {
    return nfts;
  }
  else {
    const result = nfts.filter(n => {
      const nftValue = Number(n.pseudoNftData!.m_h2qMetaCharacter.level);
      return nftValue >= minLevel && nftValue <= maxLevel;
    });

    return result;
  }
};