import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { NFTInfo } from "entities/nft";
import { QuestActiveTimerBlock, QuestLockedBlock } from "entities/quest";
import { ChooseQuestForHeroBlock } from "features/quest";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { QuestElement } from "widgets/QuestElement/QuestElement";
import styles from "./QuestListForNft.module.scss";

interface IQuestListForNft {
  activeQuest: H2QuestResponse | null;
  validQuests: H2QuestResponse[];
  invalidQuests: H2QuestResponse[] | null;
  isQuestsFold: boolean;
  selectedNft: NFTInfo;
  selectedQuest: H2QuestResponse | null;
  setSelectedQuest: (id: string) => void;
  isWaiting: boolean;
};

export const QuestListForNft = observer(function ({ validQuests, isQuestsFold, activeQuest, invalidQuests, selectedNft, selectedQuest, setSelectedQuest, isWaiting }: IQuestListForNft) {
  const { h2qAccount } = useContext(H2QContext);

  const isHeroStaken = selectedNft.pseudoNFTdata.m_lockedInQuest !== null;

  useEffect(() => {
    const checkData = async () => {
      if (h2qAccount.totalNfts.length === 0) {
        console.log("Total nfts are not loaded. Loading total nfts...");
        await h2qAccount.getTotalNfts();
      }
    };

    checkData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["list-wrapper"]}>
      {activeQuest && selectedNft &&
        <>
          <h3 className={styles["quest-list-title"]}>Current:</h3>
          <QuestElement questData={activeQuest} isAllQuestsFold={isQuestsFold}>
            <QuestActiveTimerBlock
              finishTime={selectedNft.pseudoNFTdata.m_lockedInQuest ? selectedNft.pseudoNFTdata.m_lockedInQuest.finishQuest * 1000 : 0}
            />
          </QuestElement>
        </>
      }

      {validQuests.length &&
        <>
          <h3 className={styles["quest-list-title"]}>Available:</h3>
          {validQuests.map(q => {
            return (
              <QuestElement questData={q} key={q.id} isAllQuestsFold={isQuestsFold}>
                <ChooseQuestForHeroBlock
                  quest={q}
                  action={() => setSelectedQuest(q.id)}
                  isActive={!isHeroStaken}
                  isSelected={selectedQuest?.id === q.id}
                  isWaiting={isWaiting}
                />
              </QuestElement>
            )
          })
          }
        </>
      }

      {invalidQuests && invalidQuests.length &&
        <>
          <h3 className={styles["quest-list-title"]}>Currently unavailable:</h3>
          {invalidQuests.map((q, index) => {
            return (
              <QuestElement questData={q} key={q.id} isAllQuestsFold={isQuestsFold}>
                <QuestLockedBlock
                  quest={q}
                />
              </QuestElement>
            )
          })
          }
        </>
      }

      {!isWaiting && validQuests.length === 0 && invalidQuests?.length === 0 && !activeQuest &&
        <p className={styles["no-data"]}>
          No quests are available for selected NFT
        </p>
      }

      {isWaiting &&
        <p className={styles["no-data"]}>
          Loading quest data...
        </p>
      }
    </div>
  )
});