const H2QuestIndexContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [],
                "outputs": []
            },
            {
                "name": "m_h2quest",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_h2quest",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_indexType",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_indexType",
                        "type": "uint256"
                    }
                ]
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "m_h2quest",
                "type": "address"
            },
            {
                "key": 2,
                "name": "m_indexType",
                "type": "uint256"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "m_h2quest",
                "type": "address"
            },
            {
                "name": "m_indexType",
                "type": "uint256"
            }
        ]
    },
    tvc: "te6ccgECEgEAAioAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsPBQQRA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8CgkGA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPA4OBgM8IIIQIuUIBrrjAiCCEETydCm64wIgghBotV8/uuMCDAsHAjQw+EJu4wD4RvJz0fhJ+ErHBfLggvgA2zzyAAkIAC74SvhD+ELIy//LP8+DzvhLyMv/zcntVAJ07UTQ10nCAY6vcO1E0PQFcSGAQPQOjoGJ33IigED0Dm+Rk9cL/974a/hqgED0DvK91wv/+GJw+GPjDQoNAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAVAw0ds8+EshjhyNBHAAAAAAAAAAAAAAAAAxPJ0KYMjOy//JcPsA3vIADQFOMNHbPPhKIY4bjQRwAAAAAAAAAAAAAAAAKLlCAaDIzs7JcPsA3vIADQA07UTQ0//TP9MAMfpA1NHQ0//R+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLAThEQABRzb2wgMC42Ni4wAAA=",
    code: "te6ccgECDwEAAf0ABCSK7VMg4wMgwP/jAiDA/uMC8gsMAgEOA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8BwYDA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPAsLAwM8IIIQIuUIBrrjAiCCEETydCm64wIgghBotV8/uuMCCQgEAjQw+EJu4wD4RvJz0fhJ+ErHBfLggvgA2zzyAAYFAC74SvhD+ELIy//LP8+DzvhLyMv/zcntVAJ07UTQ10nCAY6vcO1E0PQFcSGAQPQOjoGJ33IigED0Dm+Rk9cL/974a/hqgED0DvK91wv/+GJw+GPjDQcKAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAVAw0ds8+EshjhyNBHAAAAAAAAAAAAAAAAAxPJ0KYMjOy//JcPsA3vIACgFOMNHbPPhKIY4bjQRwAAAAAAAAAAAAAAAAKLlCAaDIzs7JcPsA3vIACgA07UTQ0//TP9MAMfpA1NHQ0//R+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLATg4NABRzb2wgMC42Ni4wAAA=",
    codeHash: "148886531d25d60fd398232b4c110add026c7c4620c8fce036c1764ca778c983",
};
module.exports = { H2QuestIndexContract };