import { useState } from "react";
import styles from "./UserGuidePlayerBlock.module.scss";
import { ReactComponent as IconArrow } from "app/icons/icon-arrow-down.svg";
import { ReactComponent as IconCollectoin } from "app/icons/icon-collections-black.svg";
import { ReactComponent as IconHero } from "app/icons/icon-hero-creator-black.svg";
import { ReactComponent as IconQuest } from "app/icons/icon-quest-network-black.svg";
import { ReactComponent as IconClose } from "app/icons/icon-cancel-white.svg";
import screenshot1 from "./images/img-screen-01.jpg";
import screenshot2 from "./images/img-screen-02.jpg";
import screenshot3 from "./images/img-screen-03.jpg";

import item1 from "./images/rewards/1-body-ind_item-bottom.png";
import item2 from "./images/rewards/2-head-ind_item-bottom.png";
import item3 from "./images/rewards/3-hip-elvis_item-bottom.png";
import item4 from "./images/rewards/4-legs-hunter_item-bottom.png";
import item5 from "./images/rewards/5-item-bottom.png";
import item6 from "./images/rewards/6-item-bottom.png";
import item7 from "./images/rewards/7-item-bottom.png";
import h2q from "./images/rewards/8-h2q-bottom.png";
import qst from "./images/rewards/9-qst-bottom.png";
import avatarImg from "./images/rewards/Avatar-hippo_item-bottom.png";

import { Button } from "shared/ui";

export function UserGuidePlayerBlock() {
  const [isOpen, toggleIsOpen] = useState<boolean>(false);

  const openUserGuideLink = () => {
    window.open("https://hero2quest.gitbook.io/user-guide/", "_blank");
  };

  return (
    <div className={styles["user-guide-block"]}>
      <button className={styles["toggle-btn"]} onClick={() => toggleIsOpen(prev => !prev)}>
        For Players
        <IconArrow className={isOpen ? styles.open : ""} width={15} height={15} stroke="#dadada" />
      </button>
      <div className={styles["guide-wrapper"]}>
        <div className={styles["main-guide"]}>
          <div className={styles.steps}>
            <div className={styles.step}>
              <h2><IconCollectoin
                fill="#ffffff"
                stroke="#ffffff"
                style={{
                  verticalAlign: "-0.2rem",
                  filter: "drop-shadow(1px 1px 1px #283d3f)",
                  marginRight: "0.5rem"
                }} />
                Get and collect <span className={styles["key-words"]}>ITEMS</span>
              </h2>
              <div className={styles.screen}><img src={screenshot1} alt="Guide screen #1" /></div>
            </div>

            <div className={styles["arrow-right-block"]}>
              <IconArrow stroke="#dadada" style={{ transform: "rotate(270deg)" }} />
            </div>

            <div className={styles.step}>
              <h2><IconHero
                fill="#ffffff"
                stroke="#ffffff"
                style={{
                  verticalAlign: "-0.2rem",
                  filter: "drop-shadow(1px 1px 1px #283d3f)",
                  marginRight: "0.5rem"
                }} />
                Create the right <span className={styles["key-words"]}>NFT-HERO</span>
              </h2>
              <div className={styles.screen}><img src={screenshot2} alt="Guide screen #1" /></div>

              <div className={styles["arrow-up-block"]}>
                <div>
                  <IconArrow stroke="#dadada" style={{ transform: "rotate(180deg)" }} />
                </div>
              </div>

              <div className={styles["arrow-top-down-block"]}>
                <div>
                  <IconArrow stroke="#dadada" style={{ transform: "rotate(0deg)" }} />
                </div>
              </div>
            </div>

            <div className={styles["arrow-right-block"]}>
              <IconArrow stroke="#dadada" style={{ transform: "rotate(270deg)" }} />
            </div>

            <div className={styles.step}>
              <h2><IconQuest
                fill="#ffffff"
                stroke="#ffffff"
                style={{
                  verticalAlign: "-0.2rem",
                  filter: "drop-shadow(1px 1px 1px #283d3f)",
                  marginRight: "0.5rem"
                }} />
                Complete the <span className={styles["key-words"]}>QUEST</span> task
              </h2>
              <div className={styles.screen}><img src={screenshot3} alt="Guide screen #1" /></div>
              <div className={styles["arrow-down-block"]}>
                <div>
                  <IconArrow stroke="#dadada" style={{ transform: "rotate(0deg)" }} />
                </div>
              </div>
              <div className={styles["steps-arrows"]}>
                <h3 style={{ marginTop: "2vh" }}> Get resources in QUESTS to create new NFT-HERO for the next task</h3>
              </div>
            </div>
          </div>


          <div className={`${styles["items-and-btn"]} ${isOpen ? styles.hidden : ""}`}>
            <div className={styles.btn}>
              <Button
                children={<p style={{ margin: 0, padding: "0 2rem", fontSize: "2rem" }}>User GUIDE</p>}
                widthValue={"full"}
                action={openUserGuideLink}
              />
            </div>
            <div className={styles.avatar}><img src={avatarImg} alt="Avatar sample" /></div>
            <div className={styles.items}>
              <div className={styles.item}><img src={item1} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item2} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item3} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item4} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item5} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item6} alt="Item reward sample" /></div>
              <div className={styles.item}><img src={item7} alt="Item reward sample" /></div>
            </div>

            <div className={styles["token-reward"]}><img src={h2q} alt="Reward h2q sample" /></div>
            <div className={styles["token-reward"]}><img src={qst} alt="Reward qst sample" /></div>

          </div>
        </div>
        <div className={`${styles["second-guide"]} ${isOpen ? styles.shown : ""}`}>
          <div className={styles["second-guide-inner"]}>
            <h3>How to play the <span className={styles["key-words"]}>HERO2QUEST</span> game?</h3>
            <button className={styles["close-btn"]} onClick={() => toggleIsOpen(prev => !prev)}>
              <IconClose />
            </button>
            <p>To start playing you need to create an account.<br />Your section of the NFT ASSETS collection must have resources for the game - at least 7 items and one avatar. You can only get these game resources in quests, or you can get a limited amount of resources at the beginning of the game. Once you have your items and avatars, you can create NFT-HEROES from them in the NFT Generator - and the Hero will be forever in your collection.<br />When you have Heroes, you can participate in the HERO2QUEST game to complete quests and receive new items and rewards. <br /><br />Everything is very simple:<br />1.Create a hero!<br />2.Complete the quest!<br />3.Get rewards in game tokens and resources for new Heroes!<br /><br />Do you think it's too easy?</p>
            {/* <div className={styles.btn}>
              <Button
                children={<p style={{ margin: 0, padding: "0 2rem", fontSize: "2rem" }}>User GUIDE</p>}
                widthValue={"full"}
                action={openUserGuideLink}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div >
  )
}
