import { QuestSelectedItem } from "entities/quest"
import { ReactNode } from "react"
import { Fade } from "react-awesome-reveal"
import styles from "./QuestMainBlock.module.scss"

interface IQuestMainBlock {
  isFold: boolean;
  selectedItem: QuestSelectedItem | null;
  titleBlock: ReactNode;
  bookmarkBlock: ReactNode;
  descriptionBlock: ReactNode;
  infoBlock: ReactNode;
  previewBlock: ReactNode;
  costBlock: ReactNode;
  rewardBlock: ReactNode;
  heroPlayBlock: ReactNode;
  btnBlock: ReactNode;
};

export const QuestMainBlock = ({ isFold, selectedItem, titleBlock, bookmarkBlock, descriptionBlock, infoBlock, previewBlock, costBlock, rewardBlock, heroPlayBlock, btnBlock }: IQuestMainBlock) => {

  return (
    <div className={`${styles["element-wrapper"]} ${isFold ? styles.compact : ""}`}>
      <div className={styles.title}>
        {titleBlock}
      </div>
      <div className={styles.bookmark}>
        {bookmarkBlock}
      </div>
      <div className={`${styles["info"]} ${isFold ? styles.fold : ""}`}>
        {descriptionBlock}
        {infoBlock}
        {selectedItem &&
          <Fade
            triggerOnce
            duration={300}
          >
            {previewBlock}
          </Fade>
        }
      </div>
      <div className={styles["cost-reward"]}>
        {costBlock}
        {rewardBlock}
      </div>
      <div className={`${styles["heroes-play"]} ${isFold ? styles.fold : ""}`}>
        {heroPlayBlock}
      </div>
      <div className={styles["btn-block"]}>
        {btnBlock}
      </div>
    </div>

  )
};