import styles from "./GeneratorSelector.module.scss";
import { GeneratorSelectorGallery, GeneratorSelectorStats } from "widgets";
// import { GeneratorSelectorLogo, GeneratorSelectorNewGenerator } from "entities/generatorSelector";
// import { TypeformAddNewGenerator } from "features/generatorSelector";

export const GeneratorSelector = () => {
  return (
    <div className={styles['generators-wrapper']}>
      {/* <GeneratorSelectorLogo>
        <TypeformAddNewGenerator>
          <GeneratorSelectorNewGenerator />
        </TypeformAddNewGenerator>
      </GeneratorSelectorLogo> */}

      <div className={styles["inner-wrapper"]}>

        <GeneratorSelectorStats />

        <GeneratorSelectorGallery />
      </div>
    </div>
  )
};