import { useState } from "react";
import { useNavigate } from "react-router-dom";
import longLogo from "./images/logo-h2q-long.png";
import colorLogo from "./images/logo-h2q-color.png";
import styles from "./LoginMainPage.module.scss";
import Agreement from "./Agreement";
import { Button } from "shared/ui";

const termsLink = "https://hero2quest.gitbook.io/legal-notices/terms-of-service";
const privacyLink = "https://hero2quest.gitbook.io/legal-notices/privacy-policy";
const sucurityLink = "https://hero2quest.gitbook.io/legal-notices/security-policies";

export default function LoginMainPage() {
  const navigate = useNavigate();

  const [isAgree, setIsAgree] = useState<boolean>(() => {
    return localStorage.getItem("isDisclaimerRead") && localStorage.getItem("isDisclaimerRead") === "true"
      ? true
      : false
  });

  const acceptDisclaimer = () => {
    localStorage.setItem("isDisclaimerRead", "true");
    setIsAgree(prev => true);
  };

  const createNewAccount = () => {
    navigate("create");
  };

  const loginToAccount = () => {
    navigate("login");
  };

  if (!isAgree) {
    return <Agreement action={acceptDisclaimer} />
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles["login-and-create-block"]}>

        <div className={styles["login-block"]}>
          <div className={styles.logos}>
            <img className={styles["logo-color"]} src={colorLogo} alt="H2Q Color Logo" />
            <img className={styles["logo-long"]} src={longLogo} alt="H2Q Long White Logo" />
          </div>
          <p className={styles["button-label"]}>Sign in</p>
          <Button
            colorValue="green"
            widthValue={"full"}
            children={<p style={{ padding: "0.5rem 0" }}>Login to your account</p>}
            action={loginToAccount}
          />
        </div>

        <div className={styles["create-block"]}>
          <p className={styles["button-label"]}>New to Hero2Quest?</p>
          <Button
            colorValue="darkGrey"
            widthValue={"full"}
            children={<p style={{ padding: "0.5rem 0" }}>Create new account</p>}
            action={createNewAccount}
          />
        </div>

      </div>

      <div className={styles.links}>
        <a
          href={termsLink}
          target="_blank"
          rel="noreferrer"
        >Terms
        </a>
        <a
          href={privacyLink}
          target="_blank"
          rel="noreferrer"
        >Privacy
        </a>
        <a
          href={sucurityLink}
          target="_blank"
          rel="noreferrer"
        >Security
        </a>
        {/* <Link to={"/terms-of-use"}>Contact Hero2Quest</Link> */}
      </div>

    </div>
  );
};