import { H2QContext } from "app/core/H2QContext";
import { H2QuestEssentialParams, H2QuestMandatoryParams, RewardParams } from "app/core/H2QQuestManager";
import { TInputCell } from "entities/questMaker";
import { observer } from "mobx-react";
import { useContext, useState } from "react";
import styles from "./EditQuest.module.scss";

interface IEditQuest {
  quest: TInputCell;
};

export const EditQuest = observer(function ({ quest }: IEditQuest) {
  const { h2qAccount } = useContext(H2QContext);
  const [essentialParams, setEssentialParams] = useState<H2QuestEssentialParams>(() => { return { ...quest.questParams.essentialParams } });
  const [mandatoryParams, setMandatoryParams] = useState<H2QuestMandatoryParams>(() => { return { ...quest.questParams.mandatoryParams } });
  const [additionalParams, setAdditionalParams] = useState<string>(() => { return quest.questParams.additionalParams });
  const [rewardParams, setRewardParams] = useState<RewardParams>(() => { return { ...quest.rewardParams } });

  return (
    <>
      <h2 className={styles["quest-header"]}>Edit quest params:</h2>

      <div className={styles["input-text"]}>
        <label className={styles["value-title"]}>QuestId</label>
        <input
          type="text"
          id={"questId"}
          value={quest.questId}
          disabled={true}
        />
      </div>


      {Object.entries(essentialParams).map(([paramKey, value]) => {
        return (
          <div key={paramKey} className={`${styles["input-text"]} ${h2qAccount.uiStore.rightSidebarChild.editable ? styles.editable : ""}`}>
            <label className={styles["value-title"]}>{paramKey}</label>
            <input
              type="text"
              id={paramKey}
              value={value}
              placeholder={`${paramKey}`}
              disabled={!h2qAccount.uiStore.rightSidebarChild.editable}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => {
                  setEssentialParams(prev => {
                    return {
                      ...prev,
                      [paramKey as keyof H2QuestEssentialParams]: e.target.value
                    }
                  })
                }
              }
            />
          </div>
        )
      })}

      {Object.entries(mandatoryParams).map(([paramKey, value]) => {
        return (
          <div key={paramKey} className={`${styles["input-text"]} ${h2qAccount.uiStore.rightSidebarChild.editable ? styles.editable : ""}`}>
            {paramKey === "questNarrative"
              ?
              <textarea
                id={paramKey}
                value={value}
                placeholder={`${paramKey}`}
                disabled={!h2qAccount.uiStore.rightSidebarChild.editable}
                onChange={
                  (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMandatoryParams(prev => {
                      return {
                        ...prev,
                        [paramKey as keyof H2QuestMandatoryParams]: e.target.value
                      }
                    })
                  }
                }
              />
              :
              <>
                <label className={styles["value-title"]}>{paramKey}</label>
                <input
                  type="text"
                  id={paramKey}
                  value={value}
                  placeholder={`${paramKey}`}
                  disabled={!h2qAccount.uiStore.rightSidebarChild.editable}
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                      setMandatoryParams(prev => {
                        return {
                          ...prev,
                          [paramKey as keyof H2QuestMandatoryParams]: e.target.value
                        }
                      })
                    }
                  }
                />
              </>
            }
          </div>
        )
      })}

      {Object.entries(rewardParams).map(([paramKey, value]) => {
        return (
          <div key={paramKey} className={`${styles["input-text"]} ${h2qAccount.uiStore.rightSidebarChild.editable ? styles.editable : ""}`}>
            <label className={styles["value-title"]}>{paramKey}</label>
            <input
              type="text"
              id={paramKey}
              value={value}
              disabled={!h2qAccount.uiStore.rightSidebarChild.editable}
              placeholder={`${paramKey}`}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => {
                  setRewardParams(prev => {
                    return {
                      ...prev,
                      [paramKey as keyof RewardParams]: e.target.value
                    }
                  })
                }
              }
            />
          </div>
        )
      })}

      <div className={`${styles["input-text"]} ${h2qAccount.uiStore.rightSidebarChild.editable ? styles.editable : ""}`}>
        {/* <label className={styles["value-title"]}>additionalParams</label> */}
        <textarea
          id={"additionalParams"}
          value={additionalParams}
          disabled={!h2qAccount.uiStore.rightSidebarChild.editable}
          placeholder={"additionalParams"}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { setAdditionalParams(prev => e.target.value) }}
        />
      </div>
    </>
  )
});