import styles from "./Seed.module.scss";

interface SeedProps {
  seed: string;
  inProgress?: boolean;
}

export const Seed = ({ seed, inProgress }: SeedProps) => {
  const tempSeed = seed.length > 0 ? seed.split(" ") : Array(12).map((x) => "");

  return (
    <>
      <div
        className={styles["seed-words-wrapper"]}
        style={inProgress ? { opacity: 0.3 } : {}}
      >
        {tempSeed.map((item, index) => {
          return (
            <div className={styles["seed-word-input"]} key={index}>
              <input
                type="text"
                value={item}
                readOnly={true}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
