const sideBarWidth = 145;
const topBarHeight = 84;
// export const canvasInitialWidth = 2548;
export const canvasInitialWidth = 2458 - sideBarWidth; // LOL...
// export const canvasInitialHeight = 1536;
export const canvasInitialHeight = 1536 - topBarHeight;
export const ratio = canvasInitialWidth / canvasInitialHeight;

export const calculateCanvasSize = (w: number, h: number): number[] => {
  let canvasBgWidth = 0.1;
  let canvasBgHeight = 0.1;

  // let canvasControlsWidth = 0.1;
  let canvasControlsHeight = 0.1;
  // if (w > h) {
  //   height = Math.ceil(Math.min(w / ratio, h));
  //   width = Math.ceil(height * ratio);
  // }
  // if (w <= h) {
  //   height = Math.min(h, w * ratio);
  //   width = Math.ceil(height / ratio);
  // }

  // Always horizontal:
  // height = Math.ceil(Math.min(w / ratio, h));
  // width = Math.ceil(height * ratio);

  // Always scale to fullscreen:
  // height = h;
  // width = w;

  // Always full width with keeping acpect ratio:
  canvasBgWidth = w;
  canvasBgHeight = Math.ceil(w / ratio);

  canvasControlsHeight = h;
  // canvasControlsWidth = Math.ceil(canvasControlsHeight * ratio);

  const screenSizeRatioW = canvasInitialWidth / w;
  const screenSizeRatioH = canvasInitialHeight / h;

  return [canvasBgWidth, canvasBgHeight, canvasControlsHeight, screenSizeRatioW, screenSizeRatioH];
};