import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AddToFavButton } from "shared/ui";

interface IAddQuestToFav {
  questId: string;
};

export const AddQuestToFav = observer(function ({ questId }: IAddQuestToFav) {
  const { h2qAccount } = useContext(H2QContext);
  const { id } = useParams();

  const isFav = id
    ? h2qAccount.uiStore.favQuestsForNfts[id]?.quests.includes(questId)
    : h2qAccount.uiStore.questFilter.favQuests.includes(questId);

  const addQuestToNftsFavQuests = () => {
    if (id && h2qAccount.uiStore.favQuestsForNfts[id] && h2qAccount.uiStore.favQuestsForNfts[id].quests.includes(questId)) {
      h2qAccount.uiStore.changeFavQuestsForNft(id!, questId);
      if (h2qAccount.uiStore.favQuestsForNfts[id].quests.length === 0) {
        h2qAccount.uiStore.changeNftPageFavQuestMode(id, false);
      }
      return;
    }
    if (id && h2qAccount.uiStore.favQuestsForNfts[id] && !h2qAccount.uiStore.favQuestsForNfts[id].quests.includes(questId)) {
      h2qAccount.uiStore.changeFavQuestsForNft(id!, questId);
      return;
    }
    else {
      h2qAccount.uiStore.changeFavQuestsForNft(id!, questId);
    }
  };

  const addQuestToFavQuests = () => {
    if (h2qAccount.uiStore.questFilter.favQuests.length && h2qAccount.uiStore.questFilter.favQuests.includes(questId)) {
      h2qAccount.uiStore.changeQuestFilterFavourites(questId);
      return;
    }
    else {
      h2qAccount.uiStore.changeQuestFilterFavourites(questId);
      return;
    }
  };

  const onFavClick = () => {
    if (id) {
      addQuestToNftsFavQuests();
      return;
    }

    addQuestToFavQuests();
  };

  return (
    <AddToFavButton
      action={onFavClick}
      isActive={true}
      isAddedToFav={isFav}
      size={28}
    />
  )
});