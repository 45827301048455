import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui";
import styles from "./SelectGeneratorMessage.module.scss";

export const SelectGeneratorMessage = () => {
  const navigate = useNavigate();

  const goToGeneratorsPage = () => {
    navigate("/h2q/generator");
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles['text']}>Please choose any Generator to play with first.</p>
      <p className={styles['text']}>You can make it on the Generator Gallery Page</p>
      <div className={styles['buttons']}>
        <Button
          children={(<p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>GO TO GENERATORS GALLERY</p>)}
          widthValue={40}
          colorValue={"green"}
          action={goToGeneratorsPage}
          isActive={true}
        />
      </div>
    </div>
  )
};