import { TonClient } from "@eversdk/core";
import { libWeb } from "@eversdk/lib-web";

// @ts-ignore
// eslint-disable-next-line react-hooks/rules-of-hooks
TonClient.useBinaryLibrary(libWeb);

export const client = new TonClient({
  network: {
    network_retries_count: 10,
    message_retries_count: 10,
    message_processing_timeout: 120000,
    wait_for_timeout: 120000,
    endpoints: [
      "blockchain.hero2quest.io",
      // "devnet.evercloud.dev/fce018670aa34a49a76efbeb00f684d3/graphql",
      //"devnet.evercloud.dev/782a5b5adf044953b9b15050e1bad9ef/"
    ],
  },
  abi: {},
});
