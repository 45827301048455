import styles from "./QuestActiveTimerBlock.module.scss";
import { Timer } from "entities/Timer";

interface IQuestActiveTimerBlock {
  finishTime: number;
};

export const QuestActiveTimerBlock = ({ finishTime }: IQuestActiveTimerBlock) => {
  return (
    <div className={styles["active-quest-block"]}>
      <Timer
        finishTime={finishTime}
        messageType={"heroInCurrentQuest"}
        size={17}
      />
    </div>
  )
};