const ChecksContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "time"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [],
                "outputs": []
            }
        ],
        "data": [],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "_checkList",
                "type": "uint8"
            }
        ]
    },
    tvc: "te6ccgECDwEAAUwAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsMBQQOApDtRNDXScMB+GYh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAds88jwJBgNK7UTQ10nDAfhmItDXCwOpOADcIccA4wIh1w0f8rwh4wMB2zzyPAsLBgEUIIIQaLVfP7rjAgcCIjD4Qm7jAPhG8nPR+ADbPPIACQgAJPhK+EP4QsjL/8s/z4PLB8ntVAFE7UTQ10nCAY4XcO1E0PQFcPhqgED0DvK91wv/+GJw+GPjDQoAJu1E0NP/0z/TADHTB9H4avhj+GIACvhG8uBMAhD0pCD0vfLATg4NABRzb2wgMC42Ni4wAAA=",
    code: "te6ccgECDAEAAR8ABCSK7VMg4wMgwP/jAiDA/uMC8gsJAgELApDtRNDXScMB+GYh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAds88jwGAwNK7UTQ10nDAfhmItDXCwOpOADcIccA4wIh1w0f8rwh4wMB2zzyPAgIAwEUIIIQaLVfP7rjAgQCIjD4Qm7jAPhG8nPR+ADbPPIABgUAJPhK+EP4QsjL/8s/z4PLB8ntVAFE7UTQ10nCAY4XcO1E0PQFcPhqgED0DvK91wv/+GJw+GPjDQcAJu1E0NP/0z/TADHTB9H4avhj+GIACvhG8uBMAhD0pCD0vfLATgsKABRzb2wgMC42Ni4wAAA=",
    codeHash: "8e3c104896069011bf3fde883e03bd5615ad6b059d4b492502230d4e99d9536a",
};
module.exports = { ChecksContract };