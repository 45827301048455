import { ModalText, ModalTitle, ModalTypes, ModalWrapperChildren, ModalWrapperOut } from 'entities/generator';
import { WaitingPercent } from './WaitingPercent';
import { RotatingPercent } from 'shared/ui';
// import { WaitingMessages } from "./WaitingMessages";

const type: ModalTypes = "waiting";

interface IWaiting {
  maxValueToWait: number | null;
};

export const Waiting = function ({ maxValueToWait }: IWaiting) {
  if (maxValueToWait === null) return null;

  return (
    <>
      <ModalWrapperOut>
        <ModalTitle type={type} />

        <ModalWrapperChildren>
          <ModalText type={type} />
          <div style={{
            display: 'flex',
            flex: 1,
            alignItems: "stretch",
            justifyContent: "center",
          }}>
            {/* <WaitingMessages /> */}
            <div style={{ margin: "auto" }}>
              <RotatingPercent size={75}>
                <WaitingPercent maxValue={maxValueToWait} />
              </RotatingPercent>
            </div>
          </div>
        </ModalWrapperChildren>

      </ModalWrapperOut>
    </>
  )
};