import { InitialItemElement, parts } from "entities/item";
import { NftItems } from "entities/nft";

export enum ItemBlockColorVariants {
  green = "#24E9A4",
  violet = "#8D6CFF",
  yellow = "#EADC55",
  blue = "#50B6FE",
  gray = "#818DA5",
  white = "#EDEFEC",
  red = "#FE5D6D"
};

export type ItemBlockColorKeys = keyof typeof ItemBlockColorVariants;

export interface ItemElementWithColor extends InitialItemElement {
  color: ItemBlockColorKeys;
};

const colorKeys = Object.keys(ItemBlockColorVariants).map(item => item);

type ColorMask = {
  [K: number]: ItemBlockColorKeys;
};

export const getItemsBlocksColors = (items: NftItems<InitialItemElement>): { result: NftItems<ItemElementWithColor>, colorArray: ItemBlockColorKeys[] } => {
  const colorArray = [] as ItemBlockColorKeys[];
  const colorMask = {} as ColorMask;

  let colorIndex = 0;

  const itemsArray = Object.values(items).map((item: InitialItemElement) => item);

  const itemsArraySorted = [
    itemsArray[2],
    itemsArray[3],
    itemsArray[1],
    itemsArray[0],
    itemsArray[4],
    itemsArray[5],
    itemsArray[6],
  ];

  itemsArraySorted.forEach((item: InitialItemElement, index) => {
    if (!colorMask[item.itemId]) {
      if (index === 0) {
        colorMask[item.itemId] = colorKeys[0] as keyof typeof ItemBlockColorVariants;
      } else {
        colorIndex++;
        colorMask[item.itemId] = colorKeys[colorIndex] as keyof typeof ItemBlockColorVariants;
      }
    }
  })

  const result = itemsArraySorted.map((item, index) => {

    const colorKey = colorMask[item.itemId];

    if (colorArray.includes(colorKey)) {
      const index = colorArray.indexOf(colorKey);
      colorArray.splice(index + 1, 0, colorKey);
    } else {
      colorArray.push(colorKey);
    };

    return { ...item, color: ItemBlockColorVariants[colorKey] }
  })
    .reduce((acc, item) => {
      const itemKey = parts[item.categoryId] as keyof NftItems<InitialItemElement>;
      return { ...acc, [itemKey]: item }
    }, {} as NftItems<ItemElementWithColor>);

  return { result, colorArray };
};