import { FC, PropsWithChildren } from "react";
import { AddToFavButton, MenuButton, PlusButton } from "shared/ui";
import styles from "./GalleryItemWrapper.module.scss";

interface IGalleryItemFadeAndBg {
  hasTopPadding?: boolean;
}
export const GalleryItemFadeAndBg: FC<PropsWithChildren<IGalleryItemFadeAndBg>> = ({ children, hasTopPadding = true }) => {
  return (
    <div
      style={{ "paddingTop": hasTopPadding ? "7%" : 0 }}
      className={styles["round-border-and-bg"]}
    >
      {children}
    </div>
  )
};

interface IGalleryItemButtonsBlock {
  addToFavAction?: () => void;
  isAddedToFav?: boolean;
  amount: number;
  openMenuAction?: (event: React.SyntheticEvent) => void;
  selectAction?: () => void;
};

export const GalleryItemButtonsBlock: FC<PropsWithChildren<IGalleryItemButtonsBlock>> = ({
  amount,
  addToFavAction,
  openMenuAction,
  isAddedToFav,
  selectAction
}) => {
  return (
    <div className={styles["item-bottom-btns-wrapper"]}>
      {addToFavAction && (isAddedToFav !== undefined) &&
        <AddToFavButton
          action={addToFavAction}
          isActive={true}
          isAddedToFav={isAddedToFav}
          size={25}
        />
      }
      <p className={styles["item-amount"]}>{amount}</p>
      {openMenuAction && !selectAction &&
        <MenuButton
          isActive={true}
          size={25}
          action={openMenuAction}
        />
      }
      {selectAction && !openMenuAction &&
        <PlusButton
          choseBtnType="sendToQuest"
          action={selectAction}
          isActive={true}
          isSelected={false}
        />
      }
    </div>
  )
};

interface IGalleryItemMainWrapper {
  isItemChosen?: boolean;
  isItemStaked?: boolean;
};

export const GalleryItemMainWrapper: FC<PropsWithChildren<IGalleryItemMainWrapper>> = ({
  children,
  isItemChosen = false,
  isItemStaked = false
}) => {
  return (
    <div className={`${styles["gallery-main-item-wrapper"]} ${isItemChosen ? styles.chosen : ""}  ${isItemStaked ? styles.stacked : ""}`}>
      {children}
    </div>
  )
};