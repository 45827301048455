import { FC, PropsWithChildren } from "react";
import { RotatingCircle } from "shared/ui";
import styles from "./RotatingPercent.module.scss";

interface IRotatingPercent {
  size?: number;
};

export const RotatingPercent: FC<PropsWithChildren<IRotatingPercent>> = ({ size = 50, children }) => {
  return (
    <div style={{ width: (size / 10) + "rem", height: (size / 10) + "rem" }} className={styles.wrapper}>
      <div className={styles.rotating}>
        <RotatingCircle size={size} />
      </div>
      <div style={{ fontSize: ((size / 30) + "rem") }} className={styles.percent}>
        {children}
      </div>
    </div>
  )
}
