import { RotatingCircle } from "shared/ui";
import { getRarityName } from "entities/item";
import { H2QContext } from "app/core/H2QContext";
import { getItemsFromArray, ItemElement } from "entities/item";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import styles from "./NftInfoStatsTab.module.scss";
import { NftElement } from "entities/nft";
import { ItemRewardElement } from "entities/ItemReward";

interface INftInfoStatsTab {
  nft: NftElement;
};

export const NftInfoStatsTab = observer(function ({ nft }: INftInfoStatsTab) {
  const { h2qAccount } = useContext(H2QContext);

  const nftItemRewards: ItemElement[] = h2qAccount.currentHeroRewards && h2qAccount.currentHeroRewards.itemsRewards
    ? getItemsFromArray(h2qAccount.currentHeroRewards.itemsRewards)
    : [];

  const spentHours = h2qAccount.currentHeroRewards
    ? (Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 3600)).toString().length < 2
      ? "0" + Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 3600)
      : Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 3600)
    : "00";

  const spentMinutes = h2qAccount.currentHeroRewards
    ? (Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 60)).toString().length < 2
      ? "0" + Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 60)
      : Math.floor(h2qAccount.currentHeroRewards.duration % 3600 / 60)
    : "00";

  return (
    <div>
      <h3 className={styles['info-title']}>Statistics</h3>
      {
        h2qAccount.calculateHeroQuestsRewards.state === "pending" &&
        <div className={styles.empty}>
          <RotatingCircle size={30} thick={3} />
        </div>
      }
      {
        h2qAccount.currentHeroRewards &&
        h2qAccount.currentHeroRewards.nftId === nft.pseudoNFTAddr &&
        h2qAccount.calculateHeroQuestsRewards.state === "resolved" &&
        <>
          <p className={styles['info-value']}><span className={styles['info-param']}>Quest completed:</span>{h2qAccount.currentHeroRewards.completed}</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>Time spent:</span>{spentHours}hh:{spentMinutes}mm</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>QST gained:</span>{h2qAccount.currentHeroRewards.qstReward}</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>H2Q gained:</span>{h2qAccount.currentHeroRewards.h2qReward} ({Math.round(h2qAccount.currentHeroRewards.h2qReward * 1000 / (+nft.pseudoNftData?.m_price!)) / 10}% of creation price)</p>
          <p className={styles['info-value']}><span className={styles['info-param']}>Total items gained:</span>{h2qAccount.currentHeroRewards.itemsCount}</p>
          {nftItemRewards && nftItemRewards.length &&
            <div className={styles["gained-items-list"]}>
              <p className={styles['info-value']}><span className={styles['info-param']}>Items gained:</span></p>
              <ul>
                {nftItemRewards.map(item => {
                  return (
                    <ItemRewardElement
                      key={item.iconThumbLink}
                      item={item}
                      value={h2qAccount.inventoryInfoFromBC![item.itemId].value}
                      rarity={getRarityName(h2qAccount.inventoryInfoFromBC![item.itemId].rarity)}
                    />
                  )
                })}
              </ul>
            </div>
          }
        </>
      }
    </div>
  )
});