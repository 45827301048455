import { AvatarCard, AvatarElement } from "entities/avatar";
import { GalleryItemButtonsBlock, GalleryItemFadeAndBg, GalleryItemMainWrapper } from "entities/GalleryItemWrapper";

interface IAvatarListElement {
  avatar: AvatarElement;
  isFav: boolean;
  isActiveInGenerator: boolean;
  isSelected?: boolean;
  onFavClick: (avatarId: number) => void;
  onAvatarClick: (avatarId: number) => void;
  onSendToGenerator: () => void;
};

export const AvatarListElement = ({ avatar, isFav, onFavClick, isActiveInGenerator, isSelected = false, onAvatarClick, onSendToGenerator }: IAvatarListElement) => {
  // temp variable
  const isImgMoved = true;

  return (
    <GalleryItemMainWrapper
      isItemStaked={isActiveInGenerator}
      isItemChosen={isSelected}
    >
      <GalleryItemFadeAndBg>
        <AvatarCard isImgMoved={isImgMoved} item={avatar} process={onAvatarClick} isFadeActive={true} />
      </GalleryItemFadeAndBg>
      <GalleryItemButtonsBlock
        amount={avatar.amount}
        addToFavAction={() => onFavClick(avatar.itemId)}
        selectAction={onSendToGenerator}
        isAddedToFav={isFav}
      />
    </GalleryItemMainWrapper>
  )
};