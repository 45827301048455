import { AvatarImageSVG } from "entities/avatar";
import { InitialItemElement, ItemImageSVG } from "entities/item";
import { CostAndRewardType, IQuestPrice, QuestSelectedItem } from "entities/quest";
import iconEmpty from "app/icons/icon-item-empty.svg";
import styles from "./CostAndRewardItems.module.scss";
import { useParams } from "react-router-dom";
import { h2qAccount } from "app/core/H2QContext";
// import Dice from '../../CommonBlocks/Dice/Dice';

interface ICostAndRewardItems {
  cost: IQuestPrice;
  type: CostAndRewardType;
  action: (item: QuestSelectedItem) => void;
  selectedItem: QuestSelectedItem | null;
};

export const CostAndRewardItems = ({ cost, type, action, selectedItem }: ICostAndRewardItems) => {
  const { id: nftId } = useParams();
  const nftInfo = nftId ? h2qAccount.nfts.find(n => n.pseudoNFTAddr.slice(2) === nftId) : null;
  const nftItems = nftInfo?.pseudoNFTdata.m_h2qTrueCharacter.inventory;
  const costItems: string[] = [];
  Object.values(cost.items).forEach((item: InitialItemElement) => {
    if (item) {
      costItems[item.categoryId] = item.itemId.toString()
    }
  });

  const isAvatarExist =
    type === "reward" || !nftInfo
      ? true
      : cost.avatar &&
      cost.avatar.itemId >= 0 &&
      Number(nftInfo.pseudoNFTdata.m_h2qTrueCharacter.avatar_id) === cost.avatar.itemId;

  // const isAllItemsExist =
  //   type === "cost" &&
  //   costItems.every((item, index) => {
  //     if (+item === -1) return true;
  //     if (nftItems && +item === +nftItems[index]) return true;
  //     else return false;
  //   })

  // const maxItemSlots = type === "cost" ? 7 : 6;
  const maxItemSlots = 7;

  const currentAvatar: QuestSelectedItem = {
    avatar: cost.avatar,
    type: type
  };

  const questItems = Object.values(cost.items)
    .map((item: InitialItemElement) => {
      return item;
    })
    .filter(item => item !== null);
  const emptySlotsLength = questItems.length === 7 ? 0 : maxItemSlots - questItems.length;

  const emptySlots = [...Array(emptySlotsLength)].map((i, index) => index);

  return (
    <div className={styles['items-wrapper']}>
      {cost.avatar !== null
        ? <div className={`${styles.item} ${JSON.stringify(selectedItem) === JSON.stringify(currentAvatar) ? styles.active : ""} ${isAvatarExist ? "" : styles["not-exist"]}`} onClick={() => action(currentAvatar)}>
          <AvatarImageSVG avatarImg={cost.avatar} isImgMoved={true} />
        </div>
        :
        <div className={styles['item-empty']}>
          <ItemImageSVG iconLink={iconEmpty} />
        </div>
      }

      {questItems.map((item, index) => {
        const currentItem: QuestSelectedItem = {
          item,
          type
        };
        // if (index === maxItemSlots && type === "reward") {
        //   return null;
        // }
        const isItemExist = type === "reward" || !nftItems
          ? true
          : Number(nftItems[item.categoryId]) === Number(item.itemId);
        return (
          <div
            key={index}
            className={`${styles.item} ${JSON.stringify(selectedItem) === JSON.stringify(currentItem) ? styles.active : ""} ${isItemExist ? "" : styles["not-exist"]}`}
            onClick={() => action(currentItem)}>
            <ItemImageSVG iconLink={item.iconLink} />
          </div>
        )
      })}

      {emptySlots.map((item, index) => {
        return (
          <div className={styles['item-empty']} key={index}>
            <ItemImageSVG iconLink={iconEmpty} />
          </div>
        )
      })}

      {/* {type === "reward" &&
          <div className={styles['item-empty']} >
            <Dice />
          </div>
        } */}
    </div>
  )
}