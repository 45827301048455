import styles from "./AddToFavButton.module.scss";
import { ReactComponent as IconFavGray } from "app/icons/icon-favorit-gray.svg";
import { IconColorVariants } from "app/icons/icons";
import { CSSProperties } from "react";

interface IAddToFavButton {
  action: () => void;
  isActive: boolean;
  isAddedToFav?: boolean;
  size?: number;
  style?: CSSProperties;
};

export const AddToFavButton = ({ action, isActive, size = 18, isAddedToFav = false, style }: IAddToFavButton) => {
  const iconColor = isAddedToFav
    ? IconColorVariants.yellow
    : IconColorVariants.gray;

  return (
    <button
      style={{ ...style, width: `${size / 10}rem`, height: `${size / 10}rem` }}
      disabled={!isActive}
      className={styles["fav-btn"]}
    >
      <IconFavGray
        fill={iconColor}
        className={styles["btn-image"]}
        onClick={action}
      />
    </button>
  )
}