import { H2QContext } from "app/core/H2QContext";
import { SideBarBottomBlock, SideBarHelpLinks, SideBarLogo, SideBarMainLinks, SideBarUserBlock, SideBarUserLinks } from "entities/sideBar";
import { SideBarLogin } from "features/goToLoginPage";
import { SideBarLogout } from "features/logoutFromAccount";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import styles from "./SideBar.module.scss";

export const SideBar = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const [isOpen, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkIsLoggedIn = () => {
      if (h2qAccount.data.m_h2qBalance) {
        setIsLoggedIn(true);
      }
      else {
        setIsLoggedIn(false);
      }
    };

    checkIsLoggedIn();
  }, [h2qAccount.data]);

  return (
    <div
      className={`${styles["side-nav__wrapper"]} ${isOpen ? "" : styles.closed}`}
      onMouseOver={openMenu}
      onMouseLeave={closeMenu}
    >
      <SideBarLogo
        isOpen={isOpen}
      />
      <SideBarMainLinks
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
      />
      <SideBarHelpLinks
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
      />
      <SideBarBottomBlock>
        {isLoggedIn
          ?
          <SideBarUserBlock isOpen={isOpen}>
            <SideBarUserLinks isOpen={isOpen} />
            <SideBarLogout isOpen={isOpen} />
          </SideBarUserBlock>
          :
          <SideBarLogin isOpen={isOpen} />
        }
      </SideBarBottomBlock>
    </div>
  )
});