import styles from "./Selector.module.scss";
import { ReactComponent as IconClose } from "app/icons/icon-cancel-white.svg";
import { ReactComponent as IconArrow } from "app/icons/icon-arrow-down.svg";

import { useRef, useState } from "react";
import { useOutsideClick } from "shared/lib";

interface ISelector {
  list: string[];
  selected: string[];
  select: (item: string) => void;
  unselect: (item: string) => void;
};

export default function Selector({ list, selected, select, unselect }: ISelector) {
  const [isListOpened, toggleListOpened] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, () => {
    toggleListOpened(prev => false);
  });

  return (
    <>
      <div className={styles["active-item-bar"]}>
        {selected.map(s => {
          return (
            <div className={styles["active-item"]} key={s} onClick={(e) => {
              e.stopPropagation();
              unselect(s);
            }}>
              <p>
                {s}
              </p>
              <IconClose />
            </div>
          )
        })}
      </div>
      <div className={styles["modal-block"]} ref={modalRef}>
        <div className={styles["toggle-bar"]} onClick={(e) => {
          e.stopPropagation();
          toggleListOpened(prev => !prev);
        }}>
          Choose...
          <IconArrow className={`${styles.arrow} ${isListOpened ? styles.down : ""}`} />
        </div>
        <div className={`${styles["selector-modal"]} ${isListOpened ? styles.open : ""}`}>
          {list.map(l => {
            return (
              <div
                className={`${styles["selector-item"]} ${selected.includes(l) ? styles.inactive : ""}`}
                key={l}
                onClick={() => select(l)}
              >
                {l}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
