import styles from "./ImageColorBlock.module.scss";

interface IImageColorBlock {
  color: string;
};

export const ImageColorBlock = ({ color }: IImageColorBlock) => {
  return (
    <div
      className={styles["color-block"]}
      style={{
        backgroundColor: color
      }}
    >
    </ div>
  )
};
