import { H2QContext } from "app/core/H2QContext";
import { DoubleSlider } from "entities/DoubleSlider";
import { MinMaxFilterValue } from "entities/FilterMinMaxValue";
import { initialMinNftLevel } from "features/nftFilter";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterNftLevel.module.scss";

export const FilterNftLevel = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const minVal = h2qAccount.uiStore.nftFilter.nftMinLevel;
  const maxVal = h2qAccount.uiStore.nftFilter.nftMaxLevel;

  const setMinVal = (min: number) => {
    h2qAccount.uiStore.changeNftFilterMinLevel(min);
  };

  const setMaxVal = (max: number) => {
    h2qAccount.uiStore.changeNftFilterMaxLevel(max);
  };

  return (
    <div className={styles["slider-and-inputs"]}>
      <div className={styles.slider}>
        <DoubleSlider
          min={initialMinNftLevel}
          max={h2qAccount.uiStore.nftMaxLevel}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
      <div className={styles["range-inputs"]}>
        <MinMaxFilterValue
          min={initialMinNftLevel}
          max={h2qAccount.uiStore.nftMaxLevel}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
    </div>
  )
})