import styles from "./QuestTitle.module.scss";
import { H2QuestMandatoryParams } from "app/core/H2QQuestManager";

interface IQuestTitle {
  questMParams: H2QuestMandatoryParams;
};

export function QuestTitle({ questMParams }: IQuestTitle) {

  const questName = questMParams.name || "Unavailable";
  const questWTag = questMParams.worldTag || "Unavailable";
  const questATag = questMParams.actionTag || "Unavailable";

  const questTitle = `${questName} / ${questWTag} ${questATag}`;

  return (
    <h2 className={styles.title}>
      {questTitle}
    </h2>
  )
}
