import { Container, Sprite } from '@saitonakamura/react-pixi';
import { useEffect, useState } from 'react';
import { GeneratorTooltip, GenTooltipsType, getMenuSizeAndCoord, menuIconsCoords } from "entities/generator";

interface IGeneratorRightMenu {
  // x: number;
  // y: number;
  // width: number; // not used
  // height: number;// not used
  toggleTriangles: () => void;
  applyItems: () => void;
  changeAvatar: () => void;
  toggleGenBg: () => void;
  toggleTooltips: () => void;
  isTrianglesActive: boolean;
  isItemsApplied: boolean;
  isBgActive: boolean;
  isTooltipsActive: boolean;
  isVertical: boolean;
};

export const GeneratorTopRightMenu = (props: IGeneratorRightMenu) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<GenTooltipsType | null>(null);

  const menuSizeAndCoord = getMenuSizeAndCoord(props.isVertical);

  useEffect(() => {
    setIsLoading(true)
  }, [props]);

  return (
    <Container
      position={[menuSizeAndCoord.ControlBlock!.x, menuSizeAndCoord.ControlBlock!.y]}
    >
      {
        isLoading &&
        <>
          <Sprite
            image={"/res/Generator/bgrd-menu-black.png"}
            position={[0, 0]}
          />
          <Sprite
            image={"/res/Generator/color-triangles.svg"}
            position={menuIconsCoords["menuTriangles"]}
            buttonMode={true}
            interactive={true}
            pointerdown={props.toggleTriangles}
            alpha={props.isTrianglesActive ? 1 : 0.5}
            mouseover={() => setHoveredItem("menuTriangles")}
            mouseout={() => setHoveredItem(null)}
          />
          <Sprite
            image={"/res/Generator/toggle-items.svg"}
            position={menuIconsCoords["menuUndress"]}
            buttonMode={true}
            interactive={true}
            pointerdown={props.applyItems}
            alpha={!props.isItemsApplied ? 1 : 0.5}
            mouseover={() => setHoveredItem("menuUndress")}
            mouseout={() => setHoveredItem(null)}
          />
          <Sprite
            image={"/res/Generator/change-avatar.svg"}
            position={menuIconsCoords["menuAvatars"]}
            buttonMode={true}
            interactive={true}
            pointerdown={props.changeAvatar}
            alpha={hoveredItem === "menuAvatars" ? 1 : 0.5}
            mouseover={() => setHoveredItem("menuAvatars")}
            mouseout={() => setHoveredItem(null)}

          />
          <Sprite
            image={"/res/Generator/toggle-gen-bg.svg"}
            position={menuIconsCoords["menuBg"]}
            buttonMode={true}
            interactive={true}
            pointerdown={props.toggleGenBg}
            alpha={props.isBgActive ? 1 : 0.5}
            mouseover={() => setHoveredItem("menuBg")}
            mouseout={() => setHoveredItem(null)}
          />
          <Sprite
            image={"/res/Generator/icon-tooltips-gray.svg"}
            position={menuIconsCoords["menuTooltip"]}
            buttonMode={true}
            interactive={true}
            pointerdown={props.toggleTooltips}
            alpha={props.isTooltipsActive
              ? 1
              : hoveredItem === "menuTooltip"
                ? 0.7
                : 0.5}
            mouseover={() => setHoveredItem("menuTooltip")}
            mouseout={() => setHoveredItem(null)}
          />

          {/* Tooltips for menu icons */}
          {hoveredItem !== null && props.isTooltipsActive &&
            <GeneratorTooltip
              type={hoveredItem}
            />
          }
        </>
      }
    </Container>
  )
};