import { useState } from "react";
import { AvatarFilter, AvatarFilterTopBar, AvatarList } from "widgets";
import { FilterWrapper } from "entities/FilterWrapper";

export const AssetsAvatarsPage = () => {
  const [isFilterShown, toggleFilterShown] = useState<boolean>(false);

  return (
    <>
      <AvatarFilterTopBar toggleFilter={() => toggleFilterShown(prev => !prev)} />
      <FilterWrapper
        shown={isFilterShown}
        closeFilter={() => toggleFilterShown(false)}
      >
        <AvatarFilter />
      </FilterWrapper>
      <AvatarList />
    </>
  );
};