import { VerticalDivider } from "shared/ui";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { H2QContext } from "app/core/H2QContext";
import { AvatarImageSVG } from "entities/avatar/ui/AvatarImage/AvatarImage";
import styles from './AddInventory.module.scss'
import { InitialItemElement, itemList } from "entities/item";
import { avatarsList, InitialAvatarElement } from "entities/avatar";


export const AddInventory = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const [selectedItems, addSelectedItems] = useState<InitialItemElement[]>([]);
  const [selectedHero, addSelectedHero] = useState<InitialAvatarElement | null>(null);

  const addItemToTempHeroItemsData = (item: InitialItemElement) => {
    if (selectedItems.length < 7) {
      addSelectedItems([...selectedItems, item]);
      console.log(`inv${item.categoryId}`);
    }
  };

  const removeSelectedItem = (removedItem: InitialItemElement) => {
    const newItems = selectedItems.filter((item) => item !== removedItem);
    addSelectedItems([...newItems]);
  };

  const addHeroToTempHeroItemsData = (hero: InitialAvatarElement) => {
    addSelectedHero(hero);
  };

  const clearSelected = () => {
    addSelectedItems([]);
    addSelectedHero(null);
  };

  const addInventory = () => {
    if (selectedItems.length < 7 || !selectedHero) {
      return;
    }

    const addInventoryParam = selectedItems.reduce(
      (result, item) => {
        result[`inv${item.categoryId}`] = item.itemId;
        return result;
      },
      { avatar_id: selectedHero.itemId } as any
    );

    console.log(addInventoryParam);

    h2qAccount.addInventories(addInventoryParam as any).then(() => clearSelected());
  };

  const categories = new Set(selectedItems.map((item) => item.categoryId));

  useEffect(() => {
    const headImages = document.querySelectorAll<HTMLImageElement>(".image__head");
    headImages.forEach(item => {
      const bodyImg = item.previousElementSibling as HTMLImageElement;
      const resizeRatio = bodyImg.naturalWidth / bodyImg.clientWidth;
      const itemWidth = Math.ceil(item.naturalWidth / resizeRatio);
      item.width = itemWidth;
    })
  });

  if (h2qAccount.addInventories.state === 'pending') {
    return <div style={{ color: "#fff" }}>Add inventory in progress...</div>
  }


  return (
    <>

      <div className={styles.row}>
        {
          !selectedHero &&
          avatarsList.map((item, index) => {
            return (
              <div
                className={styles.rowItem}
                key={item.itemId}
                onClick={() => addHeroToTempHeroItemsData(item)}
              >
                <AvatarImageSVG avatarImg={item} />
              </div>
            );
          })
        }
      </div>
      <div className={styles["selected-row"]}>
        {selectedHero &&
          <div
            className={`${styles.rowItem} ${styles.active} ${styles.selectedHero}`}
            onClick={() => addSelectedHero(null)}
          >
            <AvatarImageSVG avatarImg={selectedHero} />
          </div>
        }

        {[...selectedItems].sort((a, b) => a.categoryId - b.categoryId).map((item) => {
          return <div
            key={`${item.categoryId}-${item.itemId}`}
          >
            <div className={`${styles["selected-accessory"]} ${styles.active}`} onClick={() => removeSelectedItem(item)}>
              <img className="image__body" src={item.iconThumbLink} alt="H2Q Items" />
            </div>
          </div>

        })}
      </div>

      <div className={styles.grid}>
        {itemList.map(items => {
          if (categories.has(items[0].categoryId)) return null;
          return (
            <div className={styles.column} key={items[0].categoryId}>
              {items.sort((a, b) => a.itemId - b.itemId).map((item, index) => {

                return (
                  <div
                    key={`${item.categoryId}-${item.itemId}`}
                    onClick={() => addItemToTempHeroItemsData(item)}
                  >
                    <div className={styles.accessory}>
                      <img className="image__body" src={item.iconThumbLink} alt="H2Q Items" />
                      <p>ItemId: {item.itemId}</p>
                      <p>CatId: {item.categoryId}</p>
                    </div>
                  </div>
                );
              })
              }
            </div>
          )
        })
        }
      </div>
      <VerticalDivider value={10} />
      {selectedItems.length === 7 && selectedHero && <button onClick={addInventory}>ADD INVENTORY</button>}
    </>
  );
});
