import styles from "./MenuButton.module.scss";
import { ReactComponent as IconPointsGray } from "app/icons/icon-points-gray.svg";

interface IMenuButton {
  action: (event: React.SyntheticEvent) => void;
  isActive: boolean;
  size?: number;
};

export const MenuButton = ({ action, isActive, size = 13 }: IMenuButton) => {
  const width = size / 10;
  const height = width * (18 / 13);
  return (
    <button
      className={styles["menu-btn"]}
      style={{ width: `${width}rem`, height: `${height}rem` }}
      disabled={!isActive}
    >
      <IconPointsGray
        className={styles["btn-image"]}
        onClick={action}
      />
    </button>
  )
}