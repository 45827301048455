import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { FadeAndGlowWrapper } from "entities/FadeAndGlowWrapper/FadeAndGlowWrapper";
import { INftPageTab, NftElement, NftImageSVG } from "entities/nft";
import styles from "./NftPageViewer.module.scss";
import { NftUnstakeAndTimer } from "features/NftUnstakeAndTimer/NftUnstakeAndTimer";

interface INftPageViewer {
  tabs: INftPageTab[];
  nftElement: NftElement;
};

export function NftPageViewer({ tabs, nftElement }: INftPageViewer) {
  const [activeTabId, setActiveTabId] = useState<number>(0);

  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [sliderOffset, setOffset] = useState<number>(0);
  const sliderWidth = width * 0.5;
  const slidesTotalAmount: number = tabs.length;
  const maxOffest: number = sliderWidth * (slidesTotalAmount - 1);

  const moveSliderLeft = (step: number) => {
    setOffset(currentOffset => {
      const newOffset: number = currentOffset + step * sliderWidth;
      return Math.min(newOffset, 0);
    });
  };

  const moveSliderRight = (step: number) => {
    setOffset(currentOffset => {
      const newOffset: number = currentOffset - step * sliderWidth;
      return Math.max(newOffset, -maxOffest);
    });
  };

  const changeActiveTabId = (tab: INftPageTab) => {
    if (tab.isActive === false) return;

    if (activeTabId < tab.id) {
      moveSliderRight(tab.id - activeTabId);
    }
    if (activeTabId > tab.id) {
      moveSliderLeft(activeTabId - tab.id);
    }
    setActiveTabId(tab.id);
  };

  return (
    <>
      <div className={styles['tabs-wrapper']}>
        {tabs.map((t) => {
          return (
            <div
              className={`${styles.tab} ${t.id === activeTabId ? styles.active : ""} ${t.isActive === false ? styles.disabled : ""}`}
              key={t.id}
              onClick={() => changeActiveTabId(t)}
            >
              {t.title}
            </div>
          )
        })}
      </div>
      {
        nftElement &&
        <div ref={ref} className={`${styles['info-main-container']} ${activeTabId !== 0 ? styles['small-image'] : ""}`}>
          <div className={styles['info-image-wrapper']}>
            <FadeAndGlowWrapper fade={false}>
              <NftImageSVG item={nftElement} />
            </FadeAndGlowWrapper>

            {activeTabId === 0 && nftElement && nftElement.pseudoNftData?.m_lockedInQuest !== null &&
              <div style={{
                "position": "absolute",
                "width": "100%",
                "bottom": "0",
              }}>
                <NftUnstakeAndTimer
                  nftElement={nftElement}
                />
              </div>
            }

          </div>
          <div
            className={styles['info-active-page']}
            style={{ "width": sliderWidth + "px" }}
          >
            <div
              className={styles['info-pages']}
              style={{ transform: `translateX(${sliderOffset}px)` }}
            >
              {tabs.map((t, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles['info-wrapper']}`}
                    style={{ "minWidth": sliderWidth, "maxWidth": sliderWidth }}
                  >
                    {t.tab}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
    </>
  )
}