import { parts } from "entities/item";
import { fieldBootomHeight, fieldHeight } from "./generatorParams";
import { GenTooltipsType } from "./generatorTooltipParams";

//Slot coordinates:
export const cellInnerSize = 325;
export const cellBorder = 19;
export const cellOutSize = cellInnerSize + 2 * cellBorder;
export const lockImageWidth = 45;
export const lockImageHeight = 60;
export const lockImageOffsetX = cellInnerSize - lockImageWidth;
export const lockImageOffsetY = cellInnerSize - lockImageHeight;

//Generator items coordinates and sizes:
export const iconRandomWidth = 340;
export const heroSize = 512;

const slotsTotalSize = cellOutSize * 3; //1089
const slotsTopOffest = 123;
const itemsValueBarTopOffset = 44;
export const slotsLeftOffset = 150;
const heroLeftOffest = slotsLeftOffset + slotsTotalSize - 150;
const heroTopOffest = 250;
const blockColorsLeftOffset = 400;
const blockColorsTopOffset = 25;
const blockColorsWidth = 270;

export const itemsValuePriceWidth = cellInnerSize - cellBorder;
export const itemsValuePriceLeftOffset = 541;
export const itemsValuePriceTopOffset = 744;

type ItemNames =
  "Hero" |
  "Slots" |
  "ItemsBottomField" |
  "BlockColors" |
  "ItemsValue" |
  "CreateNftPrice" |
  "GenPrice" |
  "ResPrice" |
  "ResetBtn" |
  "ControlBlock" |
  "TextMessage"
  ;

type ItemSizeAndCoords = {
  x: number;
  y: number;
  width?: number;
  height?: number;
};

export type GeneratorItemSizeAndCoodrs = {
  [K in ItemNames]?: ItemSizeAndCoords
};

const menuTopOffset = 42.5;
const menuLeftOffset = 25.5;
const menuIconGap = 42.5;
const menuIconHeight = 50;

export type IconsCoordanates = {
  [K in GenTooltipsType]?: [number, number]
};

export const menuIconsCoords: IconsCoordanates = {
  "menuTriangles": [menuLeftOffset, menuTopOffset],
  "menuUndress": [menuLeftOffset, menuTopOffset + (menuIconHeight + menuIconGap) * 1],
  "menuAvatars": [menuLeftOffset + 3, menuTopOffset + (menuIconHeight + menuIconGap) * 2],
  "menuBg": [menuLeftOffset, menuTopOffset + (menuIconHeight + menuIconGap) * 3],
  "menuTooltip": [menuLeftOffset, menuTopOffset + (menuIconHeight + menuIconGap) * 4]
};

export const getHeroSizeAndCoord = (isVertical: boolean = false): GeneratorItemSizeAndCoodrs => {
  return {
    Hero: {
      x: heroLeftOffest,
      y: heroTopOffest,
    }
  }
};

export const getSlotSizeAndCoord = (isVertical: boolean = false): GeneratorItemSizeAndCoodrs => {
  return {
    Slots: {
      x: slotsLeftOffset,
      y: slotsTopOffest,
    }
  }
};

export const getMessageSizeAndCoord = (isVertical: boolean = false): GeneratorItemSizeAndCoodrs => {
  return {
    ItemsBottomField: {
      x: slotsLeftOffset + cellBorder,
      y: slotsTopOffest + slotsTotalSize + itemsValueBarTopOffset,
      width: slotsTotalSize - cellBorder,
      height: fieldBootomHeight,
    },
    BlockColors: {
      x: slotsLeftOffset + blockColorsLeftOffset,
      y: slotsTopOffest + slotsTotalSize + itemsValueBarTopOffset + blockColorsTopOffset,
      width: blockColorsWidth
    },
    TextMessage: {
      x: slotsLeftOffset + cellBorder,
      y: slotsTopOffest + slotsTotalSize + itemsValueBarTopOffset,
      width: slotsTotalSize - cellBorder,
    },
  };
};

// Dimensions for absolute positioned block on separate canvas: 
const absoluteBlockRightoffset = 59;

const priceLabelGap = 15;
const genLabelWidth = 307;
const resetLabelWidth = 183;
const labelsTopOffset = fieldHeight * 3;
export const bottomBtnsWidth = priceLabelGap * 2 + genLabelWidth + resetLabelWidth + fieldHeight;
export const btnsBlockRightOffset = absoluteBlockRightoffset;
export const btnsBlockBottomOffset = 76;

export const getBottomBtnsSizeAndCoord = (isVertical: boolean = false): GeneratorItemSizeAndCoodrs => {
  return {
    GenPrice: {
      x: 0,
      y: labelsTopOffset,
      width: genLabelWidth,
      height: fieldHeight,
    },
    ResPrice: {
      x: genLabelWidth + priceLabelGap,
      y: labelsTopOffset,
      width: resetLabelWidth,
      height: fieldHeight,
    },
    ResetBtn: {
      x: bottomBtnsWidth - fieldHeight,
      y: labelsTopOffset,
      width: fieldHeight,
      height: fieldHeight,
    }
  }
};

// Dimensions for absolute positioned block on separate canvas: 
export const controlBlockWidth = 102;
export const controlBlocHeight = 510;
export const controlBlockTopOffset = slotsTopOffest;
export const controlBlockRightOffset = absoluteBlockRightoffset;

export const getMenuSizeAndCoord = (isVertical: boolean = false): GeneratorItemSizeAndCoodrs => {
  return {
    ControlBlock: {
      x: controlBlockWidth * 4,
      y: 0,
      width: controlBlockWidth,
      height: controlBlocHeight,
    },
  };
};

export type ISlotItemsCoords = {
  [K in typeof parts[number]]: { x: number, y: number };
};

export const getSlotItemsCoords = (isVertical: boolean = false): ISlotItemsCoords => {
  return (
    {
      Body: { x: 0 * cellOutSize, y: 0 * cellOutSize },
      Head: {
        x: isVertical ? 2 * cellOutSize : 1 * cellOutSize,
        y: isVertical ? 1 * cellOutSize : 0 * cellOutSize,
      },
      Hip: { x: 2 * cellOutSize, y: 0 * cellOutSize },
      Legs: { x: 0 * cellOutSize, y: 1 * cellOutSize },
      Accessory1: { x: 0 * cellOutSize, y: 2 * cellOutSize },
      Accessory2: { x: 1 * cellOutSize, y: 2 * cellOutSize },
      Accessory3: { x: 2 * cellOutSize, y: 2 * cellOutSize },
    }
  )
};

interface IGeneratorBtnCoords {
  x: number,
  y: number
};

export const reloadBtnCoords: IGeneratorBtnCoords = {
  x: 1 * cellOutSize,
  y: 1 * cellOutSize
};

export const getApplyBtnCoords = (isVertical: boolean = false): IGeneratorBtnCoords => {
  return {
    x: isVertical ? 1 * cellOutSize : 2 * cellOutSize,
    y: isVertical ? 0 * cellOutSize : 1 * cellOutSize
  }
};