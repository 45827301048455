import { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { H2QContext } from "./core/H2QContext";
import Routing from "pages";
import { SideBar } from "widgets/SideBar/SideBar";
import { SideBarRight } from "widgets";
import { Tooltip } from "shared/ui";
import "./App.scss";

function App() {
  const { h2qAccount } = useContext(H2QContext);

  return (
    <H2QContext.Provider value={{ h2qAccount }}>
      <div className="App">
        <BrowserRouter>
          <SideBar />
          <div className="app-components-wrapper">
            <Routing />
          </div>
          <SideBarRight />
        </BrowserRouter>
      </div>
      <Tooltip />
    </H2QContext.Provider>
  );
}

export default App;
