import styles from "./TopChat.module.scss";
import { ReactComponent as IconChat } from "app/icons/icon-chat-blue.svg";
import { useState } from "react";
import { IconColorVariants } from "app/icons/icons";

export const TopChat = () => {
  const [isActive] = useState<boolean>(false);

  return (
    <div className={`${styles["chat-wrapper"]} ${isActive ? styles.active : ""}`}>
      <IconChat
        fill={IconColorVariants.blue}
      />
    </div>
  )
}
