import { CancelBtn, FieldWithBottomValue, GeneratorTooltip, GenTooltipsType, getBottomBtnsSizeAndCoord } from "entities/generator"
import { useState } from "react";

interface IGeneratorBottomRightBtns {
  genPrice: number;
  resetPrice: number;
  onResetAction: () => void;
  isResetActive: boolean;
  isTooltipsActive: boolean;
};

export const GeneratorBottomRightBtns = ({ genPrice, resetPrice, isTooltipsActive, onResetAction, isResetActive }: IGeneratorBottomRightBtns) => {
  const [hoveredItem, setHovered] = useState<GenTooltipsType | null>(null);
  const bottomBtnSizeAndCoord = getBottomBtnsSizeAndCoord(false);

  return (
    <>
      {/* Generation price */}
      <FieldWithBottomValue
        label="Generation Price"
        value={genPrice}
        x={bottomBtnSizeAndCoord.GenPrice!.x}
        y={bottomBtnSizeAndCoord.GenPrice!.y}
        width={bottomBtnSizeAndCoord.GenPrice!.width!}
        height={bottomBtnSizeAndCoord.GenPrice!.height!}
        onHoverOver={() => setHovered("generatePrice")}
        onHoverOut={() => setHovered(null)}
      />

      {/* Reset price */}
      <FieldWithBottomValue
        label="Reset"
        value={resetPrice}
        x={bottomBtnSizeAndCoord.ResPrice!.x}
        y={bottomBtnSizeAndCoord.ResPrice!.y}
        width={bottomBtnSizeAndCoord.ResPrice!.width!}
        height={bottomBtnSizeAndCoord.ResPrice!.height!}
        onHoverOver={() => setHovered("resetPrice")}
        onHoverOut={() => setHovered(null)}
      />

      {/* Reset button */}
      <CancelBtn
        x={bottomBtnSizeAndCoord.ResetBtn!.x}
        y={bottomBtnSizeAndCoord.ResetBtn!.y}
        width={bottomBtnSizeAndCoord.ResetBtn!.width!}
        height={bottomBtnSizeAndCoord.ResetBtn!.height!}
        action={onResetAction}
        isActive={isResetActive}
        onHoverOver={() => setHovered("resetBtn")}
        onHoverOut={() => setHovered(null)}
      />

      {/* Tooltips for reset button and price labels */}
      {isTooltipsActive && hoveredItem &&
        < GeneratorTooltip
          type={hoveredItem}
        />
      }
    </>
  )
}
