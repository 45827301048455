import { FC, PropsWithChildren } from "react";
import styles from "./BottomPopup.module.scss";

interface IBottomPopup {
  isActive: boolean;
  isError: boolean;
  isOk: boolean;
};

export const BottomPopup: FC<PropsWithChildren<IBottomPopup>> = ({ children, isActive, isError, isOk }) => {

  return (
    <div className={`
    ${styles.wrapper}
    ${isActive ? styles.active : ""}
    ${isError ? styles.error : ""}
    ${isOk ? styles.ok : ""}
    `}
    >
      {children}
    </div>
  )
}
