import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui";
import styles from "./OpenAvatarInGenerator.module.scss";

interface IOpenAvatarInGenerator {
  avatarId: number;
  openModal: (newAvatarId: number) => void;
};

export const OpenAvatarInGenerator = observer(function ({ avatarId, openModal }: IOpenAvatarInGenerator) {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();

  const currentAvatarId = h2qAccount.data.m_generatedHero?.avatar_id;

  const onAvatarClick = () => {
    if (h2qAccount.data.m_generatedHero === null) {
      console.log("Generation process is not started. Start with the new avatar id:", avatarId);
      navigate(`/h2q/generator/${avatarId}`);
      return;
    };
    if (currentAvatarId === avatarId) {
      console.log("Generation process is already started with the same avatar id:", h2qAccount.data.m_generatedHero?.avatar_id);
      console.log("Chosen avatar id:", avatarId);
      navigate(`/h2q/generator/${avatarId}`);
      return;
    };
    if (currentAvatarId !== undefined && currentAvatarId !== avatarId) {
      console.log("Generation process is already started with another avatar id:", h2qAccount.data.m_generatedHero?.avatar_id);
      console.log("Chosen avatar id:", avatarId);
      openModal(avatarId);
      return;
    };
  };

  return (
    <div>
      {avatarId === currentAvatarId &&
        <p className={styles.message}>Currently active in generator</p>
      }
      {currentAvatarId && avatarId !== currentAvatarId &&
        <p className={styles.message}>You have another active avatar in the generator</p>
      }
      <Button
        widthValue={"full"}
        action={onAvatarClick}
        colorValue={avatarId === currentAvatarId ? "green" : "red"}
      >
        <p style={{ "color": "white", "fontSize": "1.8rem", "padding": "0 2rem" }}>
          {avatarId === currentAvatarId
            ? "Open generator"
            : "Send to generator"
          }
        </p>
      </Button>
    </div>
  )
});