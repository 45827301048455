import styles from "./QuestLockedBlock.module.scss";
import { useParams } from "react-router-dom";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { h2qAccount } from "app/core/H2QContext";
import { ItemHexMasktoAvatarIdNumber, ItemHexMasktoItemArray } from "entities/quest";

interface IQuestLockedBlock {
  quest: H2QuestResponse;
};

export const QuestLockedBlock = ({ quest }: IQuestLockedBlock) => {
  const { id: nftId } = useParams();
  const nftInfo = h2qAccount.nfts.find(n => n.pseudoNFTAddr.slice(2) === nftId);
  const questAvatar = ItemHexMasktoAvatarIdNumber(quest.m_questParams.essentialParams.inventoryMask);
  const questItems = ItemHexMasktoItemArray(quest.m_questParams.essentialParams.inventoryMask);
  const nftItems = nftInfo?.pseudoNFTdata.m_h2qTrueCharacter.inventory;

  const isMinLevel = nftInfo && Number(nftInfo.pseudoNFTdata.m_h2qMetaCharacter.level) < quest.m_questParams.essentialParams.levelMin;
  const isMaxLevel = nftInfo && Number(nftInfo.pseudoNFTdata.m_h2qMetaCharacter.level) > quest.m_questParams.essentialParams.levelMax;
  const isAvatar = nftInfo && questAvatar >= 0 && Number(nftInfo.pseudoNFTdata.m_h2qTrueCharacter.avatar_id) !== questAvatar;
  const isItems = !questItems.every((item, index) => {
    if (+item === -1) return true;
    if (nftItems && +item === +nftItems[index]) return true;
    else return false;
  })

  return (
    <div className={styles["locked-quest-block"]}>
      <p className={styles["locked-quest-text"]} >Not available for current NFT-HERO</p>
      {isMinLevel &&
        <p className={styles["locked-quest-info"]} >- Min Level: {quest.m_questParams.essentialParams.levelMin}</p>
      }
      {isMaxLevel &&
        <p className={styles["locked-quest-info"]} >- Max Level: {quest.m_questParams.essentialParams.levelMax}</p>
      }
      {isAvatar &&
        <p className={styles["locked-quest-info"]} >- Avatar differs</p>
      }
      {isItems &&
        <p className={styles["locked-quest-info"]} >- Not enough items</p>
      }
    </div>
  )
};