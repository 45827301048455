import { SliderButton } from "@typeform/embed-react"
import { FC, PropsWithChildren } from "react"

export const TypeformAddNewGenerator: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SliderButton id="Oum5tqYJ" style={{ fontSize: 0, background: 'none', border: 0, outline: 0, padding: 0 }} className="my-button">
      {children}
    </SliderButton>
  )
}
