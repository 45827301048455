import { RotatingCircle } from "../RotatingCircle/RotatingCircle";
import styles from "./ButtonWithLabel.module.scss";

interface IButtonWithLabel {
  action: () => void;
  isActive: boolean;
  title: string
  isWaiting?: boolean;
};

export const ButtonWithLabel = ({ action, isActive, title, isWaiting = false }: IButtonWithLabel) => {
  return (
    <button className={`${styles["labeled-btn"]} ${isActive ? styles.active : ""}`}
      onClick={action}
      disabled={!isActive}
    >
      {!isWaiting && title}
      {isWaiting &&
        <RotatingCircle size={20} thick={3} />
      }
    </button>
  )
}