import { useNavigate } from "react-router-dom";
import { ReactComponent as IconLogin } from "app/icons/icon-power-button-green.svg";
import styles from "./SideBarLogin.module.scss";

interface ISideBarLogin {
  isOpen: boolean;
};

export const SideBarLogin = ({ isOpen }: ISideBarLogin) => {
  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate("/login-page");
  };

  return (
    <div
      onClick={goToLoginPage}
      className={styles["sidebar-btn-block"]}
    >
      <IconLogin className={`${styles["login-icon"]} ${isOpen ? "" : styles["login-icon-closed"]}`} />
      <span className={`${styles["login-text"]} ${isOpen ? "" : styles["login-text-closed"]}`}>Login</span>
    </div>
  )
};