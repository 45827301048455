import { VerticalDivider } from "shared/ui";
import { UserAccPageHeader } from "entities/UserAccPageHeader";
import { AddressString } from "features/AddressString";
import { ReactComponent as IconCrypto } from "app/icons/Title_MyCrypto_white.svg";
import { H2QContext } from "app/core/H2QContext";
import { useContext } from "react";
import styles from "./CryptoAcc.module.scss";
import { UserWalletBlock } from "widgets";

export default function CryptoAcc() {
  const accountId = localStorage.getItem("currentAddress") || "";
  const { h2qAccount } = useContext(H2QContext);

  // const buyH2q = () => {
  //   console.log("buyH2q");
  // };
  // const depositH2q = () => {
  //   console.log("depositH2q");
  // };
  const withdrawH2q = () => {
    console.log("withdrawH2q");
  };
  // const convertH2q = () => {
  //   console.log("convertH2q");
  // };

  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconCrypto />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>
        <h3>User Account Address</h3>

        <div
          style={{ width: "74rem" }}
        >
          <AddressString
            value={accountId}
            valueColor="white"
            bg="transparent"
            padding={false}
          />
        </div>

        <VerticalDivider value={55} />

        <div className={styles["wallet-info"]}>
          <h3>Wallets</h3>
          <div className={styles["wallet-bg"]}>
            <UserWalletBlock
              type="h2q"
              value={Math.round(Number(h2qAccount.data.m_h2qBalance) || 0)}
              walletAddress={h2qAccount.data.m_h2qWallet!}
              // buy={buyH2q}
              // deposit={depositH2q}
              // convert={convertH2q}
              withdraw={withdrawH2q}
            />

            <VerticalDivider value={30} />

            <UserWalletBlock
              type="qst"
              value={Math.round(Number(h2qAccount.data.m_qstBalance) || 0)}
              walletAddress={h2qAccount.data.m_qstWallet!}
              // buy={buyH2q}
              // deposit={depositH2q}
              // convert={convertH2q}
              withdraw={withdrawH2q}
            />

            <VerticalDivider value={30} />
            <UserWalletBlock
              type="ever"
              value={Math.round(Number(h2qAccount.h2qBalance) || 0)}
              walletAddress={accountId}
              // buy={buyH2q}
              // deposit={depositH2q}
              // convert={convertH2q}
              withdraw={withdrawH2q}
            />
          </div>
        </div>


        {/* <div className={`${styles["wallet-info"]} ${styles["bottom-shadow"]}`}>
          <h3>Everscale</h3>
          <div className={styles["wallet-bg"]}>
          </div>
        </div> */}
      </div>
    </div >
  )
}
