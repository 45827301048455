import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import styles from "./AccountStatus.module.scss";

export const AccountStatus = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  return (
    <div className={styles.statuses}>
      <div
        data-tooltip-id="h2q-tooltip"
        data-tooltip-place={"bottom-end"}
        data-tooltip-html={`<p>[h2qAccount.data.m_initialized]:\t${h2qAccount.data.m_initialized}</p>`}
        className={`${styles.status} ${h2qAccount.data.m_initialized ? styles.active : ""}`}
      >
      </div>

      <div
        data-tooltip-id="h2q-tooltip"
        data-tooltip-place={"bottom-end"}
        // data-tooltip-content="h2qAccount.data.m_generatorBusy"
        data-tooltip-html={`<p>[h2qAccount.data.m_generatorBusy]:\t${h2qAccount.data.m_generatorBusy}</p>`}
        className={`${styles.status} ${!h2qAccount.data.m_generatorBusy ? styles.active : ""}`}
      >
      </div>
    </div>
  )
});