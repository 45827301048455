import { InitialAvatarElement } from "entities/avatar";
import { getGeneratorTitleByGenId } from "entities/generatorSelector";
import { KeyTagsBlock } from "entities/keyTags";
import { getAvatarNameTag, getAvatarTypeTag, KeyTagType } from "entities/keyTags";
import styles from "./AvatarInfoMainTab.module.scss";

interface IAvatarInfoMainTab {
  avatar: InitialAvatarElement;
  showTitle?: boolean;
  showTags?: boolean;
};

export function AvatarInfoMainTab({ avatar, showTitle = true, showTags = true }: IAvatarInfoMainTab) {
  return (
    <>
      {showTitle &&
        <h3 className={styles['info-title']}>Avatar</h3>
      }
      <div>
        <p className={styles['info-value']}><span className={styles['info-param']}>ID:</span>{avatar.itemId}</p>
        <p className={styles['info-value']}><span className={styles['info-param']}>Generator Nft:</span>{getGeneratorTitleByGenId(avatar.genId!)}</p>
        <p className={styles['info-value']}><span className={styles['info-param']}>Name:</span>{avatar.avatarName}</p>
      </div>
      {showTags &&
        <div>
          <AvatarTags avatarId={avatar.itemId} />
        </div>
      }
    </>
  )
}

interface IAvatarTags {
  avatarId: number;
};

export const AvatarTags = ({ avatarId }: IAvatarTags) => {
  const avatarTags = [] as KeyTagType[];
  avatarTags.push(getAvatarTypeTag(avatarId), getAvatarNameTag(avatarId));

  if (avatarTags.length === 0) return null;

  return (
    <KeyTagsBlock nftKeyTags={avatarTags} />
  )
};