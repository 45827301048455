import { H2QContext } from "app/core/H2QContext";
import { FilterButton } from "shared/ui/FilterButton";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterItemType.module.scss";
import { parts } from "entities/item";

export const FilterItemType = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const onClickTypes = (item: string) => {
    h2qAccount.uiStore.changeItemFilterType(item);
  };

  return (
    <div className={styles["items-type"]}>
      {parts.map(type => {
        return (
          <FilterButton
            name={type}
            key={type}
            clickAction={() => onClickTypes(type)}
            isSelected={h2qAccount.uiStore.itemFilter.itemType.includes(type)}
          />
        )
      })}
    </div>
  )
})