import { FoldButton, ShowStakedButton } from "shared/ui";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AddToFavButton } from "shared/ui";
import styles from "./QuestViewControls.module.scss";

interface IQuestViewControls {
  isQuestsFold: boolean;
  toggleQuestsFold: () => void;
};

export const QuestViewControls = observer(function ({ isQuestsFold, toggleQuestsFold }: IQuestViewControls) {
  const { h2qAccount } = useContext(H2QContext);
  const { pathname } = useLocation();
  const pageUrl = pathname.split("/").slice(-1)[0];

  const showFavQuests = () => {
    if (h2qAccount.uiStore.questFilter.favQuests.length === 0) {
      return;
    }

    if (pageUrl === "quests") {
      h2qAccount.uiStore.changeQuestFilterFavMode(!h2qAccount.uiStore.questFilter.favMode)
      return;
    }
  };

  const showQuestWithStakedNfts = () => {
    if (Object.keys(h2qAccount.uiStore.questFilter.stakedQuests).length === 0) {
      return
    }

    if (pageUrl === "quests") {
      h2qAccount.uiStore.changeQuestFilterStakedMode(!h2qAccount.uiStore.questFilter.stakedMode)
      return;
    }
  };

  return (
    <div className={styles["control-item"]}>
      <h3 className={styles["buttons-title"]}>Show options</h3>
      <div className={styles.buttons}>
        <FoldButton
          action={toggleQuestsFold}
          isActive={h2qAccount.uiStore.questsToShow.length > 0}
          isFold={isQuestsFold}
          size={28}
        />
        {pageUrl === "quests" &&
          <ShowStakedButton
            action={showQuestWithStakedNfts}
            isActive={Object.keys(h2qAccount.uiStore.questFilter.stakedQuests).length > 0}
            isSelected={h2qAccount.uiStore.questFilter.stakedMode}
            size={28}
          />
        }
        <AddToFavButton
          action={showFavQuests}
          isActive={h2qAccount.uiStore.questFilter.favQuests.length > 0}
          isAddedToFav={h2qAccount.uiStore.questFilter.favMode}
          size={28}
        />
      </div>
    </div>
  )
})