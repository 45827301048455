import { IconColorVariants } from "app/icons/icons";
import { ReactComponent as IconFold } from "app/icons/icon-angle-double-top-gray.svg";
import styles from "./FoldButton.module.scss";

interface IFoldButton {
  action: () => void;
  isActive: boolean;
  isFold?: boolean;
  size?: number;
};

export const FoldButton = ({ action, isActive, size = 18, isFold = false }: IFoldButton) => {
  const iconColor = isFold
    ? IconColorVariants.yellow
    : IconColorVariants.gray;

  return (
    <button
      style={{ width: `${size / 10}rem`, height: `${size / 10}rem` }}
      disabled={!isActive}
      className={`${styles["fold-btn"]} ${isFold ? styles["turned"] : ""}`}
    >
      <IconFold
        fill={iconColor}
        className={styles["btn-image"]}
        onClick={action}
      />
    </button>
  )
}