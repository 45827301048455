import { ReactNode } from "react";
import styles from "./AvatarSidebarContainer.module.scss";

interface IAvatarSidebarContainer {
  image: ReactNode;
  info: ReactNode;
  tags?: ReactNode;
  button?: ReactNode;
};

export const AvatarSidebarContainer = ({ image, info, tags, button }: IAvatarSidebarContainer) => {
  return (
    <>
      <div className={styles["side-asset-container"]}>
        <div className={styles.image}>
          {image}
        </div>
        <div className={styles.info}>
          {info}
        </div>
        {tags &&
          <div className={styles.tags}>
            {tags}
          </div>
        }
      </div>
      {
        button &&
        <div className={styles.button}>
          {button}
        </div>
      }
    </>
  )
};