import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./HeroesPlayBlock.module.scss";

interface IHeroesPlayBlock {
  questData: H2QuestResponse
};

export const HeroesPlayBlock = observer(function ({ questData }: IHeroesPlayBlock) {
  const { h2qAccount } = useContext(H2QContext);

  const totalHeroPlaying = h2qAccount.totalNfts.length > 0
    ? h2qAccount.totalNfts.reduce((acc, nft) => {
      return nft.m_lockedInQuest && nft.m_lockedInQuest.quest === questData.id ? acc + 1 : acc
    }, 0)
    : -1;

  return (
    <div className={styles["heroes-play-block"]}>
      <h3>Heroes in the Quest</h3>
      <p>{totalHeroPlaying >= 0 ? totalHeroPlaying : "..."}</p>
    </div>
  )
})