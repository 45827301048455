import { H2QContext } from "app/core/H2QContext";
import { GalleryType, ItemsInRow } from "app/core/H2QUIParams";
import { observer } from "mobx-react";
import { useContext } from "react";
import { AddToFavButton } from "shared/ui";
import { ChangeOptionsButton } from "shared/ui";
import { ShowStakedButton } from "shared/ui";
import styles from "./ChangeViewModeForAssets.module.scss";

interface IChangeViewModeForAssets {
  modes: ItemsInRow[];
  type: GalleryType;
  isFavButton?: boolean;
  isStakedButton?: boolean;
};

//TODO: split on separat blocks from item / avatars / nfts
export const ChangeViewModeForAssets = observer(function ({ modes, type, isFavButton = false, isStakedButton = false }: IChangeViewModeForAssets) {
  const { h2qAccount } = useContext(H2QContext);

  const clickOnBtn = (item: ItemsInRow, type: GalleryType) => {
    h2qAccount.uiStore.changeGalleryCellsInRowCount(item, type);
  };

  const toggleFavMode = () => {
    if (type === "avatars") {
      showFavAvatars();
      return;
    }

    if (type === "items") {
      showFavItems();
      return;
    }

    else {
      showFavNfts();
    }
  };

  const showFavItems = () => {
    const isItemsAdded = h2qAccount.uiStore.itemFilter.favItems.flat().length > 0;
    if (!isItemsAdded) return;

    if (h2qAccount.uiStore.itemFilter.favMode) {
      h2qAccount.uiStore.changeItemFilterFavMode(false);
      return;
    }

    if (!h2qAccount.uiStore.itemFilter.favMode) {
      console.log("Enable fav view mode for items");
      h2qAccount.uiStore.changeItemFilterFavMode(true);
    }
  };

  const showFavAvatars = () => {
    const isAvatarAdded = h2qAccount.uiStore.avatarFilter.favAvatars.length > 0;
    if (!isAvatarAdded) return;

    if (h2qAccount.uiStore.avatarFilter.favMode) {
      h2qAccount.uiStore.changeAvatarFilterFavMode(false);
      return;
    }

    if (!h2qAccount.uiStore.avatarFilter.favMode) {
      h2qAccount.uiStore.changeAvatarFilterFavMode(true);
    }
  };

  const showFavNfts = () => {
    const isNftAdded = h2qAccount.uiStore.nftsToShow.filter(n => h2qAccount.uiStore.nftFilter.favNfts.includes(n.pseudoNFTAddr)).length > 0;
    if (!isNftAdded) return;

    if (h2qAccount.uiStore.nftFilter.favMode) {
      h2qAccount.uiStore.changeNftFilterFavMode(false);
      return;
    }

    if (!h2qAccount.uiStore.nftFilter.favMode) {
      h2qAccount.uiStore.changeNftFilterFavMode(true);
    }
  };

  const showStakedNfts = () => {
    const isStakedNftsExist = h2qAccount.uiStore.nftsToShow.filter(n => n.pseudoNftData?.m_lockedInQuest).length > 0;
    if (!isStakedNftsExist) return;

    if (h2qAccount.uiStore.nftFilter.stakedMode === true) {
      h2qAccount.uiStore.changeNftFilterStaked(false);
      return;
    }

    if (h2qAccount.uiStore.nftFilter.stakedMode === false) {
      h2qAccount.uiStore.changeNftFilterStaked(true);
    }
  };

  return (
    <div className={styles["buttons-wrapper"]}>
      {modes.map(item => {
        return (
          <ChangeOptionsButton
            action={() => clickOnBtn(item, type)}
            isSelected={
              (item === h2qAccount.uiStore.itemsInRowCount && type === "items") ||
              (item === h2qAccount.uiStore.nftsInRowCount && type === "nfts") ||
              (item === h2qAccount.uiStore.avatarsInRowCount && type === "avatars")
            }
            title={item.toString()}
            key={item}
          />
        )
      })}
      {
        isStakedButton && type === "nfts" &&
        <ShowStakedButton
          action={showStakedNfts}
          isActive={h2qAccount.uiStore.nftsToShow.filter(n => n.pseudoNftData?.m_lockedInQuest).length > 0}
          isSelected={h2qAccount.uiStore.nftFilter.stakedMode}
          size={26}
        />
      }
      {isFavButton &&
        <AddToFavButton
          action={toggleFavMode}
          isActive={
            (type === "avatars" && h2qAccount.uiStore.avatarFilter.favAvatars.length > 0) ||
            (type === "items" && h2qAccount.uiStore.itemFilter.favItems.flat().length > 0) ||
            ((type === "nfts" || type === "nftsInQuest") && h2qAccount.uiStore.nftsToShow.filter(n => h2qAccount.uiStore.nftFilter.favNfts.includes(n.pseudoNFTAddr)).length > 0)
          }
          isAddedToFav={
            (type === "avatars" && h2qAccount.uiStore.avatarFilter.favMode) ||
            (type === "items" && h2qAccount.uiStore.itemFilter.favMode) ||
            ((type === "nfts" || type === "nftsInQuest") && h2qAccount.uiStore.nftFilter.favMode)
          }
          size={28}
        />
      }
    </div>
  )
})