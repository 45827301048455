import { AvatarImageSVG } from 'entities/avatar/ui/AvatarImage/AvatarImage';
import { ItemImageSVG, parts } from 'entities/item';
import { h2qAccount } from "app/core/H2QContext";
import { InventoryItemData } from "app/core/H2QAccount";
import { InitialItemElement } from "entities/item";
import { AvatarInfoMainTab, ItemInfoMainTab, NftItems } from "entities/nft";
import { QuestSelectedItem } from "entities/quest";
import styles from "./QuestItemPreview.module.scss";

interface IItemPreview {
  item: QuestSelectedItem | null;
};

export function QuestItemPreview({ item }: IItemPreview) {
  if (item === null) return null;

  const itemInfoFromBC: InventoryItemData | null = (item.item && h2qAccount.inventoryInfoFromBC)
    ? h2qAccount.inventoryInfoFromBC[item.item.itemId]
    : null;

  const itemTitle = item.item
    ? parts[item.item.categoryId!] as keyof NftItems<InitialItemElement>
    : "Avatar";

  return (
    <div className={styles['preivew-wrapper']}>
      <div className={styles['preview-block']}>
        {
          (item.avatar || item.item) &&
          <div className={styles['preview-image']}>
            {item.avatar && <AvatarImageSVG avatarImg={item.avatar} isImgMoved={true} />}
            {item.item && <ItemImageSVG iconLink={item.item.iconLink} />}
          </div>
        }
        {
          !(item.avatar && item.item) && item.cost &&
          <div className={styles['preview-title']}>
            <h3 className={styles['info-title']}>Quest {item.type === "cost" ? "entrance cost" : "final reward"}:</h3>
          </div>
        }
        <div className={styles["preview-info"]}>
          {item.avatar &&
            <AvatarInfoMainTab avatar={item.avatar} showTitle={false} />
          }
          {item.item && itemInfoFromBC &&
            <ItemInfoMainTab
              itemInfo={itemInfoFromBC}
              categoryId={item.item.categoryId}
              itemId={item.item?.itemId!}
              title={itemTitle}
              showTitle={false}
            />
          }
          {item.cost &&
            <>
              {
                item.type === "cost" &&
                <div>
                  <p className={styles['info-value']}><span className={styles['info-param']}>Avatar:</span>{item.cost.avatarName}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>Items:</span> {item.cost.itemsCount}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>QST price:</span> {item.cost.qst}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>Min-Max level:</span> {item.cost.minLevel}-{item.cost.maxLevel}</p>
                </div>
              }
              {
                item.type === "reward" &&
                <div>
                  <p className={styles['info-value']}><span className={styles['info-param']}>Avatar:</span>{item.cost.avatarName}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>Items:</span> {item.cost.itemsCount}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>QST loot:</span> {Math.floor(item.cost.qst / item.cost.maxParticipants!)}</p>
                  <p className={styles['info-value']}><span className={styles['info-param']}>H2Q loot:</span> {Math.floor(item.cost.h2q! / item.cost.maxParticipants!)}</p>
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}
