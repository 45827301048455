import { FilterFoldableBlockWithTitle } from "entities/FilterFoldableBlockWithTitle";
import { FilterItemOriginalHero } from "features/itemFilter";
import { FilterItemRarity } from "features/itemFilter";
import { FilterItemType } from "features/itemFilter";
import { FilterItemValue } from "features/itemFilter";

export const ItemFilter = () => {
  return (
    <>
      <FilterFoldableBlockWithTitle
        title="Item Type"
      >
        <FilterItemType />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Item Rarity"
      >
        <FilterItemRarity />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Item Value"
      >
        <FilterItemValue />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Original hero"
      >
        <FilterItemOriginalHero />
      </FilterFoldableBlockWithTitle>
    </>
  )
}
