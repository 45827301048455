import { useContext } from 'react';
import { H2QContext } from 'app/core/H2QContext';
import { ModalButtons, ModalCancelButton, ModalPrice, ModalText, ModalTextLowBalance, ModalTitle, ModalTypes, ModalWrapperChildren, ModalWrapperOut } from "entities/generator";

interface IResetItems {
  accept: () => void;
  reject: () => void;
};

const type: ModalTypes = 'cancelItems';

export const ResetItems = ({ accept, reject }: IResetItems) => {
  const { h2qAccount } = useContext(H2QContext);
  const price = h2qAccount.data.m_discardPrice ? +h2qAccount.data.m_discardPrice : null;

  return (
    <>
      <ModalWrapperOut>
        <ModalTitle type={type} />

        <ModalWrapperChildren>
          {
            price &&
            <>
              {h2qAccount.data.m_h2qBalance && +h2qAccount.data.m_h2qBalance >= price
                ? <ModalText type={type} />
                : <ModalTextLowBalance />
              }

              <ModalPrice price={price} />

              {h2qAccount.data.m_h2qBalance && +h2qAccount.data.m_h2qBalance >= price
                ? <ModalButtons type={type} accept={accept} reject={reject} />
                : <ModalCancelButton type={type} reject={reject} />
              }
            </>
          }
        </ModalWrapperChildren>

      </ModalWrapperOut>
    </>
  )
};