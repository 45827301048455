import { ModalButtons, ModalText, ModalTitle, ModalTypes, ModalWrapperChildren, ModalWrapperOut } from 'entities/generator';

interface ICompleteAcceptCharacter {
  accept: () => void;
  reject: () => void;
};

const type: ModalTypes = "completeAcceptChar";

export const CompleteAcceptCharacter = function ({ accept, reject }: ICompleteAcceptCharacter) {
  return (
    <>
      <ModalWrapperOut>
        <ModalTitle type={type} />

        <ModalWrapperChildren>
          <ModalText type={type} />
          <ModalButtons type={type} accept={accept} reject={reject} />
        </ModalWrapperChildren>

      </ModalWrapperOut>
    </>
  )
};