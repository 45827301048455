import { RotatingCircle } from "shared/ui";
import { Button } from "shared/ui";
import { h2qAccount } from 'app/core/H2QContext';
import styles from "./ChangeAvatarWarning.module.scss";

interface IChangeAvatarWarn {
  accept: () => void;
  discard: () => void;
  isPending: boolean;
};

export function ChangeAvatarWarning({ accept, discard, isPending }: IChangeAvatarWarn) {

  const buttonOkMessage = isPending
    ? <RotatingCircle size={31} thick={3} />
    : <p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>OK</p>;

  const discardPrice = h2qAccount.data.m_discardPrice ? h2qAccount.data.m_discardPrice : "---";

  return (
    <div>
      <p className={styles['modal-items__text']}>You are currently have another avatar in the generator!</p>
      <p className={styles['modal-items__text']}>Do you really want to change it?</p>
      <p className={styles['modal-items__text']}>It will cost {discardPrice} H2Q</p>
      <div className={styles['modal-items__buttons']}>
        <Button
          children={buttonOkMessage}
          widthValue={"medium"}
          colorValue={"red"}
          action={accept}
          isActive={!isPending}
        />
        <Button
          children={(<p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>Cancel</p>)}
          widthValue={"medium"}
          action={discard}
          isActive={!isPending}
        />
      </div>
    </div>
  )
};

export function ChangeAvatarWarningFree({ accept, discard, isPending }: IChangeAvatarWarn) {

  const buttonOkMessage = isPending
    ? <RotatingCircle size={31} thick={3} />
    : <p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>OK</p>;

  return (
    <div>
      <p className={styles['modal-items__text']}>You are currently have another avatar in the generator!</p>
      <p className={styles['modal-items__text']}>Do you really want to change it?</p>
      <p className={styles['modal-items__text']}>All items you got in generator will stay and be applied to the new character</p>
      <div className={styles['modal-items__buttons']}>
        <Button
          children={buttonOkMessage}
          widthValue={"medium"}
          colorValue={"red"}
          action={accept}
          isActive={!isPending}
        />
        <Button
          children={(<p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>Cancel</p>)}
          widthValue={"medium"}
          action={discard}
          isActive={!isPending}
        />
      </div>
    </div>
  )
};