
export type UserSocial = {
  id: number;
  title: string;
  placeHolder: string;
  icon: string;
  isError: boolean;
  value: string;
};

const enterString = "Enter your ";

export const userSocials: UserSocial[] = [
  {
    id: 0,
    title: enterString + "Discord ID",
    placeHolder: "hero2quest#1234",
    icon: "/SocilaIcons/discord-gray.svg",
    isError: false,
    value: ""
  },
  {
    id: 1,
    title: enterString + "Twitter handle",
    placeHolder: "hero2quest",
    icon: "/SocilaIcons/twitter-gray.svg",
    isError: false,
    value: ""
  },
  {
    id: 2,
    title: enterString + "Youtube user ID",
    placeHolder: "hero2quest",
    icon: "/SocilaIcons/youtube-gray.svg",
    isError: false,
    value: ""
  },
  {
    id: 3,
    title: enterString + "Telegram username",
    placeHolder: "@hero2quest",
    icon: "/SocilaIcons/telegram-gray.svg",
    isError: false,
    value: ""
  },
  {
    id: 4,
    title: enterString + "Instagram username",
    placeHolder: "hero2quest",
    icon: "/SocilaIcons/instagram-gray.svg",
    isError: false,
    value: ""
  },
  {
    id: 5,
    title: enterString + "Facebook username",
    placeHolder: "hero2quest",
    icon: "/SocilaIcons/facebook-gray.svg",
    isError: false,
    value: ""
  },
];