import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { H2QContext } from "app/core/H2QContext";
import styles from "./NftPage.module.scss";
import { AvatarImageSVG } from "entities/avatar/ui/AvatarImage/AvatarImage";
import { Fade } from "react-awesome-reveal";
import { RotatingCircleCenter } from "shared/ui";
import { Button } from "shared/ui";
import { VerticalDivider } from "shared/ui";
import { BackLink } from "shared/ui/BackLink";
import { InitialItemElement, ItemImageSVG, parts } from "entities/item";
import { AvatarInfoMainTab, AvatarTags, getNftById, ItemInfoMainTab, ItemTags, NftTopInfo } from "entities/nft";
import { NftPageNftTabs } from "widgets";
import { ItemSidebarContainer, TSidebarInfo } from "entities/rigthSideBar";

type TSelectedPart = TSidebarInfo & {
  key: string;
};

export const NftPage = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const { id } = useParams();
  const [nft, setNft] = useState(() => getNftById(h2qAccount.nfts, id!));

  const selectedPart = {} as TSelectedPart;

  const changeRightSideBarChild = () => {
    h2qAccount.uiStore.setRightSidebarChild(selectedPart.key,
      <ItemSidebarContainer
        image={selectedPart.image}
        info={selectedPart.info}
        tags={selectedPart.tags}
      />
    );

    if (!h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(true);
    };
  };

  const viewAvatar = () => {
    // if sidebar already opened and show the same avatar then close it
    if (h2qAccount.uiStore.rightSidebarChild.key === "avatar" && h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      return;
    };

    selectedPart.key = "avatar";
    selectedPart.image = <AvatarImageSVG avatarImg={nft!.avatar} isImgMoved={true} />;
    selectedPart.info = <AvatarInfoMainTab avatar={nft!.avatar} showTags={false} />;
    selectedPart.tags = <AvatarTags avatarId={nft!.avatar.itemId} />

    changeRightSideBarChild();
  };

  const viewItem = async (item: InitialItemElement) => {
    // if sidebar already opened and show the same item then close it
    if (h2qAccount.uiStore.rightSidebarChild.key === parts[item.categoryId] && h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      return;
    };

    if (h2qAccount.inventoryInfoFromBC === null) {
      await h2qAccount.getInventoryDetails();
    };

    const nftItemInfo = h2qAccount.inventoryInfoFromBC![item.itemId];

    selectedPart.key = parts[item.categoryId];
    selectedPart.image = <ItemImageSVG iconLink={item.iconThumbLink} />;
    selectedPart.info = < ItemInfoMainTab itemInfo={nftItemInfo} categoryId={item.categoryId} itemId={item.itemId} title={parts[item.categoryId]} showTags={false} />;
    selectedPart.tags = <ItemTags categoryId={item.categoryId} itemId={item.itemId} itemInfo={nftItemInfo} />

    changeRightSideBarChild();
  };

  useEffect(() => {
    const nft = getNftById(h2qAccount.nfts, id!);
    setNft(nft);
    // eslint-disable-next-line
  }, [h2qAccount.nfts]);

  useEffect(() => {
    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["page-wrapper"]}>
      {(h2qAccount.fetchNTFs.state === "pending" || h2qAccount.getInventoryDetails.state === "pending") && <RotatingCircleCenter />}
      {nft && (
        <>
          <BackLink backLink="/h2q/nft" />
          <h2 className={styles["page-title"]}>{nft.info!.name}</h2>

          <NftTopInfo
            nft={nft}
          />

          <div className={styles["outlet-wrapper"]}>
            {nft && h2qAccount.inventoryInfoFromBC &&
              <NftPageNftTabs nft={nft} />
            }
          </div>

          <Fade
            direction="up"
            delay={100}
            fraction={0.1}
            triggerOnce
            duration={500}
          >
            <div className={styles["bottom-btns-menu"]}>
              {/* <div className={styles["nft-btn-image"]}>
                <NftImageSVG item={nft} />
              </div> */}
              {/* do not delete this div */}
              {/* <div></div> */}
              <div
                className={`${styles["avatar-btn-image"]} ${h2qAccount.uiStore.isRightSidebarOpen && selectedPart.key === "avatar" ? styles.active : ""}`}
                onClick={viewAvatar}
              >
                <AvatarImageSVG avatarImg={nft.avatar} isImgMoved={true} />
              </div>
              {/* do not delete this div */}
              {/* <div></div> */}
              {Object.entries(nft.items).map(([key, value]: [string, InitialItemElement]) => {
                if (value === null) {
                  return null;
                };
                return (
                  <div
                    className={`${styles["item-btn-image"]} ${h2qAccount.uiStore.isRightSidebarOpen && h2qAccount.uiStore.rightSidebarChild.key === key ? styles.active : ""}`}
                    key={key}
                    onClick={() => viewItem(value)}
                  >
                    <ItemImageSVG iconLink={value.iconLink} />
                  </div>
                )
              })}
            </div>
          </Fade>
        </>
      )
      }
      {!nft && h2qAccount.fetchNTFs.state !== "pending" && (
        <div className={styles["empty-gallery"]}>
          <p>NFT is not loaded. Possibly, it is still being generated.</p>
          <p>Try to reload the data.</p>
          <VerticalDivider value={50} />
          <Button
            widthValue="small"
            children={<>Reload the data</>}
            action={() => h2qAccount.fetchNTFs()}
          />
        </div>
      )}
    </div >
  );
});
