import styles from "./DoubleSlider.module.scss";

interface IDoubleSlider {
  min: number; // determine the entire size
  max: number; // determine the entire size
  minVal: number; // current
  maxVal: number; // current
  setMinVal: (val: number) => void;
  setMaxVal: (val: number) => void;
};

export const DoubleSlider = ({ min, max, minVal, maxVal, setMinVal, setMaxVal }: IDoubleSlider) => {
  // Convert to percentage
  const getPercent = (value: number) => {
    return (value - min) / (max - min) * 100;
  };

  const minPercent = getPercent(minVal);
  const maxPercent = getPercent(maxVal);

  return (
    <div className={styles.container}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
        }}
        className={`${styles.thumb} ${styles["thumb--left"]}`}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
        }}
        className={`${styles.thumb} ${styles["thumb--right"]}`}
      />

      <div className={styles.slider}>
        <div className={styles.slider__track} />
        <div
          className={styles.slider__range}
          style={{
            "left": minVal === 0 ? `calc(${minPercent}%)` : `calc(${minPercent}%)`,
            "width": `calc(${maxPercent - minPercent}% + 1rem)`
          }}
        />
        <div className={styles["slider__left-value"]}>{min}</div>
        <div className={styles["slider__right-value"]}>{max}</div>
      </div>
    </div>
  );
};