import { GalleryItemFadeAndBg, GalleryItemMainWrapper } from "entities/GalleryItemWrapper";
import { NftDivImage, NftElement, NftImageSVG } from "entities/nft";
import { NftElementBottomBar } from "features/NftElementBottomBar";
import { Link } from "react-router-dom";
import styles from "./NftListElement.module.scss";

interface INftListTempElement {
  nft: NftElement;
};

export const NftListTempElement = ({ nft }: INftListTempElement) => {
  return (
    <GalleryItemMainWrapper>
      <GalleryItemFadeAndBg>
        <NftImageSVG item={nft} hasBgColor={false} />
      </GalleryItemFadeAndBg>
      <NftElementBottomBar
        nft={nft}
        isActive={false}
        choose={() => { }}
        addToFav={() => { }}
        isChosen={false}
        isAddedToFav={false}
        choseBtnType={"showQuests"}
      />
    </GalleryItemMainWrapper>
  )
};

interface INftListElement {
  nft: NftElement;
  onFavClick: (nftId: string) => void;
  onChooseClick: (nftId: string) => void;
  isFav: boolean;
};

export const NftListElement = ({ nft, onFavClick, onChooseClick, isFav }: INftListElement) => {
  return (
    <GalleryItemMainWrapper isItemStaked={!!nft.pseudoNftData?.m_lockedInQuest}>
      <GalleryItemFadeAndBg>
        <Link
          className={styles["nft-link"]}
          to={`${nft.pseudoNFTAddr.split(":")[1]}`}
        >
          <NftDivImage item={nft} isFadeActive={true} />
        </Link>
      </GalleryItemFadeAndBg>
      <NftElementBottomBar
        isActive={true}
        nft={nft}
        choose={() => onChooseClick(nft.pseudoNFTAddr)}
        addToFav={() => onFavClick(nft.pseudoNFTAddr)}
        // Add some logic here in a future:
        isChosen={false}
        isAddedToFav={isFav}
        choseBtnType={"showQuests"}
      />
    </GalleryItemMainWrapper>
  )
};