import { Button } from "shared/ui";
import { RotatingCircle } from "shared/ui";
import { Timer } from "entities/Timer";
import { VerticalDivider } from "shared/ui";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "./NftInfoQuestTab.module.scss";
import { getNftInfo, NftElement } from "entities/nft";

interface INftInfoQuestTab {
  nft: NftElement;
};

export const NftInfoQuestTab = observer(function ({ nft }: INftInfoQuestTab) {
  const { h2qAccount } = useContext(H2QContext);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const navigate = useNavigate();

  const questData = nft.pseudoNftData?.m_lockedInQuest
    ? h2qAccount.quests.filter(item => item.id === nft.pseudoNftData?.m_lockedInQuest?.quest)[0]
    : null;

  const showQuest = () => {
    navigate(`/h2q/quests/${questData!.id.slice(2)}`)
  };

  const getReward = async () => {
    const nftInfo = getNftInfo(nft);
    await h2qAccount.q2hero(nftInfo);
    await h2qAccount.fetchNTFs();
    setIsFinished(prev => false);
  };

  const onFinish = async () => {
    setIsFinished(prev => true);
    h2qAccount.toggleRewardsToGet(true);
  };

  useEffect(() => {
    const checkQuests = async () => {
      if (h2qAccount.quests.length) {
        return;
      }
      else {
        if (h2qAccount.loadAllQuests.state !== "pending") {
          await h2qAccount.loadAllQuests();
        }
      }
    };

    const checkIsFinished = () => {
      if (nft.pseudoNftData?.m_lockedInQuest && Date.now() >= nft.pseudoNftData.m_lockedInQuest.finishQuest * 1000) {
        setIsFinished(prev => true);
      }
    };

    checkQuests();
    checkIsFinished();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <h3 className={styles['info-title']}>Quests</h3>
      {questData &&
        <>
          <p className={styles['info-value']}><span className={styles['info-param']}>Title:</span>{questData.m_questParams.mandatoryParams.name}</p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p className={styles['info-value']}><span className={styles['info-param']}>Time to complete:</span></p>
            <Timer
              finishTime={nft.pseudoNftData!.m_lockedInQuest!.finishQuest * 1000}
              color="white"
              onFinishAction={onFinish}
              messageType={"heroInCurrentQuest"}
            />
          </div>
          <VerticalDivider value={20} />
          <Button
            children={<p style={{ "color": "white", "fontSize": "1.4rem" }}>View quest</p>}
            widthValue={"small"}
            action={showQuest}
          />
          {isFinished &&
            <>
              <VerticalDivider value={20} />
              <p className={styles['info-value']}><span className={styles['info-param']}>Quest is finished! You can get your reward!</span></p>
              <VerticalDivider value={20} />
              <Button
                children={(h2qAccount.q2hero.state === "pending" || h2qAccount.isUnstakingAll)
                  ? <RotatingCircle size={25} thick={3} />
                  : <p style={{ "color": "white", "fontSize": "1.5rem", "lineHeight": "2.5rem" }}>Get reward</p>
                }
                widthValue={"small"}
                action={getReward}
                colorValue="blue"
                isActive={h2qAccount.q2hero.state !== "pending" && isFinished}
              />
            </>
          }
        </>
      }
      {questData === null &&
        <p className={styles['info-value']}><span className={styles['info-param']}>Hero is not stacked in any quests</span></p>
      }
      {h2qAccount.currentHeroRewards && h2qAccount.currentHeroRewards.nftId === nft.pseudoNFTAddr &&
        <>
          <VerticalDivider value={20} />
          <p className={styles['info-value']}><span className={styles['info-param']}>Quest completed:</span>{h2qAccount.currentHeroRewards.completed}</p>
          <ul className={styles["quest-list"]}>
            {h2qAccount.currentHeroRewards.completedQuests.map(q => {
              return (
                <li key={q}>
                  <Link to={`/h2q/quests/${q.slice(2)}`} className={styles['quest-link']}>
                    <h4>{h2qAccount.quests.find(quest => quest.id === q)?.m_questParams.mandatoryParams.name}</h4>
                    <p key={q}>Quest id: {q.slice(0, 32) + "..."}</p>
                  </Link>
                </li>
              )
            })}
          </ul>
        </>
      }
    </div>
  )
})