import { Container, Sprite } from "@saitonakamura/react-pixi"
import { cellBorder, cellInnerSize, cellOutSize, GeneratorTooltip, GenTooltipsType, lockImageOffsetX, lockImageOffsetY, SlotItem, SquareAmountBlock, ThreeDotsButton, threeDotsSize, TriangleItemColoredBlock, triangleSize } from "entities/generator"
import { ItemBlockColorKeys } from "entities/itemColors";
import { Circle } from "pixi.js"
import { useState } from "react";

interface IClickOnItem {
  x: number;
  y: number;
  zIndex: number;
  itemAmount: number;
  itemIndex: number;
  itemLink: string;
  isLocked: boolean;
  isTrianglesEnabled: boolean;
  isTooltipsActive: boolean;
  color: ItemBlockColorKeys;
  onItemClick: () => void;
  onDotsClick: () => void;

}
export const ClickOnItem = ({
  x,
  y,
  zIndex,
  itemAmount,
  itemIndex,
  itemLink,
  isTrianglesEnabled,
  isTooltipsActive,
  isLocked,
  color,
  onItemClick,
  onDotsClick
}: IClickOnItem) => {
  const [itemHovered, setItemHovered] = useState<GenTooltipsType | null>(null);

  return (
    <SlotItem
      x={x}
      y={y}
      zIndex={zIndex}
      itemHovered={itemHovered}
      item={
        <Sprite
          image={itemLink}
          scale={2}
          buttonMode={true}
          interactive={true}
          pointerdown={onItemClick}
          hitArea={new Circle(0, 0, cellOutSize / 6)}
          mouseover={() => setItemHovered("itemSlot")}
          mouseout={() => setItemHovered(null)}
          width={cellInnerSize * 0.9}
          height={cellInnerSize * 0.9}
          anchor={0.5}
          x={cellOutSize / 2}
          y={cellOutSize / 2}
        />
      }
      triangle={isTrianglesEnabled
        ? <Container
          interactive={true}
          mouseover={() => setItemHovered("triangleColor")}
          mouseout={() => setItemHovered(null)}
        >
          <TriangleItemColoredBlock
            color={color}
            x={cellBorder}
            y={cellOutSize - cellBorder}
          />
        </Container>
        : null
      }
      amountBlock={
        <SquareAmountBlock
          x={cellBorder + triangleSize * 0.2}
          y={cellOutSize - cellBorder - triangleSize * 0.2}
          value={itemAmount}
          onHoverOver={() => setItemHovered("currentItemAmount")}
          onHoverOut={() => setItemHovered(null)}
        />
      }
      lockBlock={isLocked
        ? <Sprite
          image={"/res/Generator/lock-security-gray.svg"}
          x={lockImageOffsetX}
          y={lockImageOffsetY}
          interactive={true}
          mouseover={() => setItemHovered("lockedItem")}
          mouseout={() => setItemHovered(null)}
        />
        : null
      }
      threeDots={
        <ThreeDotsButton
          x={(cellOutSize - threeDotsSize) / 2}
          y={cellInnerSize - cellBorder}
          action={onDotsClick}
          onHoverOver={() => setItemHovered("threeDots")}
          onHoverOut={() => setItemHovered(null)}
        />
      }
      tooltips={isTooltipsActive
        ? <>
          {itemHovered && itemHovered !== "triangleColor" &&
            < GeneratorTooltip
              type={itemHovered}
              isSlotNumber={itemIndex}
            />
          }
          {itemHovered && itemHovered === "triangleColor" &&
            <GeneratorTooltip
              type={"triangleColor"}
              isSlotNumber={itemIndex}
            />
          }
        </>
        : null
      }
    />
  )
};