import { Outlet } from "react-router-dom"
import AdminPageSelector from "../AdminPageSelector/AdminPageSelector"
import styles from "./AdminPage.module.scss";
import { ReactComponent as IconGodMode } from "app/icons/Title_GodMode_white.svg";
import { PageTitle } from "entities/PageTitle";
import { VerticalDivider } from "shared/ui";

export default function AdminPage() {
  return (
    <div className={styles["admin-wrapper"]}>
      <PageTitle>
        <IconGodMode
        />
      </PageTitle>
      <AdminPageSelector />
      <VerticalDivider value={10} />
      <Outlet />
    </div>
  )
}