import { useState } from "react";
import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';
import styles from "./AvatarGeneratorChoseGallery.module.scss";
import { AvatarCard, getAvatarsFromArray } from "entities/avatar";
import { h2qAccount } from "app/core/H2QContext";

interface IAvatarChoose {
  process: (id: number) => void;
};

export function AvatarGeneratorChoseGallery({ process }: IAvatarChoose) {
  const [sliderOffset, setOffset] = useState(0);

  const sliderWidth = 200;

  const avatars = getAvatarsFromArray(h2qAccount.data.m_avatar_amount!);

  const slidesTotalAmount: number = avatars.length;
  const maxOffest: number = sliderWidth * (slidesTotalAmount - 1);

  const moveSliderLeft = () => {
    setOffset(currentOffset => {
      const newOffset: number = currentOffset + sliderWidth;
      return Math.min(newOffset, 0);
    });
  };

  const moveSliderRight = () => {
    setOffset(currentOffset => {
      const newOffset: number = currentOffset - sliderWidth;
      return Math.max(newOffset, -maxOffest);
    });
  };

  return (
    <div className={styles.slider__wrapper}>
      {avatars.length > 1 &&
        <div className={styles["btn-left"]}>
          <img src={chevronLeft} className={styles.slider__buttons} onClick={moveSliderLeft} alt="List backward" />
        </div>
      }
      <div
        className={styles["slider__active-window"]}
        style={{ "width": sliderWidth + "px" }}
      >
        <div className={styles.slider__elements}
          style={{ transform: `translateX(${sliderOffset}px)` }}
        >
          {avatars.length > 0 && avatars.map(item => {
            return (
              <div style={{ "minWidth": sliderWidth, "maxWidth": sliderWidth }} key={item.itemId}>
                <AvatarCard isImgMoved={true} item={item} process={() => process(item.itemId)} />
              </div>
            )
          })}
          {avatars.length === 0 &&
            <div
              className={styles["no-avatars"]}
              style={{ "minWidth": sliderWidth, "maxWidth": sliderWidth, "height": sliderWidth }}
            >
              <p>
                You have no avatars to generate new NFT.
              </p>
            </div>
          }
        </div>
      </div>
      {avatars.length > 1 &&
        <div className={styles["btn-right"]}>
          <img src={chevronRight} className={styles.slider__buttons} onClick={moveSliderRight} alt="List forward" />
        </div>
      }
    </div>
  )
}