import { h2qAccount } from "app/core/H2QContext";
import { TGenStats } from "entities/generatorSelector";
import { itemList } from "entities/item";

export const getGeneratorStats = (id: number): TGenStats => {
  // TODO: calculate genStats by id:
  const h2qRewardsPool = id === 1
    ? 99999
    : 0;

  const assetsCount = id === 1
    ? itemList.reduce((acc, item) => acc += item.length, 0)
    : 0;

  const heroCount = id === 1
    ? h2qAccount.totalNfts.length
    : 0;

  const questCount = id === 1
    ? h2qAccount.quests.length
    : 0;

  return { assetsCount, heroCount, questCount, h2qRewardsPool };
};