import styles from "./NftElementBottomBar.module.scss";
import { Timer } from "entities/Timer";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import { H2QContext } from "app/core/H2QContext";
import { RotatingCircle, UnstakeButton } from "shared/ui";
import { delay, getTimeFromMilliSeconds, timeToDdHhMmSs } from "shared/lib";
import { PlusButton as ChooseNftIcon } from "shared/ui/PlusButton/PlusButton";
import { useParams } from "react-router-dom";
import { AddToFavButton } from "shared/ui";
import { getNftInfo, NftElement } from "entities/nft";

interface INftElementBottomBar {
  nft: NftElement;
  isActive: boolean;
  isChosen: boolean;
  choose: (id: string) => void;
  isAddedToFav: boolean;
  addToFav: (id: string) => void;
  choseBtnType: "sendToQuest" | "showQuests"
};

export const NftElementBottomBar = observer(function (
  { nft,
    isActive,
    isChosen,
    choose,
    isAddedToFav,
    addToFav,
    choseBtnType
  }: INftElementBottomBar) {

  const { h2qAccount } = useContext(H2QContext);
  const { id } = useParams();

  const [isQuestCompleted, setQuestCompleted] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const isNftStaked = nft.pseudoNftData?.m_lockedInQuest || false;

  const isTempParticipated = h2qAccount.tempHeroesInQuest.includes(nft.pseudoNFTAddr);
  const questLockTime = useRef<string>("0");

  const questTimerCompleteCb = async () => {
    h2qAccount.toggleRewardsToGet(true);
    setQuestCompleted(true);
  };

  const getReward = async () => {
    console.log("Getting quest reward...");

    setIsWaiting(true);
    const nftToUnstake = getNftInfo(nft);
    await h2qAccount.q2hero(nftToUnstake);

    do {
      console.log("Waiting for nfts' update, 1500ms");
      await delay(1500);
      await h2qAccount.fetchNTFs();
    }
    while (nft.pseudoNftData?.m_lockedInQuest === null)

    console.log("Got reward");
    setIsWaiting(false);
    h2qAccount.loadData();
  };

  const chooseNft = (nftId: string) => {
    choose(nftId);
  };

  useEffect(() => {
    const getQuestParams = async () => {
      setIsWaiting(true);
      const nftInfo = getNftInfo(nft);
      const questData = h2qAccount.quests.find(item => item.id.slice(2) === id);
      if (!questData) {
        setIsWaiting(false);
        return
      };

      const result = await h2qAccount.getQuestParams(nftInfo, questData);
      const questDuration = getTimeFromMilliSeconds(Number(result.duration) * 1000); //{dd, hh, mm, ss}
      questLockTime.current = timeToDdHhMmSs(questDuration, true);
      setIsWaiting(false);
    }

    if (id) {
      getQuestParams();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${styles.wrapper} ${isChosen ? styles.selected : ""}`}>

      {isChosen && h2qAccount.hero2quest.state === "pending" &&
        <div className={styles["stacked-info"]}>Start staking</div>
      }

      {isTempParticipated && !isNftStaked && h2qAccount.hero2quest.state !== "pending" &&
        <div className={styles["stacked-info"]}>Staking</div>
      }

      {isNftStaked &&
        <div className={`${styles["stacked-info"]} ${styles.flex}`}>
          <Timer
            finishTime={nft.pseudoNftData!.m_lockedInQuest!.finishQuest * 1000}
            messageType={id ?
              nft.pseudoNftData!.m_lockedInQuest?.quest.slice(2) === id
                ? "heroInCurrentQuest"
                : "heroInOtherQuest"
              : "heroInCurrentQuest"
            }
            size={16}
            onFinishAction={questTimerCompleteCb}
          />
          {isQuestCompleted &&
            <div style={{ "marginLeft": "auto", "width": "7.5rem" }}>
              <UnstakeButton
                action={getReward}
                isWaiting={isWaiting || h2qAccount.isUnstakingAll}
              />
            </div>
          }
        </div>
      }

      {
        !isNftStaked &&
        !isTempParticipated &&
        !(isChosen && h2qAccount.hero2quest.state === "pending") &&
        <>
          <AddToFavButton
            action={() => addToFav(nft.pseudoNFTAddr)}
            isActive={isActive}
            isAddedToFav={isAddedToFav}
            size={28}
            style={{ opacity: isChosen ? 0 : 1, transition: "all 0.1s ease-in-out" }}
          />

          <p style={{ opacity: isChosen ? 0 : 1, transition: "all 0.1s ease-in-out" }} className={styles.value}><span className={styles.h2q}>H2Q</span>{Number(nft.pseudoNftData?.m_h2qMetaCharacter.valPrice)}</p>

          {isWaiting
            ? <RotatingCircle size={28} thick={3} />
            : <ChooseNftIcon
              action={() => chooseNft(nft.pseudoNFTAddr)}
              isActive={isActive && !isWaiting}
              isSelected={false}
              size={33}
              style={{
                transformOrigin: "right",
                transition: "all 0.1s ease-in-out",
                opacity: isChosen ? 0 : 1
              }}
              choseBtnType={choseBtnType}
            />
          }

          <p
            className={`${styles["locked-time"]} ${isChosen ? styles.shown : ""}`}
            onClick={() => chooseNft(nft.pseudoNFTAddr)}>
            &#91; {questLockTime.current} &#93;
          </p>
        </>
      }
      {/* 
      {!isQuestCompletedBefore &&
        !isChosen &&
        !isNftStaked &&
        !isTempParticipated &&
        h2qAccount.hero2quest.state === "pending" &&
        <div className={styles["stacked-info"]}>...</div>
      } */}
    </div >
  )
});