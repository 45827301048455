import styles from "./SideBarUserBlock.module.scss";
import { FC, PropsWithChildren, useState } from "react";
import { useLocation } from "react-router-dom";
import { SideBarAvatarBlock } from "entities/sideBar";

interface ISideBarUserBlock {
  isOpen: boolean
};

export const SideBarUserBlock: FC<PropsWithChildren<ISideBarUserBlock>> = ({ isOpen, children }) => {
  const params = useLocation();

  const [isMenuShown, setMenuShown] = useState<boolean>(() => {
    if (params.pathname.split("/")[2] === "user") return true;
    else return false;
  });

  const showUserMenu = () => {
    setMenuShown(prev => !prev);
  };

  return (
    <div className={styles["user-bar"]}>
      <div className={`${styles["user-menu"]} ${isMenuShown ? styles.open : ""}`}>
        {children}
      </div>
      <div className={styles["user-info"]} onClick={showUserMenu}>
        <SideBarAvatarBlock isOpen={isOpen} />
      </div>
    </div>
  );
};