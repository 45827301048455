import { H2QuestParams, RewardParams } from "app/core/H2QQuestManager";

export const inputSquareSize = 7;
export type TCellValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const cellColors = {
  cyan: "(70,240,240)",
  lime: "(210, 245, 60)",
  green: "(0,128,0)",
  orange: "(245,130,48)",
  blue: "(0,130,200)",
  gold: "(255,215,0)",
  magenta: "(240,50,230)",
} as const;

export type TCellColor = keyof typeof cellColors & "null";
export type TCellColorValue = typeof cellColors[TCellColor] & null;

export type TCellColors = {
  [key in TCellColor]: TCellColorValue;
};

export const cellSuperColors = {
  purple: "(107,30,180)",
  red: "(255,0,0)",
} as const;

export type TCellSuperColor = keyof typeof cellSuperColors & "null";
export type TCellSuperColorValue = typeof cellSuperColors[TCellSuperColor] & null;

export type TCellSuperColors = {
  [key in TCellSuperColor]: TCellSuperColorValue;
};

export const cellAllColors = { ...cellColors, ...cellSuperColors } as const;
export type TCellAllColor = keyof typeof cellAllColors;
export type TCellAllColorValue = typeof cellAllColors[TCellAllColor];

export type TCellAllColors = {
  [key in TCellAllColor]: TCellAllColorValue;
};

export type TInputCell = {
  value: TCellValue | null;
  mainColor: TCellColor | null;
  diffColor: TCellColor | null;
  superColor: TCellSuperColor | null;
  isError: boolean;
  questParams: H2QuestParams,
  rewardParams: RewardParams,
  isDeployed: boolean;
  isChecked: boolean;
  questId: string;
};

export type TSquareCellDiffColor = {
  coords: {
    row: number;
    col: number;
  };
  color: TCellColor;
};

export type TSquareCellDiffSuperColor = {
  coords: {
    row: number;
    col: number;
  };
  color: TCellSuperColor;
};

export type TInputSquare = {
  cells: TInputCell[][];
  squareColor: TCellColor | null;
  cellDiffColors: TSquareCellDiffColor[];
  cellSuperColors: TSquareCellDiffSuperColor;
  seasonTitle: string;
  seasonDescr: string;
};

export type TCostume = {
  costumeName: string;
  costumeValue: number;
  costumeId: number;
};

export type TEpochColorId = {
  title: string;
  costumeIds: TCostume[];
};

export type TEpochColorIds = {
  [key in TCellAllColor]: TEpochColorId;
};

export type TEpoch = {
  title: string;
  seasons: TInputSquare[] | [];
};

export type TActiveSeason = {
  eIndex: number;
  sIndex: number;
};

export type TActiveCell = TActiveSeason & {
  row: number;
  col: number;
};

export type TSelectedQuests = {
  eIndex: number;
  eTitle: string;
  sIndex: number;
  sTitle: string;
  quests: Array<{ row: number, col: number }>
};