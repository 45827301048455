// константы для отрисовки кнопок и полей:
// export const btnOffsetX = 6;
// export const btnOffsetX = 14;
// export const btnOffsetY = 14;
export const fieldOffsetX = 19;
export const fieldOffsetY = 19;
export const fieldHeight = 85;
export const fieldBootomHeight = 120;
// export const buttonHeight = 100;
// export const textOffsetX = 30;
// export const textOffsetY = 20;

// параметры для кнопок
// export const backBtnBg = 0xdedede;
// export const backBtnAlpha = 0.5;
// export const frontBtnBg = 0xcfcfcfcf;
// export const frontBtnAlpha = 0.5;
// export const blueBtnText = 0x49456f;
// export const backRedBtnBg = 0xf53232;
// export const frontRedBtnBg = 0xff0000;
// export const subitBtnTextWhite = 0xffffff;
// export const labelBtnFontFamily = "Andale";
// export const labelBtnFontSize = buttonHeight / 2;

// параметры для полей значений
export const backValBg = 0x000000;
export const backValAlpha = 0.55;
export const frontValBg = 0x000000;
export const frontValAlpha = 0.3;

export const topValBg = 0x000000;
export const topValBgAlpa = 0.55;
export const bottomValBg = 0xFFFFFF;
export const bottomValBgAlpha = 0.55;

export const fieldLabelFontFamily = "Andale";
export const fieldLabelFontSize = fieldHeight * 0.25;
export const fieldLabelFontColor = 0x000000;

export const fieldValueFontFamily = "Andale";
export const fieldValueFontSize = fieldHeight * 0.4;
export const fieldValueFontColor = 0xffffff;

export const triangleColorBlockAlpha = 0.8;
export const triangleSize = 74;

export const circleBlockSize = 66;
export const circleFontSize = circleBlockSize * 0.45
export const countBlockSize = triangleSize * 0.65;
export const countBlockColor = 0x1d3f5a;
export const countBlockAlpha = 0.9;
export const countBlockFontSize = countBlockSize * 0.4;
export const countBlockFontColor = 0xffffff;
export const countBlockFontFamily = "Andale";

export const dotSize = 15;
export const dotGap = 15;
export const dotColor = 0xffffff;
export const threeDotsSize = dotSize * 3 + dotGap * 2;

export const squareColorBlockAlpha = 0.8;
export const squareSize = 30;
export const squareGap = 12;
export const rarityFontSize = 30;
export const rarityFontColor = 0xffffff;

export const heroFadeColor = 0x000000;
export const heroFadeColorAlpha = 0.22;
export const heroFadeWidth = 700;
export const heroFadeHeight = 120;