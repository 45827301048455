import { Sprite } from "@saitonakamura/react-pixi";
import { H2QContext } from "app/core/H2QContext";
import { cellBorder, cellOutSize, fieldHeight, FieldWithBottomValue, GeneratorTooltip, GenTooltipsType, itemsValuePriceWidth, SlotGenerateBtn } from "entities/generator";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

interface ICreateNft {
  x: number;
  y: number;
  onGenerate: () => void;
  isTooltipsActive: boolean;
  isVertical: boolean;
};

export const CreateNft = observer(function ({ x, y, onGenerate, isTooltipsActive, isVertical }: ICreateNft) {
  const { h2qAccount } = useContext(H2QContext);
  const [itemHovered, setItemHovered] = useState<GenTooltipsType | null>(null);

  return (
    <SlotGenerateBtn
      x={x}
      y={y}
      btnIcon={
        <Sprite
          image={"/res/Generator/btt-create-hero.svg"}
          anchor={[0.5, 0.5]}
          x={cellOutSize / 2 + 5} // Add 5px to move sprite
          y={cellOutSize / 2 - fieldHeight / 2}
          width={142}
          height={142}
          // scale={{
          //   x: isApplied ? -1 : 1,
          //   y: 1
          // }}
          // angle={isVertical ? -90 : 0}
          interactive={true}
          buttonMode={h2qAccount.data.m_generatedHero === null ? false : true}
          pointerdown={onGenerate}
          // alpha={h2qAccount.data.m_generatedHero === null ? 0.2 : 1}
          alpha={(itemHovered === "newGenerateBtn" || itemHovered === "emptyGenerateBtn") ? 0.35 : 0.25}
          mouseover={() => setItemHovered(h2qAccount.data.m_generatedHero ? "newGenerateBtn" : "emptyGenerateBtn")}
          mouseout={() => setItemHovered(null)}
        />
      }
      bottomCount={
        <FieldWithBottomValue
          label="Create NFT Hero"
          value={h2qAccount.data.m_acceptPrice && h2qAccount.data.m_generatedHero?.metaCharacter.valPrice
            ? Number(h2qAccount.data.m_acceptPrice) + Number(h2qAccount.data.m_generatedHero.metaCharacter.valPrice)
            : 0
          }
          x={cellBorder + cellBorder / 2 - 1}
          y={cellOutSize - fieldHeight - cellBorder - 2}
          width={itemsValuePriceWidth + 1}
          height={fieldHeight}
          onHoverOver={() => setItemHovered("createPrice")}
          onHoverOut={() => setItemHovered(null)}
        />
      }
      tooltips={
        isTooltipsActive && itemHovered && itemHovered !== "itemTypesAmount"
          ? <GeneratorTooltip
            type={itemHovered}
            isVertical={isVertical}
          />
          : null
      }
    />
  )
});