import { KeyTagType } from "entities/keyTags/model/keyTags";
import styles from "./KeyTagsBlock.module.scss";

interface IColoredKeyTag {
  tagName: string;
  color: string;
};

const ColoredKeyTag = ({ tagName, color }: IColoredKeyTag) => {
  return (
    <p
      style={{
        border: `1px solid ${color}`,
        color: `${color}`
      }}
      className={styles.tag}
    >
      {tagName}
    </p>
  )
};

interface IKeyTagsBlock {
  nftKeyTags: KeyTagType[];
  isTitleShown?: boolean;
};

export const KeyTagsBlock = ({ nftKeyTags, isTitleShown = true }: IKeyTagsBlock) => {
  return (
    <>
      {isTitleShown && <h3 className={styles['info-title']}>KEY TAG:</h3>}
      <div>
        {nftKeyTags.map((item, index) => {
          return (
            <ColoredKeyTag tagName={item.text} key={index} color={item.color} />
          )
        })}
      </div>
    </>)
};