import { InfoStatisticsBlock } from "entities/InfoStatisticsBlock";
import { observer } from 'mobx-react';
import { useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { H2QContext } from 'app/core/H2QContext';
import styles from "./ItemStats.module.scss";

export const ItemStats = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const { pathname } = useLocation();
  const currentPath = pathname.split("/")[3];

  return (
    <div className={styles['stats-wrapper']}>
      {currentPath === "avatar" &&
        <>
          <InfoStatisticsBlock
            title="Avatars available"
            value={h2qAccount.data.m_avatar_amount
              ? h2qAccount.uiStore.avatarsToShow.reduce((acc, a) => acc + a.amount, 0)
              : 0}
          />
        </>
      }

      {currentPath === "item" &&
        <>
          <InfoStatisticsBlock
            title="Items available"
            value={h2qAccount.data.m_inventories
              ? h2qAccount.uiStore.itemsToShow.reduce((acc, i) => acc + i.amount, 0)
              : 0}
          />
          <InfoStatisticsBlock
            title="Items total value"
            value={h2qAccount.uiStore.itemsToShow && h2qAccount.inventoryInfoFromBC
              ? h2qAccount.uiStore.itemsToShow.reduce((acc, item) => acc + (item.amount * Number(h2qAccount.inventoryInfoFromBC![item.itemId].value)), 0)
              : 0}
          />
        </>
      }
    </div >
  )
})
