import { FC, PropsWithChildren, useRef } from "react"
import { useOutsideClick } from "shared/lib";
import styles from "./FilterWrapper.module.scss";

interface IFilterWrapper {
  shown: boolean;
  closeFilter: () => void;
};

export const FilterWrapper: FC<PropsWithChildren<IFilterWrapper>> = ({ children, shown, closeFilter }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick(menuRef, () => {
    closeFilter();
  });

  return (
    <div className={`${styles["filter-wrapper"]} ${shown ? styles.shown : ""}`} ref={menuRef}>
      {children}
    </div>
  )
}