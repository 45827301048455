import { useContext } from "react";
import { H2QContext } from "app/core/H2QContext";
import { initialMaxItemValue, initialMinItemValue } from "features/itemFilter";
import { observer } from "mobx-react";
import { FilterCloseButton } from "shared/ui";

export const ItemSelectedTopBar = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  return (
    <>
      {h2qAccount.uiStore.itemFilter.itemRarity.length > 0 &&
        h2qAccount.uiStore.itemFilter.itemRarity.map(r => {
          return (
            <FilterCloseButton key={r} name={r} clickAction={() => h2qAccount.uiStore.changeItemFilterRarity(r)} />
          )
        })
      }
      {h2qAccount.uiStore.itemFilter.itemType.length > 0 &&
        h2qAccount.uiStore.itemFilter.itemType.map(t => {
          return (
            <FilterCloseButton key={t} name={t} clickAction={() => h2qAccount.uiStore.changeItemFilterType(t)} />
          )
        })
      }
      {h2qAccount.uiStore.itemFilter.originalHero.length > 0 &&
        h2qAccount.uiStore.itemFilter.originalHero.map(h => {
          return (
            <FilterCloseButton key={h} name={h} clickAction={() => h2qAccount.uiStore.changeItemFilterOriginalHero(h)} />
          )
        })
      }
      {h2qAccount.uiStore.itemFilter.itemMinValue !== initialMinItemValue &&
        <FilterCloseButton
          name={`Min Value - ${h2qAccount.uiStore.itemFilter.itemMinValue}`}
          clickAction={() => h2qAccount.uiStore.changeItemFilterMinValue(initialMinItemValue)}
        />
      }
      {h2qAccount.uiStore.itemFilter.itemMaxValue !== initialMaxItemValue &&
        <FilterCloseButton
          name={`Max Value - ${h2qAccount.uiStore.itemFilter.itemMaxValue}`}
          clickAction={() => h2qAccount.uiStore.changeItemFilterMaxValue(initialMaxItemValue,)}
        />
      }
    </>
  )
})