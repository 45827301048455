import { modalTitleVariants, ModalTypes } from 'entities/generator';
import styles from "./ModalTitle.module.scss";

interface IModalTitle {
  type: ModalTypes
};

export function ModalTitle({ type }: IModalTitle) {
  return (
    <p className={styles['modal-title']}>{modalTitleVariants[type]}</p>
  )
};