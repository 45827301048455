import styles from "./ProperNftGallery.module.scss";
import { FlipWrapper } from "entities/FlipWrapper";
import { NftItemsGrid } from "entities/NftItemsGrid";
import { QuestClientData } from "app/core/H2QAccount";
import { useContext } from "react";
import { NftDivImage, NftElement, NftInfoMainTab } from "entities/nft";
import { NftElementBottomBar } from "features/NftElementBottomBar";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { GalleryItemFadeAndBg } from "entities/GalleryItemWrapper";
import { observer } from "mobx-react";
import { H2QContext } from "app/core/H2QContext";
import { ReloadButton } from "shared/ui";
import { ReactComponent as NoHero } from "app/icons/icon-item-empty.svg";

export type ProperNft = {
  nftEl: NftElement;
  questClientData: QuestClientData | null;
};

interface IProperNftGallery {
  nfts: ProperNft[];
  selectedNfts: string[];
  setSelectedNft: (id: string) => void;
  nftFromGalleryId?: string;
  questData: H2QuestResponse;
};

export const ProperNftGallery = observer(function ({ questData, nfts, selectedNfts, setSelectedNft, nftFromGalleryId }: IProperNftGallery) {
  const { h2qAccount } = useContext(H2QContext);
  const favNftsParams = h2qAccount.uiStore.favNftsForQuests[questData.id];

  const startTime = questData
    ? questData.m_questParams.mandatoryParams.startTime * 1000
    : Infinity;

  const finishTime = questData
    ? questData.m_questParams.mandatoryParams.finishTime * 1000
    : 0;

  const isQuestActive = Date.now() > startTime && Date.now() < finishTime;

  const addNftToFav = (nftId: string) => {
    if (!isQuestActive) {
      return;
    }
    const chosenNftId = nfts.find(item => item.nftEl.pseudoNFTAddr === nftId)?.nftEl.pseudoNFTAddr;
    if (!chosenNftId) {
      return;
    }

    h2qAccount.uiStore.changeFavNftsForQuest(questData.id, nftId);
  };

  const isShowOnlyFavNfts = favNftsParams && favNftsParams.isFavModeEnabled;

  const nftsToShow = nfts.filter(data => {
    // show nft which currently staked in this quest:
    if (data.nftEl.pseudoNftData?.m_lockedInQuest && data.nftEl.pseudoNftData.m_lockedInQuest.quest === questData.id) {
      return true;
    }
    // Do not show nfts which have already participated in this quest
    if (data.questClientData?.m_questFulfilled) {
      return false;
    }
    return true;
  });

  return (
    <>
      <div
        className={styles["gallery-wrapper"]}
        style={{
          gridTemplateColumns: `repeat(${h2qAccount.uiStore.nftsInQuestRowCount}, 1fr)`
        }}
      >
        {h2qAccount.fetchNTFs.state === "resolved" && nftsToShow.length && nftsToShow.map((item, index) => {
          if (isShowOnlyFavNfts && !favNftsParams.favNfts.includes(item.nftEl.pseudoNFTAddr)) {
            return null;
          }
          else {
            return (
              <div
                className={`
                ${styles["gallery-item"]}
                ${selectedNfts.includes(item.nftEl.pseudoNFTAddr) ? styles["item-selected"] : ""} 
                ${item.nftEl.pseudoNftData?.m_lockedInQuest ? styles.staked : ""} 
                ${(nftFromGalleryId === item.nftEl.pseudoNFTAddr.slice(2) && index === 0) ? styles["nft-previous-selected"] : ""}`}
                key={item.nftEl.pseudoNFTAddr}>
                <div className={styles.flip} style={{ width: h2qAccount.uiStore.nftsInQuestRowCount !== 2 ? "100%" : "50%" }}>
                  <FlipWrapper
                    frontChild={
                      <GalleryItemFadeAndBg hasTopPadding={false}>
                        <NftDivImage item={item.nftEl} hasBgColor={false} isFadeActive={true} />
                      </GalleryItemFadeAndBg>
                    }
                    backChild={(<NftItemsGrid nft={item.nftEl} />)}
                    ratio={1}
                  />
                  <NftElementBottomBar
                    isActive={true}
                    nft={item.nftEl}
                    isChosen={selectedNfts.includes(item.nftEl.pseudoNFTAddr)}
                    choose={setSelectedNft}
                    isAddedToFav={favNftsParams && favNftsParams.favNfts.includes(item.nftEl.pseudoNFTAddr)}
                    addToFav={addNftToFav}
                    choseBtnType={"sendToQuest"}
                  />
                </div>
                {h2qAccount.uiStore.nftsInQuestRowCount === 2 &&
                  <div className={styles.info}>
                    <NftInfoMainTab nft={item.nftEl} />
                  </div>}
              </div>
            )
          }
        })
        }
      </div >
      {h2qAccount.fetchNTFs.state === "resolved" && nftsToShow.length === 0 &&
        <div className={styles["empty-block"]}>
          <NoHero className={styles["empty-bg"]} preserveAspectRatio="none" />
          <p className={styles["empty-title"]}>You don't have suitable HEROES</p>
          <div className={styles["empty-text-btn"]}>
            <p>Request from collection again</p>
            <ReloadButton
              isWaiting={false}
              reloadAction={h2qAccount.fetchNTFs}
            />
          </div>
        </div>
      }
    </>
  )
});