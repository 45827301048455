import { H2QContext } from "app/core/H2QContext";
import { FilterButton } from "shared/ui/FilterButton";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterNftGeneratorType.module.scss";
import { newGeneratorsList } from "entities/generatorSelector";

export const FilterNftGeneratorType = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const onClickGeneratorType = (gen: number) => {
    h2qAccount.uiStore.changeNftFilterGenId(gen);
  };

  return (
    <div className={styles["items-type"]}>
      {newGeneratorsList.map(g => {
        return (
          <FilterButton
            key={g.id}
            name={g.shortName}
            clickAction={() => onClickGeneratorType(g.id)}
            isSelected={h2qAccount.uiStore.nftFilter.genId.includes(g.id)}
          />
        )
      })}
    </div>
  )
})