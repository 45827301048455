import { GeneratorParams, TGenStats } from "entities/generatorSelector";
import { ReactNode } from "react";
import styles from "./GeneratorSidebarContainer.module.scss";

interface IGeneratorSidebarContainer {
  gen: GeneratorParams;
  stats: TGenStats;
  button?: ReactNode;
};

export const GeneratorSidebarContainer = ({ gen, stats, button }: IGeneratorSidebarContainer) => {
  const annotation = gen.id === 1
    ? "Ready for some wild fun?\n\nMeme Party is a cool and funny game where you create animals in recognizable characters.Mix and match items and clothes to get unexpectedly funny and original combinations.Choose from a variety of funny attributes: clothes, objects, gadgets, food, musical instruments, toys.Create a cat chef in an apron and with a ladle, a dog astronaut in a spacesuit, a bird DJ with headphones and a remote control.The possibilities are limited only by your imagination! Share your masterpieces with friends, have contests for the funniest image, and create unforgettable memes.Meme Party is a great way to have fun with friends and family.Laugh, play and create your own unique memes! Join the meme party!"
    : gen.id === 2
      ? "Jason and the Argonauts is a story of heroism, adventure, love, and betrayal. It is one of the most famous Greek myths."
      : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. At magni aspernatur dolor nisi dolorum, sed veritatis hic ipsam aliquid! Harum aspernatur, doloribus blanditiis necessitatibus aliquam accusamus ea quis quasi dignissimos!";

  const features = gen.id === 1
    ? "To complete quests, it's enough to think logically in order to gather the necessary items in tasks for high progress in the game. This simple and knowledge-free game offers relaxed and enjoyable entertainment.\n\nLogical Thinking: Progress through quests by logically assembling required items in tasks.\n\nSimple and Accessible: No prior knowledge required, offering a relaxing and fun experience.\n\nCasual Gameplay: Enjoy a laid - back and enjoyable game without any pressure."
    : gen.id === 2
      ? "Study the myth: Familiarize yourself with the original myth of 'Jason and the Argonauts' to understand the context of the game, the characters' motivations, and possible events.\n\nChoose characters: Before starting each quest, you must carefully select characters based on their skills, abilities, and interactions.Consider which heroes will be most effective in a particular situation.\n\nCollect items: You will need to search, find and use various items to complete quests.Carefully study the environment, interact with characters and solve puzzles to gain access to new items.\n\nUse items: You will need to combine items, use them in the right places and in the right situations to be successful.Think carefully about your actions and don't be afraid to experiment.\n\nSolve logic puzzles: You will have to solve puzzles, draw conclusions and make decisions based on logic and deduction.Use all the knowledge and information available to you to find the right path.\n\nRethink the narrative: You can influence the course of history by making different decisions and acting outside the box.Look for alternative ways to complete quests, come up with new storylines and create your own version of the myth."
      : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. At magni aspernatur dolor nisi dolorum, sed veritatis hic ipsam aliquid! Harum aspernatur, doloribus blanditiis necessitatibus aliquam accusamus ea quis quasi dignissimos!";

  const details = gen.id === 1
    ? "Created in 2015 but never released, this unique game make for the first Hero Generator on the platform.\n\nThe first of its kind, it is directly related to the platform's development and is a collector's item for HERO2QUEST fans due to its rarity and historical significance."
    : gen.id === 2
      ? "The Golden Fleece: The Golden Fleece was a symbol of wealth and power. It was said to have been given to King Aeetes by the gods.\n\nThe Argonauts: The Argonauts were a group of heroes from all over Greece.They were known for their strength, courage, and intelligence"
      : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. At magni aspernatur dolor nisi dolorum, sed veritatis hic ipsam aliquid! Harum aspernatur, doloribus blanditiis necessitatibus aliquam accusamus ea quis quasi dignissimos!";


  return (
    <>
      <div className={styles["gen-info-wrapper"]}>
        <h2 className={styles["gen-title"]}>{gen.longName}</h2>

        <div className={styles["gen-stat"]}>
          <div className={styles["gen-logo"]}>
            <img src={gen.banner} alt="Generator banner" />
          </div>
          <div className={styles["gen-info"]}>
            <p>Content owner:<span>{gen.owner}</span></p>
            <ul>
              <li>Assets:<span>{stats.assetsCount}</span></li>
              <li>Heroes:<span>{stats.heroCount}</span></li>
              <li>Quests:<span>{stats.questCount}</span></li>
            </ul>
          </div>
          <div className={styles["gen-reward-wrapper"]}>
            <div className={styles["gen-reward"]}>
              <h3>H2Q<span>REWARDS</span></h3>
              {stats.h2qRewardsPool}
            </div>
          </div>
        </div>

        <div className={styles["gen-text"]}>
          <h3>Annotation</h3>
          <p>{annotation}</p>
        </div>

        <div className={styles["gen-text"]}>
          <h3>Game details</h3>
          <p>{details}</p>
        </div>

        <div className={styles["gen-text"]}>
          <h3>Game features</h3>
          <p>{features}</p>
        </div>


      </div>
      {button &&
        <div className={styles.button}>
          {button}
        </div>
      }
    </>
  )
};