import { AssetsCollectionControlBlock, ItemStats } from "widgets";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./AssetsMainPage.module.scss";
import { ReactComponent as IconAssets } from "app/icons/Title_ASSSETS_white.svg";
import { ReactComponent as IconItem } from "app/icons/Title_ITEM_White.svg";
import { ReactComponent as IconAvatar } from "app/icons/Title_AVATAR_white.svg";
import { ReactComponent as IconSlash } from "app/icons/slash_white.svg";
import { PageTitle } from "entities/PageTitle";
import { SelectGeneratorMessage } from "features/SelectGeneratorMessage";

export const AssetsMainPage = () => {

  const { pathname } = useLocation();
  const currentGalleryName = pathname.split("/")[3];
  const genId: number | null = Number(localStorage.activeGen) || null;

  // console.log("On collections page:", currentGalleryName);

  return (
    <>
      <div className={styles.collections__wrapper}>
        <PageTitle>
          <IconAssets />
          <IconSlash
            style={{ marginRight: "1rem", marginLeft: "0.5rem", scale: "2 1" }}
          />
          {currentGalleryName === "avatar" &&
            <IconAvatar />
          }
          {currentGalleryName === "item" &&
            <IconItem />
          }
        </PageTitle>

        {genId
          ? <>
            <ItemStats />
            <AssetsCollectionControlBlock />
            <Outlet />
          </>
          : <SelectGeneratorMessage />
        }
      </div>
    </>
  );
}
