import { useEffect, useRef } from 'react'
import { itemList } from "entities/item";
import { avatarsList } from "entities/avatar";

const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject(null)
    };

    image.src = url;
  });
};

interface IExportedNFTImage {
  avatarId: number;
  items: number[];
};

export function ExportedNftImage({ avatarId, items }: IExportedNFTImage) {
  const exportImage = useRef<HTMLCanvasElement>(null);
  const avatarItem = avatarsList.filter(a => a.itemId === avatarId)[0];
  const id = avatarsList.indexOf(avatarItem);

  const makeExportedImage = async () => {
    if (!exportImage.current) return;

    exportImage.current!.width = avatarItem.imageWidth;
    exportImage.current!.height = avatarItem.imageHeight;

    const canvas = exportImage.current!;
    const ctx = canvas.getContext("2d");

    ctx!.clearRect(0, 0, canvas.width, canvas.height);

    const avatarImg = await loadImage(avatarsList[id].imageLink);
    if (avatarImg !== null) {
      ctx!.drawImage(avatarImg, 0, 0);
    }

    const avatarHead = avatarsList[id].headLink
      ? await loadImage(avatarsList[id].headLink!)
      : null;

    if (avatarHead !== null) {
      const headPosX = avatarsList[id].headPos!.X * avatarsList[id].imageWidth;
      const headPosY = avatarsList[id].headPos!.Y * avatarsList[id].imageHeight;
      ctx!.drawImage(avatarHead, headPosX, headPosY);
    }

    items.forEach(async (item, index) => {
      const img = await loadImage(itemList[index][item].imageLink!);
      if (img !== null) {
        ctx!.drawImage(img, itemList[index][item].imagePos!.X * avatarItem.imageWidth, itemList[index][item].imagePos!.Y * avatarItem.imageHeight)
      }
    });
  };

  const downloadImage = () => {
    const canvas = exportImage.current!;
    const result = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");

    const link = document.createElement("a");
    link.style.display = 'none';
    link.href = canvas.toDataURL(result);
    link.download = "NFT-Image";

    link.click();
    URL.revokeObjectURL(link.href);
  }

  useEffect(() => {
    makeExportedImage();
  });

  return (
    <>
      <canvas
        ref={exportImage}
        style={{
          "display": "none"
        }}
        onClick={downloadImage}
      ></canvas>
    </>
  )
}
