import { identicon } from '@basementuniverse/marble-identicons';
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./ProfileDetails.module.scss";
import { ReactComponent as IconEdit } from "app/icons/icon-edit-pencil-gray.svg";
import { ReactComponent as CopyPasteIcon } from "app/icons/icon-copy-black.svg";
import { ReactComponent as ErrorIcon } from "app/icons/icon-error-red.svg";
import { ReactComponent as CancelIcon } from "app/icons/icon-cancel-white.svg";
import { ReactComponent as UpdatedIcon } from "app/icons/icon-status-updated-white.svg";
import { ReactComponent as IconProfile } from "app/icons/Title_PROFILE_white.svg";
import { delay } from "shared/lib";
import { BottomPopup } from "entities/BottomPopup";
import { Button } from "shared/ui";
import { h2qAccount } from "app/core/H2QContext";
import { UserAccPageHeader } from "entities/UserAccPageHeader";
import { UserSocial, UserSocialInputBlock, userSocials } from "features/UserSocialInputBlock";

export interface UserLSData {
  nick: string;
  email: string;
  nameSurname: string;
  socials: string[];
};

export function MarbleIdenticon({ name }: { name: string }) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
      ref.current.appendChild(identicon(name, { fontSize: 0, size: 256 }));
    }
  });

  return <div className={styles.identicon} ref={ref}></div>;
};

export const ProfileDetails = function () {
  const accountId = localStorage.getItem("currentAddress") || "";

  const [nickName, setNickName] = useState<string>("");
  const [nickActive, setNickActive] = useState<boolean>(false);
  const [nickError, toggleNickError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [emailActive, setEmailActive] = useState<boolean>(false);
  const [emailError, toggleEmailError] = useState<boolean>(false);

  const [nameSurname, setNameSurname] = useState<string>("");
  const [nameActive, setNameActive] = useState<boolean>(false);
  const [nameError, toggleNameError] = useState<boolean>(false);

  const [socials, setSocials] = useState<UserSocial[]>(() => userSocials);
  const [socialsError, toggleSocialsError] = useState<boolean>(false);

  const [isDataChanged, toggleDataChanged] = useState<boolean>(false);
  const [isPopupActive, togglePopupActive] = useState<boolean>(false);

  const [isClickedAddres, setIsClickedAddres] = useState<boolean>(false);
  // const [isClickedKey, setIsClickedKey] = useState<boolean>(false);

  const updateNickname = () => {
    if (localStorage.getItem("currentAddress") && localStorage.getItem("currentAddress")?.slice(0, 15) + "..." === nickName) {
      toggleNickError(prev => false);
      return;
    };

    const nickNameCheck = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/g;
    if (nickName !== "" && !nickNameCheck.test(nickName)) {
      console.log("Wrong nickname format!", nickName);
      toggleNickError(prev => true);
      return;
    };

    const userData: UserLSData = JSON.parse(localStorage.getItem("userData")!);
    userData.nick = nickName;
    console.log("Update user nickname:", nickName);
    toggleNickError(prev => false);
    localStorage.setItem("userData", JSON.stringify(userData));
    h2qAccount.uiStore.setUserNickname(nickName);
  };

  const updateEmail = () => {
    const emailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
    if (email !== "" && !emailCheck.test(email)) {
      console.log("Wrong email format!", email);
      toggleEmailError(prev => true);
      return;
    };

    const userData: UserLSData = JSON.parse(localStorage.getItem("userData")!);
    userData.email = email;
    console.log("Update user email:", email);
    toggleEmailError(prev => false);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const updateName = () => {
    const nameCheck = /^[a-zA-Z ]+$/;
    if (nameSurname !== "" && !nameCheck.test(nameSurname)) {
      console.log("Wrong name format!", nameSurname);
      toggleNameError(prev => true);
      return;
    }

    const userData: UserLSData = JSON.parse(localStorage.getItem("userData")!);
    userData.nameSurname = nameSurname;
    console.log("Update user name and surname:", nameSurname);
    toggleNameError(prev => false);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const updateSocials = () => {
    let errorcount = 0;
    //TODO: something works wrong...
    // const socialsCheck = /^[a-zA-Z0-9-]+$/g;
    const newSocials = [...socials].map(item => {
      // const isError = item.value === ""
      //   ? false
      //   : !socialsCheck.test(item.value);
      // debugger;
      // item.isError = isError;
      item.isError = false;

      // if (isError) {
      //   errorcount += 1;
      //   console.log("Wrong data format!", item.value, !socialsCheck.test(item.value), errorcount);
      // }
      return item;
    });

    setSocials(prev => newSocials);

    if (errorcount === 0) {
      const userData: UserLSData = JSON.parse(localStorage.getItem("userData")!);

      userData.socials = newSocials.map(item => item.value);
      console.log("Update user socials:", userData.socials);
      toggleSocialsError(prev => false);
      localStorage.setItem("userData", JSON.stringify(userData));
    }
    else {
      toggleSocialsError(prev => true);
    }
  }

  const copyAccAddress = async () => {
    if (accountId && navigator && navigator.clipboard) {
      navigator.clipboard.writeText(accountId);
      console.log("Copy user account address to clipboard");
    }

    setIsClickedAddres(true);
    await delay(300);
    setIsClickedAddres(false);
  };

  // const copyPublicKey = async () => {
  //   setIsClickedKey(true);
  //   await delay(300);
  //   setIsClickedKey(false);
  // };


  const changeNickname = (val: string) => {
    toggleDataChanged(true);
    togglePopupActive(true);
    setNickName(val);
  };

  const changeEmail = (val: string) => {
    toggleDataChanged(true);
    togglePopupActive(true);
    setEmail(val);
  };

  const changeName = (val: string) => {
    toggleDataChanged(true);
    togglePopupActive(true);
    setNameSurname(val);
  };

  const changeSocials = (value: string, id: number) => {
    const newSocials = [...socials];
    newSocials[id].value = value;
    setSocials(newSocials);
    toggleDataChanged(true);
    togglePopupActive(true);
  };

  const updateLSUserData = async () => {
    updateNickname();
    updateEmail();
    updateName();
    updateSocials();

    toggleDataChanged(false);
    await delay(3000);
    togglePopupActive(false);
  };

  useEffect(() => {
    // if there is NO user data make empty object in LS:
    const checkLSUserDataExist = () => {
      const userData: UserLSData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")!)
        : {};

      if (!userData.nick) {
        userData.nick = h2qAccount.uiStore.userNickname;
      }
      if (!userData.email) {
        userData.email = "";
      }
      if (!userData.nameSurname) {
        userData.nameSurname = "";
      }
      if (!userData.socials) {
        userData.socials = ["", "", "", "", "", ""];
      }
      localStorage.setItem("userData", JSON.stringify(userData));
    };

    const getLSUserData = () => {
      const userData: UserLSData = JSON.parse(localStorage.getItem("userData")!);
      setNickName(userData.nick);
      setEmail(userData.email);
      setNameSurname(userData.nameSurname);

      const newSocials = [...socials];
      newSocials.map((item, index) => item.value = userData.socials[index]);
      setSocials(newSocials);

      toggleDataChanged(false);
      togglePopupActive(false);
    };

    checkLSUserDataExist();
    getLSUserData();
    // eslint-disable-next-line
  }, []);

  const bottomPopupChild = (
    <div className={styles["bottom-message-wrapper"]}>
      {isDataChanged
        ? <>
          <div style={{
            width: "33rem",
            position: "relative",
            bottom: "0.5rem"
          }}>
            <Button
              children={<p style={{ fontSize: "2rem", margin: 0 }}>SAVE SETTINGS</p>}
              colorValue="green"
              widthValue={"full"}
              action={updateLSUserData}
            />
          </div>
        </>
        : nickError || emailError || nameError || socialsError
          ? <>
            <ErrorIcon
              style={{ width: "3rem", height: "3rem", marginLeft: "0", marginRight: "1rem" }}
            />
            <p style={{ fontSize: "2rem", color: "#dadada", margin: 0 }}>Error detected</p>
          </>
          : <>
            < UpdatedIcon
              style={{ width: "3rem", height: "3rem", marginRight: "1rem" }}
            />
            <p style={{ fontSize: "2rem", color: "#dadada", margin: 0 }}>Profile updated!</p>
          </>
      }
      <CancelIcon
        style={{ width: "3rem", height: "3rem", marginLeft: "auto", cursor: "pointer" }}
        onClick={() => togglePopupActive(false)}
      />
    </div>
  );

  const isOk = !nickError && !nameError && !emailError && !socialsError && !isDataChanged;

  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconProfile />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>

        <div className={styles["nickname-icon-block"]}>
          <div className={styles["icon-block"]}>
            <MarbleIdenticon name={accountId} />
          </div>
          <div className={styles["nickname-block"]}>
            <label>Nickname</label>
            <div
              className={`${styles["nick-input-wrapper"]} ${nickError ? styles["string-block-error"] : ""} ${nickActive ? styles.active : ""}`}
              onFocus={() => setNickActive(true)}
              onBlur={() => setNickActive(false)}
            >
              <input type="text"
                value={nickName}
                onChange={(value) => changeNickname(value.target.value)}
              />
              {!nickError &&
                < IconEdit
                  className={styles["icon-edit"]}
                />
              }
              {nickError &&
                <>
                  <label className={styles["label-error"]}>Use only "a-zA-Z", "0-9" "-", "_", " " symbols!</label>
                  <ErrorIcon
                    className={styles["icon-error"]}
                  />
                </>
              }
            </div>
          </div>
        </div>

        <div className={styles["address-block"]}>
          <label>User Account Address</label>
          <div className={styles["readonly-string-block"]}>
            <p>{accountId}</p>
            <CopyPasteIcon
              className={`${styles['icon-copy']} ${isClickedAddres ? styles.active : ""}`}
              onClick={copyAccAddress}
            />
          </div>
        </div>

        {/* <div className={styles["address-block"]}>
          <label>Public key</label>
          <div className={styles["string-block"]}>
            <p>{accountId}</p>
            <CopyPasteIcon
              className={`${styles['icon-copy']} ${isClickedKey ? styles.active : ""}`}
              onClick={copyPublicKey}
            />
          </div>
        </div> */}

        <div className={styles["address-block"]}>
          <label>Email address</label>
          <div
            className={`${styles["string-block"]} ${emailError ? styles["string-block-error"] : ""} ${emailActive ? styles.active : ""}`}
            onFocus={() => setEmailActive(true)}
            onBlur={() => setEmailActive(false)}
          >
            <input type="email" value={email} placeholder="Enter email adress" onChange={(e) => changeEmail(e.target.value)} />
            {emailError &&
              <>
                <label className={styles["label-error"]}>Wrong email format!</label>
                <ErrorIcon
                  className={styles["icon-error"]}
                />
              </>
            }
          </div>
        </div>

        <div className={styles["address-block"]}>
          <label>Name</label>
          <div
            className={`${styles["string-block"]} ${nameError ? styles["string-block-error"] : ""} ${nameActive ? styles.active : ""}`}
            onFocus={() => setNameActive(true)}
            onBlur={() => setNameActive(false)}
          >
            <input type="text" placeholder="Enter name and surname" value={nameSurname} onChange={(e) => changeName(e.target.value)} />
            {nameError &&
              <>
                <label className={styles["label-error"]}>Use only "a-zA-Z" and " " symbols!</label>
                <ErrorIcon
                  className={styles["icon-error"]}
                />
              </>
            }
          </div>
        </div>

        <div className={`${styles["socials-wrapper"]} ${styles["bottom-shadow"]}`}>
          <p>Social</p>
          <div className={styles["socials"]}>
            {userSocials.map((item) => {
              return (
                <UserSocialInputBlock
                  key={item.id}
                  item={item}
                  value={socials[item.id].value}
                  onChangeValue={changeSocials}
                />
              )
            })}
          </div>
        </div>

      </div>

      <BottomPopup
        isActive={isPopupActive}
        isError={(nickError || emailError || nameError || socialsError) && !isDataChanged}
        isOk={isOk}
        children={bottomPopupChild}
      />
    </div >
  )
}