const IndexBasisContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [],
                "outputs": []
            },
            {
                "name": "getInfo",
                "inputs": [],
                "outputs": [
                    {
                        "name": "addrRoot",
                        "type": "address"
                    },
                    {
                        "name": "codeHashData",
                        "type": "uint256"
                    }
                ]
            },
            {
                "name": "destruct",
                "inputs": [],
                "outputs": []
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "_addrRoot",
                "type": "address"
            },
            {
                "key": 2,
                "name": "_codeHashData",
                "type": "uint256"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "_addrRoot",
                "type": "address"
            },
            {
                "name": "_codeHashData",
                "type": "uint256"
            }
        ]
    },
    tvc: "te6ccgECFQEAAlMAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsSBQQUA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPAwLBgNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwREQYDPCCCEEfGLdq64wIgghBotV8/uuMCIIIQazbRBrrjAg0KBwNyMPhG8uBM+EJu4wDR2zwijiEk0NMB+kAwMcjPhyDOcc8LYQLIz5Os20Qazsv/zclw+wCRW+LjAPIAEAkIACjtRNDT/9M/MfhDWMjL/8s/zsntVAAI+Er4SwI0MPhCbuMA+Ebyc9H4SfhKxwXy4GT4ANs88gALDgJ07UTQ10nCAY6vcO1E0PQFcSGAQPQOjoGJ33IigED0Dm+Rk9cL/974a/hqgED0DvK91wv/+GJw+GPjDQwQAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAyQw+Eby4Ez4Qm7jANHbPNs88gAQDw4ALvhK+EP4QsjL/8s/z4PO+EvIy//Nye1UADj4SfhKxwXy4GT4APhKyM+FCM6Ab89AyYEAoPsAADTtRNDT/9M/0wAx+kDU0dDT/9H4a/hq+GP4YgAK+Eby4EwCEPSkIPS98sBOFBMAFHNvbCAwLjY2LjAAAA==",
    code: "te6ccgECEgEAAiYABCSK7VMg4wMgwP/jAiDA/uMC8gsPAgERA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPAkIAwNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwODgMDPCCCEEfGLdq64wIgghBotV8/uuMCIIIQazbRBrrjAgoHBANyMPhG8uBM+EJu4wDR2zwijiEk0NMB+kAwMcjPhyDOcc8LYQLIz5Os20Qazsv/zclw+wCRW+LjAPIADQYFACjtRNDT/9M/MfhDWMjL/8s/zsntVAAI+Er4SwI0MPhCbuMA+Ebyc9H4SfhKxwXy4GT4ANs88gAICwJ07UTQ10nCAY6vcO1E0PQFcSGAQPQOjoGJ33IigED0Dm+Rk9cL/974a/hqgED0DvK91wv/+GJw+GPjDQkNAEOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAyQw+Eby4Ez4Qm7jANHbPNs88gANDAsALvhK+EP4QsjL/8s/z4PO+EvIy//Nye1UADj4SfhKxwXy4GT4APhKyM+FCM6Ab89AyYEAoPsAADTtRNDT/9M/0wAx+kDU0dDT/9H4a/hq+GP4YgAK+Eby4EwCEPSkIPS98sBOERAAFHNvbCAwLjY2LjAAAA==",
    codeHash: "d3cd71c53f0d4d1954b5bf8f726bdbc7a60688a34e350ff990c83fddc327a3f2",
};
module.exports = { IndexBasisContract };