import { H2QContext } from "app/core/H2QContext";
import { AvatarSelectedTopBar } from "features/avatarFilter";
import { FilterBarWrapper } from "features/FilterTopBarWrapper"
import { observer } from "mobx-react"
import { useContext } from "react";

interface IAvatarFilterTopBar {
  toggleFilter: () => void;
};

export const AvatarFilterTopBar = observer(function ({ toggleFilter }: IAvatarFilterTopBar) {
  const { h2qAccount } = useContext(H2QContext);

  const isFiltersActive =
    h2qAccount.uiStore.avatarFilter.avatarName.length !== 0 ||
    h2qAccount.uiStore.avatarFilter.genId.length !== 0

  const clearFilter = () => {
    h2qAccount.uiStore.clearAvatarFilter();
  };

  return (
    <FilterBarWrapper
      isFiltersActive={isFiltersActive}
      clearFilter={clearFilter}
      toggleFilter={toggleFilter}
    >
      <AvatarSelectedTopBar />
    </FilterBarWrapper>
  )
})