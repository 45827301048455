import { Container, PixiComponent } from '@saitonakamura/react-pixi';
import { useEffect, useState } from 'react';
import { Graphics } from 'pixi.js';
import { backValAlpha, backValBg, fieldOffsetX, fieldOffsetY, frontValAlpha, frontValBg } from 'entities/generator';

interface IFiledWithBg {
  x: number;
  y: number;
  width: number;
  height: number;
  onHoverOver?: () => void;
  onHoverOut?: () => void;
};

interface IValueBg {
  width: number;
  height: number;
};

const FieldBg3d = PixiComponent<IValueBg, Graphics>("FieldBg3d", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(frontValBg, frontValAlpha);
    ins.drawRect(- fieldOffsetX, fieldOffsetY, props.width, props.height);
    ins.beginFill(backValBg, backValAlpha);
    ins.drawRect(0, 0, props.width, props.height);
  }
});

export const FieldWithBg = (props: IFiledWithBg) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true)
  }, [props]);

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      mouseover={props.onHoverOver}
      mouseout={props.onHoverOut}
    >
      {
        isLoading &&
        <>
          <FieldBg3d height={props.height} width={props.width} />
        </>
      }
    </Container>
  )
};