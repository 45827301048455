import CryptoJS from "crypto-js";

export const encryptPhrase = (phrase: string, key: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(phrase, key).toString();

  return ciphertext;
};

export const decryptPhrase = (cipher: string, key: string): string => {
  const bytes = CryptoJS.AES.decrypt(cipher, key);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const checkPhraseForValidity = (phrase: string): boolean => {
  if (phrase.length === 0) return false;

  const splitPhrase = phrase.split(" ");

  if (splitPhrase.length === 12 && splitPhrase.every(word => word.length > 0)) {
    return true
  }

  return false;
};

export const complexPin = (pin: string[]): string => {
  const complexPin = Number(pin.join("")).toString(16) + "salt_phrase" + Number(pin.join("")).toString(16);

  return complexPin;
};