import { IconColorVariants, LinkColor } from "app/icons/icons";
import { UserSidebarElement } from "entities/sideBar";
import { useState } from "react";
import { NavLink } from "react-router-dom"
import styles from "./SideBarUserLink.module.scss";

interface ISideBarUserLink {
  menuItem: UserSidebarElement;
  isOpen: boolean;
}
export const SideBarUserLink = ({ menuItem, isOpen }: ISideBarUserLink) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const color: LinkColor = menuItem.onlyColor
    ? menuItem.onlyColor
    : "blue";

  const iconColor = IconColorVariants[color];

  const iconHoverColor = IconColorVariants.white;

  return (
    <>
      <NavLink
        className={({ isActive }) => (isActive
          ? `${styles["menu-item"]} ${styles.selected}`
          : styles["menu-item"]
        )}
        to={menuItem.link}
      >
        <div className={styles["item-body"]}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div
            className={styles["item-logo"]}
          >
            <menuItem.icon
              fill={menuItem.noFillIcon
                ? "none"
                : isHover
                  ? iconHoverColor
                  : iconColor
              }
              stroke={isHover
                ? iconHoverColor
                : iconColor
              }
            />
          </div>
          <div className={`${styles["item-text"]} ${isOpen ? "" : styles.closed}`}>
            {menuItem.text}
          </div>
        </div>
      </NavLink>
    </>
  )
};