import { client } from "app/model/tonClient";
import { abiContract, signerKeys } from "@eversdk/core";
import { Account } from "@eversdk/appkit";
import { H2QSuperRootContract } from "app/contracts/H2QSuperRootContract.js";
import { H2QInventoryDetailsContract } from "app/contracts/H2QInventoryDetailsContract";
import { H2QAccountContract } from "app/contracts/H2QAccountContract.js";
import { ChecksContract } from "app/contracts/ChecksContract.js";
import { DataContract } from "app/contracts/DataContract.js";
import { DataChunkContract } from "app/contracts/DataChunkContract.js";
import { H2QNftRootContract } from "app/contracts/H2QNftRootContract";
import { IndexContract } from "app/contracts/IndexContract.js";
import { IndexBasisContract } from "app/contracts/IndexBasisContract.js";
import { NftRootBaseContract } from "app/contracts/NftRootBaseContract.js";
import { NftRootCustomMintContract } from "app/contracts/NftRootCustomMintContract.js";
import { H2QPseudoNftContract } from "app/contracts/H2QPseudoNftContract.js";
import { H2QPseudoNftPlatformContract } from "app/contracts/H2QPseudoNftPlatformContract.js";
import { TokenWalletContract } from "app/contracts/TokenWalletContract.js";

import { H2QAccountPlatformContract } from "app/contracts/H2QAccountPlatformContract"
import { H2QNftDeployerContract } from "app/contracts/H2QNftDeployerContract"
import { H2QUtilsContract } from "app/contracts/H2QUtilsContract"
import { H2QuestClientPlatformContract } from "app/contracts/H2QuestClientPlatformContract"
import { H2QuestContract } from "app/contracts/H2QuestContract"
import { H2QuestIndexContract } from "app/contracts/H2QuestIndexContract"
import { H2QuestManagerContract } from "app/contracts/H2QuestManagerContract"
import { H2QuestPlatformContract } from "app/contracts/H2QuestPlatformContract"
import { InventoryDetailsContract } from "app/contracts/InventoryDetailsContract"
import { NftDeployerContract } from "app/contracts/NftDeployerContract"
import { NftRootOwnerContract } from "app/contracts/NftRootOwnerContract"
import { PseudoNftContract } from "app/contracts/PseudoNftContract"
import { PseudoNftPlatformContract } from "app/contracts/PseudoNftPlatformContract"
import { TokenRootOwnerContract } from "app/contracts/TokenRootOwnerContract"

import { makeAutoObservable, runInAction, toJS } from "mobx";
import { task } from "mobx-task";
import { parse256bytesTo64numbers } from "./parsers";
import { delay, Tuple } from "shared/lib";
import {
  fetchByCodeHash, fetchByInitCodeHash,
  fetchGraphQL,
  getAccountData,
  getAccountsData,
} from "./fetchGraphQL";

import { h2qDB } from "app/db/db";
import { h2qAccount } from "./H2QContext";
import {
  addQuestAdmin,
  getCurrentQuestCodeHash,
  H2QuestParams,
  H2QuestResponse, RewardParams,
} from "./H2QQuestManager";
import {
  H2QFullCharacter,
  H2QuestClient,
  H2QuestGetter,
} from "./H2QuestGetter";
import { config } from "app/config";
import { H2QuestClientContract } from "app/contracts/H2QuestClientContract";
import { H2QuestGetterContract } from "app/contracts/H2QuestGetterContract";
import { isQuestValidForNft, ItemHexMaskToInventoryItemArray, QuestsForNft } from "entities/quest";
import { RarityValue } from "entities/item";
import { MetaCharacter, NFTInfo, PseudoNFTData, TrueCharacter } from "entities/nft";
import { UserLSData } from "pages/UserAccountPage/ProfileDetails/ProfileDetails";
import { filterByFinishTime } from "features/questFilter";
import { H2QUIParamsObservable } from "./H2QUIParams";
import { complexPin, encryptPhrase } from "shared/lib/encryptPhrase";


(globalThis as any).toJS = toJS;
(globalThis as any).h2qDB = h2qDB;
(globalThis as any).addQuest = addQuestAdmin;


export function parseInventoryReward(m_inventoryReward: string) {
  const u256 = m_inventoryReward.slice(2);
  return {
    inv0: parseInt(u256.slice(0, 8), 16),
    inv1: parseInt(u256.slice(8, 16), 16),
    inv2: parseInt(u256.slice(16, 24), 16),
    inv3: parseInt(u256.slice(24, 32), 16),
    inv4: parseInt(u256.slice(32, 40), 16),
    inv5: parseInt(u256.slice(40, 48), 16),
    inv6: parseInt(u256.slice(48, 56), 16),
  }
}


const contractsList = [
  ChecksContract,
  DataChunkContract,
  DataContract,
  H2QAccountContract,
  H2QAccountPlatformContract,
  H2QInventoryDetailsContract,
  H2QNftDeployerContract,
  H2QNftRootContract,
  H2QPseudoNftContract,
  H2QPseudoNftPlatformContract,
  H2QSuperRootContract,
  H2QUtilsContract,
  H2QuestClientContract,
  H2QuestClientPlatformContract,
  H2QuestContract,
  H2QuestGetterContract,
  H2QuestIndexContract,
  H2QuestManagerContract,
  H2QuestPlatformContract,
  IndexBasisContract,
  IndexContract,
  InventoryDetailsContract,
  NftDeployerContract,
  NftRootBaseContract,
  NftRootCustomMintContract,
  NftRootOwnerContract,
  PseudoNftContract,
  PseudoNftPlatformContract,

  TokenRootOwnerContract,
  TokenWalletContract,

].map(({ abi }) => {
  return new Account({
    abi,
  });
});

export interface QuestClientData {
  id: string,
  m_questFulfilled: boolean,
  m_questParams: H2QuestParams,
  m_h2qTrueCharacter: TrueCharacter,
  m_questGetter: string;
  m_h2questAddress: string;
};

export interface H2QuestParamsResult {
  allowed: boolean;
  duration: string; // int 16
  qstAmount: string; // actually string
  reward: {
    qstReward: string;
    h2qReward: string;
    experience: string;
  };
}

interface RawH2AccountData {
  m_lockedH2QTokens: string;
  m_generatorBusy: boolean;
  m_acceptPrice: string;
  m_discardPrice: string;
  m_h2qRoot: string;
  m_qstRoot: string;
  m_h2qWallet: string;
  m_qstWallet: string;
  m_h2qBalance: string;
  m_qstBalance: string;
  m_generatedHero: null | RawGeneratedHero;
  m_nftDeployer: string;
  m_code: string;
  m_nonce: number;
  m_avatar_amount: string;
  m_inventories: [string, string, string, string, string, string, string];
  m_inventory_amount: string[];
  m_initialized: boolean;
  m_askedInventoryDetails: string;
  m_pseudoNftPlatformCode: string;
}

export interface RawGeneratedHero {
  metaCharacter: MetaCharacter;
  trueCharacter: TrueCharacter;
}

interface H2AccountData {
  m_lockedH2QTokens: string;
  m_lockedQSTokens: string;
  m_generatorBusy: boolean;
  m_acceptPrice: string;
  m_discardPrice: string;
  m_h2qRoot: string;
  m_qstRoot: string;
  m_h2qWallet: string;
  m_qstWallet: string;
  m_h2qBalance: string;
  m_qstBalance: string;
  m_generatedHero: null | {
    avatar_id: number;
    inventory: [number, number, number, number, number, number, number];
    h2q_accept_price: number;
    h2q_discard_price: number;

    metaCharacter: MetaCharacter;
    trueCharacter: TrueCharacter;

    heroAlreadyDeployed?: boolean;
  };
  m_nftDeployer: string;
  m_code: string;
  m_nonce: number;
  m_avatar_amount: Tuple<number, 64>;
  m_inventories: Tuple<Tuple<number, 64>, 7>;
  m_inventory_amount: number[];
  m_initialized: boolean;
  m_askedInventoryDetails: number;
}

export interface InventoryParams {
  inv0: number;
  inv1: number;
  inv2: number;
  inv3: number;
  inv4: number;
  inv5: number;
  inv6: number;
}

export interface AddInventoryParams extends InventoryParams {
  avatar_id: number;
}

interface AddInventoryParamsNew {
  avatar_id: number;
  inv: [number, number, number, number, number, number, number];
}

interface AddStartGenerateCharacter {
  avatar_id: number;
}

interface ChangeAvatar {
  av: number;
}

export interface InventoryItemData {
  imageHash: string;
  inventoryKind: [string, string, string, string, string, string, string];
  originalHero: string;
  rarity: RarityValue;
  tags: string[];
  value: string;
}

export interface InventoryAllItemsData {
  [key: number]: InventoryItemData;
}

type QuestInfoAndData = {
  questParams: H2QuestParamsResult | null;
  questId: string | null;
  clientData: QuestClientData | null;
  questRewardItems: number[];
}

export type AsyncReturnType<T extends (...args: any) => Promise<any>> =
  T extends (...args: any) => Promise<infer R> ? R : any;

export class H2QAccountObservable {
  //var for nfts which were sent to a quest but not has been updated yet by BC (we do not have changes yet in h2qaccount.nfts)
  tempHeroesInQuest: string[] = [];
  //pinCode
  pin: string | null = null;
  //last entered time
  lastEnter: number = 0;
  //seed phrase
  phrase: string = "";
  //h2q balance
  balance: number | null = 0;
  //array of available nfts
  nfts: Array<NFTInfo> = []; // TODO
  //nfts which are currently being created (to show in nfts gallery)
  nftsInProgress: Array<any> = []; // TODO
  // var to get completing percentage of executing operation (every has different messages from BC during execution). no doubt we need something more usefull
  logMessages: Array<any> = [];
  // var from BC to store information about items (rarity, value, tags and etc...) 
  inventoryInfoFromBC: InventoryAllItemsData | null = null;
  // contracts ))
  contracts: {
    superRoot?: Account;
    h2qAccount?: Account;
  } = {};

  // var to check in generator is current nft already was generated by somebody - we can't generate exactly the same nft
  doesNftAlreadyExist: boolean = false;

  //var to store ui setting and users's selected filters 
  uiStore;

  constructor(private nonce = 1) {
    makeAutoObservable(this);
    // if (this.phrase) {
    //   this.getAccount().then(async () => {
    //     await this.loadData();
    //     await this.getH2qBalance();
    //   });
    // }

    this.uiStore = new H2QUIParamsObservable(this);
  }

  // Status when complete any quest
  hasRewardsToGet = false;
  toggleRewardsToGet(status: boolean) {
    runInAction(() => {
      this.hasRewardsToGet = status;
    })
  };

  // Satus when getting all rewards
  isUnstakingAll = false;
  toggleUnstakingAll(status: boolean) {
    runInAction(() => {
      this.isUnstakingAll = status;
    })
  };

  clearLogMessages() {
    runInAction(() => {
      this.logMessages.length = 0;
    });
  }

  h2qBalance?: number | null;
  getH2qBalance = task(async () => {
    const balance =
      Number(await this.contracts.h2qAccount?.getBalance()) / 1000_000_000;
    runInAction(() => {
      this.h2qBalance = balance;
    });
  });

  superRootBalance?: number | null;
  getSuperRootBalance = task(async () => {
    const balance =
      Number(await this.contracts.superRoot?.getBalance()) / 1000_000_000;
    runInAction(() => {
      this.superRootBalance = balance;
    });
  });

  lastGeneratedSeed?: string;
  generateSeed = task(
    async () => {
      let seed = await client.crypto.mnemonic_from_random({
        word_count: 12,
      });

      runInAction(() => {
        this.lastGeneratedSeed = seed.phrase;
      });

      return this.lastGeneratedSeed;
    },
    { state: "resolved" }
  );

  setPin(pin: string | null) {
    runInAction(() => {
      this.pin = pin;
    })
  };

  setLastEnteredTime(time: number) {
    runInAction(() => {
      this.lastEnter = time;
    })
  };

  coroutineUpdateStarted = false;
  async startCoroutineUpdate() {
    if (this.coroutineUpdateStarted) return;
    this.coroutineUpdateStarted = true;
    while (this.coroutineUpdateStarted) {
      await this.loadData();
      if (
        Number(h2qAccount.data.m_h2qBalance) > 0 ||
        Number(h2qAccount.data.m_qstBalance) > 0
      ) {
        this.coroutineUpdateStarted = false;
      }
      await delay(5000);
    }
  }

  createAccount = task(
    async () => {
      if (!this.lastGeneratedSeed) throw Error("generate seed first");
      this.data = {};
      if (!this.pin) throw Error("Enter PIN code first");
      await this.init(this.lastGeneratedSeed);
      this.startCoroutineUpdate(); // no await
      console.log("account created");
    },
    { state: "resolved" }
  );

  loginToAccount = task(
    async (phrase: string) => {
      if (!phrase) throw Error("generate seed first");
      this.data = {};
      await this.init(phrase);
      this.startCoroutineUpdate(); // no await
      console.log("logged in");
    },
    { state: "resolved" }
  );

  logoutFromAccount() {
    this.resetActiveSession();

    localStorage.removeItem("cipher");
    // remove in a future ->
    localStorage.removeItem("currentSeed");
    localStorage.removeItem("currentAddress");
    // <- remove

    this.uiStore.clearUsersSettings();
    this.resetNftsInProgress();
    this.clearQuestsForHero();
    this.clearLogMessages();
  }

  async init(phrase: string) {
    await client;
    this.phrase = phrase;
    this.lastGeneratedSeed = "";
    try {
      await this.getAccount();
      /// ??
      if (this.nfts.length === 0) {
        this.fetchNTFs();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async wait_for(condition: () => boolean, maxTimeout = 30000, period = 1000) {
    for (let i = 0, attempts = (maxTimeout / period) | 0; i < attempts; i++) {
      if (condition()) {
        return;
      }
      await delay(period);
    }
  }

  async run<T, R>(params: {
    funcName: string;
    input: T;
    account?: Account;
    nonlocal?: boolean;
  }): Promise<R> {
    let account = params.account || this.contracts.h2qAccount;

    for (; !account;) {
      account = params.account || this.contracts.h2qAccount;
      await delay(1000);
    }

    const run = Boolean(params.nonlocal)
      ? account?.run.bind(account)
      : account?.runLocal.bind(account);

    // console.log(params.funcName, params.nonlocal);

    if (!run) {
      throw Error("Contract not intialized");
    }

    try {
      const r = (await run(params.funcName, params.input as object)) as any;
      const {
        decoded: { output },
      } = r;

      return (output?.value0 as R) || (output as R);
    } catch (e: any) {
      console.error(e);
      const exit_code = e?.data?.local_error?.data?.exit_code;
      if (exit_code) {
        console.log("============== ERROR ==============");

        const errorMap = {
          100: "uint16 constant INVALID_CALLER = 100;",
          101: "uint16 constant error_not_my_pubkey;",
          102: "uint16 constant error_not_external_message;",
          103: "uint16 constant error_not_my_account;",
          104: "uint16 constant error_not_my_generator;",
          105: "uint16 constant error_not_initialized;",
          106: "uint16 constant error_hero_has_no_value;",
          107: "uint16 constant error_ever_balance_too_low;",
          108: "uint16 constant error_h2q_balance_too_low;",
          109: "uint16 constant error_items_count_too_high;",
          110: "uint16 constant error_items_count_is_zero;",
          111: "uint16 constant error_hero_already_initialized;",
          112: "uint16 constant error_not_enough_inventories;",
          113: "uint16 constant error_not_authorized_generation;",
          114: "uint16 constant error_generator_is_busy;",
        };
        console.error((errorMap as any)[exit_code]);
      }

      throw e;
    }
  }

  resetActiveSession() {
    runInAction(() => {
      this.superRootBalance = null;
      this.h2qBalance = null;
      this.lastGeneratedSeed = "";
      this.contracts = {};
      this.data = {};
      this.raw_data = {};
      this.nfts = [];
      this.balance = null;
      this.currentHeroRewards = null;
      this.currentHeroStakeDuration = 0;
      this.hasRewardsToGet = false;
    })
  };

  setPhrase(phrase: string) {
    runInAction(() => {
      this.phrase = phrase;
    })
  };

  async getSigner() {
    const mnemonic = {
      phrase: this.phrase,
    };
    const masterKey = await client.crypto.mnemonic_derive_sign_keys(mnemonic);
    const signer = signerKeys(masterKey);
    return signer;
  }

  async getAccount() {
    await client;

    const mnemonic = {
      phrase: this.phrase,
    };

    console.log("mnemonic phrase length:", mnemonic.phrase.length);
    const masterKey = await client.crypto.mnemonic_derive_sign_keys(mnemonic);
    // console.log("master key", masterKey);

    const signer = signerKeys(masterKey);

    const superRoot = new Account(
      {
        abi: H2QSuperRootContract.abi,
      } as any,
      {
        client,
        signer,
        address: config.superRootAddress,
      }
    );

    console.log("run superRoot.deployH2QAccount");

    try {
      let address: string;

      if (!localStorage.getItem("cipher")) {
        const result = await this.run<
          { nonce: number; devmode: boolean },
          { h2qAccount: string }
        >({
          funcName: "deployH2QAccount",
          input: { nonce: this.nonce, devmode: true },
          account: superRoot,
          nonlocal: true,
        });
        address = result.h2qAccount;

        localStorage.removeItem("tempSeed");
        localStorage.setItem("cipher", encryptPhrase(this.phrase, complexPin(this.pin!.split(""))));
        localStorage.setItem("currentAddress", address);

        console.log("run superRoot.deployH2QAccount -= DONE =-", address);
      }
      else {
        address = localStorage.getItem("currentAddress") as string;
        console.log(
          "run superRoot.deployH2QAccount[cache] -= DONE =-",
          address
        );
      }

      const userData: UserLSData = localStorage.userData
        ? JSON.parse(localStorage.userData)
        : {};
      this.uiStore.setUserNickname(userData.nick ? userData.nick : address.slice(0, 15));

      // TODO: temp

      const h2qAccount = new Account(
        {
          abi: H2QAccountContract.abi,
        },
        {
          client,
          signer,
          address,
        }
      );

      await h2qAccount.subscribeMessages("boc", async (msg) => {
        try {
          const decoded = await h2qAccount.decodeMessage(msg.boc);
          runInAction(() => {
            this.logMessages.push(decoded);
            console.log(decoded);
          });

          return;
        } catch {
          for (let i = 0; i < contractsList.length; i++) {
            try {
              const decoded = await contractsList[i].decodeMessage(msg.boc);
              runInAction(() => {
                this.logMessages.push(decoded);
                console.log(decoded);
              });
              return;
            } catch (e) { }
          }
        }
      });

      runInAction(() => {
        this.phrase = "";
        this.pin = null;
        this.lastEnter = Date.now();
        this.contracts = {
          superRoot,
          h2qAccount,
        };
      });

      await this.loadData();
    } catch (e) {
      console.error(e);
    }
  }

  checkEvents() {
    for (let i = 0; i < this.logMessages.length; i++) {
      let event = this.logMessages[i];
      switch (event?.name) {
        case "HeroAlreadyDeployed":
          throw Error("HeroAlreadyDeployed");
      }
    }
  }

  loadData = task(
    async (...names: Array<keyof H2AccountData>) => {
      if (!this.contracts.h2qAccount) return;

      const accountRawData: RawH2AccountData = await getAccountData(
        this.contracts.h2qAccount
      );

      try {
        await this.getInventoryDetails();
      }
      catch (e) {
        console.error(e)
      }

      const newData = Object.entries(accountRawData)
        .map(([name, value]) => {
          return { name, value };
        })
        .reduce((result, item) => {
          switch (item.name) {
            case "m_inventories":
              if (item.value) {
                result[item.name] = item.value.map(parse256bytesTo64numbers);
              }
              break;
            case "m_avatar_amount":
              result[item.name] = parse256bytesTo64numbers(item.value);
              break;
            case "m_inventory_amount":
              result[item.name] = item.value.map((v: string) => Number(v));
              break;
            case "m_generatedHero":
              const value: RawGeneratedHero = item.value;
              if (item.value) {
                result[item.name] = {
                  h2q_accept_price: Number(0),
                  h2q_discard_price: Number(0),
                  avatar_id: Number(value.trueCharacter.avatar_id),
                  inventory: value.trueCharacter.inventory.map(Number) as any,
                  metaCharacter: value?.metaCharacter,
                  trueCharacter: value?.trueCharacter,
                };
              } else {
                result[item.name] = null;
              }
              break;
            case "m_nonce":
              result[item.name] = parseInt(item.value, 16);
              break;
            default:
              result[item.name as keyof H2AccountData] = item.value;
          }
          return result;
        }, {} as Partial<H2AccountData>);

      await this.getH2qBalance();
      await this.getSuperRootBalance();

      const doesNftAlreadyExist = !!(
        await this.isNFTexist(accountRawData?.m_generatedHero?.trueCharacter)
      )

      runInAction(() => {
        this.doesNftAlreadyExist = !!doesNftAlreadyExist;
        this.raw_data = { ...accountRawData };
        this.data = { ...newData };
        this.uiStore.filterItemsToShow();
        this.uiStore.filterAvatarsToShow();
      });

      return this.data;
    },
    { state: "resolved" }
  );

  raw_data: Partial<RawH2AccountData> = {};
  data: Partial<H2AccountData> = {};

  addInventories = task(
    async (params: AddInventoryParams) => {
      const input: AddInventoryParamsNew = {
        avatar_id: params.avatar_id,
        inv: [
          params.inv0,
          params.inv1,
          params.inv2,
          params.inv3,
          params.inv4,
          params.inv5,
          params.inv6,
        ],
      };

      await this.run({
        funcName: "addInventories",
        input,
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
      await this.loadData();
    },
    { state: "resolved" }
  );

  startGenerateCharacter = task(
    async (params: AddStartGenerateCharacter) => {
      await this.run({
        funcName: "startGenerateCharacter",
        input: params,
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
      for (let i = 0; i < 60; i++) {
        if (
          this.data.m_generatedHero !== null &&
          Number(this.data.m_generatedHero?.metaCharacter.valPrice) !== 0
        ) {
          await this.checkNFTexist()
          return;
        }
        await delay(1000);
        await this.loadData();
      }
    },
    { state: "resolved" }
  );

  acceptCharacter = task(
    async () => {
      if (!this.data.m_generatedHero)
        throw Error("Lets generate Hero `m_generatedHero` first");

      for (let i = 0; i < 60; i++) {
        if (!this.data.m_generatorBusy) {
          break;
        }
        await this.loadData();
        await delay(1000);
      }

      const hero = toJS(this.raw_data.m_generatedHero);
      if (hero) {
        hero.metaCharacter.name = "RDS" + ((Math.random() * 100) | 0);
      }

      const hero_hash = await this.run({
        funcName: 'getTrueCharacterHash',
        nonlocal: false,
        input: {
          hero,
        }
      })


      const { pseudoNFTAddr } = (await this.run({
        funcName: "acceptCharacter",
        input: {
          bCloneAccepted: false,
          hero_hash,
          name: hero?.metaCharacter?.name,
          imageHash: "0",
        },
        nonlocal: true,
        account: this.contracts.h2qAccount,
      })) as any;

      const m_generatedHero = this.raw_data.m_generatedHero?.trueCharacter;
      for (let i = 0; i < 60; i++) {
        if (this.data.m_generatedHero === null) {
          break;
        }
        await delay(1000);
        await this.loadData();
        this.checkEvents();
      }

      if (m_generatedHero) {
        await h2qDB.nfts.add({
          ...toJS(m_generatedHero),
          pseudoNFTAddr,
        });
      }
    },
    { state: "resolved" }
  );

  discardCharacter = task(
    async () => {
      if (!this.raw_data.m_generatedHero)
        throw Error("m_generatedHero is empty, nothing to discard");

      const hero = toJS(this.raw_data.m_generatedHero);
      const hero_hash = await this.run({
        funcName: 'getTrueCharacterHash',
        nonlocal: false,
        input: {
          hero,
        }
      })

      await this.run({
        funcName: "discardCharacter",
        input: { hero_hash },
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
      for (let i = 0; i < 60; i++) {
        if (this.data.m_generatedHero === null) {
          return;
        }
        await delay(1000);
        await this.loadData();
      }
    },
    { state: "resolved" }
  );

  resetAccount = task(
    async () => {
      await this.run({
        funcName: "resetAccount",
        input: {},
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
      await this.loadData();
    },
    { state: "resolved" }
  );

  nextGenerateCharacter = task(
    async (
      excluded:
        | [number]
        | [number, number]
        | [number, number, number]
        | number[]
    ) => {
      if (!this.raw_data.m_generatedHero)
        throw Error("use startGenerateCharacter first");

      const prevGeneratedHero = toJS(this.raw_data.m_generatedHero);

      const hero_hash = await this.run({
        funcName: 'getTrueCharacterHash',
        nonlocal: false,
        input: {
          hero: prevGeneratedHero,
        }
      });

      console.log(hero_hash);

      await this.run({
        funcName: "nextGenerateCharacter",
        input: {
          hero_hash,
          excluded,
        },
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
      for (let i = 0; i < 60; i++) {
        await this.loadData();
        if (
          JSON.stringify(toJS(this.data.m_generatedHero)) !==
          JSON.stringify(prevGeneratedHero)
        ) {
          await this.checkNFTexist()
          return;
        }
        await delay(1000);
      }
    },
    { state: "resolved" }
  );

  changeAvatar = task(
    async (params: ChangeAvatar) => {
      const currentAvId = this.data.m_generatedHero?.avatar_id;
      console.log("Starting to change avatar. Current avatar id:", currentAvId);

      await this.run({
        funcName: "changeAvatar",
        input: params,
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });

      for (let i = 0; i < 60; i++) {
        if (
          this.data.m_generatedHero !== null &&
          this.data.m_generatedHero?.avatar_id === params.av
        ) {
          await this.checkNFTexist()
          return;
        }
        await delay(1000);
        await this.loadData();
      }
    },
    { state: "resolved" }
  );

  mintH2Q = task(
    async (amount: number) => {
      await this.run({
        funcName: "mintH2Q",
        input: {
          amount,
        },
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });

      await this.loadData();
    },
    { state: "resolved" }
  );

  getPseudoNftCodeHash = task(
    async () => {

      if (!this.contracts.superRoot) {
        return null;
      }
      const { m_pseudoNftCode } = await getAccountData(this.contracts.superRoot)
      const deployer = await this.contracts.superRoot?.getAddress()
      const owner = await this.contracts.h2qAccount?.getAddress();
      return await this.run({
        funcName: "getOwnedPseudoNftCodeHash",
        input: {
          pseudoNftCode: m_pseudoNftCode,
          deployer,
          owner,
        },
        nonlocal: false,
        account: this.contracts.h2qAccount,
      });
    },
    { state: "resolved" }
  );

  loadNftsInProgress = async () => {
    const nftsInProgress = await h2qDB.nfts.toArray();
    runInAction(() => {
      this.nftsInProgress = nftsInProgress;
    });
  };

  resetNftsInProgress = async () => {
    await h2qDB.nfts.clear();
    runInAction(() => {
      this.nftsInProgress = [];
    });
  };

  cleanNftsInProgress = async (pseudoNFTAddrs: string[]) => {
    console.log("=== cleanNftsInProgress");
    const deletedCount = await h2qDB.nfts
      .where("pseudoNFTAddr")
      .anyOf(pseudoNFTAddrs)
      .delete();
    if (deletedCount > 0) {
      await this.loadNftsInProgress();
    }
  };

  // TODO: refactor
  fetchNTFs = task(
    async () => {
      await this.loadNftsInProgress();
      const codeHash: string = (await this.getPseudoNftCodeHash()) as string;

      const pseudoNFTaddresses = await fetchGraphQL(codeHash);
      // const pseudoNFTs = pseudoNFTaddresses.map((address) => {
      //   return new PseudoNFTContractObservable(address);
      // });

      const pseudoNFTsdata = await getAccountsData(pseudoNFTaddresses, abiContract(H2QPseudoNftContract.abi));

      const result = (
        await Promise.all(
          pseudoNFTsdata.map(async (pseudoNFTdata) => {
            // TODO: refactor

            const trueNFTData = new Account(DataContract, {
              client,
              address: (pseudoNFTdata as any)["m_trueNFTAddress"],
            });

            try {
              const trueNFTInfo = await this.run({
                funcName: "getInfo",
                nonlocal: false,
                account: trueNFTData,
                input: {},
              });

              return {
                pseudoNFTdata,
                trueNFTInfo,
                pseudoNFTAddr: pseudoNFTdata.id,
              };
            } catch (e) {
              return null;
            }
          })
        )
      ).filter((x) => x) as Array<NFTInfo>;

      const pseudoNFTAddrs = result.map((x) => {
        return x.pseudoNFTAddr;
      });

      await this.cleanNftsInProgress(pseudoNFTAddrs);

      runInAction(() => {
        this.nfts = result;
        this.uiStore.questFilter.stakedQuests = this.uiStore.getStakedQuests();
        this.uiStore.getNftFilterMaxParams();
        this.uiStore.filterNftsToShow();
      });

      return this.nfts;
    },
    { state: "pending" }
  );

  setInventoryValue = task(
    async () => {
      return await this.run({
        funcName: "setInventoryValue",
        input: {},
        nonlocal: true,
        account: this.contracts.h2qAccount,
      });
    },
    { state: "resolved" }
  );

  getInventoryAddress = task(
    // async (t: number, c: number) => {
    async () => {
      return (await this.run({
        funcName: "getInventoryAddress",
        // input: { c, t },
        input: {},
        account: this.contracts.h2qAccount,
      })) as string;
    },
    { state: "resolved" }
  );

  getInventoryDetails = task(
    // async (t: number, c: number) => {
    async () => {

      if (this.inventoryInfoFromBC) return;
      // const address: string = await this.getInventoryAddress(t, c);
      const address: string = await this.getInventoryAddress();

      const inventoryDetailsContract = new Account(H2QInventoryDetailsContract, {
        client,
        address,
      });

      const { m_details } = await this.run<
        {},
        { m_details: InventoryAllItemsData }
      >({
        funcName: "m_details",
        input: {},
        account: inventoryDetailsContract,
      });

      runInAction(() => {
        this.inventoryInfoFromBC = m_details;
      });
    },
    {
      state: "resolved",
    }
  );

  getQuestClientData = async (pseudoNft: string, quest: string): Promise<QuestClientData | null> => {
    const address = await this.run<{}, any>({
      funcName: "getQuestClientAddress",
      input: { pseudoNft, quest },
      account: h2qAccount.contracts.h2qAccount,
    });

    // console.log("getQuestClientAddress ->", address);

    const data = await getAccountsData(
      [address],
      abiContract(H2QuestClientContract.abi)
    );
    // console.log(data);

    if (data.length > 0) {
      return data[0];
    }
    else {
      return null;
    }
  };

  async deployQuestClient(pseudoNft: string, quest: string) {
    // console.log("deployQuestClient", pseudoNft, quest);

    try {
      const questClientData = await this.getQuestClientData(pseudoNft, quest);

      if (questClientData === null) {
        throw Error("dosnt exists");
      }
      return this.getQuestGetterByAddress(questClientData.id);
    }
    catch (e) {
      await this.run<{}, any>({
        funcName: "deployQuestClient",
        input: { pseudoNft, quest },
        nonlocal: true,
        account: h2qAccount.contracts.h2qAccount,
      });
      return await this.getQuestGetter(pseudoNft, quest);
    }
  }
  async getQuestGetter(pseudoNft: string, quest: string) {
    const address = await this.run<{}, any>({
      funcName: "getQuestClientAddress",
      input: { pseudoNft, quest },
      account: h2qAccount.contracts.h2qAccount,
    });

    // console.log("getQuestGetter:", pseudoNft, quest, "=>");
    return this.getQuestGetterByAddress(address);
  }

  async getQuestGetterByAddress(address: string) {
    const h2qQuestClientContract = new H2QuestClient(address);
    return await h2qQuestClientContract.questGetter();
  }

  getFullHeros(): Array<H2QFullCharacter> {
    return toJS(
      this.nfts.map((v) => {
        return {
          trueCharacter: v.pseudoNFTdata.m_h2qTrueCharacter,
          metaCharacter: v.pseudoNFTdata.m_h2qMetaCharacter,
        };
      })
    );
  }

  q2hero = task(async (nft: NFTInfo) => {
    console.log(
      "h2Quest",
      await this.run({
        funcName: "q2Hero",
        input: toJS({
          heroAddress: nft.pseudoNFTAddr,
        }),
        nonlocal: true,
      })
    );
    for (let i = 0; i < 3; i++) {
      await this.loadData();
      await delay(1000);
    }

    runInAction(() => {
      this.tempHeroesInQuest = this.tempHeroesInQuest.filter(item => item !== nft.pseudoNFTAddr);
      console.log("Remove nft from tempHeroInQuest array");
    });

  }, {
    state: "rejected"
  });

  hero2quest = task(async (nft: NFTInfo, quest: H2QuestResponse) => {
    const params = await this.getQuestParams(nft, quest);
    console.log(
      "h2Quest",
      await this.run({
        funcName: "h2Quest",
        input: toJS({
          heroMeta: toJS(nft.pseudoNFTdata.m_h2qMetaCharacter),
          heroAddress: nft.pseudoNFTAddr,
          questAddress: quest.id,
          qstamount: params.qstAmount,
        }),
        nonlocal: true,
      })
    );

    runInAction(() => {
      this.tempHeroesInQuest.push(nft.pseudoNFTAddr);
      console.log("Add nft to tempHeroInQuest array");
    });
  },
    {
      state: "resolved"
    });

  quests: H2QuestResponse[] = [];
  async addQuestAdmin(params: H2QuestParams, rewardParams: RewardParams) {

    const maxNonce = Number(this.quests.filter((q) => {
      return (
        Number(q.m_questParams.essentialParams.levelMin) === params.essentialParams.levelMin &&
        Number(q.m_questParams.essentialParams.levelMax) === params.essentialParams.levelMax
      )
    }).sort(
      (a, b) => (parseInt(b.m_nonce, 16) - parseInt(a.m_nonce, 16))
    )[0]?.m_nonce || 0)

    console.log(maxNonce, "FOUND <<>>")

    const initialQuestsLength = h2qAccount.quests.length;

    await addQuestAdmin(params, rewardParams, maxNonce);

    for (let i = 0; i < 10; i++) {
      await this.loadAllQuests();
      console.log(initialQuestsLength, "<<<<<");
      if (initialQuestsLength !== h2qAccount.quests.length) {
        return;
      }
      await delay(1000);
    }
  }

  loadAllQuests = task(
    async () => {
      while (!h2qAccount.contracts.superRoot) {
        await delay(1000);
      }
      const codeHash = await getCurrentQuestCodeHash(h2qAccount.contracts.superRoot);
      const quests: H2QuestResponse[] = await fetchByCodeHash(codeHash);
      runInAction(() => {
        this.quests = quests
          .filter(item => filterByFinishTime(item))
          .filter(item => {
            return item.m_questParams.mandatoryParams.name !== 'First' && Number(item.m_maxParticipants) > 0;
          })
          .sort((a, b) => {
            return (
              -(a.m_questParams.mandatoryParams.startTime | 0) +
              (b.m_questParams.mandatoryParams.startTime | 0)
            );
          });

        this.uiStore.questFilter.stakedQuests = this.uiStore.getStakedQuests();
        this.uiStore.filterQuestsToShow();
      });
    },
    { state: "resolved" }
  );

  questsForHero: QuestsForNft = {
    nftId: null,
    active: null,
    valid: [],
    invalid: []
  };

  filterQuestsByHero = task(
    async (nft: NFTInfo) => {
      /*
       * like an example: h2qAccount.nfts[0]
       * */
      // const hashes = await getAllCodeHash(nft.pseudoNFTdata.m_h2qTrueCharacter);
      // const quests: H2QuestResponse[] = (await findAllByCodeHashes(hashes))
      //   .filter(item => item.m_questParams.mandatoryParams.name !== 'First')
      //   .filter(q => filterByStartTime(q) && filterByFinishTime(q));

      let active = null as H2QuestResponse | null;
      const valid = [] as H2QuestResponse[];
      const invalid = [] as H2QuestResponse[];

      if (this.quests.length === 0 || this.loadAllQuests.state !== "pending") await this.loadAllQuests();

      const stakedQuestId = nft.pseudoNFTdata.m_lockedInQuest?.quest;
      const stakedQuestIndex = this.quests.findIndex(q => q.id === stakedQuestId);
      if (stakedQuestId && stakedQuestIndex !== -1) {
        active = this.quests[stakedQuestIndex];
      }

      const completedQuestsId = await this.getCompletedQuestsId(this.quests, nft.pseudoNFTAddr);
      const incompletedQuests = this.quests.filter(q => !completedQuestsId.includes(q.id));


      for (const q of incompletedQuests) {
        if (isQuestValidForNft(nft, q)) {
          valid.push(q);
          continue;
        }
        invalid.push(q);
      }

      runInAction(() => {
        this.questsForHero.nftId = nft.pseudoNFTAddr;
        this.questsForHero.active = active;
        this.questsForHero.valid = valid;
        this.questsForHero.invalid = invalid;
      })
    },
    { state: "resolved" }
  );

  clearQuestsForHero() {
    runInAction(() => {
      this.questsForHero.nftId = null;
      this.questsForHero.active = null;
      this.questsForHero.valid = [];
      this.questsForHero.invalid = [];
    })
  };

  async getCompletedQuestsId(quests: H2QuestResponse[], nftId: string) {
    const ids = (await Promise.all(quests.map(async q => {
      return await this.getQuestClientData(nftId, q.id);
    })))
      .filter(data => data && data.m_questFulfilled)
      .map(data => data?.m_h2questAddress);
    return ids;
  };

  async getQuestParams(
    nft: NFTInfo,
    quest: H2QuestResponse
  ): Promise<H2QuestParamsResult> {

    const now = Number(new Date());
    if (quest.m_questParams.mandatoryParams.finishTime * 1000 < now) {
      console.error("Quest has finishTime < now");
    }

    // console.log(`deployQuestClient ${nft.pseudoNFTAddr} ${quest.id}`)

    const questGetter: H2QuestGetter = await this.deployQuestClient(
      nft.pseudoNFTAddr,
      quest.id
    );

    // console.log(`questGetter.getQuestParams ${JSON.stringify(nft.pseudoNFTdata)}`)
    const { output } = (await questGetter.getQuestParams(
      nft.pseudoNFTdata
    )) as any;

    return output;
  }

  async getPseudoNFTAddress(ch: TrueCharacter): Promise<string> {
    return await this.run({
      funcName: "getPseudoNFTAddress",
      input: { ch: toJS(ch), deployer: config.superRootAddress }
    })
  }

  isNFTexist = async (trueCharacter?: TrueCharacter) => {
    if (!trueCharacter) return;

    const address = await this.getPseudoNFTAddress(trueCharacter);
    const { acc_type } = await new Account(PseudoNftContract, {
      client,
      address,
    }).getAccount()

    return (acc_type !== 3)

  }

  checkNFTexist = task(async () => {
    if (!h2qAccount?.data?.m_generatedHero) return;

    const doesNftAlreadyExist = Boolean(await this.isNFTexist(h2qAccount.data.m_generatedHero.trueCharacter))
    runInAction(() => {
      this.doesNftAlreadyExist = doesNftAlreadyExist
    })
  })

  // get expectedQuests() {
  //   return this.quests.filter(q => {
  //     return (q.m_questParams.mandatoryParams.startTime * 1000) > Number(new Date())
  //   })
  // }

  // get questsWithEverStaked() {
  //   return this.quests.filter(q => {
  //     return Number(q.m_totalClientsLocked) > 0;
  //   })
  // }

  async getQuestParamsAndClientData(nft: NFTInfo) {
    return (await Promise.all(this.quests.map(async (quest, index) => {
      if (nft.pseudoNFTdata.m_h2qMetaCharacter.level === "0") {
        return { questParams: null, questId: null, clientData: null, questRewardItems: [] }
      }
      const clientData = await this.getQuestClientData(nft.pseudoNFTAddr, quest.id);
      const questParams = clientData?.m_questFulfilled
        ? await this.getQuestParams(nft, quest)
        : null;
      const questRewardItems = clientData?.m_questFulfilled
        ? ItemHexMaskToInventoryItemArray(quest.m_inventoryReward)
        : [];

      return {
        questParams,
        questId: quest.id,
        clientData,
        questRewardItems
      };
    })))
  };

  calcRewards(rewards: QuestInfoAndData[]) {
    return rewards.reduce((acc, item) => {
      acc.completed += 1;
      acc.completedQuests = item.questId ? [...acc.completedQuests].concat(item.questId) : acc.completedQuests
      acc.duration += Number(item?.questParams?.duration)
      acc.qstReward += Number(item?.questParams?.reward?.qstReward)
      acc.qstAmount += Number(item?.questParams?.qstAmount)
      acc.h2qReward += Number(item?.questParams?.reward?.h2qReward)

      // delete the last element from array of rewards - it is an avatar id. add avatar rewards in a future
      const itemsWithoutAvatar = [...item.questRewardItems];
      itemsWithoutAvatar.pop();
      itemsWithoutAvatar.forEach((itemId, catId) => {
        if (itemId > 0) {
          acc.itemsRewards[catId][itemId] += 1;
        }
      });

      acc.itemsCount = acc.itemsRewards.flat().filter((item) => item).length;

      return acc;
    },
      {
        completed: 0,
        completedQuests: [] as string[],
        duration: 0,
        qstAmount: 0,
        qstReward: 0,
        h2qReward: 0,
        itemsRewards: new Array(7).fill(null).map(() => new Array(64).fill(0)),
        itemsCount: 0
      });
  };

  calculateQuestsStats = task(async () => {
    if (this.loadAllQuests.state !== "pending") {
      await this.loadAllQuests();
    }
    const result: QuestInfoAndData[] = (await Promise.all(this.nfts.map(async (nft, index) => {

      // it does not calculate stats for nft if it is staken in a quest, but it might have completed other quests in a past
      // if (!nft.pseudoNFTdata?.m_lockedInQuest) return null;
      return index === 0 ? (await this.getQuestParamsAndClientData(nft)) : []
        .filter(({ questParams }) => questParams)
    })))
      .filter(r => r !== null).flat()

    return this.calcRewards(result)

  }, {
    state: "pending"
  })

  currentHeroStakeDuration = 0;
  setCurrentHeroStakeDuration = (duration: number) => {
    runInAction(() => {
      this.currentHeroStakeDuration = duration;
    })
  };

  currentHeroRewards = {} as AsyncReturnType<typeof this.calculateHeroQuestsRewards> & { nftId: string } | null;
  calculateHeroQuestsRewards = task(async (nftId: string) => {
    console.count("calculateHeroQuestsRewards");
    if (this.quests.length === 0 && h2qAccount.loadAllQuests.state !== "pending") {
      await this.loadAllQuests();
    }
    const nft = this.nfts.find(nft => nft.pseudoNFTAddr === nftId);

    if (!nft) {
      console.log("Nft does not exist");
      return;
    };

    const rewards = (await this.getQuestParamsAndClientData(nft))
      .filter(({ questParams }) => questParams)
      .filter(r => r !== null);

    const result = this.calcRewards(rewards);
    runInAction(() => {
      this.currentHeroRewards = { ...result, nftId: nft.pseudoNFTAddr };
    });

    return result
  },
    { state: "pending" }
  )

  /// all nfts
  totalNfts: PseudoNFTData[] = [];
  async getTotalNfts() {
    console.log("Get total nfts...");

    const r = await this.contracts.h2qAccount?.runLocal('getInitPseudoNftCodeHash', {
      deployer: this.raw_data.m_nftDeployer,
      platformCode: this.raw_data.m_pseudoNftPlatformCode,
    })

    const initCodeHash = r?.decoded?.output?.value0

    if (initCodeHash) {
      const allNfts: PseudoNFTData[] = await fetchByInitCodeHash(initCodeHash);
      runInAction(() => {
        this.totalNfts = [...allNfts];
      })
    }
  }
}