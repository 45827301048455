import { Button, UnstakeButton } from 'shared/ui';
import { RotatingCircle } from "shared/ui";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useState } from "react";
import styles from "./StakeHeroBlockForOneNft.module.scss";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { NFTInfo } from "entities/nft";
import { delay, getTimeFromMilliSeconds, timeToDdHhMmSs } from "shared/lib";
import { Timer } from "entities/Timer";

interface IStakeHeroBlockForOneNft {
  nftToSend: NFTInfo;
  selectedQuests: H2QuestResponse | null;
  clearSelectedQuest: () => void;
  isWaiting: boolean;
  setIsWaiting: (status: boolean) => void;
};

export const StakeHeroBlockForOneNft = observer(function ({ selectedQuests, nftToSend, clearSelectedQuest, isWaiting, setIsWaiting }: IStakeHeroBlockForOneNft) {
  const { h2qAccount } = useContext(H2QContext);
  const [isCompleted, setCompleted] = useState<boolean>(false);

  const finishTime = selectedQuests
    ? (selectedQuests.m_questParams.mandatoryParams.finishTime) * 1000
    : 0;

  const isExpired = Date.now() > finishTime;

  const sendHeroToQuest = async () => {
    if (isExpired || selectedQuests === null) return;

    setIsWaiting(true);
    try {
      await h2qAccount.hero2quest(nftToSend, selectedQuests);
      h2qAccount.setCurrentHeroStakeDuration(0);
      console.log("Nft was succesfully sent to selected quest");
      await delay(3000);

      do {
        await h2qAccount.fetchNTFs();
        console.log("Whaiting for nft to be updated, 2000ms");
        await delay(2000);
      } while (
        h2qAccount.nfts.find(n => n.pseudoNFTAddr === nftToSend.pseudoNFTAddr)?.pseudoNFTdata.m_lockedInQuest === null
      );
      console.log("Nft has been updated");
    }
    catch (error) {
      console.warn("Error while sending nfts to quest");
      console.warn(error);
    }
    finally {
      h2qAccount.loadData();
      clearSelectedQuest();
      setIsWaiting(false);
    }
  };

  const unstakeHero = async () => {
    console.log("Unstaking Hero and getting quest reward...");
    setIsWaiting(true);
    try {
      await h2qAccount.q2hero(nftToSend);
      await delay(3000);
      do {
        await h2qAccount.fetchNTFs();
        console.log("Waiting for nft to be updated, 2000ms");
        await delay(2000);
      }
      while (
        h2qAccount.nfts.find(n => n.pseudoNFTAddr === nftToSend.pseudoNFTAddr)?.pseudoNFTdata.m_lockedInQuest !== null
      )
      console.log("Got reward");
    }
    catch (error) {
      console.warn("Error while unstaking nfts from quest");
      console.warn(error);
    }
    finally {
      h2qAccount.loadData();
      setIsWaiting(false);
    }
  };

  const completeQuest = () => {
    if (nftToSend.pseudoNFTdata.m_lockedInQuest && nftToSend.pseudoNFTdata.m_lockedInQuest?.finishQuest * 1000 < Date.now()) {
      setCompleted(prev => true);
      h2qAccount.toggleRewardsToGet(true);
    }
  };

  const playBtnText = (<p className={styles["btn-style"]}>
    {selectedQuests
      ? isExpired
        ? "EXPIRED"
        : "PLAY"
      : "CHOOSE"
    }
  </p>);

  return (
    <>
      {!nftToSend.pseudoNFTdata.m_lockedInQuest && !isWaiting && h2qAccount.currentHeroStakeDuration > 0 &&
        <div className={styles["stake-time-block"]}>{timeToDdHhMmSs(getTimeFromMilliSeconds(h2qAccount.currentHeroStakeDuration))}</div>
      }
      <div className={styles["send-hero-block"]}>
        {nftToSend.pseudoNFTdata.m_lockedInQuest && !isCompleted &&
          <>
            <p className={styles["send-hero-text"]} >Hero will be available in:</p>
            <Timer
              finishTime={nftToSend.pseudoNFTdata.m_lockedInQuest.finishQuest * 1000}
              messageType={"heroInCurrentQuest"}
              onFinishAction={completeQuest}
              style={{ "padding": "1.6rem 3.2rem", "backgroundColor": "#090916", "borderRadius": "0.3rem" }}
            />
          </>
        }
        {nftToSend.pseudoNFTdata.m_lockedInQuest && isCompleted &&
          <>
            <p className={styles["send-hero-text"]} >Quest completed</p>
            <UnstakeButton
              action={unstakeHero}
              isWaiting={isWaiting}
              style={{ "width": "12rem", "height": "3.5rem", "margin": "0 auto" }}
            />
          </>
        }
        {!nftToSend.pseudoNFTdata.m_lockedInQuest && isWaiting &&
          <>
            <p className={styles["send-hero-text"]} >Processing...</p>
            <div className={styles.btn}>
              <RotatingCircle size={30} />
            </div>
          </>
        }
        {!nftToSend.pseudoNFTdata.m_lockedInQuest && !isWaiting &&
          <>
            <p className={styles["send-hero-text"]} >Quests selected</p>
            <p className={styles["hero-amount"]}>{selectedQuests ? 1 : 0}</p>
            <div className={styles.btn}>
              <Button
                children={playBtnText}
                isActive={selectedQuests !== null && !isExpired}
                colorValue={"blue"}
                widthValue={"full"}
                action={sendHeroToQuest}
              />
            </div>
          </>
        }
      </div>
    </>
  )
});