import { UserAccPageHeader } from "entities/UserAccPageHeader";
import styles from "./SupportPage.module.scss";
import { ReactComponent as IconArrowDown } from "app/icons/icon-arrow-down.svg";
import { ReactComponent as IconAttachment } from "app/icons/icon-attachment.svg";
import { ReactComponent as IconSupport } from "app/icons/Title_SUPPORT_white.svg";
import { ReactComponent as IconClose } from "app/icons/icon-cancel-white.svg";
import { FC, PropsWithChildren, SyntheticEvent, useRef, useState } from 'react'
import { helpThemes } from "./helpThemes";
import { Button } from "shared/ui/Button";
import { CheckBox } from "shared/ui";

function SendFormChild() {
  const inputFiles = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>("");
  const [accAddress, setAccAddress] = useState<string>(() => {
    let address = "";
    if (localStorage.getItem("currentAddress")) {
      address = localStorage.getItem("currentAddress")!;
    }
    return address;
  });
  const [description, setDescription] = useState<string>("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isDragOver, setDragOver] = useState<boolean>(false);
  const [isListOpened, toggleListOpened] = useState<boolean>(false);
  const [helpTheme, setHelpTheme] = useState<string>(() => helpThemes[0]);

  const selectHelpTheme = (theme: string) => {
    setHelpTheme(prev => theme);
    toggleListOpened(false);
  };

  const clickOnInputFile = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (inputFiles.current) {
      const input = inputFiles.current;
      input.click();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
  };

  const handleDopFiles = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("drop");

    let newUserFiles = attachments;

    if (event.dataTransfer && event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        newUserFiles = [...newUserFiles, files[i]];
        console.log(files[i]);
      }
      setAttachments(newUserFiles);
    }
    setDragOver(false);
  }

  const addFiles = (): void => {
    let newUserFiles = attachments;
    if (inputFiles.current) {
      const input = inputFiles.current;
      const files = input.files;

      for (let i = 0; i < files!.length; i++) {
        newUserFiles = [...newUserFiles, files![i]]
      }
      setAttachments(newUserFiles);
    }
  };

  const removeFile = (file: File) => {
    const newAttachements = [...attachments].filter(item => item !== file);
    setAttachments(prev => newAttachements);
  };

  const submitForm = () => {

  };

  return (
    <div className={styles["form-wrapper"]}>
      <h3>Can't find  the answers you are looking for?</h3>
      <h3>You can submit a request here</h3>

      <fieldset className={styles.form}>

        <label>Your Email address<span>*</span></label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

        <label>Your Account address<span>*</span></label>
        <input type="text" value={accAddress} onChange={(e) => setAccAddress(e.target.value)} />

        <label>What can we help with?<span>*</span></label>
        <div className={styles["selector-wrapper"]}>
          <div className={styles.selected} onClick={() => toggleListOpened(prev => !prev)}>
            {helpTheme}
            <IconArrowDown className={`${styles["arrow-small"]} ${isListOpened ? styles.down : ""}`} />
          </div>
          <div className={`${styles.list} ${isListOpened ? styles["opened-list"] : ""}`}>{helpThemes.map(theme => {
            return (
              <div key={theme} className={styles["list-element"]}
                onClick={() => selectHelpTheme(theme)}
              >
                {theme}
              </div>
            )
          })}</div>
        </div>

        <label>Description<span>*</span></label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        <p>In order to best help, plese enter as many details as you can</p>

        <label>Attachments (optional)</label>

        <div
          className={`${styles["attach-wrapper"]} ${isDragOver ? styles.drag : ""}`}
          onDragOver={(event) => handleDragOver(event)}
          onDragLeave={(event) => handleDragLeave(event)}
          onDrop={(event) => handleDopFiles(event)}
        >
          <input
            type="file"
            id={styles["input-files"]}
            ref={inputFiles}
            multiple
            accept="image/png, image/jpg, image/jpeg"
            onChange={addFiles}
          />
          <span onClick={clickOnInputFile}>Add File </span>or drop files here
        </div>
        {attachments.length > 0 && attachments.map((file, index) => {
          return (
            <div key={index} className={styles["attached-item"]}>
              <IconAttachment className={styles.file} />
              <div>{file.name}</div>
              <IconClose className={styles.delete} onClick={() => removeFile(file)} />
            </div>
          )
        })}
      </fieldset>
      <div className={styles["btn-block"]}>
        <Button
          children={<p style={{ fontSize: "1.5rem", margin: 0 }}>Submit</p>}
          widthValue="full"
          isActive={email !== "" && accAddress !== "" && helpTheme !== helpThemes[0] && description !== ""}
          action={submitForm}
        />
      </div>
    </div>
  )
};

const CompromisedAccountHelp = () => {
  const [isCheched, setIsChecked] = useState<boolean>(false);

  const submitForm = () => {
  };

  return (
    <div className={styles["form-wrapper"]}>
      <h3>If you believe your account has been compromised, let us know and we can lock your account.</h3>
      <div className={styles["checkbox-block"]}>
        <CheckBox
          isChecked={isCheched}
          action={() => setIsChecked(prev => !prev)}
          size={20}
        />
        <label>I agree that I will not be able to unblock my account on my own</label>
      </div>
      <div className={styles["btn-block"]}>
        <Button
          children={<p style={{ fontSize: "1.5rem", margin: 0 }}>Lock Account</p>}
          widthValue="full"
          colorValue="red"
          isActive={isCheched}
          action={submitForm}
        />
      </div>

    </div>
  )
};

interface ISupportBlock {
  title: string;
};

const SupportBlock: FC<PropsWithChildren<ISupportBlock>> = ({ title, children }) => {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  return (
    <div className={styles["support-block"]}>
      <div className={styles["icon-title-wrapper"]}
        onClick={() => toggleOpen(prev => !prev)}
      >
        <div className={styles["icon-wrapper"]}>
          <IconArrowDown
            className={`${styles.arrow} ${isOpen ? styles.down : ""}`}
          />
        </div>
        <div className={styles["title-wrapper"]}>
          {title}
        </div>
      </div>
      <div className={`${styles["child-wrapper"]} ${isOpen ? styles.open : ""}`}>
        {children}
      </div>
    </div>
  )
}

export default function SupportPage() {
  return (
    <div className={styles["page-wrapper"]}>
      <UserAccPageHeader>
        <IconSupport />
      </UserAccPageHeader>

      <div className={styles["info-block"]}>
        <h3>If you need help related to your account, we can help you</h3>
        <SupportBlock
          title="Contact Hero2Quest Support"
          children={SendFormChild()}
        />

        <SupportBlock
          title="Help with a compromised account"
          children={CompromisedAccountHelp()}
        />
      </div>
    </div>
  )
}