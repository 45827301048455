import { H2QContext } from "app/core/H2QContext";
import { FilterButton } from "shared/ui/FilterButton";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterItemRarity.module.scss";
import { rarity } from "entities/item";

export const FilterItemRarity = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const onClickRarities = (item: string) => {
    h2qAccount.uiStore.changeItemFilterRarity(item);
  };

  return (
    <div className={styles["items-type"]}>
      {rarity.map(p => {
        return (
          <FilterButton
            key={p}
            name={p}
            clickAction={() => onClickRarities(p)}
            isSelected={h2qAccount.uiStore.itemFilter.itemRarity.includes(p)}
          />
        )
      })}
    </div>
  )
})