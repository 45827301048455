import { h2qAccount } from "app/core/H2QContext"
import { avatarsList } from "entities/avatar";
import { NftElement } from "entities/nft";

const AVATAR_ANIMAL = "Animal";
const AVATAR_HUMAN = "Human";
const AVATAR_OTHER = "Other";

export type KeyTagType = {
  text: string;
  color: string;
};

export const getTagColor = (id: number, inventoryKindId: number = 6): string => {
  const startColor = 0xA01010;
  const endColor = 0xEEEEEE;
  const maxColors = 64 * (inventoryKindId + 1);

  const colorGap = Math.round((endColor - startColor) / maxColors);

  const colorValue = (startColor + id * (inventoryKindId + 1) * colorGap).toString(16);
  return `#${colorValue}`
};

export const getAvatarTypeTag = (avatarId: number): KeyTagType => {
  const result: KeyTagType = {
    text: AVATAR_OTHER,
    color: getTagColor(0)
  };
  if (avatarId && avatarId < 16) {
    result.text = AVATAR_ANIMAL
  }
  if (avatarId && avatarId >= 16 && avatarId < 32) {
    result.text = AVATAR_HUMAN
  }
  return result
};

export const getAvatarNameTag = (avatarId: number): KeyTagType => {
  const avatar = avatarsList.find(avatar => avatar.itemId === avatarId)!;

  const avatarName = avatar.avatarName;
  const color = getTagColor(avatarId);
  return {
    text: avatarName,
    color
  }
};

export const getItemKeyTags = (items: string[]): KeyTagType[] => {
  const tags = [] as KeyTagType[];

  const nftItems = Array.from(new Set(items));
  if (nftItems && nftItems.length) {
    nftItems.forEach(item => {
      if (h2qAccount.inventoryInfoFromBC && h2qAccount.inventoryInfoFromBC[Number(item)]) {
        const newTag: KeyTagType = {
          text: h2qAccount.inventoryInfoFromBC[Number(item)].originalHero,
          color: getTagColor(Number(item))
        }
        tags.push(newTag)
      }
    })
  };

  return tags;
};

export const getNftKeyTags = (nft: NftElement): KeyTagType[] => {
  const tags = [] as KeyTagType[];

  const avatarTypeTag = getAvatarTypeTag(nft.avatar.itemId);
  tags.push(avatarTypeTag);

  const avatarNameTag = getAvatarNameTag(nft.avatar.itemId);
  tags.push(avatarNameTag);

  if (nft.pseudoNftData?.m_h2qTrueCharacter.inventory) {
    const itemTags = getItemKeyTags(nft.pseudoNftData?.m_h2qTrueCharacter.inventory);
    return tags.concat(itemTags);
  }

  return tags;
}