import { GeneratorSelectorItem } from "entities/generatorSelector";
import { ReactComponent as AddGeneratorIcon } from "app/icons/icon-add-generator-white.svg";
import { Link } from "react-router-dom";
import styles from "./GeneratorGalleryItemAddNew.module.scss";
import { TypeformAddNewGenerator } from "../TypeformAddNewGenerator/TypeformAddNewGenerator";
import { GeneratorItemButtons } from "../GeneratorItemButtons/GeneratorItemButtons";
import { FlipWrapper } from "entities/FlipWrapper";

export const GeneratorGalleryItemAddNew = () => {
  return (
    <GeneratorSelectorItem
      topChild={(
        <FlipWrapper
          flipped={false}
          frontChild={
            <Link className={styles["generator-link"]} to="" onClick={() => { }}>
              <div className={`${styles["add-generator"]}`}>
                <TypeformAddNewGenerator>
                  <AddGeneratorIcon
                    width={120}
                    height={120}
                  />
                </TypeformAddNewGenerator>
              </div>
            </Link>
          }
          backChild={<></>}
          ratio={740 / 600}
        />
      )}
      bottomChild={(
        <GeneratorItemButtons
          genId={-1}
          title={"Your Brand Name"}
        />
      )
      }
    />
  )
};