import { FC, PropsWithChildren, useState } from "react"
import { ReactComponent as IconArrow } from "app/icons/icon-arrow-down.svg";
import styles from "./FilterFoldableBlockWithTitle.module.scss";

interface IFilterFoldableBlockWithTitle {
  title: string;
  // activeItem: number;
};

export const FilterFoldableBlockWithTitle: FC<PropsWithChildren<IFilterFoldableBlockWithTitle>> = ({ children, title }) => {
  const [isFold, setIsFold] = useState<boolean>(false);

  return (
    <div className={styles["block-wrapper"]}>
      <div className={styles.head} onClick={() => setIsFold(prev => !prev)}>
        <div className={styles.title}>{title}</div>
        <div><IconArrow className={`${styles.arrow} ${isFold ? "" : styles.down}`} /></div>
        {/* {activeItem > 0 &&
          <span>{activeItem}</span>
        } */}
      </div>
      <div className={`${styles.body} ${isFold ? "" : styles.open}`}>
        {children}
      </div>
    </div>
  )
}