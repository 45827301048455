import React from 'react'
import styles from "./Button.module.scss";

const btnGreenColorLeft = "#00c899";
const btnGreenColorRight = "#00bdc5";
const btnRedColorLeft = "#ff0064";
const btnRedColorRight = "#ff6464";
const btnDarkGreyColorLeft = "#202435";
const btnDarkGreyColorRight = "#202435";
const btnBlueColorLeft = "#3F5491";
const btnBlueColorRight = "#4367A6";
const btnDisabledColor = "#575962";
const btnGoldColorLeft = "#fec771";
const btnGoldColorRight = "#fed671";
const btnVioletColorLeft = "#3D00C7";
const btnVioletColorRight = "#6E23B7";

interface IButton {
  children: React.ReactNode;
  colorValue?: "green" | "red" | "darkGrey" | "blue" | "gold" | "violet";
  widthValue?: "content" | "small" | "medium" | "full" | number;
  isActive?: boolean;
  action?: () => void;
}

export const Button = ({
  colorValue = "green",
  widthValue = "content",
  isActive = true,
  children,
  action
}: IButton) => {

  const setWidthValue = (): string => {
    let result = "fit-content";
    if (widthValue === "full") result = "100%";
    else if (widthValue === "medium") result = "75%";
    else if (widthValue === "small") result = "35%";
    else {
      result = widthValue + "rem";
    }
    return result
  }
  const widthVal = setWidthValue();

  const setBackgroundColor = (): string => {
    let leftColor = btnGreenColorLeft;
    let rightColor = btnGreenColorRight;

    if (colorValue === "darkGrey") {
      leftColor = btnDarkGreyColorLeft;
      rightColor = btnDarkGreyColorRight;
    }
    if (colorValue === "red") {
      leftColor = btnRedColorLeft;
      rightColor = btnRedColorRight;
    }
    if (colorValue === 'blue') {
      leftColor = btnBlueColorLeft;
      rightColor = btnBlueColorRight;
    }
    if (colorValue === 'gold') {
      leftColor = btnGoldColorLeft;
      rightColor = btnGoldColorRight;
    }
    if (colorValue === 'violet') {
      leftColor = btnVioletColorLeft;
      rightColor = btnVioletColorRight;
    }
    if (!isActive) {
      leftColor = btnDisabledColor;
      rightColor = btnDisabledColor;
    }

    const result = `linear-gradient(to right, ${leftColor}, ${rightColor})`;
    return result
  }

  const btnClickAction = () => {
    if (isActive && action) {
      action();
    }
  }

  return (
    <button
      className={styles.container}
      disabled={!isActive}
      style={{ "width": widthVal }}
      onClick={btnClickAction}
    >
      <div
        className={styles.back}
        style={{ "backgroundImage": setBackgroundColor() }}
      >
      </div>

      <div
        className={`${styles['front-and-text-wrapper']} ${isActive ? styles["front-and-text-active"] : ""}`}
      >
        <div
          className={styles.front}
          style={{ "backgroundImage": setBackgroundColor() }}
        >
        </div>

        <div
          className={styles['text-bg']}
          style={{ "backgroundImage": setBackgroundColor() }}>
        </div>

        <div
          className={styles.text}>
          {children}
        </div>
      </div>
    </button >
  )
}
