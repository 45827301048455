import styles from "./ChangeOptionsButton.module.scss";

interface IChangeOptionsButton {
  action: () => void;
  title: string;
  isSelected: boolean;
};

export const ChangeOptionsButton = ({ action, title, isSelected }: IChangeOptionsButton) => {
  return (
    <button onClick={action} className={`${styles["options-btn"]} ${isSelected ? styles.selected : ""}`}>
      {title}
    </button>
  )
}