import { CheckBox } from "shared/ui";
import styles from "./UserNotificationSelector.module.scss";

interface IUserNotificationSelector {
  title: string;
  description: string;
  isChecked: boolean;
  action: () => void;
};

export function UserNotificationSelector({ title, description, action, isChecked }: IUserNotificationSelector) {
  return (
    <div className={styles["notification-wrapper"]}>
      <div className={styles["tick-wrapper"]}>
        <CheckBox
          isChecked={isChecked}
          action={action}
        />
      </div>
      <div className={styles["description-wrapper"]}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}
