import styles from "./ShowStakedButton.module.scss";
import { ReactComponent as IconStaked } from "app/icons/icon-nft-hero-black.svg";
import { IconColorVariants } from "app/icons/icons";

interface IShowStakedButton {
  action: () => void;
  isActive: boolean;
  isSelected: boolean;
  size: number;
};

export const ShowStakedButton = ({ action, isActive, isSelected, size }: IShowStakedButton) => {
  const iconColor = isSelected
    ? IconColorVariants.yellow
    : IconColorVariants.gray;

  return (
    <button
      style={{ width: `${size / 10}rem`, height: `${size / 10}rem` }}
      disabled={!isActive}
      className={styles["stake-btn"]}
    >
      <IconStaked
        fill={iconColor}
        stroke={iconColor}
        className={styles["btn-image"]}
        onClick={action}
      />
    </button>
  )
}