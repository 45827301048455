import { FilterFoldableBlockWithTitle } from "entities/FilterFoldableBlockWithTitle";
import { FilterAvatarGeneratorType } from "features/avatarFilter";
import { FilterAvatarName } from "features/avatarFilter";

export const AvatarFilter = () => {
  return (
    <>
      <FilterFoldableBlockWithTitle
        title="Generator Nft"
      >
        <FilterAvatarGeneratorType />
      </FilterFoldableBlockWithTitle>

      <FilterFoldableBlockWithTitle
        title="Avatar Name"
      >
        <FilterAvatarName />
      </FilterFoldableBlockWithTitle>
    </>
  )
};