import { ReactComponent as iconHeroCreator } from "app/icons/icon-hero-creator-black.svg";
import { ReactComponent as iconCollections } from "app/icons/icon-collections-black.svg";
import { ReactComponent as iconNftHero } from "app/icons/icon-nft-hero-black.svg";
import { ReactComponent as iconQuestNetwork } from "app/icons/icon-quest-network-black.svg";
import { ReactComponent as iconQuestExpected } from "app/icons/icon-expected-quests-black.svg";
import { ReactComponent as iconQuestManager } from "app/icons/icon-quest-manager.svg";
import { ReactComponent as iconHelp } from "app/icons/icon-question-blue.svg";
import { ReactComponent as iconInfo } from "app/icons/icon-info-blue.svg";
import { ReactComponent as iconAccSupport } from "app/icons/icon-account-support-black.svg";
import { ReactComponent as iconUserProfile } from "app/icons/icon-profile-user-black.svg";
import { ReactComponent as iconCryptoAcc } from "app/icons/icon-cryptoacc-blue.svg";
import { ReactComponent as iconGameStat } from "app/icons/icon-game-statistics-blue.svg";
import { ReactComponent as iconNotification } from "app/icons/icon-notification-blue.svg";
import { ReactComponent as iconSettings } from "app/icons/icon-settings-black.svg";
import { ReactComponent as iconLogout } from "app/icons/icon-logout-red.svg";
import { LinkColor } from "app/icons/icons";

export interface ISideBarBlock {
  isOpen: boolean;
  isLoggedIn: boolean;
};

export type sidebarElement = {
  id: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  link: string;
  isActiveForGuest: boolean;
  isDisabled: boolean;
  isSmall?: boolean;
  isBig?: boolean;
  isExternal?: boolean;
};

const h2qPathPrefixUrl = "/h2q/";

export const sidebarMainData: sidebarElement[] = [
  {
    id: 0,
    icon: iconHeroCreator,
    text: "Creator",
    link: h2qPathPrefixUrl + "generator",
    isActiveForGuest: true,
    isDisabled: false,
  },
  {
    id: 1,
    icon: iconCollections,
    text: "Assets",
    link: h2qPathPrefixUrl + "collections",
    isActiveForGuest: false,
    isDisabled: false,
  },
  {
    id: 2,
    icon: iconNftHero,
    text: "Hero",
    link: h2qPathPrefixUrl + "nft",
    isActiveForGuest: false,
    isDisabled: false,
  },
  {
    id: 3,
    icon: iconQuestNetwork,
    text: "Quest",
    link: h2qPathPrefixUrl + "quests",
    isActiveForGuest: false,
    isDisabled: false,
    isBig: true
  },
  {
    id: 4,
    icon: iconQuestExpected,
    text: "Expected",
    link: h2qPathPrefixUrl + "expected-quests",
    isActiveForGuest: false,
    isDisabled: false,
  },
  {
    id: 5,
    icon: iconQuestManager,
    text: "God Mode",
    link: h2qPathPrefixUrl + "admin",
    isActiveForGuest: false,
    isDisabled: false,
  },
];

export const sidebarHelpData: sidebarElement[] = [
  {
    id: 6,
    icon: iconInfo,
    text: "About",
    link: "/guide",
    isActiveForGuest: true,
    isDisabled: false,
    isSmall: true,
  },
  {
    id: 7,
    icon: iconHelp,
    text: "Manual",
    link: "https://hero2quest.gitbook.io/user-guide/",
    isActiveForGuest: true,
    isDisabled: false,
    isSmall: true,
    isExternal: true,
  },
  {
    id: 8,
    icon: iconAccSupport,
    text: "Support",
    link: "/support",
    isActiveForGuest: true,
    isDisabled: false,
    isSmall: true,
  },
];

export enum UserBarTitles {
  support = "Support",
  profile = "Profile",
  crypto = "My Crypto",
  statistics = "Statistics",
  notification = "Notification",
  settings = "Settings",
  logout = "Logout"
};

export type UserBarTitlesKeys = keyof typeof UserBarTitles;

export type UserSidebarElement = {
  id: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: UserBarTitles;
  link: string;
  onlyColor?: LinkColor;
  noFillIcon?: boolean;
};

const h2qUserPathPrefixUrl = "h2q/user/";

export const userSidebarData: UserSidebarElement[] = [
  {
    id: 10,
    icon: iconUserProfile,
    text: UserBarTitles.profile,
    link: h2qUserPathPrefixUrl + "profile-details"
  },
  {
    id: 11,
    icon: iconCryptoAcc,
    text: UserBarTitles.crypto,
    link: h2qUserPathPrefixUrl + "crypto-acc"
  },
  {
    id: 12,
    icon: iconGameStat,
    text: UserBarTitles.statistics,
    link: h2qUserPathPrefixUrl + "game-stat"
  },
  {
    id: 13,
    icon: iconNotification,
    text: UserBarTitles.notification,
    link: h2qUserPathPrefixUrl + "notifications"
  },
  {
    id: 14,
    icon: iconSettings,
    text: UserBarTitles.settings,
    link: h2qUserPathPrefixUrl + "settings",
    noFillIcon: true
  },
  {
    id: 15,
    icon: iconLogout,
    text: UserBarTitles.logout,
    link: h2qUserPathPrefixUrl + "settings",
    onlyColor: "red",
  },
];