import { Button } from 'shared/ui'
import { modalButtonRejectTextVariants, ModalTypes } from 'entities/generator'
import styles from "./ModalCancelButton.module.scss";

interface IModalCancelButton {
  type: ModalTypes;
  reject: () => void;
};

export const ModalCancelButton = ({ reject, type }: IModalCancelButton) => {
  return (
    <div className={styles['modal-one-button']}>
      <Button children={modalButtonRejectTextVariants[type]} action={reject} widthValue={"medium"} colorValue={"red"} />
    </div>
  )
};