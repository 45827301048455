import styles from "./AddressString.module.scss";
import { ReactComponent as CopyPasteIcon } from "app/icons/icon-copy-black.svg";
import { useState } from "react";
import { delay } from "shared/lib";

enum BgColors {
  transparent = "transparent",
  darker = "#11101E",
  dark = "#171827",
};

enum ValueColors {
  white = "#dadada",
  grey = "#4c4a52"
};

export type BgKey = keyof typeof BgColors;
export type ValColorKey = keyof typeof ValueColors;

interface IAddressString {
  value: string;
  valueColor?: ValColorKey;
  bg?: BgKey;
  padding?: boolean;
  isLink?: boolean;
};

export function AddressString({
  value,
  valueColor = "white",
  bg = "transparent",
  padding = true,
  isLink = false
}: IAddressString) {

  const [isClickedAddres, setIsClickedAddres] = useState<boolean>(false);

  const copyAddress = async () => {
    if (value && navigator && navigator.clipboard) {
      navigator.clipboard.writeText(value);
    }

    setIsClickedAddres(true);
    await delay(300);
    setIsClickedAddres(false);
  };

  const openAddress = () => {
    if (!isLink) return;
    window.open(`https://blockchain.hero2quest.io/accounts/accountDetails?id=${value}`, "_blank");
  };

  return (
    <div
      className={`${styles["readonly-string-block"]} ${isLink ? styles.link : ""}`}
      style={{
        backgroundColor: BgColors[bg],
        padding: padding === true ? "1rem 1.5rem" : "0"
      }}
    >
      <p style={{ color: ValueColors[valueColor] }} onClick={openAddress}>{value}</p>
      <CopyPasteIcon
        className={`${styles['icon-copy']} ${isClickedAddres ? styles.active : ""}`}
        onClick={copyAddress}
      />
    </div>
  )
}