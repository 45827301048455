import { FC, PropsWithChildren } from "react";
import styles from "./InfoStatisticsBlock.module.scss";

interface IInfoStatisticsBlock {
  title: string;
  value: string | number;
};

export const InfoStatisticsBlock: FC<PropsWithChildren<IInfoStatisticsBlock>> = ({ title, value, children }) => {
  return (
    < div className={styles['stats-item']}>
      <div className={styles['stats-title']}>
        {title}
      </div>
      <div className={styles['stats-value']}>
        <span>
          {value}
        </span>
        {children}
      </div>
    </div>
  )
}
