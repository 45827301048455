import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CopyPasteIcon } from "app/icons/icon-copy-black.svg";
import "./LoginToAccount.scss";
import { VerticalDivider } from "shared/ui";
import { Fade } from "react-awesome-reveal";
import { Button } from "shared/ui";

export const LoginToAccount = () => {
  const [inputSeed, changeInputSeed] = useState<string[]>(Array.from("-".repeat(12)));
  const navigate = useNavigate();

  const PasteSeedPhrase = <p style={{ fontSize: "1.8rem", letterSpacing: "0.015rem", lineHeight: "2.1rem", padding: "0.5rem 0" }}>
    <CopyPasteIcon
      stroke="#dadada"
      fill="#dadada"
      style={{ verticalAlign: "-0.5rem", height: "2.1rem", marginRight: "1rem" }}
    />
    Paste seed phrase from clipboard
  </p>;

  const LoginPhrase = <p style={{ textTransform: "uppercase", padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>Proceed</p>;

  const onSeedChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newSeedWord = e.target.value;

    const checkPhrase = newSeedWord.split(" ").filter(item => item !== "");
    if (checkPhrase.length > 1) {
      console.log("Pasting from clipboard new whole phrase with length", checkPhrase.length);
      const newPhrase = generateSeedFromClipboard(newSeedWord);
      changeInputSeed(prev => newPhrase);
      return;
    };

    const newSeeds = [...inputSeed];
    newSeeds[index] = newSeedWord;
    changeInputSeed(prev => newSeeds);
  };

  const generateSeedFromClipboard = (clipText: string): string[] => {
    if (clipText.length) {
      const modifiedClipText = clipText
        .split(/[\n\r-.,!]/)
        .filter((item) => item.length > 0);
      if (modifiedClipText.length === 12) return modifiedClipText;
      else {
        const additionalClipText = Array.from(
          { length: 12 - modifiedClipText.length },
          (item, i) => "-"
        );
        return modifiedClipText.concat(additionalClipText).slice(-12);
      }
    } else {
      return Array.from({ length: 12 }, (item, i) => "-");
    }
  };

  const checkInputSeedLength = (): boolean => {
    const result = inputSeed.filter(item => item.length !== 0 && item !== "-").length === 12 ? true : false;
    return result;
  }

  const pasteSeedPhrase = () => {
    if (navigator.clipboard.readText === undefined) {
      console.log("Copy from clipboard is not available in your browser");
      return;
    }
    navigator.clipboard.readText().then((cliptext) => {
      const seedFromClip = generateSeedFromClipboard(cliptext);
      changeInputSeed(seedFromClip);
    });
  };

  const proceedToEnterPin = async () => {
    const seedString = inputSeed.join(" ");
    localStorage.setItem("tempSeed", seedString);
    navigate("/login-page/setpin");
  };

  return (
    <div className="login-to-account__wrapper">
      <Link to={"/login-page"}>
        <span>&#8592;</span>Go back
      </Link>
      <h2>Login to your account</h2>
      <p>Enter the seed phrase to login to your account</p>
      <div className="seed-words-wrapper">
        {inputSeed.map((item, index) => {
          return (
            <div className="seed-word-input" key={index}>
              <input
                type="text"
                value={item}
                onChange={(e) => onSeedChange(e, index)}
              />
            </div>
          );
        })}
      </div>
      {navigator.clipboard.readText !== undefined &&
        <>
          <Button
            colorValue='darkGrey'
            widthValue='medium'
            children={PasteSeedPhrase}
            action={pasteSeedPhrase}
          />
          <VerticalDivider value={50} />
        </>
      }

      {inputSeed.filter(item => item.length !== 0 && item !== "-").length === 12 &&
        <Fade
          direction="up"
          duration={500}
        >
          <Button
            colorValue='green'
            widthValue='medium'
            isActive={checkInputSeedLength()}
            children={LoginPhrase}
            action={proceedToEnterPin}
          />
        </Fade>
      }
    </div>
  );
};