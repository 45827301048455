import { h2qAccount } from "app/core/H2QContext";
import { AvatarElement, makeAvatarElement } from "entities/avatar";
import { InitialItemElement, makeInitialItemElement, parts, rarity } from "entities/item";
import { ReactNode } from "react";
import { useOutletContext } from "react-router-dom";

export interface TrueCharacter {
  avatar_id: string;
  inventory: [string, string, string, string, string, string, string];
};

export interface MetaCharacter {
  clone_id: string;
  genPrice: string;
  generation: string;
  valPrice: string;
  experience: string;
  generator_id: string;
  level: string;
  name: string;
  imageHash: string;
};

export interface PseudoNFTData {
  id: string;
  _pubkey: string;
  _timestamp: string;
  _constructorFlag: boolean;
  m_questClientPlatformCode: string;
  m_questPlatformCode: string;
  m_h2qTrueCharacter: TrueCharacter;
  m_nftDeployer: string;
  m_h2qMetaCharacter: MetaCharacter;
  m_codeOriginal: string;
  m_locked: boolean;
  m_prelockedInQuest: any | null;
  m_lockedInQuest: {
    quest: string,
    startQuest: number,
    finishQuest: number,
    reward: {
      qstReward: string,
      h2qReward: string,
      experience: string,
      inventory: string,
    },
  } | null,
  m_creatorPubkey: string;
  m_rightBro: any | null;
  m_initialized: boolean;
  m_trueNFTAddress: string;
  m_creator: string;
  m_owner: string;
  m_price: string;
};

export interface TrueNFTinfo {
  addrAuthor: string;
  addrOwner: string;
  addrRoot: string;
  chunkSize: string;
  chunks: string;
  contentHash: string;
  createdAt: string;
  description: string;
  meta: {
    duration: string;
    extra: string;
    height: string;
    json: string;
    width: string;
  };
  mimeType: string;
  name: string;
  royalty: string;
  royaltyMin: string;
  size: string;
  version: string;
};

export interface NftItems<T> {
  Legs: T | null;
  Hip: T | null;
  Body: T | null;
  Head: T | null;
  Accessory1: T | null;
  Accessory2: T | null;
  Accessory3: T | null;
};

export interface NftElement {
  avatar: AvatarElement;
  items: NftItems<InitialItemElement>;
  info?: TrueNFTinfo;
  pseudoNftData?: PseudoNFTData;
  pseudoNFTAddr: string;
  nftOriginType: string;
  nftOriginality: number;
};

export interface NftInProgress {
  avatar_id: string;
  inventory: string[];
  pseudoNFTAddr: string;
  id: number;
};

export interface INftPageTab {
  title: string;
  id: number;
  tab: ReactNode;
  isActive?: boolean;
};

type ContextNftType = {
  nft: NftElement | null;
};

export interface NFTInfo {
  pseudoNFTdata: PseudoNFTData;
  trueNFTInfo: TrueNFTinfo;
  pseudoNFTAddr: string;
};

export const useNft = () => {
  return useOutletContext<ContextNftType>()
};

export const makeNftItemsObject = (items: string[]): NftItems<InitialItemElement> => {
  const nftItems = {} as NftItems<InitialItemElement>;

  items.forEach((item, categoryId) => {
    const itemNumber = +item;
    const newItem: InitialItemElement | null = itemNumber >= 0 ? makeInitialItemElement(itemNumber, categoryId) : null;
    const itemKey = parts[categoryId] as keyof NftItems<InitialItemElement>;
    nftItems[itemKey] = newItem;
  });

  return nftItems;
};

export const makeNftObject = (item: NFTInfo | NftInProgress): NftElement => {
  const newNft = {} as NftElement;

  let avatarId: number = -1;
  let items: string[] = [];

  if ("pseudoNFTdata" in item) {
    avatarId = +item.pseudoNFTdata.m_h2qTrueCharacter.avatar_id;
    items = item.pseudoNFTdata.m_h2qTrueCharacter.inventory;
    newNft.pseudoNftData = item.pseudoNFTdata;
    newNft.info = item.trueNFTInfo;
    const { nftType, nftTypePercent } = getNftType(items);
    newNft.nftOriginType = nftType;
    newNft.nftOriginality = nftTypePercent;
  }

  else {
    avatarId = +item.avatar_id;
    items = item.inventory;
  }

  newNft.pseudoNFTAddr = item.pseudoNFTAddr;

  const avatarData: AvatarElement = makeAvatarElement(1, avatarId);
  newNft.avatar = avatarData;

  const nftItems = makeNftItemsObject(items);
  newNft.items = nftItems;

  return newNft;
};

export const getNftInfo = (nft: NftElement): NFTInfo => {
  const nftInfo: NFTInfo = {
    pseudoNFTdata: nft.pseudoNftData!,
    trueNFTInfo: nft.info!,
    pseudoNFTAddr: nft.pseudoNFTAddr,
  };

  return nftInfo;
};

export const ORIGINAL_NFT_TYPE = "Original";
export const nftOriginalities = [1, 2, 3, 4, 5, 6, 7].map(o => Math.floor(o * 100 / 7)); // 14, 28, 42, 57, 71, 85, 100 percents

export const getNftType = (nftItems: string[]): { nftType: string; nftTypePercent: number } => {
  const modeMap = {} as any;
  let maxEl = nftItems[0];
  let maxCount = 1;

  for (let i = 0; i < nftItems.length; i++) {
    const el = nftItems[i];

    if (!modeMap[el]) {
      modeMap[el] = 1;
    }
    else {
      modeMap[el]++;
    }

    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }

  const nftType = maxCount >= 4
    ? `${h2qAccount.inventoryInfoFromBC![Number(maxEl)].originalHero.charAt(0).toUpperCase() +
    h2qAccount.inventoryInfoFromBC![Number(maxEl)].originalHero.slice(1)}`
    : ORIGINAL_NFT_TYPE;

  const nftTypePercent = Math.floor(maxCount * 100 / nftItems.length);

  return {
    nftType,
    nftTypePercent
  };
};

export const getNftInProgress = (nfts: NftInProgress[]): NftElement[] => {
  const nftsInProgress = [] as NftElement[];

  nfts.forEach(item => {
    const newNft = makeNftObject(item);
    nftsInProgress.push(newNft);
  })

  return nftsInProgress;
};

const sortNftsReversedOrder = (a: NftElement, b: NftElement) => {
  if (a.info && b.info && a.info.createdAt < b.info.createdAt) {
    return 1;
  }
  if (a.info && b.info && a.info.createdAt > b.info.createdAt) {
    return -1;
  }
  return 0;
};

export const getNftFromArray = (nftInfoArray: NFTInfo[]): NftElement[] => {
  return nftInfoArray.map((item) => {
    return makeNftObject(item);
  })
};

// return sorted NFT for NFT Gallery page:
export const getNfts = (nftInfoArray: NFTInfo[]): NftElement[] => {
  return getNftFromArray(nftInfoArray).sort(sortNftsReversedOrder);
};

export const getNftById = (nftInfoArray: NFTInfo[], id: string): NftElement | null => {
  const nftIndex = nftInfoArray.findIndex(
    (item) => item.pseudoNFTAddr.split(":")[1] === id
  );
  if (nftIndex === -1) {
    return null;
  };
  const nftInfo = nftInfoArray[nftIndex];
  const resultNft = makeNftObject(nftInfo);
  return resultNft;
};

export const getNftMediumRarityName = (nftItems: string[]) => {
  const mediumRarityValue = nftItems.reduce((acc: number, item) => {
    const itemRarity = h2qAccount.inventoryInfoFromBC![Number(item)].rarity;
    return acc + Number(itemRarity);
  }, 0);

  return rarity[Math.floor(mediumRarityValue / 7)];
};