import styles from "./SideBarLogo.module.scss";
import h2qShort from "./h2q-short.png";
import h2qLong from "./h2q-long.png";

interface ISideBarLogo {
  isOpen: boolean;
};

export const SideBarLogo = ({ isOpen }: ISideBarLogo) => {
  return (
    <div className={`${styles["side-nav__toggle-logo"]} ${isOpen ? styles.long : styles.short}`}>
      {isOpen && <img src={h2qLong} alt={""} style={{ "position": "relative", "left": "0.2rem", "top": "0.2rem" }} />}
      {!isOpen && <img src={h2qShort} alt={""} />}
    </div>
  )
};