import { Tooltip as ReactTooltip } from 'react-tooltip';

export const Tooltip = () => {
  return (
    <ReactTooltip
      id="h2q-tooltip"
      style={{
        fontSize: "1.6rem",
        backgroundColor: "#282b38",
        zIndex: 100
      }} />
  )
};