import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import colorLogo from "./images/logo-h2q-color.png";
import longLogo from "./images/logo-h2q-long.png";
import styles from "./Agreement.module.scss";
import { VerticalDivider } from "shared/ui";
import { Button, CheckBox } from "shared/ui";

interface IAgreement {
  action: () => void;
};

export default function Agreement({ action }: IAgreement) {
  const [isChecked, setChecked] = useState<boolean>(false);

  const agreePhrase = (
    <p
      style={{
        padding: "0.5rem 0",
      }}
    >
      Agree and Continue
    </p>
  );

  return (
    <div className={styles["disclaimer-wrapper"]}>
      <div className={styles.logos}>
        <img className={styles["logo-color"]} src={colorLogo} alt="H2Q Color Logo" />
        <img className={styles["logo-long"]} src={longLogo} alt="H2Q Long White Logo" />
      </div>

      <section className={styles.agreement}>
        <h3 className={styles["agree-title"]}>Disclaimer</h3>
        <p className={styles["agree-text"]}>This user interface, hosted on a website (“hero2quest.io”), is an open source external software portal for the Quest Network, a decentralized and communitydriven platform for generating nft heroes and their stories, powered by the everscale blockchain. This interface and the Hero2quest Game are provided by Quest Network inc, however, all transactions carried out under the protocol are performed by the corresponding smart contracts without permission. Because the interface is open source, and the hero2quest protocol and associated smart contracts are available to any user, organization, or third party, there are a number of third-party web and mobile user interfaces that allow you to interact with the Hero2quest game.</p>
        <p className={styles["agree-text"]}>THIS INTERFACE AND THE HERO2QUEST GAME ARE PROVIDED "AS IS" AT YOUR RISK AND WITHOUT WARRANTY OF ANY KIND. Quest Network inc. does not provide, own or control the hero2quest protocol or any transactions conducted over the protocol or via related smart contracts. By using or accessing this Hero2quest Interface or Game and related smart contracts, you agree that no developer or entity involved in the creation, deployment or maintenance of this hero2quest Interface or Game shall be liable for any claims or damages relating to your use of, inability to use, or your interactions with other users of this Interface or the Hero2quest Game, including any direct, indirect, incidental, special, punitive or consequential damages or loss of profits, digital assets, tokens, or any other value.</p>
        <p className={styles["agree-text"]}>By using or accessing this Interface, games Hero2quest, or related smart contracts, you represent that you are not located in, incorporated or established in, or a citizen or resident of the Prohibited Jurisdictions. You also represent that you are not subject to sanctions or otherwise designated on any list of prohibited or restricted parties or excluded or denied persons, including but not limited to the lists maintained by the United States' Department of Treasury's Office of Foreign Assets Control, the United Nations Security Council, the European Union or its Member States, or any other government authority.</p>
      </section>

      <div className={styles["check-wrapper"]}>
        <CheckBox
          isChecked={isChecked}
          action={() => setChecked(prev => !prev)}
          size={23}
        />
        <div className={styles["check-label"]}>I have read, understand and accept these terms
        </div>
      </div>
      <VerticalDivider value={30} />
      {isChecked &&
        <Fade
          direction="up"
          duration={500}
        >
          <Button
            colorValue="green"
            widthValue="full"
            children={agreePhrase}
            isActive={isChecked}
            action={action}
          />
        </Fade>
      }
    </div>
  )
}
