import styles from "./UserGuidePage.module.scss";
import { ReactComponent as IconAbout } from "app/icons/Title_ABOUTE_white.svg";
import { PageTitle } from "entities/PageTitle";
import { UserGuideBrandsBlock, UserGuidePlayerBlock } from "widgets";

export default function UserGuidePage() {
  return (
    <div className={styles["user-guide"]}>
      <PageTitle>
        <IconAbout />
      </PageTitle>
      <UserGuideBrandsBlock />
      <UserGuidePlayerBlock />
    </div>
  )
}
