import { Container, PixiComponent, Sprite } from '@saitonakamura/react-pixi'
import { avatarsList } from "entities/avatar";
import { itemList } from "entities/item";
import { Circle, Graphics } from "pixi.js";
import { GeneratorTooltip, getHeroSizeAndCoord, heroFadeColor, heroFadeColorAlpha, heroFadeHeight, heroFadeWidth, iconRandomWidth } from "entities/generator";
import { useState } from "react";

interface IHeroBottomFade {
  x: number;
  y: number;
  width: number;
  height: number;
};

const HeroBottomFade = PixiComponent<IHeroBottomFade, Graphics>("HeroBottomFade", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(heroFadeColor, heroFadeColorAlpha);
    ins.drawEllipse(props.x, props.y, props.width / 2, props.height / 2);
    ins.endFill();
  }
});

interface IHero {
  items: number[];
  avatar: number;
  isApplied: boolean;
  isAvatarSelected: boolean;
  isTooltipsEnabled: boolean;
  isVertical: boolean;
};

export function GeneratorHero({ items, avatar, isApplied, isAvatarSelected, isTooltipsEnabled, isVertical }: IHero) {
  const avatarItem = avatarsList.filter(a => a.itemId === avatar)[0];

  const [isHeroHovered, setHovered] = useState<boolean>(false);
  const heroSizeAndCoord = getHeroSizeAndCoord(isVertical);

  return (
    <>
      <Container
        position={[heroSizeAndCoord.Hero!.x, heroSizeAndCoord.Hero!.y]}
        interactive={true}
        hitArea={new Circle(avatarItem.imageWidth / 2, avatarItem.imageWidth / 2, avatarItem.imageWidth / 5)}
        mouseout={() => setHovered(false)}
        mouseover={() => setHovered(true)}
      >
        {
          isAvatarSelected &&
          <>
            <HeroBottomFade
              x={heroSizeAndCoord.Hero!.x - avatarItem.imageWidth * 0.33}
              y={heroSizeAndCoord.Hero!.y + avatarItem.imageHeight * 0.67}
              width={heroFadeWidth}
              height={heroFadeHeight}
            />
            <Sprite
              image={avatarItem.imageThumbLink}
              scale={2}
            />
            {avatarItem.headThumbLink &&
              <Sprite
                image={avatarItem.headThumbLink}
                x={avatarItem.headPos.X * avatarItem.imageWidth}
                y={avatarItem.headPos.Y * avatarItem.imageHeight}
                scale={2}
              />
            }
            {items.length > 0 && isApplied &&
              items.map((item, index) => {
                return (
                  <Sprite
                    key={itemList[index][item].imageThumbLink}
                    image={itemList[index][item].imageThumbLink}
                    x={itemList[index][item].imagePos!.X * avatarItem.imageWidth}
                    y={itemList[index][item].imagePos!.Y * avatarItem.imageHeight}
                    scale={2}
                  />
                )
              })
            }
          </>
        }

        {/* No avatar */}
        {
          !isAvatarSelected &&
          <Sprite
            image={"/res/Generator/icon-random-black.svg"}
            alpha={0.15}
            width={iconRandomWidth}
            height={iconRandomWidth}
            anchor={0}
            x={avatarItem.imageThumbWidth * 0.94}
            y={avatarItem.imageThumbHeight * 0.62}
          />
        }

        {/* Hero tooltips */}
        {isHeroHovered && isTooltipsEnabled &&
          <GeneratorTooltip
            type={isAvatarSelected ? "hero" : "emptyHero"} />
        }
      </Container>
    </>
  )
}
