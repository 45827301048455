import { ModalCancelButton, ModalText, ModalTitle, ModalTypes, ModalWrapperChildren, ModalWrapperOut } from 'entities/generator';

interface IErrorMessage {
  errorMessage: string;
  reject: () => void;
};

const type: ModalTypes = "error";

export const ErrorMessage = ({ reject, errorMessage }: IErrorMessage) => {
  return (
    <>
      <ModalWrapperOut>
        <ModalTitle type={type} />

        <ModalWrapperChildren>
          <ModalText type={type} addMessage={errorMessage} />
          <ModalCancelButton type={type} reject={reject} />
        </ModalWrapperChildren>

      </ModalWrapperOut>
    </>
  )
};