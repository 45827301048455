import { VerticalDivider } from "shared/ui";
import { useState } from "react";
import { delay } from "shared/lib";
import { getNftKeyTags } from "entities/keyTags/model/keyTags";
import { hexToDecNumber } from "entities/quest";
import { ReactComponent as CopyPasteIcon } from "app/icons/icon-copy-black.svg";

import styles from "./NftInfoMainTab.module.scss";
import { getNftMediumRarityName, NftElement } from "entities/nft";
import { KeyTagsBlock } from "entities/keyTags";
import { getGeneratorTitleByGenId } from "entities/generatorSelector";

interface INftInfoMainTab {
  nft: NftElement;
};

export function NftInfoMainTab({ nft }: INftInfoMainTab) {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const copyNftAddres = async () => {
    if (nft) navigator.clipboard.writeText(nft.pseudoNFTAddr);
    setIsClicked(true);
    await delay(300);
    setIsClicked(false);
  };

  const nftItems = nft.pseudoNftData?.m_h2qTrueCharacter.inventory ?
    nft.pseudoNftData.m_h2qTrueCharacter.inventory
    : [];

  const nftKeyTags = getNftKeyTags(nft);

  return (
    <div className={styles.wrapper}>
      <h3 className={styles['info-title']}>NFT HERO</h3>
      <p className={styles['info-value']}>
        <span className={styles['info-param']}>ID:</span>
        <a
          className={styles.link}
          href={`https://net.ever.live/accounts/accountDetails?id=${nft.pseudoNFTAddr}`}
          target="_blank"
          rel="noreferrer"
        >
          {nft.pseudoNFTAddr.slice(0, 15) + "..."}
        </a>
        <CopyPasteIcon
          className={`${styles['copy-btn']} ${isClicked ? styles.active : ""}`}
          stroke="#dadada"
          fill="#dadada"
          style={{ verticalAlign: "-0.5rem", height: "2.1rem", marginRight: "1rem" }}
          onClick={copyNftAddres}
        />
      </p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Name:</span>{nft.info!.name}</p>
      <VerticalDivider value={20} />
      <p className={styles['info-value']}><span className={styles['info-param']}>Generator Nft:</span>{getGeneratorTitleByGenId(nft.avatar.genId!)}</p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Type:</span>{nft.nftOriginality + "%"} / {nft.nftOriginType}</p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Rarity:</span>{getNftMediumRarityName(nftItems) ? getNftMediumRarityName(nftItems) : "Not available"}</p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Generation price:</span>{nft.pseudoNftData?.m_h2qMetaCharacter.genPrice || 0}</p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Value:</span>{nft.pseudoNftData?.m_h2qMetaCharacter.valPrice || 0}</p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Experience:</span>{nft.pseudoNftData?.m_h2qMetaCharacter.experience
        ? hexToDecNumber(nft.pseudoNftData?.m_h2qMetaCharacter.experience)
        : 0}
      </p>
      <p className={styles['info-value']}><span className={styles['info-param']}>Level:</span>{nft.pseudoNftData?.m_h2qMetaCharacter.level || 0}</p>

      {nftKeyTags.length &&
        <KeyTagsBlock
          nftKeyTags={nftKeyTags}
        />
      }
    </div>
  )
}
