import { H2QContext } from "app/core/H2QContext";
import { FilterBarWrapper } from "features/FilterTopBarWrapper"
import { initialMinNftLevel, initialMinNftValue, NftSelectedTopBar } from "features/nftFilter";
import { observer } from "mobx-react"
import { useContext } from "react";

interface INftFilterTopBar {
  toggleFilter: () => void;
};

export const NftFilterTopBar = observer(function ({ toggleFilter }: INftFilterTopBar) {
  const { h2qAccount } = useContext(H2QContext);

  const isFiltersActive =
    h2qAccount.uiStore.nftFilter.avatarName.length !== 0 ||
    h2qAccount.uiStore.nftFilter.genId.length !== 0 ||
    h2qAccount.uiStore.nftFilter.nftMinValue !== initialMinNftValue ||
    h2qAccount.uiStore.nftFilter.nftMaxValue !== h2qAccount.uiStore.nftMaxValue ||
    h2qAccount.uiStore.nftFilter.nftMinLevel !== initialMinNftLevel ||
    h2qAccount.uiStore.nftFilter.nftMaxLevel !== h2qAccount.uiStore.nftMaxLevel ||
    h2qAccount.uiStore.nftFilter.nftOriginType.length !== 0 ||
    h2qAccount.uiStore.nftFilter.nftOriginality.length !== 0;

  const clearFilter = () => {
    h2qAccount.uiStore.clearNftFilter();
  };

  return (
    <FilterBarWrapper
      isFiltersActive={isFiltersActive}
      clearFilter={clearFilter}
      toggleFilter={toggleFilter}
    >
      <NftSelectedTopBar />
    </FilterBarWrapper>
  )
})