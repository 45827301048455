import { parts } from "entities/item";
import { cellBorder, cellInnerSize, cellOutSize, getApplyBtnCoords, getBottomBtnsSizeAndCoord, getMessageSizeAndCoord, getSlotItemsCoords, iconRandomWidth, lockImageHeight, lockImageWidth, menuIconsCoords, reloadBtnCoords } from "./generatorCoordinates";
import { circleBlockSize, countBlockSize, dotSize, fieldHeight, triangleSize } from "./generatorParams";

// Tooltips options:
export const tooltipRadius = 24;
export const tooltipBg = 0x202334;
export const tooltipBgAlpha = 0.8;
export const tooltipBorder = 0xffffff;
export const tooltipBorderAlpha = 0.8;
export const tooltipBorderWidth = 0;
export const tooltipFontColor = 0xffffff;
export const tooltipFont = "Andale";
export const tooltipFontSize = 24;
export const tooltipLineHeight = tooltipFontSize * 1.2;
export const tooltipFontOffset = 33;

const itemSizeAndCoord = getMessageSizeAndCoord(false);
const bottomBtnSizeAndCoord = getBottomBtnsSizeAndCoord(false);
const avatarImageThumbWidth = 512;

const tooltipHeight = (row: number): number => {
  return tooltipFontOffset * 2 + tooltipLineHeight * row
};

export type ArrowTo = "b-left" | "b-right" | "right";

type GenTooltip = {
  text: string;
  rows: number;
  width: number;
  height: number;
  arrowTo: ArrowTo;
  x: number;
  y: number;
};

export const genTooltips = [
  "emptySlot",
  "itemSlot",
  "emptyHero",
  "hero",
  "emptyReloadBtn",
  "newReloadBtn",
  "emptyGenerateBtn",
  "newGenerateBtn",
  "lockedItem",
  "triangleColor",
  "colorsBlock",
  "itemsValue",
  "createPrice",
  "generatePrice",
  "resetPrice",
  "resetBtn",
  "menuTriangles",
  "menuUndress",
  "menuAvatars",
  "menuBg",
  "menuTooltip",
  "threeDots",
  "currentItemAmount",
  "itemTypesAmount"
] as const;

export type GenTooltipsType = typeof genTooltips[number];

export type IGenTooltips = {
  [K in GenTooltipsType]: GenTooltip;
};

export const getGeneratorTooltips = (isVertical: boolean, slotHovered = 0): IGenTooltips => {
  return {
    "emptySlot": {
      text: "A slot where a random ITEM from the collection is loaded",
      rows: 4,
      x: cellOutSize / 2,
      y: cellOutSize / 2,
      width: 330,
      height: tooltipHeight(4),
      arrowTo: "b-left"
    },
    "emptyHero": {
      text: "The avatar will be loaded from the collection or you can choose it yourself",
      rows: 3,
      width: 450,
      height: tooltipHeight(3),
      x: avatarImageThumbWidth * 0.94 + iconRandomWidth / 2,
      y: avatarImageThumbWidth * 0.62 + iconRandomWidth / 2,
      arrowTo: "b-left"
    },
    "hero": {
      text: "The HERO you see right now can be minted as an NFT",
      rows: 2,
      width: 450,
      height: tooltipHeight(2),
      x: avatarImageThumbWidth * 0.94 + iconRandomWidth / 2,
      y: avatarImageThumbWidth * 0.62 + iconRandomWidth / 2,
      arrowTo: "b-left"
    },
    "emptyReloadBtn": {
      text: "Obtain 7 ITEMS in quests and load them into the slot to create new HEROES",
      rows: 3,
      width: 468,
      height: tooltipHeight(3),
      x: reloadBtnCoords.x + (cellOutSize + circleBlockSize) / 2,
      y: reloadBtnCoords.y + (cellOutSize - fieldHeight) / 2 - circleBlockSize,
      arrowTo: "b-left"
    },
    "newReloadBtn": {
      text: "Request new items from the collection",
      rows: 3,
      width: 290,
      height: tooltipHeight(3),
      x: reloadBtnCoords.x + (cellOutSize + circleBlockSize) / 2,
      y: reloadBtnCoords.y + (cellOutSize - fieldHeight) / 2 - circleBlockSize,

      arrowTo: "b-left"
    },
    "emptyGenerateBtn": {
      text: "To create an NFT-HERO, you must have 7 items and an avatar in your collection",
      rows: 3,
      width: 468,
      height: tooltipHeight(3),
      x: getApplyBtnCoords(isVertical).x + cellOutSize / 2,
      y: getApplyBtnCoords(isVertical).y + (cellOutSize - fieldHeight) / 2,
      arrowTo: "b-right"
    },
    "newGenerateBtn": {
      text: "Minting an nft-hero from displayed items",
      rows: 3,
      width: 295,
      height: tooltipHeight(3),
      x: getApplyBtnCoords(isVertical).x + cellOutSize / 2,
      y: getApplyBtnCoords(isVertical).y + (cellOutSize - fieldHeight) / 2,
      arrowTo: "b-left"
    },
    "itemSlot": {
      text: "Items from the collection",
      rows: 2,
      width: 280,
      height: tooltipHeight(2),
      x: getSlotItemsCoords(isVertical)[parts[slotHovered!]].x + cellOutSize / 2,
      y: getSlotItemsCoords(isVertical)[parts[slotHovered!]].y + cellOutSize / 2,
      arrowTo: "b-left"
    },
    "lockedItem": {
      text: "This item will be locked and will remain in the slot when changing items",
      rows: 3,
      width: 480,
      height: tooltipHeight(3),
      x: getSlotItemsCoords(isVertical)[parts[slotHovered!]].x + cellInnerSize - lockImageWidth / 2 + 10,
      y: getSlotItemsCoords(isVertical)[parts[slotHovered!]].y + cellInnerSize - lockImageHeight / 2,
      arrowTo: "b-right"
    },
    "triangleColor": {
      text: "Each set of items has its own color",
      rows: 3,
      width: 265,
      height: tooltipHeight(3),
      x: getSlotItemsCoords(isVertical)[parts[slotHovered!]].x + cellBorder + (triangleSize * 0.1),
      y: getSlotItemsCoords(isVertical)[parts[slotHovered!]].y + cellOutSize - cellBorder - (triangleSize * 0.9),
      arrowTo: "b-left"
    },
    "currentItemAmount": {
      text: "Number of copies of available items",
      rows: 3,
      width: 300,
      height: tooltipHeight(3),
      x: getSlotItemsCoords(isVertical)[parts[slotHovered!]].x + cellBorder + (triangleSize * 0.2) + (countBlockSize * 0.75),
      y: getSlotItemsCoords(isVertical)[parts[slotHovered!]].y + cellOutSize - cellBorder - (triangleSize * 0.2) - countBlockSize,
      arrowTo: "b-left"
    },
    "threeDots": {
      text: "Open description for current item",
      rows: 2,
      width: 310,
      height: tooltipHeight(2),
      x: getSlotItemsCoords(isVertical)[parts[slotHovered!]].x + cellBorder + cellInnerSize / 2,
      y: getSlotItemsCoords(isVertical)[parts[slotHovered!]].y + cellInnerSize - dotSize,
      arrowTo: "b-left"
    },
    "itemTypesAmount": {
      text: "Number of available options for items that can be used to create NFTs",
      rows: 3,
      width: 480,
      height: tooltipHeight(3),
      x: reloadBtnCoords.x + cellOutSize / 2,
      y: reloadBtnCoords.y + (cellOutSize - fieldHeight - circleBlockSize) / 2,
      arrowTo: "b-left"
    },
    "colorsBlock": {
      text: "Items from different sets are marked with color",
      rows: 3,
      width: 390,
      height: tooltipHeight(3),
      x: itemSizeAndCoord.BlockColors!.x + itemSizeAndCoord.BlockColors!.width! / 2,
      y: itemSizeAndCoord.BlockColors!.y,
      arrowTo: "b-left"
    },
    "itemsValue": {
      text: "The total cost of all items displayed on the slots",
      rows: 3,
      width: 390,
      height: tooltipHeight(3),
      x: cellOutSize + cellOutSize / 2,
      y: 2 * cellOutSize - cellBorder - fieldHeight,
      arrowTo: "b-left"
    },
    "createPrice": {
      text: "The total cost of the mint of the NFT-HERO",
      rows: 3,
      width: 280,
      height: tooltipHeight(3),
      x: 2 * cellOutSize + cellOutSize / 2,
      y: 2 * cellOutSize - cellBorder - fieldHeight,
      arrowTo: "b-left"
    },
    "generatePrice": {
      text: "The cost to generate NFT-HERO",
      rows: 2,
      width: 320,
      height: tooltipHeight(2),
      x: bottomBtnSizeAndCoord.GenPrice!.x + bottomBtnSizeAndCoord.GenPrice!.width! / 2,
      y: bottomBtnSizeAndCoord.GenPrice!.y,
      arrowTo: "b-left",
    },
    "resetPrice": {
      text: "The cost to reset the current cycle of picking up all items",
      rows: 3,
      width: 380,
      height: tooltipHeight(3),
      x: bottomBtnSizeAndCoord.ResPrice!.x + bottomBtnSizeAndCoord.ResPrice!.width! / 2,
      y: bottomBtnSizeAndCoord.ResPrice!.y,
      arrowTo: "b-right"
    },
    "resetBtn": {
      text: "Button to reset all items on slots",
      rows: 3,
      width: 303,
      height: tooltipHeight(3),
      x: bottomBtnSizeAndCoord.ResetBtn!.x + bottomBtnSizeAndCoord.ResetBtn!.width! / 2,
      y: bottomBtnSizeAndCoord.ResetBtn!.y,
      arrowTo: "b-right"
    },
    "menuTriangles": {
      text: "Set items' markers",
      rows: 1,
      width: 340,
      height: tooltipHeight(1),
      x: 0,
      y: menuIconsCoords["menuTriangles"]![1],
      arrowTo: "right",
    },
    "menuUndress": {
      text: "Undress avatar",
      rows: 1,
      width: 280,
      height: tooltipHeight(1),
      x: 0,
      y: menuIconsCoords["menuUndress"]![1],
      arrowTo: "right"
    },
    "menuAvatars": {
      text: "Change avatar",
      rows: 1,
      width: 265,
      height: tooltipHeight(1),
      x: 0,
      y: menuIconsCoords["menuAvatars"]![1],
      arrowTo: "right"
    },
    "menuBg": {
      text: "Hide background",
      rows: 1,
      width: 300,
      height: tooltipHeight(1),
      x: 0,
      y: menuIconsCoords["menuBg"]![1],
      arrowTo: "right"
    },
    "menuTooltip": {
      text: "Toggle tooltips",
      rows: 1,
      width: 300,
      height: tooltipHeight(1),
      x: 0,
      y: menuIconsCoords["menuTooltip"]![1],
      arrowTo: "right"
    },
  }
};