import { H2QContext } from "app/core/H2QContext";
import { InitialItemElement, ItemImageSVG } from "entities/item";
import { makeNftItemsObject, makeNftObject, NftImageSVG } from "entities/nft";
import { ItemHexMasktoItemArray } from "entities/quest";
import { observer } from "mobx-react";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { UnstakeButton } from "shared/ui";
import styles from "./RewardsModalWindow.module.scss";

type RewardsData = {
  questId: string;
  questReward: string;
  h2q: string;
  qst: string;
};

interface RewardsDataForNft {
  [k: string]: RewardsData;
};

interface IRewardsModalWindow {
  isShown: boolean; // just for rerender and recalc the reward data
  hideRewards: () => void;
};

export const RewardsModalWindow = observer(function ({ isShown, hideRewards }: IRewardsModalWindow) {
  const { h2qAccount } = useContext(H2QContext);

  const stakedNfts = useMemo(() => {
    return h2qAccount.nfts.filter(item => item?.pseudoNFTdata?.m_lockedInQuest);
  }, [h2qAccount.nfts]);

  const checkIsQuestFinished = (finishTimeString: number) => {
    const questTimeLeft = Date.now() - (finishTimeString * 1000);
    return questTimeLeft > 0;
  };

  const getFinishedNfts = () => {
    return stakedNfts.filter(item => checkIsQuestFinished(item.pseudoNFTdata.m_lockedInQuest?.finishQuest || 0));
  };

  const getAllRewards = async () => {
    if (h2qAccount.q2hero.state === "pending" || h2qAccount.hasRewardsToGet === false) {
      return
    };

    h2qAccount.toggleUnstakingAll(true);
    const finishedNfts = getFinishedNfts();

    if (finishedNfts.length === 0) {
      return;
    }

    try {
      await Promise.all(finishedNfts.map(async item => await h2qAccount.q2hero(item)));
      console.log("Successfully get all rewards");
      h2qAccount.toggleRewardsToGet(false);
    }
    catch (error) {
      console.warn("Error during getting rewards");
      console.warn(error);
    }
    finally {
      h2qAccount.toggleUnstakingAll(false);
      hideRewards();
      await h2qAccount.fetchNTFs();
    }
  };

  const finishedNfts = getFinishedNfts();

  const rewards = useMemo(() => {
    const newRewards: RewardsDataForNft = {};

    if (finishedNfts.length) {
      finishedNfts.forEach(n => {
        newRewards[n.pseudoNFTAddr] = {
          questId: n.pseudoNFTdata.m_lockedInQuest!.quest,
          questReward: n.pseudoNFTdata.m_lockedInQuest!.reward.inventory,
          h2q: n.pseudoNFTdata.m_lockedInQuest!.reward.h2qReward,
          qst: n.pseudoNFTdata.m_lockedInQuest!.reward.qstReward,
        }
      });
    }
    return newRewards;
  }, [finishedNfts]);

  useEffect(() => {
    const checkQuests = async () => {
      if (h2qAccount.quests.length === 0 && h2qAccount.loadAllQuests.state !== "pending") {
        await h2qAccount.loadAllQuests();
      }
    }
    checkQuests();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["rewards-window"]}>
      <h3 className={styles.title}>New rewards for completed quests</h3>
      <div className={styles["scroll-area"]}>

        {Object.entries(rewards).map(([nftId, r]) => {
          return (
            <Fragment key={nftId}>
              <h3 className={styles["quest-name"]}>Quest: <span>{h2qAccount.quests.find(q => q.id === r.questId)?.m_questParams.mandatoryParams.name}</span></h3>
              <h3 className={styles["quest-name"]}>Nft-Hero: <span>{h2qAccount.nfts.find(n => n.pseudoNFTAddr === nftId)?.trueNFTInfo.name}</span></h3>
              <div className={styles.rewards} key={nftId}>
                <div className={styles.nft}><NftImageSVG item={makeNftObject(h2qAccount.nfts.find(n => n.pseudoNFTAddr === nftId)!)} /></div>
                <div className={styles["items-block"]}>
                  <h4>Items:</h4>
                  <div className={styles.items}>
                    {Object.values(makeNftItemsObject(ItemHexMasktoItemArray(r.questReward))).map((i: InitialItemElement) => {
                      if (i === null) return null;
                      return (
                        <div key={i.iconThumbLink} className={styles.item}>
                          <ItemImageSVG iconLink={i.iconThumbLink} />
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
                <div className={styles["coins-block"]}>
                  <h4>H2Q</h4>
                  <p>{r.h2q}</p>
                </div>
                <div className={styles["coins-block"]}>
                  <h4>QST</h4>
                  <p>{r.qst}</p>
                </div>
                {/* Do no delete next div */}
                <div></div>
              </div>
            </Fragment>
          )
        })}
      </div>

      <UnstakeButton action={getAllRewards} isWaiting={h2qAccount.isUnstakingAll} style={{}} />
    </div>
  )
});