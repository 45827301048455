import { useState } from "react";
import loadingIcon from "app/icons/icon-refresh-white.svg";
import styles from "./ItemImage.module.scss";

interface IItemImage {
  iconLink: string;
  isFadeActive?: boolean;
  onClick?: () => void;
};

export const ItemImageSVG = ({ iconLink }: IItemImage) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const rotatingSize = 50;

  const onLoadImage = () => {
    setLoaded(true);
  };

  return <svg viewBox="0 0 512 512"
    width={512}
    height={512}
    style={{
      position: "relative",
      display: "block",
      width: "100%",
      height: "100%",
      zIndex: 1
    }}
  >
    {/* Loading image */}
    {
      !isLoaded &&
      <image className={styles.loading} href={loadingIcon} width={rotatingSize} height={rotatingSize} x={256 - rotatingSize / 2} y={256 - rotatingSize / 2} />
    }
    <image style={{ "display": isLoaded ? "inherit" : "none" }} onLoad={onLoadImage} href={iconLink} width={512} height={512} x={0} y={0} />
  </svg>
}

export const ItemDivImage = ({ iconLink, isFadeActive = false, onClick }: IItemImage) => {
  return (
    <div onClick={onClick} className={`${styles.item__item} ${isFadeActive ? styles.fade : ""}`}>
      <ItemImageSVG iconLink={iconLink} />
    </div>
  )
}