const H2QuestClientPlatformContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "h2questClientCode",
                        "type": "cell"
                    },
                    {
                        "name": "tip3WalletCode",
                        "type": "cell"
                    },
                    {
                        "name": "nftDeployer",
                        "type": "address"
                    },
                    {
                        "name": "h2qRoot",
                        "type": "address"
                    },
                    {
                        "name": "qstRoot",
                        "type": "address"
                    },
                    {
                        "components": [
                            {
                                "name": "avatar_id",
                                "type": "uint8"
                            },
                            {
                                "name": "inventory",
                                "type": "uint8[]"
                            }
                        ],
                        "name": "trueCharacter",
                        "type": "tuple"
                    }
                ],
                "outputs": []
            },
            {
                "name": "m_pseudoNftAddress",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_pseudoNftAddress",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_h2questAddress",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_h2questAddress",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_nftDeployer",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nftDeployer",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_h2qRoot",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_h2qRoot",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_qstRoot",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_qstRoot",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "m_h2qTrueCharacter",
                "inputs": [],
                "outputs": [
                    {
                        "components": [
                            {
                                "name": "avatar_id",
                                "type": "uint8"
                            },
                            {
                                "name": "inventory",
                                "type": "uint8[]"
                            }
                        ],
                        "name": "m_h2qTrueCharacter",
                        "type": "tuple"
                    }
                ]
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "m_pseudoNftAddress",
                "type": "address"
            },
            {
                "key": 2,
                "name": "m_h2questAddress",
                "type": "address"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "m_pseudoNftAddress",
                "type": "address"
            },
            {
                "name": "m_h2questAddress",
                "type": "address"
            },
            {
                "name": "m_h2questClientCode",
                "type": "cell"
            },
            {
                "name": "m_tip3WalletCode",
                "type": "cell"
            },
            {
                "name": "m_nftDeployer",
                "type": "address"
            },
            {
                "name": "m_h2qRoot",
                "type": "address"
            },
            {
                "name": "m_qstRoot",
                "type": "address"
            },
            {
                "components": [
                    {
                        "name": "avatar_id",
                        "type": "uint8"
                    },
                    {
                        "name": "inventory",
                        "type": "uint8[]"
                    }
                ],
                "name": "m_h2qTrueCharacter",
                "type": "tuple"
            }
        ]
    },
    tvc: "te6ccgECIQEABNoAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsWBQQgA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8DQsGA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPBUVBgIoIIIQSYCJ6bvjAiCCEHGkYr674wIPBwM8IIIQT5XdorrjAiCCEFSlM6i64wIgghBxpGK+uuMCDgkIAU4w0ds8+E8hjhuNBHAAAAAAAAAAAAAAAAA8aRivoMjOzslw+wDe8gAUA/4w+EJu4wD4RvJzIZXU1NTR0JLU1OL6QNTR0PpA1NHQ+kDTB9Mf9ARZbwISbwIB0fhK+EnHBfLgivgnbxCCEHc1lAC88uBr+ABVBPhsVQP4bVUC+G5Y+G8B+HD4cfhOyM74Ss8W+CrQAcnbPCD7BNAgizits1jHBZPXTdDe10zQCxwKASLtHu1TghAsqN7v7UPY2zzyABsCFu1E0NdJwgGOgOMNDBQEiHDtRND0BXEhgED0Do6Bid9yIoBA9A6OgYnfiCCJXyBwIG1vAm8C+HH4cPhv+G74bfhs+Gv4aoBA9A7yvdcL//hicPhjDQ0gDQBDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAFoMNHbPPhRIY4ojQRwAAAAAAAAAAAAAAAAM+V3aKDIzgFvIgLLBwFvIgLLH/QAyXD7AN7yABQEUCCCEBjgnZe64wIgghAaEanKuuMCIIIQQN8VgLrjAiCCEEmAiem64wITEhEQAU4w0ds8+FAhjhuNBHAAAAAAAAAAAAAAAAAyYCJ6YMjOzslw+wDe8gAUAU4w0ds8+E4hjhuNBHAAAAAAAAAAAAAAAAAwN8VgIMjOzslw+wDe8gAUAU4w0ds8+EshjhuNBHAAAAAAAAAAAAAAAAAmhGpyoMjOzslw+wDe8gAUAU4w0ds8+EohjhuNBHAAAAAAAAAAAAAAAAAmOCdl4MjOzslw+wDe8gAUAIjtRNDT/9M/0wAx+kDU0dD6QNTU1NHQ+kDU0dD6QNTR0PpA0wfTH/QEWW8CEm8CAdH4cfhw+G/4bvht+Gz4a/hq+GP4YgAK+Eby4EwCEPSkIPS98sBOGBcAFHNvbCAwLjY2LjACAnEaGQEZuCqHtevg+2efAf5AEBsCkbmVG93/CdkZ3wl54t8JmgA5O2eEH2CaBBFnFbZrGOCyeum6G9rpmh2j3ap/Cb8J3wn/Ch8JXwl/CjBCBiqHtf2oextnnwH+QBAcGwCE+FH4UPhP+E74TfhM+Ev4SvhD+ELIy//LP8+DzlVgyM7MzFUwyM5VIMjOWcjOAW8iAssHAW8iAssf9ADNzc3Nye1UAhYhizits1jHBYqK4h4dAQgB2zzJHwEmAdTUMBLQ2zzIz44rbNYSzM8RyR8BdtWLL0pA1yb0BNMJMSDXSpHUjoKIAeKLX0vfLATo1yYwAcjPi9KQ9ACAIM8LCc+X0vfLATrMEszIzxHOIAAA",
    code: "te6ccgECHgEABK0ABCSK7VMg4wMgwP/jAiDA/uMC8gsTAgEdA8jtRNDXScMB+GaJ+Gkh2zzTAAGOH4MI1xgg+CjIzs7J+QAB0wABlNP/AwGTAvhC4vkQ8qiV0wAB8nri0z8B+EMhufK0IPgjgQPoqIIIG3dAoLnytPhj0x8B+CO88rnTHwHbPPI8CggDA1LtRNDXScMB+GYi0NMD+kAw+GmpOADcIccA4wIh1w0f8rwh4wMB2zzyPBISAwIoIIIQSYCJ6bvjAiCCEHGkYr674wIMBAM8IIIQT5XdorrjAiCCEFSlM6i64wIgghBxpGK+uuMCCwYFAU4w0ds8+E8hjhuNBHAAAAAAAAAAAAAAAAA8aRivoMjOzslw+wDe8gARA/4w+EJu4wD4RvJzIZXU1NTR0JLU1OL6QNTR0PpA1NHQ+kDTB9Mf9ARZbwISbwIB0fhK+EnHBfLgivgnbxCCEHc1lAC88uBr+ABVBPhsVQP4bVUC+G5Y+G8B+HD4cfhOyM74Ss8W+CrQAcnbPCD7BNAgizits1jHBZPXTdDe10zQCBkHASLtHu1TghAsqN7v7UPY2zzyABgCFu1E0NdJwgGOgOMNCREEiHDtRND0BXEhgED0Do6Bid9yIoBA9A6OgYnfiCCJXyBwIG1vAm8C+HH4cPhv+G74bfhs+Gv4aoBA9A7yvdcL//hicPhjCgodCgBDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAFoMNHbPPhRIY4ojQRwAAAAAAAAAAAAAAAAM+V3aKDIzgFvIgLLBwFvIgLLH/QAyXD7AN7yABEEUCCCEBjgnZe64wIgghAaEanKuuMCIIIQQN8VgLrjAiCCEEmAiem64wIQDw4NAU4w0ds8+FAhjhuNBHAAAAAAAAAAAAAAAAAyYCJ6YMjOzslw+wDe8gARAU4w0ds8+E4hjhuNBHAAAAAAAAAAAAAAAAAwN8VgIMjOzslw+wDe8gARAU4w0ds8+EshjhuNBHAAAAAAAAAAAAAAAAAmhGpyoMjOzslw+wDe8gARAU4w0ds8+EohjhuNBHAAAAAAAAAAAAAAAAAmOCdl4MjOzslw+wDe8gARAIjtRNDT/9M/0wAx+kDU0dD6QNTU1NHQ+kDU0dD6QNTR0PpA0wfTH/QEWW8CEm8CAdH4cfhw+G/4bvht+Gz4a/hq+GP4YgAK+Eby4EwCEPSkIPS98sBOFRQAFHNvbCAwLjY2LjACAnEXFgEZuCqHtevg+2efAf5AEBgCkbmVG93/CdkZ3wl54t8JmgA5O2eEH2CaBBFnFbZrGOCyeum6G9rpmh2j3ap/Cb8J3wn/Ch8JXwl/CjBCBiqHtf2oextnnwH+QBAZGACE+FH4UPhP+E74TfhM+Ev4SvhD+ELIy//LP8+DzlVgyM7MzFUwyM5VIMjOWcjOAW8iAssHAW8iAssf9ADNzc3Nye1UAhYhizits1jHBYqK4hsaAQgB2zzJHAEmAdTUMBLQ2zzIz44rbNYSzM8RyRwBdtWLL0pA1yb0BNMJMSDXSpHUjoKIAeKLX0vfLATo1yYwAcjPi9KQ9ACAIM8LCc+X0vfLATrMEszIzxHOHQAA",
    codeHash: "3d2580b5eb112a62ec558815b6403fbc921e698b6fe50e52205ade42982df4c1",
};
module.exports = { H2QuestClientPlatformContract };