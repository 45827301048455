import { getRandomText } from "./randomText";
import { inputSquareSize, TCellValue, TEpoch, TInputCell, TInputSquare, TSquareCellDiffColor, TSquareCellDiffSuperColor } from "./squares";

export const fillQuestWithDefaultValues = (x: number, y: number): TInputCell => {
  const newCell: TInputCell = {
    value: x + y + 1 > inputSquareSize ? x + y + 1 - inputSquareSize as TCellValue : x + y + 1 as TCellValue,
    // value: null,
    mainColor: null,
    diffColor: null,
    superColor: null,
    isError: false,
    questParams: {
      essentialParams: {
        levelMin: 0,
        levelMax: 99,
        inventoryMask: ""
      },
      mandatoryParams: {
        startTime: Number(Math.floor(Date.now() / 1000)),
        finishTime: Number(Math.floor(Date.now() / 1000 + 31 * 24 * 60 * 60)),
        name: `Quest-${(x + 1) * (y + 1)}`,
        worldTag: "World Tag",
        actionTag: "Action Tag",
        questNarrative: "Quest Narrative",
        qstAmount: 0
      },
      additionalParams: ""
    },
    rewardParams: {
      maxWinners: 300,
      rewardsMask: "",
      qstTotalReward: 100,
      h2qTotalReward: 100,
      experienceReward: 500,
      maxParticipants: 100
    },
    isDeployed: false,
    isChecked: false,
    questId: `0:${getRandomText(30).replace(/[^a-zA-Z0-9]/g, "")}`,
  };

  return newCell;
};

export const updateQuestWithRandomGeneratedValues = (quest: TInputCell, x: number, y: number): TInputCell => {
  quest.questId = `0:${getRandomText(30).replace(/[^a-zA-Z0-9]/g, "")}`;
  quest.isDeployed = Math.random() * 10 > 7 ? true : false;
  quest.isChecked = true;

  quest.questParams.essentialParams.levelMin = Math.floor(Math.random() * (50 + 1));
  quest.questParams.essentialParams.levelMax = Math.floor(Math.random() * (99 - 50 + 1) + 50);

  quest.questParams.mandatoryParams.startTime = Math.floor(Math.random() * 1 * 60 * 60 + Date.now() / 1000);
  quest.questParams.mandatoryParams.finishTime = Math.floor(Math.random() * 31 * 24 * 60 * 60 + Date.now() / 1000);
  quest.questParams.mandatoryParams.qstAmount = Math.floor(Math.random() * 50 + 5);
  quest.questParams.mandatoryParams.name = `Quest-${(x + 1) * (y + 1)}-${getRandomText(30)}`;
  quest.questParams.mandatoryParams.worldTag = `${getRandomText(30)}`;
  quest.questParams.mandatoryParams.actionTag = `${getRandomText(30)}`;
  quest.questParams.mandatoryParams.questNarrative = `${getRandomText(150)}`;

  quest.rewardParams.maxWinners = Math.floor(Math.random() * 300 + 30);
  quest.rewardParams.qstTotalReward = Math.floor(Math.random() * 100 + 10);
  quest.rewardParams.h2qTotalReward = Math.floor(Math.random() * 100 + 10);
  quest.rewardParams.experienceReward = Math.floor(Math.random() * 500 + 50);
  quest.rewardParams.maxParticipants = Math.floor(Math.random() * 100 + 50);

  return quest;
};

export const makeEmptySeason = (): TInputSquare => {
  const square = {} as TInputSquare;

  const cellData = {} as TInputCell;
  const emptyCells = new Array(7).fill(null).map(() => new Array(7).fill({ ...cellData }))
    .map((row, y) => {
      return row.map((cell, x) => {
        return fillQuestWithDefaultValues(x, y);
      })
    });

  const cellColorsData = {} as TSquareCellDiffColor;
  const cellColors = new Array(inputSquareSize).fill(null).map(() => { return { ...cellColorsData } });

  const cellSuperColorsData = {} as TSquareCellDiffSuperColor;
  // const cellSuperColors = new Array(2).fill(null).map(() => { return { ...cellSuperColorsData } });

  square.cells = emptyCells;
  square.cellDiffColors = cellColors;
  square.cellSuperColors = cellSuperColorsData;
  square.seasonTitle = "New Season Title";
  square.seasonDescr = "New Season Description";

  return square;
};

export const makeEmptyEpoch = (epochId: number) => {
  const newEpoch = {} as TEpoch;
  newEpoch.seasons = [];
  newEpoch.title = "NewEpoch-" + epochId;

  // const ids = {} as TEpochColorIds;

  // Object.keys(cellAllColors).forEach((colorKey) => {
  //   const newEpochId = {} as TEpochColorId;
  //   newEpochId.title = null;
  //   newEpochId.costumeIds = [];
  //   ids[colorKey as TCellAllColor] = newEpochId;
  // });

  // newEpoch.colorIds = ids;
  return newEpoch;
};