import { useContext } from "react";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { FilterCloseButton } from "shared/ui";
import { initialMinNftLevel, initialMinNftValue } from "features/nftFilter/model/filterNfts";
import { getGeneratorTitleByGenId } from "entities/generatorSelector";

export const NftSelectedTopBar = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  return (
    <>
      {h2qAccount.uiStore.nftFilter.genId.length > 0 &&
        h2qAccount.uiStore.nftFilter.genId.map(g => {
          return (
            <FilterCloseButton key={g} name={getGeneratorTitleByGenId(g)} clickAction={() => h2qAccount.uiStore.changeNftFilterGenId(g)} />
          )
        })
      }
      {h2qAccount.uiStore.nftFilter.avatarName.length > 0 &&
        h2qAccount.uiStore.nftFilter.avatarName.map(n => {
          return (
            <FilterCloseButton key={n} name={n} clickAction={() => h2qAccount.uiStore.changeNftFilterAvatarName(n)} />
          )
        })
      }
      {h2qAccount.uiStore.nftFilter.nftMinValue !== initialMinNftValue &&
        <FilterCloseButton
          name={`Min Value - ${h2qAccount.uiStore.nftFilter.nftMinValue}`}
          clickAction={() => h2qAccount.uiStore.changeNftFilterMinValue(initialMinNftValue)}
        />
      }
      {h2qAccount.uiStore.nftFilter.nftMaxValue !== h2qAccount.uiStore.nftMaxValue &&
        <FilterCloseButton
          name={`Max Value - ${h2qAccount.uiStore.nftFilter.nftMaxValue}`}
          clickAction={() => h2qAccount.uiStore.changeNftFilterMaxValue(h2qAccount.uiStore.nftMaxValue)}
        />
      }
      {h2qAccount.uiStore.nftFilter.nftOriginType.length > 0 &&
        h2qAccount.uiStore.nftFilter.nftOriginType.map(o => {
          return (
            <FilterCloseButton key={o} name={o} clickAction={() => h2qAccount.uiStore.changeNftFilterOriginType(o)} />
          )
        })
      }
      {h2qAccount.uiStore.nftFilter.nftOriginality.length > 0 &&
        h2qAccount.uiStore.nftFilter.nftOriginality.map(o => {
          return (
            <FilterCloseButton key={o} name={Math.ceil(o * 7 / 100).toString().concat("/7")} clickAction={() => h2qAccount.uiStore.changeNftFilterOriginality(o)} />
          )
        })
      }
      {h2qAccount.uiStore.nftFilter.nftMinLevel !== initialMinNftLevel &&
        <FilterCloseButton
          name={`Min Level - ${h2qAccount.uiStore.nftFilter.nftMinLevel}`}
          clickAction={() => h2qAccount.uiStore.changeNftFilterMinLevel(initialMinNftLevel)}
        />
      }
      {h2qAccount.uiStore.nftFilter.nftMaxLevel !== h2qAccount.uiStore.nftMaxLevel &&
        <FilterCloseButton
          name={`Max Level - ${h2qAccount.uiStore.nftFilter.nftMaxLevel}`}
          clickAction={() => h2qAccount.uiStore.changeNftFilterMaxLevel(h2qAccount.uiStore.nftMaxLevel)}
        />
      }
    </>
  )
})