import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { MarbleIdenticon } from "pages/UserAccountPage/ProfileDetails/ProfileDetails";
import { useContext } from "react";
import styles from "./SideBarAvatar.module.scss";

interface ISideBarAvatarBlock {
  isOpen: boolean;
};

export const SideBarAvatarBlock = observer(function ({ isOpen }: ISideBarAvatarBlock) {
  const { h2qAccount } = useContext(H2QContext);

  const accountId = localStorage.getItem("currentAddress") || "";

  return (
    <>
      <div className={styles["user-bar__avatar"]}>
        <MarbleIdenticon name={accountId} />
      </div>
      <div
        className={`${styles["user-bar__name"]} ${isOpen ? "" : styles.closed}`}>
        {h2qAccount.uiStore.userNickname}
      </div>
    </>
  )
});