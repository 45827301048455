import { Container, PixiComponent, Text } from "@saitonakamura/react-pixi";
import { countBlockAlpha, countBlockColor, countBlockFontColor, countBlockFontFamily, countBlockFontSize, countBlockSize } from "entities/generator";
import { Graphics, TextStyle } from "pixi.js";
import { useEffect, useState } from "react";

interface ISquareAmountBlockBg {
  size: number;
  color: number;
  alpha: number;
};
const SquareAmountBlockBg = PixiComponent<ISquareAmountBlockBg, Graphics>("SquareAmountBlockBg", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(props.color, props.alpha);
    ins.moveTo(0, 0);
    ins.lineTo(0, 0 - props.size);
    ins.lineTo(0 + (props.size * 0.75), 0 - props.size);
    ins.lineTo(0 + props.size, 0 - (props.size * 0.75));
    ins.lineTo(0 + props.size, 0);
    ins.lineTo(0, 0);
    ins.endFill();
  }
});

interface ISquareAmountLabel {
  size: number;
  label: number;
};

const SquareAmountLabel = (props: ISquareAmountLabel) => {
  return (
    <Text
      text={props.label.toString()}
      anchor={[0.5, 0.5]}
      x={props.size / 2}
      y={- props.size / 2}
      style={
        new TextStyle({
          fontFamily: countBlockFontFamily,
          fontSize: countBlockFontSize,
          fill: countBlockFontColor,
          fontWeight: "700",
          align: "center",
        })
      }
    />
  )
};

interface ISquareAmountBlock {
  x: number;
  y: number;
  value: number;
  onHoverOver: () => void;
  onHoverOut: () => void;
};

export const SquareAmountBlock = (props: ISquareAmountBlock) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true)
  }, [props]);

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      mouseover={props.onHoverOver}
      mouseout={props.onHoverOut}
    >
      {isLoaded &&
        <>
          <SquareAmountBlockBg size={countBlockSize} color={countBlockColor} alpha={countBlockAlpha} />
          <SquareAmountLabel size={countBlockSize} label={props.value} />
        </>
      }
    </Container>
  )
}