import { Navigate, Route, Routes } from "react-router-dom";
import LoginMainPage from "pages/LoginMainPage/LoginMainPage";
import { GeneratorSelector } from "pages/GeneratorSelector/GeneratorSelector";
import { AssetsNftPage } from "pages/AssetsNftPage/AssetsNftPage";
import { NftPage } from "pages/NftPage/NftPage";
import NotFound from "pages/NotFound/NotFound";
import { Generator } from "pages/Generator/Generator";
import { AddInventory } from "pages/AdminPanel/AddInventory/AddInventory";
import { WrapperMainComponent } from "pages/WrapperMainComponent/WrapperMainComponent";
import { AddQuest } from "pages/AdminPanel/AddQuest/AddQuest";
import AdminPage from "pages/AdminPanel/AdminPage/AdminPage";
import { QuestsPage } from "pages/QuestsPage/QuestsPage";
import { ProfileDetails } from "pages/UserAccountPage/ProfileDetails/ProfileDetails";
import { LoginToAccount } from "pages/LoginLoginToAccount/LoginToAccount";
import { CreateNewAccountPage } from "pages/LoginCreateAccount/CreateNewAccount";
import { SelectedQuestPage } from "pages/SelectedQuestPage/SelectedQuestPage";
import { QuestsForNftPage } from "pages/QuestsForNftPage/QuestsForNftPage";
import { ExpectedQuestsPage } from "pages/ExpectedQuestsPage/ExpectedQuestsPage";
import { GameStat } from "pages/UserAccountPage/GameStat/GameStat";
import Settings from "pages/UserAccountPage/Settings/Settings";
import Notifications from "pages/UserAccountPage/Notifications/Notifications";
import CryptoAcc from "pages/UserAccountPage/CryptoAcc/CryptoAcc";
import SupportPage from "pages/SupportPage/SupportPage";
import WrapperOpenedComponent from "pages/WrapperOpenedComponent/WrapperOpenedComponent";
import UserGuidePage from "pages/UserGuidePage/UserGuidePage";
import { AssetsMainPage } from "pages/AssetsMainPage/AssetsMainPage";
import { AssetsAvatarsPage } from "pages/AssetsAvatarsPage/AssetsAvatarsPage";
import { AssetsItemsPage } from "pages/AssetsItemsPage/AssetsItemsPage";
import { AddQuestNew } from "./AdminPanel/AddQuestNew/AddQuestNew";
import { LoginSetPin } from "./LoginSetPin/LoginSetPin";
import ErrorOnLogin from "./ErrorOnLogin/ErrorOnLogin";

export default function Routing() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="error-login" element={<ErrorOnLogin />} />

      <Route
        path="/"
        element={<Navigate to="/h2q/generator" />}
      />

      <Route path="/" element={<WrapperOpenedComponent />}>
        <Route path="support" element={<SupportPage />} />
        <Route path="guide" element={<UserGuidePage />} />
      </Route>

      <Route path="/h2q" element={<WrapperMainComponent />}>

        <Route path="admin" element={<AdminPage />}>
          <Route path="" element={<Navigate to="add-inventory" />} />
          <Route path="add-inventory" element={<AddInventory />} />
          <Route path="add-quest" element={<AddQuest />} />
          <Route path="add-quest-seasons" element={<AddQuestNew />} />
        </Route>

        <Route path="collections" element={<AssetsMainPage />}>
          <Route path="" element={<Navigate to="avatar" />} />
          <Route path="avatar" element={<AssetsAvatarsPage />} />
          <Route path="item" element={<AssetsItemsPage />} />
        </Route>

        <Route path="nft" element={<AssetsNftPage />} />
        <Route path="nft/:id" element={<NftPage />} />

        <Route path="generator" element={<GeneratorSelector />} />
        <Route path="generator/:id" element={<Generator />} />

        <Route path="quests" element={<QuestsPage />} />
        <Route path="quests/:id" element={<SelectedQuestPage />} />
        <Route path="quests/for-nft-id/:id" element={<QuestsForNftPage />} />

        <Route path="expected-quests" element={<ExpectedQuestsPage />} />

        <Route path="user">
          <Route path="profile-details" element={<ProfileDetails />} />
          <Route path="game-stat" element={<GameStat />} />
          <Route path="settings" element={<Settings />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="crypto-acc" element={<CryptoAcc />} />
        </Route>

      </Route>

      <Route path="/login-page" element={<LoginMainPage />} />
      <Route path="/login-page/login" element={<LoginToAccount />} />
      <Route path="/login-page/create" element={<CreateNewAccountPage />} />
      <Route path="/login-page/setpin" element={<LoginSetPin />} />
    </Routes>

  )
}
