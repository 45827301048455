import { NftFilter, NftFilterTopBar, NFTList, NFTStats } from "widgets";
import { ReactComponent as HeroesLogo } from "app/icons/Logo_HERO_white.svg";
import styles from "./AssetsNftPage.module.scss";
import { PageTitle } from "entities/PageTitle";
import { FilterWrapper } from "entities/FilterWrapper";
import { useState } from "react";
import { SelectGeneratorMessage } from "features/SelectGeneratorMessage";

export const AssetsNftPage = () => {
  const [isFilterShown, toggleFilterShown] = useState<boolean>(false);
  const genId: number | null = Number(localStorage.activeGen) || null;

  console.log("On nfts collections page");

  return (
    <>
      <div className={styles.collections__wrapper}>
        <PageTitle>
          <HeroesLogo />
        </PageTitle>

        {genId
          ? <>
            <NFTStats />
            <NftFilterTopBar toggleFilter={() => toggleFilterShown(prev => !prev)} />
            <FilterWrapper
              shown={isFilterShown}
              closeFilter={() => toggleFilterShown(false)}
            >
              <NftFilter />
            </FilterWrapper>
            <NFTList />
          </>
          : <SelectGeneratorMessage />
        }
      </div>
    </>
  );
}
