import { h2qAccount } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";

export const filterByFinishTime = (quest: H2QuestResponse) => {
  return quest.m_questParams.mandatoryParams.finishTime * 1000 > Date.now();
};

export const filterByStartTime = (quest: H2QuestResponse) => {
  return quest.m_questParams.mandatoryParams.startTime * 1000 < Date.now();
};

export const filterQuests = (quests: H2QuestResponse[]): H2QuestResponse[] => {
  const questsByFinish = filterQuestByExpired(quests);
  const questsByExpected = filterQuestByExpected(questsByFinish);
  const questsByFav = filterQuestByFav(questsByExpected);
  const questsByStaked = filterQuestByStaked(questsByFav);

  return questsByStaked;
};

const filterQuestByExpired = (quests: H2QuestResponse[]): H2QuestResponse[] => {
  return quests.filter(q => filterByFinishTime(q))
};

const filterQuestByExpected = (quests: H2QuestResponse[]): H2QuestResponse[] => {
  return h2qAccount.uiStore.questFilter.expectedMode
    ? quests.filter(q => !filterByStartTime(q))
    : quests
};

const filterQuestByFav = (quests: H2QuestResponse[]): H2QuestResponse[] => {
  const favQuests = h2qAccount.uiStore.questFilter.favQuests;
  const favMode = h2qAccount.uiStore.questFilter.favMode;

  return !favMode
    ? quests
    : quests.length === 0
      ? quests
      : quests.filter(q => favQuests.includes(q.id))
};

const filterQuestByStaked = (quests: H2QuestResponse[]): H2QuestResponse[] => {
  const stakedMode = h2qAccount.uiStore.questFilter.stakedMode;
  const stakedQuests = Object.keys(h2qAccount.uiStore.questFilter.stakedQuests);

  return !stakedMode
    ? quests
    : quests.length === 0
      ? quests
      : quests.filter(q => stakedQuests.includes(q.id))
}