import styles from "./UserSocialInputBlock.module.scss";
import { ReactComponent as ErrorIcon } from "app/icons/icon-error-red.svg";
import { useState } from "react";
import { UserSocial } from "../model/socials";

interface IUserSocialInputBlock {
  item: UserSocial;
  value: string;
  onChangeValue: (val: string, id: number) => void;
};

export function UserSocialInputBlock({ item, value, onChangeValue }: IUserSocialInputBlock) {
  const [isActive, setActive] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles["icon-wrapper"]}>
        <img src={item.icon} alt={"Icon " + item.title} />
      </div>
      <div className={styles["input-text-wrapper"]}>
        <label>{item.title}</label>
        <div
          className={`${styles["string-block"]} ${item.isError ? styles["string-block-error"] : ""} ${isActive ? styles.active : ""}`}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        >
          <input type="email" value={value} placeholder={item.placeHolder} onChange={(e) => onChangeValue(e.currentTarget.value, item.id)} />
          {item.isError &&
            <>
              <label className={styles["label-error"]}>Wrong format!</label>
              <ErrorIcon
                className={styles["icon-error"]}
              />
            </>
          }
        </div>
      </div>
    </div>)
}
