import { H2QuestResponse } from "app/core/H2QQuestManager";
import { InitialAvatarElement } from "entities/avatar";
import { InitialItemElement } from "entities/item";
import { makeNftItemsObject, makeNftObject, NFTInfo, NftItems } from "entities/nft";
import { ItemHexMasktoAvatarIdNumber, ItemHexMasktoItemArray } from "..";

const checkAvatar = (avatar: InitialAvatarElement, quest: H2QuestResponse): boolean => {
  const questAvatarNumber = ItemHexMasktoAvatarIdNumber(quest.m_questParams.essentialParams.inventoryMask);

  if (questAvatarNumber >= 0) {
    return questAvatarNumber === avatar.itemId
  }
  else return true;
};

const checkItem = (nftItems: NftItems<InitialItemElement>, quest: H2QuestResponse): boolean => {
  const questItemsNumber = ItemHexMasktoItemArray(quest.m_questParams.essentialParams.inventoryMask);
  const questItems = makeNftItemsObject(questItemsNumber);
  return checkAllItemsExist(nftItems, questItems);
};

const checkAllItemsExist = (nftItems: NftItems<InitialItemElement>, questItems: NftItems<InitialItemElement>): boolean => {
  const questItemsData = Object.values(questItems)
    .map((qItem: InitialItemElement | null) => {
      if (qItem) {
        return `${qItem.categoryId}-${qItem.itemId}`
      }
      else return null;
    })
    .filter(item => item);

  if (questItemsData.length === 0) {
    return true;
  }

  const nftItemsData = Object.values(nftItems).map((nItem: InitialItemElement) => {
    return `${nItem.categoryId}-${nItem.itemId}`
  });
  return questItemsData.every(value => nftItemsData.includes(value!));
};

const checkLevelMin = (nftLvl: number, levelMin: number): boolean => {
  return Number(nftLvl) >= levelMin;
};

const checkLevelMax = (nftLvl: number, levelMax: number): boolean => {
  return Number(nftLvl) <= levelMax;
};

export const isQuestValidForNft = (nftInfo: NFTInfo, quest: H2QuestResponse): boolean => {
  const nft = makeNftObject(nftInfo);
  const result =
    checkAvatar(nft.avatar, quest) &&
    checkItem(nft.items, quest) &&
    checkLevelMin(Number(nft.pseudoNftData!.m_h2qMetaCharacter.level), quest.m_questParams.essentialParams.levelMin) &&
    checkLevelMax(Number(nft.pseudoNftData!.m_h2qMetaCharacter.level), quest.m_questParams.essentialParams.levelMax);

  return result;
};