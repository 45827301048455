import { ModalCancelButton, ModalText, ModalTitle, ModalTypes, ModalWrapperChildren, ModalWrapperOut } from "entities/generator";

const type: ModalTypes = "allItemsWereUsed";

interface IItemsWereUsed {
  reject: () => void;
}

export const AllItemsWereUsed = ({ reject }: IItemsWereUsed) => {

  return (
    <>
      <ModalWrapperOut>
        <ModalTitle type={type} />

        <ModalWrapperChildren>
          <ModalText type={type} />
          <ModalCancelButton type={type} reject={reject} />
        </ModalWrapperChildren>
      </ModalWrapperOut>
    </>
  )
};