import { Sprite } from "@saitonakamura/react-pixi";
import { H2QContext } from "app/core/H2QContext";
import { cellBorder, cellOutSize, CircleAmountBlock, circleBlockSize, fieldHeight, FieldWithBottomValue, GeneratorTooltip, GenTooltipsType, itemsValuePriceWidth, reloadBtnCoords, SlotReloadBtn } from "entities/generator";
import { observer } from "mobx-react";
import { useContext, useState } from "react";

interface IReloadItems {
  isAvatarSelected: boolean;
  isTooltipsActive: boolean;
  isVertical: boolean;
  onUpdateItems: () => void;
}
export const ReloadItems = observer(function ({ isAvatarSelected, isTooltipsActive, isVertical, onUpdateItems }: IReloadItems) {
  const { h2qAccount } = useContext(H2QContext);
  const [itemHovered, setItemHovered] = useState<GenTooltipsType | null>(null);

  //amout of total different items in user's collection
  const totalDiffItems = h2qAccount.data.m_inventories
    ? h2qAccount.data.m_inventories.reduce((sumCat, category) => sumCat = sumCat + category.reduce((sumItem, item) => sumItem = item > 0 ? sumItem + 1 : sumItem, 0), 0)
    : 0;

  return (
    <SlotReloadBtn
      x={reloadBtnCoords.x}
      y={reloadBtnCoords.y}
      btnIcon={
        <Sprite
          image={"/res/Generator/btt-in-hero-item-reload.svg"}
          anchor={[0.5, 0.5]}
          x={cellOutSize / 2}
          y={cellOutSize / 2 - fieldHeight / 2}
          width={200}
          height={200}
          interactive={true}
          buttonMode={isAvatarSelected ? true : false}
          alpha={itemHovered === "itemTypesAmount" || itemHovered === "newReloadBtn" || itemHovered === "emptyReloadBtn" ? 0.35 : 0.25}
          pointerdown={onUpdateItems}
          mouseover={() => setItemHovered(h2qAccount.data.m_generatedHero ? "newReloadBtn" : "emptyReloadBtn")}
          mouseout={() => setItemHovered(null)}
        />
      }
      centerCount={
        <CircleAmountBlock
          value={totalDiffItems}
          x={cellOutSize / 2 - circleBlockSize / 2}
          y={cellOutSize / 2 - fieldHeight / 2 - circleBlockSize / 2}
          onHoverOver={() => { setItemHovered(prev => "itemTypesAmount") }}
          onHoverOut={() => setItemHovered(null)}
          onClick={onUpdateItems}
        />
      }
      bottomCount={
        <FieldWithBottomValue
          label="Items Value"
          value={h2qAccount.data.m_generatedHero?.metaCharacter.valPrice
            ? Number(h2qAccount.data.m_generatedHero.metaCharacter.valPrice)
            : 0
          }
          x={cellBorder + cellBorder / 2 - 1}
          y={cellOutSize - fieldHeight - cellBorder - 2}
          width={itemsValuePriceWidth + 1}
          height={fieldHeight}
          onHoverOver={() => setItemHovered("itemsValue")}
          onHoverOut={() => setItemHovered(null)}
        />
      }
      tooltips={isTooltipsActive
        ? <>
          {itemHovered && itemHovered !== "itemTypesAmount" &&
            <GeneratorTooltip
              type={itemHovered}
              isVertical={isVertical}
            />
          }
          {itemHovered === "itemTypesAmount" &&
            <GeneratorTooltip
              type={"itemTypesAmount"}
              isVertical={isVertical}
            />
          }
        </>
        : null
      }
    />
  )
});