import { ReactComponent as IconGeneratorTitle } from "app/icons/Title_CREATOR_white.svg";

export const GeneratorTitle = () => {
  return (
    <IconGeneratorTitle
      style={{
        position: "absolute",
        height: "2.5rem",
        top: "3.5rem",
        left: "6.5rem",
        opacity: 0.5,
      }}
    />
  )
};