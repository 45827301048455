import { InfoStatisticsBlock } from "entities/InfoStatisticsBlock";
import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { observer } from "mobx-react";
import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./QuestsTotalStats.module.scss";
import { calcQuestTotalStats } from "widgets/questStats";

interface IQuestTotalStats {
  quests: H2QuestResponse[];
};

export const QuestsTotalStats: FC<PropsWithChildren<IQuestTotalStats>> = observer(function ({ quests, children }) {
  const { h2qAccount } = useContext(H2QContext);

  const questsStat = calcQuestTotalStats(quests);

  const { pathname } = useLocation();
  const pageUrl = pathname.split("/").slice(-1)[0];

  useEffect(() => {
    const getNftLocked = async () => {
      await h2qAccount.getTotalNfts();
    };

    getNftLocked();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["top-info-wrapper"]}>
      <InfoStatisticsBlock
        title="All Quests"
        value={h2qAccount.loadAllQuests.state === "pending"
          ? "..."
          : questsStat.totalQuests
        }
      />
      {pageUrl === "quests" &&
        <InfoStatisticsBlock
          title="Heroes Playing"
          value={h2qAccount.loadAllQuests.state === "pending"
            ? "..."
            : h2qAccount.totalNfts.reduce((acc, nft) => {
              const questsIds = [...h2qAccount.uiStore.questsToShow.map(q => q.id)];
              const isPlaying = nft.m_lockedInQuest && questsIds.includes(nft.m_lockedInQuest.quest);
              return isPlaying ? acc + 1 : acc;
            }, 0)
          }
        />
      }
      <InfoStatisticsBlock
        title="H2Q Pool / Total"
        value={h2qAccount.loadAllQuests.state === "pending"
          ? "..."
          : `${questsStat.tokensPoolH2Q}/${questsStat.tokensTotalH2Q}`
        }
        children={<span className={styles.h2q}>H2Q</span>}
      />

      <InfoStatisticsBlock
        title="QST Pool / Total"
        value={h2qAccount.loadAllQuests.state === "pending"
          ? "..."
          : `${questsStat.tokensPoolQST}/${questsStat.tokensTotalQST}`
        }
        children={<span className={styles.qst}>QST</span>
        }
      />
      <InfoStatisticsBlock
        title={pageUrl === "quests" ? "Items Pool / Total" : "Items Total"}
        value={h2qAccount.loadAllQuests.state === "pending"
          ? "..."
          : pageUrl === "quests"
            ? `${questsStat.totalInventoryLocked}/${questsStat.totalInventory}`
            : questsStat.totalInventory
        }
      />
      {children}
    </div>
  )
});