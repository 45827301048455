const IndexContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "root",
                        "type": "address"
                    }
                ],
                "outputs": []
            },
            {
                "name": "getInfo",
                "inputs": [],
                "outputs": [
                    {
                        "name": "addrRoot",
                        "type": "address"
                    },
                    {
                        "name": "addrOwner",
                        "type": "address"
                    },
                    {
                        "name": "addrData",
                        "type": "address"
                    }
                ]
            },
            {
                "name": "destruct",
                "inputs": [],
                "outputs": []
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "_addrData",
                "type": "address"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "_addrRoot",
                "type": "address"
            },
            {
                "name": "_addrOwner",
                "type": "address"
            },
            {
                "name": "_addrData",
                "type": "address"
            }
        ]
    },
    tvc: "te6ccgECGAEAAskAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsVBQQXA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPA8OBgNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwUFAYDPCCCEBXvwNq64wIgghBHVlTcuuMCIIIQR8Yt2rrjAhAJBwMkMPhG8uBM+EJu4wDR2zzbPPIAEwgKADT4SfhMxwXy4GT4TMjPhQjOgG/PQMmBAKD7AASGMPhCbuMA+EbycyGT1NHQ3vpA0fgq2zwgbvLQZSBu8n/Q+kD6QDD4SfhMxwXy4GT4ACH4avhriccFkyD4at4w2zzyAA4LDwoAOvhM+Ev4SvhD+ELIy//LP8+DzlnIzgHIzs3Nye1UAhjQIIs4rbNYxwWKiuIMDQEK103Q2zwNAEjXTNCLL0pA1yb0BDHTCTGLX0vfLATo1yYg10rCAZLXTZIwbeIDYu1E0NdJwgGPJnDtRND0BYkgcSOAQPQOjoGJ3/hs+Gv4aoBA9A7yvdcL//hicPhj4w0PDxMAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABADgjD4RvLgTPhCbuMA0ds8I44oJdDTAfpAMDHIz4cgznHPC2FeIMjPkle/A2rOWcjOAcjOzc3NyXD7AJJfA+LjAPIAExIRACjtRNDT/9M/MfhDWMjL/8s/zsntVAAM+Er4S/hMAELtRNDT/9M/0wAx+kDU0dD6QNTR0PpA0fhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLAThcWABRzb2wgMC42Ni4wAAA=",
    code: "te6ccgECFQEAApwABCSK7VMg4wMgwP/jAiDA/uMC8gsSAgEUA6btRNDXScMB+GaJ+Gkh2zzTAAGOFIMI1xgg+CjIzs7J+QBY+EL5EPKo3tM/AfhDIbnytCD4I4ED6KiCCBt3QKC58rT4Y9MfAfgjvPK50x8B2zzyPAwLAwNS7UTQ10nDAfhmItDTA/pAMPhpqTgA3CHHAOMCIdcNH/K8IeMDAds88jwREQMDPCCCEBXvwNq64wIgghBHVlTcuuMCIIIQR8Yt2rrjAg0GBAMkMPhG8uBM+EJu4wDR2zzbPPIAEAUHADT4SfhMxwXy4GT4TMjPhQjOgG/PQMmBAKD7AASGMPhCbuMA+EbycyGT1NHQ3vpA0fgq2zwgbvLQZSBu8n/Q+kD6QDD4SfhMxwXy4GT4ACH4avhriccFkyD4at4w2zzyAAsIDAcAOvhM+Ev4SvhD+ELIy//LP8+DzlnIzgHIzs3Nye1UAhjQIIs4rbNYxwWKiuIJCgEK103Q2zwKAEjXTNCLL0pA1yb0BDHTCTGLX0vfLATo1yYg10rCAZLXTZIwbeIDYu1E0NdJwgGPJnDtRND0BYkgcSOAQPQOjoGJ3/hs+Gv4aoBA9A7yvdcL//hicPhj4w0MDBAAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABADgjD4RvLgTPhCbuMA0ds8I44oJdDTAfpAMDHIz4cgznHPC2FeIMjPkle/A2rOWcjOAcjOzc3NyXD7AJJfA+LjAPIAEA8OACjtRNDT/9M/MfhDWMjL/8s/zsntVAAM+Er4S/hMAELtRNDT/9M/0wAx+kDU0dD6QNTR0PpA0fhs+Gv4avhj+GIACvhG8uBMAhD0pCD0vfLAThQTABRzb2wgMC42Ni4wAAA=",
    codeHash: "f5f3e0aa639e590cd976548a2b24be44603b87d976ee61250b2333d8d14862a0",
};
module.exports = { IndexContract };