import iconRefresh from "app/icons/icon-refresh-white.svg";
import styles from "./ReloadButton.module.scss";

interface IReloadButton {
  reloadAction: () => void;
  isWaiting: boolean;
}

export const ReloadButton = ({ reloadAction, isWaiting }: IReloadButton) => {

  const action = () => {
    if (!isWaiting) reloadAction();
  };

  return (
    <button
      className={`${styles['reload-btn']} ${!isWaiting ? styles.active : ""}`}
      disabled={isWaiting}
    >
      <img className={`${styles["reload-image"]} ${isWaiting ? styles.rotating : ""}`} src={iconRefresh} alt="Reload Info" onClick={action} />
    </button>)
}
