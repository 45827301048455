import { RefreshButton } from "features/RefreshButton";
import { AccountStatus, Balances, TopChat } from "entities/topBar";
import { UnstakeAllButton } from "features/UnstakeAllButton";
import styles from "./TopBar.module.scss";
import { RewardsModalWindow } from "widgets/RewardsModalWindow/RewardsModalWindow";
import { useState } from "react";
import { RewardsModalWrapper } from "entities/RewardsModalWrapper";
import { ActiveGeneratorTopButton } from "features/ActiveGeneratorTopButton";

export const TopBar = () => {
  const [isRewardsShown, toggleRewardsShown] = useState<boolean>(false);

  if (!localStorage.getItem("currentAddress")) {
    return null;
  };

  return (
    <div className={styles["top-bar__wrapper"]}>
      <ActiveGeneratorTopButton />
      <UnstakeAllButton action={() => toggleRewardsShown(prev => !prev)} />
      <TopChat />
      <RefreshButton />
      <Balances />
      <RewardsModalWrapper isShown={isRewardsShown} close={() => toggleRewardsShown(false)}>
        <RewardsModalWindow isShown={isRewardsShown} hideRewards={() => toggleRewardsShown(false)} />
      </RewardsModalWrapper>
      <AccountStatus />
    </div >
  );
};