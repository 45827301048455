export interface NotificationItem {
  id: number;
  title: string;
  description: string;
  isSelected: boolean;
};

export const notificationList: NotificationItem[] = [
  {
    id: 0,
    title: "Adding new quests",
    description: "When a new quest appeared",
    isSelected: true,
  },
  {
    id: 1,
    title: "Unstaking heroes",
    description: "When a new quest appeared",
    isSelected: true,
  },
  {
    id: 2,
    title: "Claim rewards",
    description: "When a quest reward is ready to be taken",
    isSelected: true,
  },
  {
    id: 3,
    title: "New Hero Generator",
    description: "When a new Hero Generator was added to the Questnetwork system",
    isSelected: true,
  },
  {
    id: 4,
    title: "New Avatar added",
    description: "When a new Avatar was added to the Questnetwork system",
    isSelected: true,
  },
  {
    id: 5,
    title: "New Item added",
    description: "When a new Item was added to the Questnetwork system",
    isSelected: true,
  },
];