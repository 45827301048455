import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { calcQuestTotalStats } from "widgets/questStats";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./QuestFloatingStats.module.scss";

interface IQuestFloatingStats {
  quests: H2QuestResponse[];
};

export const QuestFloatingStats = observer(function ({ quests }: IQuestFloatingStats) {
  const { h2qAccount } = useContext(H2QContext);
  const [isShown, toggleIsShown] = useState<boolean>(false);
  const { pathname } = useLocation();
  const pageUrl = pathname.split("/").slice(-1)[0];

  const questsStat = calcQuestTotalStats(quests);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > 240) {
        toggleIsShown(true)
      } else {
        toggleIsShown(false)
      }
    };

    window.addEventListener('scroll', controlNavbar);

    return () => { window.removeEventListener('scroll', controlNavbar) };
  }, []);

  return (
    <div className={`${styles["top-info-wrapper"]} ${isShown ? styles.shown : ""}`}>
      <div className={styles["top-info"]}>
        <p className={styles["top-info__value"]}><span className={styles.title}>Quests:</span>
          {h2qAccount.totalNfts.length === 0
            ? "..."
            : questsStat.totalQuests
          }
        </p>
      </div>

      {pageUrl === "quests" &&
        <div className={styles["top-info"]}>
          <p className={styles["top-info__value"]}><span className={styles.title}>Heroes playing:</span>
            {h2qAccount.loadAllQuests.state === "pending"
              ? "..."
              : h2qAccount.totalNfts.reduce((acc, nft) => {
                const questsIds = [...h2qAccount.uiStore.questsToShow.map(q => q.id)];
                const isPlaying = nft.m_lockedInQuest && questsIds.includes(nft.m_lockedInQuest.quest);
                return isPlaying ? acc + 1 : acc;
              }, 0)
            }
          </p>
        </div>
      }

      <div className={styles["top-info"]}>
        <p className={styles["top-info__value"]}><span className={styles.title}>H2Q Pool/Total:</span>
          {h2qAccount.loadAllQuests.state === "pending"
            ? "..."
            : `${questsStat.tokensPoolH2Q}/${questsStat.tokensTotalH2Q}`
          }
          <span className={styles.h2q}>H2Q</span>
        </p>
      </div>

      <div className={styles["top-info"]}>
        <p className={styles["top-info__value"]}><span className={styles.title}>QST Pool/Total:</span>
          {h2qAccount.loadAllQuests.state === "pending"
            ? "..."
            : `${questsStat.tokensPoolQST}/${questsStat.tokensTotalQST}`
          }
          <span className={styles.qst}>QST</span>
        </p>
      </div>

      <div className={styles["top-info"]}>
        <p className={styles["top-info__value"]}><span className={styles.title}>Total Items:</span>
          {h2qAccount.loadAllQuests.state === "pending"
            ? "..."
            : questsStat.totalInventoryLocked
          }
        </p>
      </div>
    </div>)
});