import { ISideBarBlock, SideBarLink, sidebarMainData } from "entities/sideBar";
import styles from "./SideBarMainLinks.module.scss";

export const SideBarMainLinks = ({ isOpen, isLoggedIn }: ISideBarBlock) => {
  const sideMainData = isLoggedIn ? sidebarMainData : sidebarMainData.filter(item => item.isActiveForGuest);

  return (
    <div className={styles["side-nav__links-block"]}>
      {sideMainData.map((item) => {
        return (
          <SideBarLink
            key={item.id}
            item={item}
            isOpen={isOpen}
          />
        )
      })}
    </div>
  )
};