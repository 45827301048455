import { useState } from "react";
import { ItemFilterTopBar } from "widgets/ItemFilterTopBar/ItemFilterTopBar";
import { FilterWrapper } from "entities/FilterWrapper";
import { ItemFilter, ItemList } from "widgets";

export const AssetsItemsPage = () => {
  const [isFilterShown, toggleFilterShown] = useState<boolean>(false);

  return (
    <>
      <ItemFilterTopBar toggleFilter={() => toggleFilterShown(prev => !prev)} />
      <FilterWrapper
        shown={isFilterShown}
        closeFilter={() => toggleFilterShown(false)}
      >
        <ItemFilter />
      </FilterWrapper>
      <ItemList />
    </>
  );
};