import { Button } from "shared/ui";
import { RotatingPercent } from "shared/ui";
import { VerticalDivider } from "shared/ui";
import styles from "./LoadingImages.module.scss";

interface ILoadingImages {
  currentPercent: number;
  action: () => void;
};

export function LoadingImages({ currentPercent, action }: ILoadingImages) {
  return (
    <div className={styles["loading-wrapper"]}>
      <p className={styles.message}>Please wait while all assets are being loaded...</p>
      <RotatingPercent size={75}>
        <p style={{ fontWeight: 400, margin: 0, color: "#dadada" }}>
          {currentPercent + "%"}
        </p>
      </RotatingPercent>
      <VerticalDivider value={50} />
      <Button
        children={<p style={{ color: "white", fontSize: "20px", "textAlign": "center", padding: "0 10px" }}>I DO NOT WANT TO WAIT</p>}
        widthValue={"full"}
        colorValue={"green"}
        action={action}
      />
    </div>
  )
}