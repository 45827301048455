import { TCellColor, TCellColors, TCellSuperColor, TCellSuperColors } from "entities/questMaker";
import { useRef, useState } from "react";
import { useOutsideClick } from "shared/lib";
import styles from "./ChangeColorMenu.module.scss";

interface IChangeCellColor {
  selectedColor: TCellColor | TCellSuperColor | null;
  onSelectColor: (colorKey: string) => void;
  colors: TCellColors | TCellSuperColors;
};

export const ChangeCellColor = ({ colors, selectedColor, onSelectColor }: IChangeCellColor) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMenuOpened, setMenuOpened] = useState<boolean>(false);

  useOutsideClick(menuRef, () => {
    setMenuOpened(false);
  });

  const openMenu = (e: React.SyntheticEvent) => {
    setMenuOpened(prev => !prev);
    e.stopPropagation();
  };

  const chooseColor = (e: React.SyntheticEvent, colorKey: string) => {
    if (!isMenuOpened) return;

    onSelectColor(colorKey);
    setMenuOpened(false);
    e.stopPropagation();
  };

  const resetColor = (e: React.SyntheticEvent) => {
    if (!isMenuOpened) return;

    onSelectColor("null");
    setMenuOpened(false);
    e.stopPropagation();
  };

  return (
    <>
      <div ref={menuRef} className={`${styles.colors} ${isMenuOpened ? styles.opened : ""}`}>
        {Object.entries(colors).map(([colorKey, colorValue]) => {
          return (
            <div
              className={styles.color}
              style={{
                backgroundColor: `RGB${colorValue}`
              }}
              key={colorKey}
              onClick={(e) => chooseColor(e, colorKey)}
            >
            </div>
          )
        })}
        {selectedColor !== null &&
          <div className={`${styles.color} ${styles.reset}`} onClick={(e) => resetColor(e)}>CL</div>
        }
      </div>
      <button
        style={{
          backgroundColor: selectedColor
            ? selectedColor in colors
              ? `RGB${colors[selectedColor as keyof typeof colors]}`
              : ""
            : ""
        }}
        className={styles["reset-btn"]} onClick={(e) => openMenu(e)}
      />
    </>
  )
};