import { ReactNode } from "react";
import styles from "./QuestSeasons.module.scss";

interface IQuestSeasons {
  seasonsSlot: ReactNode;
  addSeasonSlot: ReactNode;
};

export const QuestSeasons = ({ seasonsSlot, addSeasonSlot }: IQuestSeasons) => {

  return (
    <>
      <div className={styles.squares}>
        {seasonsSlot}

        {addSeasonSlot}
      </div>
    </>
  )
};