import { H2QContext } from "app/core/H2QContext";
import { getNftInfo, NftElement } from "entities/nft";
import { Timer } from "entities/Timer";
import { observer } from "mobx-react";
import { useContext, useState } from "react";
import { UnstakeButton } from "shared/ui";
import styles from "./NftUnstakeAndTimer.module.scss";

interface INftUnstakeAndTimer {
  nftElement: NftElement;
};

export const NftUnstakeAndTimer = observer(function ({ nftElement }: INftUnstakeAndTimer) {
  const { h2qAccount } = useContext(H2QContext);
  const finishTime = nftElement.pseudoNftData?.m_lockedInQuest && nftElement.pseudoNftData.m_lockedInQuest
    ? nftElement.pseudoNftData.m_lockedInQuest.finishQuest * 1000
    : Infinity;

  const [isFinished, setIsFinished] = useState<boolean>(() => !!finishTime && Date.now() >= finishTime);

  const unstakeHero = async () => {
    const nftInfo = getNftInfo(nftElement);
    try {
      console.log("Gettin reward from quest...");
      await h2qAccount.q2hero(nftInfo);
    }
    catch (error) {
      console.warn("Error during getting reward");
      console.warn(error);
    }
    finally {
      h2qAccount.fetchNTFs();
      h2qAccount.loadData();
    }
  };

  return (
    <div className={styles["unstake-timer-block"]}>
      {!isFinished &&
        <Timer
          finishTime={finishTime}
          messageType={"heroInCurrentQuest"}
          onFinishAction={() => setIsFinished(true)}
        />
      }

      {isFinished &&
        <p className={styles["unstake-text"]}>Quest completed</p>
      }
      {isFinished &&
        <>
          <UnstakeButton
            action={unstakeHero}
            isWaiting={h2qAccount.q2hero.state === "pending"}
            style={{ "width": "12rem", "height": "3.5rem", "margin": "0" }}
          />
        </>
      }
    </div>
  )
});