const H2QAccountPlatformContract = {
    abi: {
        "ABI version": 2,
        "version": "2.3",
        "header": [
            "pubkey",
            "time",
            "expire"
        ],
        "functions": [
            {
                "name": "constructor",
                "inputs": [
                    {
                        "name": "code",
                        "type": "cell"
                    },
                    {
                        "name": "final_address",
                        "type": "address"
                    },
                    {
                        "name": "devmode",
                        "type": "bool"
                    }
                ],
                "outputs": []
            },
            {
                "name": "m_nonce",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nonce",
                        "type": "uint256"
                    }
                ]
            },
            {
                "name": "m_nftDeployer",
                "inputs": [],
                "outputs": [
                    {
                        "name": "m_nftDeployer",
                        "type": "address"
                    }
                ]
            }
        ],
        "data": [
            {
                "key": 1,
                "name": "m_nonce",
                "type": "uint256"
            },
            {
                "key": 2,
                "name": "m_nftDeployer",
                "type": "address"
            }
        ],
        "events": [],
        "fields": [
            {
                "name": "_pubkey",
                "type": "uint256"
            },
            {
                "name": "_timestamp",
                "type": "uint64"
            },
            {
                "name": "_constructorFlag",
                "type": "bool"
            },
            {
                "name": "m_nonce",
                "type": "uint256"
            },
            {
                "name": "m_nftDeployer",
                "type": "address"
            }
        ]
    },
    tvc: "te6ccgECFAEAAqUAAgE0AwEBAcACAEPQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgBCSK7VMg4wMgwP/jAiDA/uMC8gsQBgUEAAADzu1E0NdJwwH4Zon4aSHbPNMAAY4igwjXGCD4KMjOzsn5AAHTAAGU0/8DAZMC+ELiIPhl+RDyqJXTAAHyeuLTPwH4QyG58rQg+COBA+iogggbd0CgufK0+GPTHwH4I7zyudMfAds88jwODAcDUu1E0NdJwwH4ZiLQ0wP6QDD4aak4ANwhxwDjAiHXDR/yvCHjAwHbPPI8Dw8HAzogggtme+O64wIgghBA3xWAuuMCIIIQUMfyYrrjAgoJCAFQMNHbPPhKIY4cjQRwAAAAAAAAAAAAAAAANDH8mKDIzsv/yXD7AN7yAA0BTjDR2zz4SyGOG40EcAAAAAAAAAAAAAAAADA3xWAgyM7OyXD7AN7yAA0D/DD4Qm7jAPhG8nMhlNTU0dCR1OL6QNIA0Wim/mDCAJn4S/hJxwXy4HuOGfhFIG6SMHDe8uBm+EL4RSBukjBw3rry4GXi+CdvEIIYBvwjrAC88uBr+AAi+wRY0CCLOK2zWMcFk9dN0N7XTNDtHu1T+Er4S1qCEHhf4mPtQ9jbPAwTCwAE8gACdO1E0NdJwgGOr3DtRND0BXEhgED0Dm+Rk9cL/95yIoBA9A6OgYnf+Gv4aoBA9A7yvdcL//hicPhj4w0ODQA07UTQ0//TP9MAMdP/1NHQ+kDR+Gv4avhj+GIAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAACvhG8uBMAhD0pCD0vfLAThIRABRzb2wgMC42Ni4wARqgeF/iY18E2zz4D/IAEwAu+Er4Q/hCyMv/yz/Pg8v/+EvIzs3J7VQ=",
    code: "te6ccgECEQEAAngABCSK7VMg4wMgwP/jAiDA/uMC8gsNAwIBAAADzu1E0NdJwwH4Zon4aSHbPNMAAY4igwjXGCD4KMjOzsn5AAHTAAGU0/8DAZMC+ELiIPhl+RDyqJXTAAHyeuLTPwH4QyG58rQg+COBA+iogggbd0CgufK0+GPTHwH4I7zyudMfAds88jwLCQQDUu1E0NdJwwH4ZiLQ0wP6QDD4aak4ANwhxwDjAiHXDR/yvCHjAwHbPPI8DAwEAzogggtme+O64wIgghBA3xWAuuMCIIIQUMfyYrrjAgcGBQFQMNHbPPhKIY4cjQRwAAAAAAAAAAAAAAAANDH8mKDIzsv/yXD7AN7yAAoBTjDR2zz4SyGOG40EcAAAAAAAAAAAAAAAADA3xWAgyM7OyXD7AN7yAAoD/DD4Qm7jAPhG8nMhlNTU0dCR1OL6QNIA0Wim/mDCAJn4S/hJxwXy4HuOGfhFIG6SMHDe8uBm+EL4RSBukjBw3rry4GXi+CdvEIIYBvwjrAC88uBr+AAi+wRY0CCLOK2zWMcFk9dN0N7XTNDtHu1T+Er4S1qCEHhf4mPtQ9jbPAkQCAAE8gACdO1E0NdJwgGOr3DtRND0BXEhgED0Dm+Rk9cL/95yIoBA9A6OgYnf+Gv4aoBA9A7yvdcL//hicPhj4w0LCgA07UTQ0//TP9MAMdP/1NHQ+kDR+Gv4avhj+GIAQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAACvhG8uBMAhD0pCD0vfLATg8OABRzb2wgMC42Ni4wARqgeF/iY18E2zz4D/IAEAAu+Er4Q/hCyMv/yz/Pg8v/+EvIzs3J7VQ=",
    codeHash: "3b7a06ddbb4325b60e1796cfe61b48a627546afa11b849df97651bc8bb54662e",
};
module.exports = { H2QAccountPlatformContract };