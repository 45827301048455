import { Container, PixiComponent, Text } from '@saitonakamura/react-pixi';
import { useEffect, useState } from 'react';
import { Graphics, TextStyle } from 'pixi.js';
import { bottomValBg, bottomValBgAlpha, fieldLabelFontColor, fieldLabelFontFamily, fieldLabelFontSize, fieldValueFontColor, fieldValueFontFamily, fieldValueFontSize, topValBg, topValBgAlpa } from 'entities/generator';

interface IFieldWithBottomValue {
  x: number;
  y: number;
  width: number;
  height: number;
  label: string;
  value: number;
  onHoverOver?: () => void;
  onHoverOut?: () => void;
};

interface IValueBg {
  width: number;
  height: number;
  position: "top" | "bottom"
};

const FieldBg = PixiComponent<IValueBg, Graphics>("FieldBg", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(
      props.position === "top" ? topValBg : bottomValBg,
      props.position === "top" ? topValBgAlpa : bottomValBgAlpha
    );
    ins.drawRect(0, 0, props.width, props.height);
  }
});

interface IValueText {
  label: string | null;
  height: number;
  width: number;
};

const FieldLabel = (props: IValueText) => {
  if (props.label === null) return null;

  return (
    <Text
      anchor={[0.5, 0.5]}
      text={props.label}
      x={props.width / 2}
      y={props.height / 2}
      style={
        new TextStyle({
          fontFamily: fieldLabelFontFamily,
          fontSize: fieldLabelFontSize,
          fill: fieldLabelFontColor,
          align: "center",
        })
      }
    />
  )
};


interface IValueValue {
  height: number;
  width: number;
  value: number;
};

const FieldValue = (props: IValueValue) => {

  return (
    <Text
      text={props.value.toString()}
      anchor={[0.5, 0.5]}
      x={props.width / 2}
      y={props.height / 2}
      style={
        new TextStyle({
          fontFamily: fieldValueFontFamily,
          fontSize: fieldValueFontSize,
          fill: fieldValueFontColor,
          align: "center",
        })
      }
    />
  )
};

export const FieldWithBottomValue = (props: IFieldWithBottomValue) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true)
  }, [props]);

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      mouseover={props.onHoverOver}
      mouseout={props.onHoverOut}
    >
      {
        isLoaded &&
        <>
          <Container
            position={[0, 0]}
          >
            <FieldBg
              height={props.height * 0.65}
              width={props.width}
              position="top"
            />
            <FieldValue
              height={props.height * 0.65}
              width={props.width}
              value={props.value}
            />
          </Container>

          <Container
            position={[0, (props.height * 0.65)]}
          >
            <FieldBg
              height={props.height * 0.35}
              width={props.width}
              position="bottom"
            />
            <FieldLabel
              height={props.height * 0.35}
              width={props.width}
              label={props.label}
            />
          </Container>
        </>
      }
    </Container>
  )
};