
import { Account } from "@eversdk/appkit";
import { H2QuestManagerContract } from "app/contracts/H2QuestManagerContract";
import { H2QuestPlatformContract } from "app/contracts/H2QuestPlatformContract";
import { client } from "app/model/tonClient";
import { abiContract, signerKeys } from "@eversdk/core";
import { config } from "app/config";
import { H2QuestGetterContract } from "app/contracts/H2QuestGetterContract";
import {
  fetchByCodeHash, getAccountData,
  getAccountsData,
} from "./fetchGraphQL";
import { H2QuestContract } from "app/contracts/H2QuestContract";
import { H2QuestIndexContract } from "app/contracts/H2QuestIndexContract";
import { TrueCharacter } from "entities/nft";

export interface H2QuestEssentialParams {
  levelMin: number; // uint8
  levelMax: number; // uint8
  inventoryMask: string; //8 numbers of 32 bits each to represent requirements to enter the quest
  //uint256
}

export interface H2QuestMandatoryParams {
  startTime: number; // uint32
  finishTime: number; // uint32
  name: string;
  worldTag: string;
  actionTag: string;
  questNarrative: string;
  qstAmount: number;
}

export interface H2QuestParams {
  essentialParams: H2QuestEssentialParams;
  mandatoryParams: H2QuestMandatoryParams;
  additionalParams: string;
  paramsScheme?: string;
}

export interface H2QuestResponse {
  id: string;
  m_h2questManager: string;
  m_nonce: string;
  m_questClientPlatformCode: string;
  m_questGetterCode: string;
  m_questIndexCode: string;
  m_questParams: H2QuestParams;
  m_superRoot: string;
  m_totalClientsInitialized: string;
  m_totalQstReward: string;
  m_totalH2QReward: string;
  m_totalClientsLocked: string;
  m_inventoryReward: string;
  m_maxParticipants: string;
  m_maxWinners: string;
  m_personalQstReward: string;
  m_experienceUp: string;
}

async function findNonceForlevel(
  levelMin: number,
  levelMax: number,
  questManager: string,
  maxNonce: number = 0
): Promise<{ levelFoundMax: number; m_nonce: number }> {
  for (let nonce = maxNonce; ; nonce++) {
    // console.log(nonce);

    const questManagerAccount = new Account(
      {
        abi: H2QuestManagerContract.abi,
      },
      {
        client,
        address: questManager,
      }
    );

    const { decoded: { output: { i: m_nonce, a: address } } } = await questManagerAccount.runLocal('findQuestAddress', {
      nonce,
      levelMin,
      levelMax,
      questManager,
    }) as any;

    if (!address) {
      nonce = m_nonce;
      continue
    }

    const account = new Account(
      {
        abi: H2QuestPlatformContract.abi,
      },
      {
        client,
        address,
      }
    );
    const { acc_type } = await account.getAccount();

    if (acc_type !== 3) {
      nonce = m_nonce
      continue;
    }

    // console.log("address found:", address, 'nonce=', m_nonce);
    return { m_nonce, levelFoundMax: levelMax };
  }
}

export interface RewardParams {
  rewardsMask: string
  qstTotalReward: number
  h2qTotalReward: number
  maxWinners: number
  experienceReward: number
  maxParticipants: number
}

export async function addQuestAdmin(
  params: H2QuestParams,
  {
    rewardsMask,
    qstTotalReward,
    h2qTotalReward,
    maxWinners,
    experienceReward,
    maxParticipants,
  }: RewardParams,
  maxNonce = 0
) {
  const mnemonic = {
    phrase:
      "below industry matter reunion below document medal win recipe area razor bunker",
  };
  const masterKey = await client.crypto.mnemonic_derive_sign_keys(mnemonic);
  const signer = signerKeys(masterKey);
  // console.log('signer', signer)


  const { m_nonce, levelFoundMax } = await findNonceForlevel(
    params.essentialParams.levelMin,
    params.essentialParams.levelMax,
    config.m_h2questManager,
    maxNonce,
  );

  console.log("Founded levelFoundMax = ", levelFoundMax);
  params.essentialParams.levelMax = levelFoundMax;

  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      signer,
      address: config.m_h2questManager,
    }
  );

  console.log(await getAccountData(questManagerAccount))

  const { cell, paramsScheme } = await getCell({
    INVENTORY_REWARD: rewardsMask,
    TOTALQST_REWARD: qstTotalReward.toString(),
    TOTALH2Q_REWARD: h2qTotalReward.toString(),
    MAXWINNERS: maxWinners.toString(),
    EXPERIENCEUP: experienceReward.toString(),
    MAXPARTICIPANTS: maxParticipants.toString(),
  });

  const input = {
    nonce: m_nonce,
    questParams: {
      ...params,
      additionalParams: cell,
      paramsScheme,
    },
    questGetterCode: H2QuestGetterContract.code,
  };

  console.log("before deployH2Quest", input);
  const {
    decoded: {
      output: { h2QuestAddress },
    },
  } = (await questManagerAccount.run("deployH2Quest", input)) as any;
  console.log(`DONE: ${h2QuestAddress}`); // "0:012908feba17f9a1b38477cfe378f736c781e2b44bdcde2c53e3f8511e37abc8"

  return h2QuestAddress;
}

export async function getInitCodeHash() {
  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      address: config.m_h2questManager,
    }
  );

  const {
    decoded: {
      output: { value0 },
    },
  } = (await questManagerAccount.runLocal("getInitCodeHash", {})) as any;
  return value0;
}

export async function getIndexCodeHash(itemClass: number, itemType: number) {
  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      address: config.m_h2questManager,
    }
  );

  const input = {
    itemClass,
    itemType,
  };
  // console.log(input);

  const {
    decoded: {
      output: { value0 },
    },
  } = (await questManagerAccount.runLocal("getIndexCodeHash", input)) as any;
  return value0;
}

export async function getCurrentQuestCodeHash(superRoot?: Account) {
  if (!superRoot) throw Error('Supper root required')
  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      address: config.m_h2questManager,
    }
  );

  const { m_h2questCode: questCode } = await getAccountData(superRoot)
  const deployer = config.superRootAddress

  const input = {
    questCode,
    deployer,
  };
  // console.log(input);

  const {
    decoded: {
      output: { value0 },
    },
  } = (await questManagerAccount.runLocal("getCurrentQuestCodeHash", input)) as any;
  return value0;
}

export async function getAllCodeHash(trueCharacter: TrueCharacter) {
  const promises = [
    getIndexCodeHash(7, 0),
    getIndexCodeHash(7, +trueCharacter.avatar_id + 1),
  ];

  for (let i = 0; i < 7; i++) {
    promises.push(getIndexCodeHash(i, 0));
    promises.push(getIndexCodeHash(i, +trueCharacter.inventory[i]));
  }
  return await Promise.all(promises);
}

export async function findAllByCodeHashes(hashes: string[]) {
  const indexes = (
    await Promise.all(
      hashes.map((hash) => {
        return fetchByCodeHash(hash, abiContract(H2QuestIndexContract.abi));
      })
    )
  ).map((items: any[]) => {
    // console.log(items);
    return items.map((item) => item?.m_h2quest);
  });

  const questsIndexes = indexes
    .reduce((result, items, i, arr) => {
      if (i % 2 === 1) {
        result.push(new Set(items.concat(arr[i - 1])));
      }
      return result;
    }, [])
    .reduce((result, itemsSet: Set<string>, i, arr) => {
      if (i === 0) return Array.from(itemsSet);
      return result.filter((item: string) => itemsSet.has(item));
    }, [] as Array<string>);

  return await getAccountsData(questsIndexes, abiContract(H2QuestContract.abi));
}

(window as any).getInitCodeHash = getInitCodeHash;
(window as any).getAllCodeHash = getAllCodeHash;
(window as any).findAllByCodeHashes = findAllByCodeHashes;
(window as any).getCurrentCodeHash = getCurrentQuestCodeHash;

interface CellProperties {
  INVENTORY_REWARD: string;
  GACHA_REWARD: string;
  TOTALQST_REWARD: string;
  TOTALH2Q_REWARD: string;
  PERSONALQST_REWARD: string;
  PERSONALH2Q_REWARD: string;
  MAXPARTICIPANTS: string;
  MAXWINNERS: string;
  EXPERIENCEUP: string;
}

const maskMap = {
  INVENTORY_REWARD: [1, "uint256"],
  GACHA_REWARD: [2, "uint8"],
  TOTALQST_REWARD: [4, "uint128"],
  TOTALH2Q_REWARD: [8, "uint128"],
  PERSONALQST_REWARD: [16, "uint128"],
  PERSONALH2Q_REWARD: [32, "uint128"],
  MAXPARTICIPANTS: [64, "uint32"],
  MAXWINNERS: [128, "uint32"],
  EXPERIENCEUP: [256, "uint256"],
};

type MaskKey = keyof typeof maskMap;

async function getCell(props: Partial<CellProperties>) {
  // (window as any).getCurrentCodeHash = getCurrentQuestCodeHash;
  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      address: config.m_h2questManager,
    }
  );

  let paramsScheme = 0x0;
  let cell = "";
  async function codeCell(c: string, v: string, type: string): Promise<string> {
    const {
      decoded: {
        output: { value0 },
      },
    } = (await questManagerAccount.runLocal(`store_${type}`, {
      c,
      v,
    })) as any;

    return value0;
  }

  for (let i = 0, entries = Object.entries(props); i < entries.length; i++) {
    const [key, value] = entries[i];
    const [_mask, type] = maskMap[key as MaskKey] as any;
    paramsScheme |= _mask;
    cell = await codeCell(cell, value, type);
  }

  return { cell, paramsScheme };
}

async function getQuestManagerCode() {
  const questManagerAccount = new Account(
    {
      abi: H2QuestManagerContract.abi,
    },
    {
      client,
      address: config.m_h2questManager,
    }
  );
  const {
    decoded: {
      output: { value0 },
    },
  } = (await questManagerAccount.runLocal(`m_questPlatformCode`, {})) as any;

  return value0;
}

(window as any).getCell = getCell;

(window as any).getQuestManagerCode = getQuestManagerCode;

//const hashes = await getAllCodeHash(h2qAccount.nfts[0].pseudoNFTdata.m_h2qTrueCharacter)
// h2qAccount.deployQuestClient(h2qAccount.nfts[0].pseudoNFTAddr, "0:00cb71527430267dbd5c73ee6839b9edbf1f72dd04db2f494a4eae17f4ed347c")

/// получили все квесты для героя h2qAccount.nfts[0]
// const quests = await findAllByCodeHashes(await getAllCodeHash(h2qAccount.nfts[0].pseudoNFTdata.m_h2qTrueCharacter))

// сделалли квест геттер через deployQuestClient
// const questGetter = await h2qAccount.deployQuestClient(h2qAccount.nfts[0].pseudoNFTAddr, quests[0].id)

// спросили у квест геттера
// await questGetter.getQuestParams(h2qAccount.nfts[0].pseudoNFTdata)
/*
bool allowed, // может ли участвовать
uint duration, // сколько займет участие
uint128 qstAmount, // цена входа ?
H2QStructures.H2QReward reward //
*/
