import { H2QContext } from "app/core/H2QContext";
import { DoubleSlider } from "entities/DoubleSlider";
import { MinMaxFilterValue } from "entities/FilterMinMaxValue";
import { initialMinNftValue } from "features/nftFilter/model/filterNfts";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import styles from "./FilterNftValue.module.scss";

export const FilterNftValue = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const minVal = h2qAccount.uiStore.nftFilter.nftMinValue;
  const maxVal = h2qAccount.uiStore.nftFilter.nftMaxValue;

  const setMinVal = (min: number) => {
    h2qAccount.uiStore.changeNftFilterMinValue(min);
  };

  const setMaxVal = (max: number) => {
    h2qAccount.uiStore.changeNftFilterMaxValue(max);
  };

  return (
    <div className={styles["slider-and-inputs"]}>
      <div className={styles.slider}>
        <DoubleSlider
          min={initialMinNftValue}
          max={h2qAccount.uiStore.nftMaxValue}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
      <div className={styles["range-inputs"]}>
        <MinMaxFilterValue
          min={initialMinNftValue}
          max={h2qAccount.uiStore.nftMaxValue}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
    </div>
  )
})