import { H2QuestParams, H2QuestResponse } from "app/core/H2QQuestManager";
import { InitialAvatarElement, makeInitialAvatarElement } from "entities/avatar";
import { InitialItemElement } from "entities/item";
import { makeNftItemsObject, NftItems } from "entities/nft";
import { ItemHexMasktoAvatarIdNumber, ItemHexMasktoItemArray } from "entities/quest";

export type CostAndRewardType = "cost" | "reward";

export interface QuestTitleCostData {
  avatarName: string;
  itemsCount: string;
  qst: number;
  h2q?: number | null;
  minLevel?: number | null;
  maxLevel?: number | null;
  maxParticipants?: number | null;
};

export interface QuestSelectedItem {
  type: CostAndRewardType;
  avatar?: InitialAvatarElement | null;
  item?: InitialItemElement;
  cost?: QuestTitleCostData;
};

export interface IQuestPrice {
  avatar: InitialAvatarElement | null;
  items: NftItems<InitialItemElement>;
  qstAmount: number;
  h2qAmount?: number;
  levelMin?: number;
  levelMax?: number;
  maxParticipants?: number;
};

export type QuestsForNft = {
  nftId: string | null;
  active: H2QuestResponse | null;
  valid: H2QuestResponse[];
  invalid: H2QuestResponse[];
};

export const getQuestPrice = (questParams: H2QuestParams): IQuestPrice => {
  const result = {} as IQuestPrice;

  result.qstAmount = questParams.mandatoryParams.qstAmount;

  if (questParams.essentialParams.inventoryMask.length === 66) {
    const questItemsNumber = ItemHexMasktoItemArray(questParams.essentialParams.inventoryMask);
    const questItems = makeNftItemsObject(questItemsNumber);
    result.items = questItems;

    const questAvatarNumber = ItemHexMasktoAvatarIdNumber(questParams.essentialParams.inventoryMask);
    const avatarData = questAvatarNumber >= 0 ? makeInitialAvatarElement(questAvatarNumber) : null;
    result.avatar = avatarData;

    result.levelMin = questParams.essentialParams.levelMin;
    result.levelMax = questParams.essentialParams.levelMax;
  }
  return result;
}

export const getQuestReward = (questData: H2QuestResponse): IQuestPrice => {
  const result = {} as IQuestPrice;

  result.qstAmount = Number(questData.m_totalQstReward);
  result.h2qAmount = Number(questData.m_totalH2QReward);
  result.maxParticipants = Number(questData.m_maxParticipants);

  if (questData.m_inventoryReward.length === 66) {
    const questItemsNumber = ItemHexMasktoItemArray(questData.m_inventoryReward);
    const questItems = makeNftItemsObject(questItemsNumber);
    result.items = questItems;

    const questAvatarNumber = ItemHexMasktoAvatarIdNumber(questData.m_inventoryReward);
    const avatarData = questAvatarNumber >= 0 ? makeInitialAvatarElement(questAvatarNumber) : null;
    result.avatar = avatarData;
  }

  return result
};