import { Button } from 'shared/ui';
import { RotatingCircle } from "shared/ui";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react";
import { useContext, useState } from "react";
import styles from "./StakeHeroBlock.module.scss";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { getNftInfo, NftElement, NFTInfo } from "entities/nft";
import { delay } from "shared/lib";
import { ModalWrapper } from "entities/ModalWrapper";
import { NotEnoughBalanceWarning } from "features/modalWindows";

interface IStakeHeroBlock {
  questData: H2QuestResponse;
  nftsToSend: NftElement[];
  clearNftsToSend: () => void;
};

export const StakeHeroBlock = observer(function ({ questData, nftsToSend, clearNftsToSend }: IStakeHeroBlock) {
  const { h2qAccount } = useContext(H2QContext);
  const [isModalShown, toggleModal] = useState<boolean>(false);

  const finishTime = questData
    ? questData.m_questParams.mandatoryParams.finishTime * 1000
    : 0;

  const isExpired = Date.now() > finishTime;

  const sendHeroesToQuest = async () => {
    const isBalanceEnough = questData.m_questParams.mandatoryParams.qstAmount <= Number(h2qAccount.data.m_qstBalance);

    if (!isBalanceEnough) {
      toggleModal(true);
      return;
    };

    if (isExpired || nftsToSend.length === 0) {
      return;
    }

    Promise.all(nftsToSend.map(async (n) => {
      const nftInfo: NFTInfo = getNftInfo(n);
      await h2qAccount.hero2quest(nftInfo, questData!);
    }))
      .then(() => {
        console.log("All nfts was succesfully sent to quest");
      })
      .catch((e) => {
        console.log("Error while sending nfts to quest");
        console.warn(e);
      })
      .finally(async () => {
        h2qAccount.loadData();
        clearNftsToSend();
        do {
          await h2qAccount.fetchNTFs();
          console.log("Whaiting for nfts to be updated, 2000ms");
          await delay(2000);
        } while (
          h2qAccount.nfts
            .map(nft => nft.pseudoNFTdata.m_lockedInQuest?.quest)
            .filter(questId => questId && questId === questData.id)
            .length < nftsToSend.length
        );
        console.log("Nft has been updated");

        if (h2qAccount.loadAllQuests.state !== "pending") {
          await h2qAccount.loadAllQuests();
        }
      })
  };

  const playBtnText = (<p className={styles["btn-style"]}>
    {isExpired
      ? "EXPIRED"
      : nftsToSend.length > 0
        ? "PLAY"
        : "CHOOSE"
    }
  </p>);

  return (
    <>
      <div className={styles["send-hero-block"]}>
        {h2qAccount.hero2quest.state === "pending" &&
          <>
            <p className={styles["send-hero-text"]} >Please wait</p>
            <p className={styles["send-hero-text"]} >Hero is stacking in quest...</p>
            <div className={styles.btn}>
              <RotatingCircle size={30} />
            </div>
          </>
        }
        {
          h2qAccount.hero2quest.state !== "pending" &&
          <>
            <p className={styles["send-hero-text"]} >{nftsToSend.length > 0 ? "Stake heroes" : "Choose heroes"}</p>
            <p className={styles["hero-amount"]}>{nftsToSend.length}</p>
            <div className={styles.btn}>
              <Button
                children={playBtnText}
                isActive={nftsToSend.length > 0 && !isExpired}
                colorValue={"blue"}
                widthValue={"full"}
                action={sendHeroesToQuest}
              />
            </div>
          </>
        }
      </div>
      {
        isModalShown &&
        <ModalWrapper close={() => toggleModal(false)} >
          <NotEnoughBalanceWarning action={() => toggleModal(false)} amount={questData.m_questParams.mandatoryParams.qstAmount} />
        </ModalWrapper>
      }
    </>
  )
});