import styles from "./UserGameStatBlock.module.scss";

interface IUserGameStatBlock {
  title: string;
  key1: string;
  key2: string;
  key3: string;
  key4: string;
  val1: number | string;
  val2: number | string;
  val3: number | string;
  val4: number | string;
};

export function UserGameStatBlock({
  title,
  key1, key2, key3, key4,
  val1, val2, val3, val4
}: IUserGameStatBlock) {
  return (
    <div className={styles["stat-block"]}>
      <h3>{title}</h3>

      <div className={styles["info-wrapper"]}>
        <div className={styles["info-first-block"]}>
          <h4>{key1}</h4>
          <div>{val1}</div>
        </div>

        <div className={styles["info-second-block"]}>
          <div className={`${styles["stat-row-element"]} ${styles.left}`}>
            <h4>{key2}</h4>
            <div>{val2}</div>
          </div>

          <div className={styles["stat-row-element"]}>
            <h4>{key3}</h4>
            <div>{val3}</div>
          </div>

          <div className={`${styles["stat-row-element"]} ${styles.right}`}>
            <h4>{key4}</h4>
            <div>{val4}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
