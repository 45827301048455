import styles from "./QuestProperNftControlBlock.module.scss";
import { ChangeOptionsButton } from "shared/ui";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { H2QContext } from "app/core/H2QContext";
import { ItemsInRow } from "app/core/H2QUIParams";
import { AddToFavButton } from "shared/ui";

interface INftViewOptionsBlock {
  questId: string;
};

export const QuestProperNftControlBlock = observer(function ({ questId }: INftViewOptionsBlock) {
  const { h2qAccount } = useContext(H2QContext);

  const toggleViewMode = (inRow: ItemsInRow) => {
    h2qAccount.uiStore.changeGalleryCellsInRowCount(inRow, "nftsInQuest");
  };

  const contextQuestParams = h2qAccount.uiStore.favNftsForQuests[questId] || null;

  const showFavNfts = () => {
    if (contextQuestParams) {
      h2qAccount.uiStore.changeQuestPageFavNftMode(questId, !contextQuestParams.isFavModeEnabled);
    }
  };

  useEffect(() => {
    if (contextQuestParams && contextQuestParams.isFavModeEnabled) {
      h2qAccount.uiStore.changeQuestPageFavNftMode(questId, false);
      console.log("Disable fav mode on page load");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["options-wrapper"]}>
      <ChangeOptionsButton title="2+I" isSelected={h2qAccount.uiStore.nftsInQuestRowCount === 2} action={() => toggleViewMode(2)} />
      <ChangeOptionsButton title="4" isSelected={h2qAccount.uiStore.nftsInQuestRowCount === 4} action={() => toggleViewMode(4)} />
      <ChangeOptionsButton title="6" isSelected={h2qAccount.uiStore.nftsInQuestRowCount === 6} action={() => toggleViewMode(6)} />
      <AddToFavButton
        action={showFavNfts}
        isActive={contextQuestParams ? contextQuestParams.favNfts.length > 0 : false}
        isAddedToFav={contextQuestParams ? contextQuestParams.isFavModeEnabled : false}
        size={25}
      />
    </div>
  )
});