import styles from "./UserAccPageHeader.module.scss";
import { FC, PropsWithChildren } from "react";

export const UserAccPageHeader: FC<PropsWithChildren> = ({ children }) => {

  return (
    <div className={styles.header}>
      {children}
    </div>
  )
}