import { Button } from "shared/ui";
import styles from "./NotEnoughBalanceWarning.module.scss";

interface INotEnoughBalanceWarning {
  action: () => void;
  amount: number;
};

export function NotEnoughBalanceWarning({ action, amount }: INotEnoughBalanceWarning) {

  const buttonOkMessage = <p style={{ padding: "0.5rem 0", lineHeight: "2.1rem", fontSize: "1.8rem" }}>OK</p>;

  return (
    <div>
      <p className={styles['modal-items__text']}>You currently do not have enough QST balance to stake NFT-Hero in this quest!</p>
      <p className={styles['modal-items__text']}>Price to enter is: {amount} QST</p>

      <div className={styles['modal-items__buttons']}>
        <Button
          children={buttonOkMessage}
          widthValue={"small"}
          colorValue={"green"}
          action={action}
        />
      </div>
    </div>
  )
}