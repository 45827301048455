import { ReloadButton, RotatingCircleCenter } from "shared/ui";
import { QuestFloatingStats } from "widgets";
import { QuestList } from "widgets";
import { QuestsTotalStats } from "widgets";
import { H2QContext } from "app/core/H2QContext";
import { observer } from "mobx-react"
import { useContext, useEffect, useState } from "react";
import { ReactComponent as IconExpected } from "app/icons/Title_EXPECTED_white.svg";
import styles from "./ExpectedQuestsPage.module.scss";
import { PageTitle } from "entities/PageTitle";
import { QuestViewControls } from "features/questFilter";

export const ExpectedQuestsPage = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const [isQuestsFold, toggleQuestsFold] = useState<boolean>(false);

  const quests = h2qAccount.uiStore.questsToShow;

  useEffect(() => {
    const loadQuests = () => {
      console.log("Load all expected quests");
      if (h2qAccount.loadAllQuests.state !== "pending") {
        h2qAccount.loadAllQuests();
      }
    };

    h2qAccount.uiStore.changeQuestFilterExpectedMode(true);
    loadQuests();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["page-wrapper"]}>
      {h2qAccount.loadAllQuests.state === "pending" &&
        <RotatingCircleCenter />
      }
      <PageTitle>
        <IconExpected style={{
          marginRight: "2rem",
        }} />
        <ReloadButton
          reloadAction={() => h2qAccount.loadAllQuests()}
          isWaiting={h2qAccount.loadAllQuests.state === "pending"}
        />
      </PageTitle>
      <QuestsTotalStats quests={quests}>
        <QuestViewControls
          isQuestsFold={isQuestsFold}
          toggleQuestsFold={() => toggleQuestsFold(prev => !prev)}
        />
      </QuestsTotalStats>
      <QuestFloatingStats quests={quests} />
      {quests.length !== 0
        ? <QuestList quests={quests} isAllQuestsFold={isQuestsFold} />
        : <p className={styles["no-data"]}>
          No expected quests are available
        </p>
      }
    </div>
  )
});