import { IconColorVariants } from "app/icons/icons";
import styles from "./ShowLockedQuestsButton.module.scss";
import { ReactComponent as IconDisabled } from "app/icons/icon-disabled.svg";

interface IShowLockedQuestsButton {
  action: () => void;
  isActive: boolean;
  isSelected: boolean;
  size: number;
};

export const ShowLockedQuestsButton = ({ action, isActive, isSelected, size }: IShowLockedQuestsButton) => {
  const iconColor = isSelected
    ? IconColorVariants.yellow
    : IconColorVariants.gray;

  return (
    <button
      style={{ width: `${size / 10}rem`, height: `${size / 10}rem` }}
      disabled={!isActive}
      className={styles["stake-btn"]}
    >
      <IconDisabled
        fill={iconColor}
        className={styles["btn-image"]}
        onClick={action}
      />
    </button>
  )
}
