import { Button } from 'shared/ui';
import { modalButtonAcceptTextVariants, modalButtonRejectTextVariants, ModalTypes } from 'entities/generator';
import styles from "./ModalButtons.module.scss";

interface IModalButtons {
  type: ModalTypes;
  accept?: () => void;
  reject?: () => void;
};

export const ModalButtons = function ({ type, accept, reject }: IModalButtons) {

  return (
    <div className={`${styles['modal-buttons']}`}>
      <Button children={modalButtonAcceptTextVariants[type]} action={accept} widthValue={"full"} />
      <Button children={modalButtonRejectTextVariants[type]} action={reject} widthValue={"full"} colorValue={"red"} />
    </div>
  )
}