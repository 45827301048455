// import { makeNftItemsObject, makeNftObject, NftImageSVG } from "entities/nft";
import { getColorEqualityToCostumesBy7 } from "entities/questMaker/model/costumes";
import { cellAllColors, TCellAllColor, TEpochColorId } from "entities/questMaker/model/squares";
import { useMemo, useState } from "react";
import styles from "./ColorsAndCostumeIds.module.scss";

export const ColorsAndCostumeIds = () => {
  const costumeListBy7 = useMemo(() => getColorEqualityToCostumesBy7(), []);

  const [colorOpened, setColorOpened] = useState<TEpochColorId>(costumeListBy7.cyan);

  const chooseColor = (color: TCellAllColor) => {
    setColorOpened(costumeListBy7[color]);
  };

  return (
    <div>
      <div className={styles["epoch-colors"]}>
        {Object.entries(costumeListBy7).map(([colorKey, value]) => {
          return (
            <div
              key={colorKey}
              className={`${styles["epoch-color"]} ${colorOpened === value ? styles.active : ""}`}
              style={{ backgroundColor: `RGB${cellAllColors[colorKey as TCellAllColor]}` }}
              onClick={() => chooseColor(colorKey as TCellAllColor)}
            >
            </div>
          )
        })}
      </div>
      <div className={styles.ids}>
        {colorOpened.costumeIds.map((id, index) => {
          // const nftelement = makeNftObject({ avatar_id: "0", id: index, inventory: [...new Array(7).fill(null).map(item => id.costumeId.toString())], pseudoNFTAddr: "" })

          return (
            <div className={styles.id} key={id.costumeName}>
              {/* <div className={styles.nft}>
                <NftImageSVG item={nftelement} />
              </div> */}
              <div className={styles.info}>
                [#{id.costumeId}:{id.costumeName.slice(0, 10)}]:{id.costumeValue}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};