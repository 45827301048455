import { H2QContext } from "app/core/H2QContext";
import { FilterButton } from "shared/ui/FilterButton";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterNftOriginality.module.scss";
import { nftOriginalities } from "entities/nft";

export const FilterNftOriginality = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const onClickGeneratorType = (gen: number) => {
    h2qAccount.uiStore.changeNftFilterOriginality(gen);
  };

  return (
    <div className={styles["items-type"]}>
      {nftOriginalities.map(o => {
        return (
          <FilterButton
            key={o}
            name={Math.ceil(o * 7 / 100).toString().concat("/7")}
            clickAction={() => onClickGeneratorType(o)}
            isSelected={h2qAccount.uiStore.nftFilter.nftOriginality.includes(o)}
          />
        )
      })}
    </div>
  )
})