import styles from "./CostAndRewardPrice.module.scss";

interface ICostAndRewardPrice {
  price: number;
};

export function CostAndRewardPrice({ price }: ICostAndRewardPrice) {
  return (
    <div className={styles['cost-wrapper']}>
      <>
        {price}<span className={styles.qst}>QST</span>
      </>
    </div>
  )
}
