import { H2QContext } from "app/core/H2QContext";
import { AvatarElement, AvatarImageSVG, AvatarListElement } from "entities/avatar";
import { ModalWrapper } from "entities/ModalWrapper";
import { AvatarInfoMainTab, AvatarTags } from "entities/nft";
import { AvatarSidebarContainer, TSidebarInfo } from "entities/rigthSideBar";
import { ChangeAvatarWarningFree } from "features/modalWindows";
import { OpenAvatarInGenerator } from "features/OpenAvatarInGenerator";
import { observer } from "mobx-react";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { RotatingCircleCenter } from "shared/ui";
import styles from "./AvatarList.module.scss";

type TSelectedAvatar = TSidebarInfo & {
  button: ReactNode;
};

export const AvatarList = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const navigate = useNavigate();
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const selectedAvatar = useRef<number | null>(null);

  const openModal = (avatarId: number) => {
    selectedAvatar.current = avatarId;
    setModalOpened(true);
  }

  const closeModal = () => {
    setModalOpened(false);
  };

  const onFavClick = (avatarId: number) => {
    h2qAccount.uiStore.changeAvatarFilterFavourites(avatarId);
  };

  const openSideBarInfo = (avatar: AvatarElement) => {
    const newSelectedKey = `avatar-${avatar.itemId}`;

    // if sidebar already opened and show the same avatar close it
    if (h2qAccount.uiStore.rightSidebarChild.key === newSelectedKey && h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(false);
      return;
    };

    const selecteAvatar = {} as TSelectedAvatar;

    selecteAvatar.image = <AvatarImageSVG avatarImg={avatar} isImgMoved={true} />;
    selecteAvatar.info = <AvatarInfoMainTab avatar={avatar} showTags={false} />;
    selecteAvatar.tags = <AvatarTags avatarId={avatar.itemId} />
    selecteAvatar.button = <OpenAvatarInGenerator
      avatarId={avatar.itemId}
      openModal={openModal}
    />

    h2qAccount.uiStore.setRightSidebarChild(newSelectedKey,
      <AvatarSidebarContainer
        image={selecteAvatar.image}
        info={selecteAvatar.info}
        tags={selecteAvatar.tags}
        button={selecteAvatar.button}
      />
    );

    if (!h2qAccount.uiStore.isRightSidebarOpen) {
      h2qAccount.uiStore.toggleRightSidebar(true);
    };
  };

  const onSendAvatarToGenerator = (avatarId: number) => {
    if (h2qAccount.data.m_generatedHero === null) {
      console.log("Generation process is not started. Start with the new avatar id:", avatarId);
      navigate(`/h2q/generator/${avatarId}`);
      return;
    };
    const currentAvatarId = h2qAccount.data.m_generatedHero?.avatar_id;
    if (currentAvatarId === avatarId) {
      console.log("Generation process is already started with the same avatar id:", currentAvatarId);
      console.log("Chosen avatar id:", avatarId);
      navigate(`/h2q/generator/${avatarId}`);
      return;
    };
    if (currentAvatarId !== undefined && currentAvatarId !== avatarId) {
      console.log("Generation process is already started with another avatar id:", currentAvatarId);
      console.log("Chosen avatar id:", avatarId);
      openModal(avatarId);
      return;
    };
  };

  const openNewGenerator = async (id: number) => {
    setIsPending(true);
    try {
      // disabled after adding changeAvatar free function
      // await h2qAccount.discardCharacter();
      await h2qAccount.changeAvatar({ av: id });
      setIsPending(false);
      setModalOpened(false);
      navigate(`/h2q/generator/${id}`);
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      h2qAccount.uiStore.toggleRightSidebar(false);
      h2qAccount.uiStore.setRightSidebarChild(null, null);
    }
    // eslint-disable-next-line
  }, []);

  const avatars = h2qAccount.uiStore.avatarsToShow;

  return (
    <>
      {h2qAccount.loadData.state !== "pending" && avatars && avatars.length > 0 &&
        <div
          className={styles["avatar-gallery__wrapper"]}
          style={{
            gridTemplateColumns: `repeat(${h2qAccount.uiStore.avatarsInRowCount}, 1fr)`
          }}
        >
          {avatars.map((avatar, index) => {
            const key = `avatar-${avatar.itemId}`;
            return (
              <Fade
                key={key}
                direction="up"
                delay={75 * (index % 4)}
                fraction={0.1}
                triggerOnce
                duration={800}
              >
                <AvatarListElement
                  avatar={avatar}
                  isFav={h2qAccount.uiStore.avatarFilter.favAvatars.includes(avatar.itemId)}
                  onFavClick={onFavClick}
                  isActiveInGenerator={h2qAccount.data.m_generatedHero?.avatar_id === avatar.itemId}
                  isSelected={h2qAccount.uiStore.rightSidebarChild.key === key && h2qAccount.uiStore.isRightSidebarOpen}
                  onAvatarClick={() => openSideBarInfo(avatar)}
                  onSendToGenerator={() => onSendAvatarToGenerator(avatar.itemId)}
                />
              </Fade>
            );
          })}
        </div>
      }

      {h2qAccount.loadData.state !== "pending" && avatars && avatars.length === 0 &&
        <div className={styles["empty-gallery"]}>
          <p>You have no avatars yet.</p>
        </div>
      }

      {h2qAccount.loadData.state === "pending" && <RotatingCircleCenter />}

      {isModalOpened &&
        <ModalWrapper close={closeModal}>
          {/* <ChangeAvatarWarning
            accept={() => openNewGenerator(selectedId)}
            discard={closeModal}
            isPending={isPending} /> */}
          <ChangeAvatarWarningFree
            accept={() => openNewGenerator(selectedAvatar.current!)}
            discard={closeModal}
            isPending={isPending} />
        </ModalWrapper>
      }
    </>
  )
});
