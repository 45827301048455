import { modalTextVariants, ModalTypes } from 'entities/generator';
import styles from "./ModalText.module.scss";

interface IModalText {
  type: ModalTypes;
  addMessage?: string;
};

export const ModalText = ({ type, addMessage }: IModalText) => {

  return (
    <>
      <p className={styles['modal-text-top']}>{modalTextVariants[type]}</p>
      {
        addMessage &&
        <p className={styles['modal-text']}>{addMessage}</p>
      }
    </>
  )
};

export const ModalTextLowBalance = () => {
  return (
    <p className={styles['modal-text']}>Not enough H2Q Tokens. To proceed you must have at least:</p>)
};