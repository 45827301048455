import styles from "./RotatingCircle.module.scss";

interface IRotatingCircle {
  size?: number;
  thick?: number;
};

export const RotatingCircle = ({ size = 50, thick = 5 }: IRotatingCircle) => {
  return (
    <>
      <p
        style={{
          "width": `${size / 10}rem`,
          "height": `${size / 10}rem`,
          "lineHeight": `${size / 10}rem`,
          "borderWidth": `${thick / 10}rem`,
          "boxSizing": "border-box"
        }}
        className={styles.rotating}></p>
    </>
  )
}
