import { H2QContext } from "app/core/H2QContext";
import { DoubleSlider } from "entities/DoubleSlider";
import { MinMaxFilterValue } from "entities/FilterMinMaxValue";
import { initialMaxItemValue, initialMinItemValue } from "features/itemFilter/model/filterItems";
import { observer } from "mobx-react";
import { useContext } from "react";
import styles from "./FilterItemValue.module.scss";

export const FilterItemValue = observer(function () {
  const { h2qAccount } = useContext(H2QContext);

  const minVal = h2qAccount.uiStore.itemFilter.itemMinValue;
  const maxVal = h2qAccount.uiStore.itemFilter.itemMaxValue;

  const setMinVal = (min: number) => {
    h2qAccount.uiStore.changeItemFilterMinValue(min);
  };

  const setMaxVal = (max: number) => {
    h2qAccount.uiStore.changeItemFilterMaxValue(max);
  };

  return (
    <div className={styles["slider-and-inputs"]}>
      <div className={styles.slider}>
        <DoubleSlider
          min={initialMinItemValue}
          max={initialMaxItemValue}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
      <div className={styles["range-inputs"]}>
        <MinMaxFilterValue
          min={initialMinItemValue}
          max={initialMaxItemValue}
          minVal={minVal}
          maxVal={maxVal}
          setMinVal={setMinVal}
          setMaxVal={setMaxVal}
        />
      </div>
    </div>
  )
});