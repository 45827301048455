import { GalleryItemButtonsBlock, GalleryItemFadeAndBg, GalleryItemMainWrapper } from "entities/GalleryItemWrapper";
import { ItemDivImage, ItemElement } from "entities/item";
import { useRef, useState } from "react";
import { useOutsideClick } from "shared/lib";
import styles from "./ItemListElement.module.scss";

// temporaty element. Only for BC developers. will be removed in a future
interface IItemMenu {
  itemId: number;
  categoryId: number;
  closeMenu: (value: null) => void;
};

const ItemMenu = ({ itemId, categoryId, closeMenu }: IItemMenu) => {
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, () => {
    closeMenu(null);
  });

  return (
    <div className={styles["menu-items-wrapper"]} ref={menuRef}>
      <ul className={styles["menu-list"]}>
        <li>ItemId - {itemId}</li>
        <li>CategoryId - {categoryId}</li>
      </ul>
    </div>
  )
};

interface IItemListElement {
  item: ItemElement;
  isFav: boolean;
  isSelected: boolean;
  onFavClick: (item: ItemElement) => void;
  onItemClick: (item: ItemElement) => void;
};

export const ItemListElement = ({ item, isFav, isSelected, onFavClick, onItemClick }: IItemListElement) => {
  const [menuShown, toggleMenuShown] = useState<string | null>(null);

  const showContextMenu = (item: ItemElement, event: React.SyntheticEvent) => {
    event.stopPropagation();
    toggleMenuShown(prev => `${item.categoryId}-${item.itemId}`);
  };

  return (
    <GalleryItemMainWrapper isItemChosen={isSelected}>
      <GalleryItemFadeAndBg>
        <ItemDivImage iconLink={item.iconThumbLink} isFadeActive={true} onClick={() => onItemClick(item)} />
      </GalleryItemFadeAndBg>
      <GalleryItemButtonsBlock
        amount={item.amount}
        addToFavAction={() => onFavClick(item)}
        openMenuAction={(event) => showContextMenu(item, event)}
        isAddedToFav={isFav}
      />
      {menuShown === `${item.categoryId}-${item.itemId}` &&
        <ItemMenu itemId={item.itemId}
          categoryId={item.categoryId}
          closeMenu={(value) => toggleMenuShown(value)}
        />
      }
    </GalleryItemMainWrapper>
  )
};