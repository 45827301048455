import { ReactNode, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Stage } from "@saitonakamura/react-pixi";
import useResizeObserver from "use-resize-observer";
import { H2QContext } from "app/core/H2QContext";
import { GeneratorBottomRightBtns, GeneratorHero, GeneratorSlots } from "widgets";
import { ExcludedItems } from "entities/generator";
import { AvatarGeneratorChoseGallery } from "features/modalWindows";
import { AcceptCharacter, AllItemsWereUsed, CompleteAcceptCharacter, ErrorMessage, ExportedNftImage, NftAlreadyGenerated, ResetItems, Waiting } from "features/generatorActions";
import { calculateCanvasSize, canvasInitialWidth, canvasInitialHeight } from "entities/generator";
// import { ChangeAvatarWarning } from "./GeneratorModals/ChangeAvatarWarning/ChangeAvatarWarning";
import { GeneratorTopRightMenu } from "widgets";
import { makeNftItemsObject } from "entities/nft";
import { getItemsBlocksColors } from "entities/itemColors";
import { ModalWrapper } from "entities/ModalWrapper";
import {
  cellOutSize,
  controlBlockRightOffset,
  controlBlockTopOffset,
  controlBlocHeight, controlBlockWidth,
  GeneratorBg,
  GeneratorTitle,
  ModalTypes,
  slotsLeftOffset,
  btnsBlockRightOffset,
  fieldHeight,
  bottomBtnsWidth,
  btnsBlockBottomOffset
} from "entities/generator";
import { GeneratorMessageBlock } from "widgets";

export const Generator = observer(function () {
  const { h2qAccount } = useContext(H2QContext);
  const params = useParams();
  const navigate = useNavigate();

  //To calculate initial width and heigh and not to render 2 times at first load:
  const sideBarWidth = 128;
  const topBarHeight = 72;
  const [startWidth, startHeight] = calculateCanvasSize(
    window.innerWidth - sideBarWidth,
    window.innerHeight - topBarHeight
  );

  const [isItemsApplied, setItemsApplied] = useState<boolean>(true);
  const [isBgActive, toggleBgActive] = useState<boolean>(true);
  const [isTrianglesActive, setTrianglesActive] = useState<boolean>(false);
  const [isTooltipsActive, setTooltipsActive] = useState<boolean>(false);

  const [currentModal, setCurrentModal] = useState<ReactNode | null>(null);

  const [canvasBgSize, setCanvasBgSize] = useState<{ w: number; h: number }>(() => { return { w: startWidth, h: startHeight } });
  const [canvasControlsHeight, setCanvasControlsHeight] = useState<number>(() => startHeight);
  const [screenSizeRatio, setScreenSizeRatio] = useState<{ w: number; h: number }>(() => { return { w: 1, h: 1 } });

  const items = h2qAccount.data.m_generatedHero ? h2qAccount.data.m_generatedHero.inventory : [];
  const avatarId = +params.id!;

  const [isAvatarSelected, setAvatarChosen] = useState<boolean>(() => {
    const availableAvatars = h2qAccount.data.m_avatar_amount?.filter(item => item).length;
    if (availableAvatars && h2qAccount.data.m_avatar_amount![avatarId] !== 0) {
      return true;
    } else return false
  });

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width, height }) => {
      if (width && height) {
        const [newBgWidth, newBgHeight, newControlsHeight, screenSizeRatioW, screenSizeRatioH] = calculateCanvasSize(width, height);
        setCanvasBgSize({ w: newBgWidth, h: newBgHeight });
        setCanvasControlsHeight(newControlsHeight);
        setScreenSizeRatio({ w: screenSizeRatioW, h: screenSizeRatioH });
      }
    },
  });

  const nftItemsObject = items.length > 0 ? makeNftItemsObject(items.map(i => i.toString())) : null;

  let itemsWithColors = null;
  let itemsColorArray = null;
  if (nftItemsObject) {
    const { result, colorArray } = getItemsBlocksColors(nftItemsObject);
    itemsWithColors = result;
    itemsColorArray = colorArray;
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  const gotTNftPage = () => {
    navigate("/h2q/nft");
  };

  const clearGeneratorAfterAcceptChar = () => {
    setCurrentModal(modalChooseAvatarGallery);
  };

  const changeAvatar = async (newAvatarId: number) => {
    h2qAccount.clearLogMessages();
    await h2qAccount.changeAvatar({
      av: newAvatarId
    });
  };

  const switchAvatarPage = (id: number) => {
    navigate(`/h2q/generator/${id}`);
    setAvatarChosen(true);
    closeModal();
  };

  const discardH2QCharacter = async () => {
    h2qAccount.clearLogMessages();
    setTrianglesActive(false);
    console.log("Discard character!");
    // Only 4 message got on this operation:
    changeModalWaitMessage(4);
    try {
      await h2qAccount.discardCharacter();
      // TODO: need to proper type here
    } catch (error: any) {
      console.log("Error", error);
      changeModalErrorMesage(error.mesage);
    } finally {
      closeModal();
    }
  };

  const acceptH2QCharacter = async () => {
    h2qAccount.clearLogMessages();
    console.log("Accept character!");
    // 14 messages got on this operation:
    changeModalWaitMessage(14);
    try {
      await h2qAccount.acceptCharacter();
      changeModalMessage("completeAcceptChar");
      // TODO: need to proper type here
    } catch (error: any) {
      console.log("Error", error);
      changeModalErrorMesage(error.message);
    } finally {
      setAvatarChosen(false);
    }
  };

  // tempopary disabled after adding free changeAvatar function
  // const onAcceptChangeAvatar = async (id: number) => {
  //   await discardH2QCharacter();
  //   switchAvatarPage(id);
  // };

  const onUpdateItems = async (excluded: ExcludedItems) => {
    //Check if user has already generated Hero with only 7 items in collection: 
    const itemsAvailable = h2qAccount.data.m_inventories?.flat().filter(item => item !== 0).length;

    if (h2qAccount.data.m_generatedHero && itemsAvailable && itemsAvailable <= 7) {
      console.log("Already generated hero with available items");
      changeModalMessage("allItemsWereUsed");
      return;
    }

    h2qAccount.clearLogMessages();
    // 18 messages got on this operation:
    changeModalWaitMessage(18);
    setItemsApplied(true);
    try {
      if (h2qAccount.data.m_generatedHero === null) {
        console.log("Executed start generation items with avatarId:", avatarId);
        await h2qAccount.startGenerateCharacter({
          avatar_id: avatarId,
        });
      } else {
        console.log("Executed next generation items with avatarId:", avatarId);
        console.log("Excluded items:", excluded);
        await h2qAccount.nextGenerateCharacter(excluded);
      }
      // TODO: need to proper type here
    } catch (error: any) {
      console.log("Error", error);
      changeModalErrorMesage(error.message);
    } finally {
      closeModal();
    }
  };

  const onResetItems = () => {
    if (h2qAccount.data.m_generatedHero) {
      changeModalMessage("cancelItems");
      console.log("reset");
    }
  };

  const onGenerateBtn = () => {
    if (h2qAccount.doesNftAlreadyExist) {
      changeModalMessage("nftAlredyExists");
      console.log("Nft already exists");
      return;
    }

    console.log("Nft does not exist");
    if (h2qAccount.data.m_generatedHero) {
      setItemsApplied(true);
      changeModalMessage("acceptChar");
      console.log("accept");
    }
  };

  const onOpenAvatarChooseGallery = () => {
    changeModalMessage("changeChar");
    console.log("chose avatar");
  };

  const onChooseAvatar = async (id: number) => {
    if (h2qAccount.data.m_generatedHero === null) {
      console.log(
        "Generation process is not started. Start with the new avatar id:",
        id
      );
      switchAvatarPage(id);
      return;
    }

    const currentAvatarId = h2qAccount.data.m_generatedHero?.avatar_id;
    if (currentAvatarId === id) {
      console.log(
        "Generation process is already started with the same avatar id:",
        h2qAccount.data.m_generatedHero?.avatar_id
      );
      console.log("Chosen avatar id:", id);
      closeModal();
      return;
    }

    if (currentAvatarId !== undefined && currentAvatarId !== id) {
      // console.log(
      //   "Generation process is already started with the avatar id:",
      //   h2qAccount.data.m_generatedHero?.avatar_id
      // );
      console.log("Starting to change avatar...");
      console.log("Chosen avatar id:", id);
      changeModalWaitMessage(1);
      await changeAvatar(id);
      closeModal();
      switchAvatarPage(id);
      // tempopary disabled after adding free changeAvatar function
      // setModalChooseAvatarWarning(id);
      // setModalOpen(true);
      return;
    }
  };

  const onToggleTriangles = () => {
    if (items.length > 0) {
      setTrianglesActive(prev => !prev);
    }
  };

  const onToggleItemsOnHero = () => {
    if (items.length > 0) {
      setItemsApplied(prev => !prev);
    }
  };

  const makeModalError = (error: string) => {
    return <ErrorMessage errorMessage={error} reject={closeModal} />;
  };

  const modalResetItems = (
    <ResetItems
      accept={discardH2QCharacter}
      reject={closeModal}
    />
  );

  const modalAcceptChar = (
    <AcceptCharacter
      accept={acceptH2QCharacter}
      reject={closeModal}
    />
  );

  const modalNftAlredyExists = (
    <NftAlreadyGenerated
      reject={closeModal}
    />
  );

  const modalAllItemsWereUsed = (
    <AllItemsWereUsed
      reject={closeModal}
    />
  );

  const modalCompleteAcceptChar = (
    <CompleteAcceptCharacter
      accept={gotTNftPage}
      reject={clearGeneratorAfterAcceptChar}
    />
  );

  const modalChooseAvatarGallery = (
    <AvatarGeneratorChoseGallery process={onChooseAvatar} />
  );

  // tempopary disabled after adding free changeAvatar function
  // const setModalChooseAvatarWarning = (id: number) => {
  //   const modalAvatarWarning = (
  //     <ChangeAvatarWarning
  //       accept={() => onAcceptChangeAvatar(id)}
  //       reject={() => setModalOpen(false)}
  //     />
  //   );
  //   setCurrentModal(modalAvatarWarning);
  // };

  const changeModalWaitMessage = (messages: number) => {
    const waiting = <Waiting maxValueToWait={messages} />;
    setCurrentModal(waiting);
  };

  const changeModalErrorMesage = (error: any) => {
    const modalError = makeModalError(error);
    setCurrentModal(modalError);
  };

  const changeModalMessage = (type: ModalTypes) => {
    if (type === "cancelItems") setCurrentModal(modalResetItems);
    if (type === "acceptChar") setCurrentModal(modalAcceptChar);
    if (type === "completeAcceptChar") setCurrentModal(modalCompleteAcceptChar);
    if (type === "nftAlredyExists") setCurrentModal(modalNftAlredyExists);
    if (type === "allItemsWereUsed") setCurrentModal(modalAllItemsWereUsed);
    if (type === "changeChar") setCurrentModal(modalChooseAvatarGallery);
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          position: "relative",
          // backgroundImage: isBgActive ? "url('/res/bgr_horizontal.png')" : "none",
          backgroundColor: "#3A3D4E",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          height: "calc(100vh - 8rem)",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          // backgroundPosition: "center",
          overflow: "hidden",
        }}
      >

        <GeneratorTitle />

        {/* Stage for Bg and hero */}
        <Stage
          raf={false}
          renderOnComponentChange={true}
          width={canvasInitialWidth}
          height={canvasInitialHeight}
          // width={
          //   canvasWidth >= canvasHeight
          //     ? canvasInitialWidth
          //     : canvasInitialHeight
          // }
          // height={
          //   canvasWidth >= canvasHeight
          //     ? canvasInitialHeight
          //     : canvasInitialWidth
          // }
          options={{
            backgroundAlpha: 0,
            autoDensity: false,
          }}
          style={{
            width: canvasBgSize.w + "px",
            height: canvasBgSize.h + "px",
          }}
        >
          {isBgActive &&
            <GeneratorBg
              avatarId={avatarId}
            />
          }

          <GeneratorHero
            avatar={avatarId}
            items={items}
            isVertical={false}
            isApplied={isItemsApplied}
            isAvatarSelected={isAvatarSelected}
            isTooltipsEnabled={isTooltipsActive}
          />
        </Stage>

        {/* Stage for right bottom side buttons */}
        <Stage
          raf={false}
          renderOnComponentChange={true}
          width={bottomBtnsWidth}
          height={fieldHeight * 4}
          options={{
            backgroundAlpha: 0,
            autoDensity: false,
          }}
          style={{
            // width: bottomBtnsWidth / screenSizeRatioW + "px",
            height: fieldHeight * 4 / screenSizeRatio.h + "px",
            position: "absolute",
            right: btnsBlockRightOffset / screenSizeRatio.w,
            bottom: btnsBlockBottomOffset / screenSizeRatio.h,
          }}
        >

          <GeneratorBottomRightBtns
            genPrice={h2qAccount.data.m_acceptPrice ? Number(h2qAccount.data.m_acceptPrice) : 0}
            resetPrice={h2qAccount.data.m_discardPrice ? Number(h2qAccount.data.m_discardPrice) : 0}
            isTooltipsActive={isTooltipsActive}
            isResetActive={h2qAccount.data.m_generatedHero !== null}
            onResetAction={onResetItems}
          />

        </Stage>

        {/* Stage for top right menu */}
        <Stage
          raf={false}
          renderOnComponentChange={true}
          width={controlBlockWidth * 5}
          height={controlBlocHeight}
          options={{
            backgroundAlpha: 0,
            autoDensity: false,
          }}
          style={{
            // width: controlBlockWidth / screenSizeRatio + "px",
            height: controlBlocHeight / screenSizeRatio.h + "px",
            position: "absolute",
            right: controlBlockRightOffset / screenSizeRatio.w,
            top: controlBlockTopOffset / screenSizeRatio.h,
          }}
        >
          {/* Top right menu */}
          <GeneratorTopRightMenu
            toggleTriangles={onToggleTriangles}
            applyItems={onToggleItemsOnHero}
            changeAvatar={onOpenAvatarChooseGallery}
            toggleGenBg={() => { toggleBgActive(prev => !prev) }}
            toggleTooltips={() => setTooltipsActive(prev => !prev)}
            isTrianglesActive={isTrianglesActive}
            isItemsApplied={isItemsApplied}
            isBgActive={isBgActive}
            isTooltipsActive={isTooltipsActive}
            isVertical={false}
          />
        </Stage>

        {/* Stage for Slots and information block */}
        <Stage
          raf={false}
          renderOnComponentChange={true}
          width={slotsLeftOffset + 3.35 * cellOutSize}
          height={canvasInitialHeight}
          options={{
            backgroundAlpha: 0,
            autoDensity: false,
            antialias: true,
          }}
          style={{
            // width: canvasControlsWidth / ratio + "px", // to keep aspect ratio and get auto width value
            height: canvasControlsHeight + "px",
            position: "absolute",
            left: 0,
            bottom: 0,
          }}
        >
          {/* Slots */}
          <GeneratorSlots
            update={onUpdateItems}
            generate={onGenerateBtn}
            isAvatarSelected={isAvatarSelected}
            isTooltipsActive={isTooltipsActive}
            // isVertical={canvasWidth < canvasHeight}
            isVertical={false}
            isTrianglesEnabled={isTrianglesActive}
            slotColors={itemsWithColors!}
            isLocksReset={!h2qAccount.data.m_generatedHero}
          />

          {/* Message and information block */}
          <GeneratorMessageBlock
            isVertical={false}
            items={items}
            isTooltipsActive={isTooltipsActive}
            itemsColorArray={itemsColorArray}
          />
        </Stage>

        {/* Modal for messages */}
        {currentModal &&
          <ModalWrapper
            close={closeModal}
            isTransparent={true}>
            {currentModal}
          </ModalWrapper>
        }

        <ExportedNftImage avatarId={avatarId} items={items} />
      </div>
    </>
  );
});
