import { H2QContext } from "app/core/H2QContext";
import { H2QuestResponse } from "app/core/H2QQuestManager";
import { getNftFromArray } from "entities/nft";
import { QuestElement } from "widgets";
import { StakedNftGrid } from "entities/quest";
import { OpenQuestBlock } from "features/quest";
import styles from "./QuestList.module.scss";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";

interface IQuestList {
  quests: H2QuestResponse[];
  isAllQuestsFold: boolean;
  isStakedMode?: boolean;
};

export const QuestList = observer(function ({ quests, isAllQuestsFold, isStakedMode = false }: IQuestList) {
  const { h2qAccount } = useContext(H2QContext);

  useEffect(() => {
    const getTotalNfts = async () => {
      await h2qAccount.getTotalNfts();
    };

    getTotalNfts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["list-wrapper"]}>
      {quests.map((q, index) => {
        return (
          <div key={q.id} className={styles["staked-element"]}>
            <QuestElement questData={q} isAllQuestsFold={isAllQuestsFold}>
              <OpenQuestBlock questData={q} />
            </QuestElement>
            {
              isStakedMode && h2qAccount.uiStore.questFilter.stakedQuests[q.id] &&
              <StakedNftGrid
                nfts={getNftFromArray(h2qAccount.uiStore.questFilter.stakedQuests[q.id])}
              />
            }
          </div>
        )
      })
      }
    </div>
  )
});