import { Container, PixiComponent, Text } from "@saitonakamura/react-pixi";
import { circleBlockSize, circleFontSize, countBlockColor, countBlockFontColor, countBlockFontFamily } from "entities/generator";
import { Graphics, TextStyle } from "pixi.js";
import { useEffect, useState } from "react";

interface ICircleAmountBg {
  size: number;
  color: number;
};

const CircleAmountBg = PixiComponent<ICircleAmountBg, Graphics>("CircleAmountBg", {
  create: () => new Graphics(),
  applyProps: (ins, _, props) => {
    ins.clear();
    ins.beginFill(props.color);
    ins.drawCircle(props.size / 2, props.size / 2, props.size / 2)
  }
});

interface ICircleAmountLabel {
  size: number;
  label: number;
};

const CircleAmountLabel = (props: ICircleAmountLabel) => {
  return (
    <Text
      text={props.label.toString()}
      anchor={[0.5, 0.5]}
      x={props.size / 2}
      y={props.size / 2}
      style={
        new TextStyle({
          fontFamily: countBlockFontFamily,
          fontSize: circleFontSize,
          fill: countBlockFontColor,
          fontWeight: "500",
          align: "center",
        })
      }
    />
  )
};

interface ICircleAmountBlock {
  x: number;
  y: number;
  value: number;
  onHoverOver: () => void;
  onHoverOut: () => void;
  onClick: () => void;
};

export const CircleAmountBlock = (props: ICircleAmountBlock) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true)
  }, [props]);

  return (
    <Container
      position={[props.x, props.y]}
      interactive={true}
      buttonMode={true}
      mouseover={props.onHoverOver}
      mouseout={props.onHoverOut}
      mousedown={props.onClick}
    >
      {isLoaded &&
        <>
          <CircleAmountBg size={circleBlockSize} color={countBlockColor} />
          <CircleAmountLabel size={circleBlockSize} label={props.value} />
        </>
      }
    </Container>
  )
}