import { h2qAccount } from "app/core/H2QContext"
import { getRarityName, ItemElement, parts } from "entities/item"

export const initialMaxItemValue = 300;
export const initialMinItemValue = 0;

export const filterItems = (items: ItemElement[]): ItemElement[] => {
  const itemsByFav = filterItemvByFav(items);
  const itemsByCategory = filterItemsByCategory(itemsByFav);
  const itemsByRarity = filterItemsByRarity(itemsByCategory);
  const itemsByValue = filterItemsByValue(itemsByRarity);
  const itemsByHero = filterItemsByHero(itemsByValue);
  return itemsByHero;
}

const filterItemvByFav = (items: ItemElement[]): ItemElement[] => {
  const favItems = h2qAccount.uiStore.itemFilter.favItems;
  const favMode = h2qAccount.uiStore.itemFilter.favMode;

  return !favMode
    ? items
    : favItems.flat().length === 0
      ? items
      : items.filter(item => favItems[item.categoryId].includes(item.itemId))
};

const filterItemsByCategory = (items: ItemElement[]): ItemElement[] => {
  const categories = h2qAccount.uiStore.itemFilter.itemType;

  const result = categories.length > 0
    ? items.filter(item => categories.includes(parts[item.categoryId]))
    : items;
  return result;
};

const filterItemsByRarity = (items: ItemElement[]): ItemElement[] => {
  const rarities = h2qAccount.uiStore.itemFilter.itemRarity;

  const result = rarities.length > 0 && h2qAccount.inventoryInfoFromBC
    ? items.filter(item => rarities.includes(getRarityName(h2qAccount.inventoryInfoFromBC![item.itemId].rarity)))
    : items;
  return result;
};

const filterItemsByValue = (items: ItemElement[]): ItemElement[] => {
  const minVal = h2qAccount.uiStore.itemFilter.itemMinValue;
  const maxVal = h2qAccount.uiStore.itemFilter.itemMaxValue;

  if (minVal === initialMinItemValue && maxVal === initialMaxItemValue) {
    return items;
  }
  else {
    const result = items.filter(item => {
      const itemValue = Number(h2qAccount.inventoryInfoFromBC![item.itemId].value);
      return itemValue >= minVal && itemValue <= maxVal
    })
    return result;
  }
};

const filterItemsByHero = (items: ItemElement[]): ItemElement[] => {
  const originalHeroes = h2qAccount.uiStore.itemFilter.originalHero;

  const result = originalHeroes.length === 0
    ? items
    : items.filter(item => {
      const itemOriginalHero = h2qAccount.inventoryInfoFromBC![item.itemId].originalHero;
      return originalHeroes.includes(itemOriginalHero);
    })
  return result;
};
