import { CSSProperties } from "react";
import { RotatingCircle } from "shared/ui";
import styles from "./UnstakeButton.module.scss";

interface IUnstakeButton {
  action: () => void;
  isWaiting: boolean;
  size?: number;
  style?: CSSProperties;
};

export const UnstakeButton = ({ action, isWaiting, size = 14, style }: IUnstakeButton) => {
  const styleProps = style ?
    { ...style, fontSize: (size / 10) + "rem" }
    : { fontSize: (size / 10) + "rem" };
  return (
    <button
      style={styleProps}
      className={`${styles["unstake-btn"]} ${!isWaiting ? styles.active : ""}`}
      onClick={action}
      disabled={isWaiting}
    >
      {!isWaiting
        ? "Unstake"
        : <RotatingCircle size={size} thick={2} />
      }
    </button>
  )
}
