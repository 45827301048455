import { H2QuestResponse } from "app/core/H2QQuestManager";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'shared/ui';
import styles from "./OpenQuestBlock.module.scss";

interface IOpenQuestBlock {
  questData: H2QuestResponse;
};

export const OpenQuestBlock = ({ questData }: IOpenQuestBlock) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const finishTime = questData.m_questParams.mandatoryParams.finishTime * 1000;

  const btnText = (<p className={styles["btn-style"]}>VIEW</p>);

  const isQuestActive = Date.now() < finishTime;

  const openQuest = () => {
    if (!isQuestActive) {
      return
    }
    if (id) {
      navigate(`/h2q/quests/${questData.id.slice(2)}?nft-id=${id}`);
    } else {
      navigate(`/h2q/quests/${questData.id.slice(2)}`);
    }
  };

  return (
    <div className={styles["open-quest-block"]}>
      <p className={styles["open-quest-text"]} >Suitable HEROES for QUEST</p>
      <div className={styles.btn}>
        <Button
          children={btnText}
          colorValue={"blue"}
          widthValue={"full"}
          action={openQuest}
          isActive={isQuestActive}
        />
      </div>
    </div>
  )
}