import styles from "./CheckBox.module.scss";

interface ICheckBox {
  isChecked: boolean;
  action: () => void;
  size?: number;
};

export const CheckBox = ({ isChecked, action, size = 20 }: ICheckBox) => {

  return (
    <>
      <div
        className={`${styles["check-box"]} ${isChecked ? styles.checked : ""}`} onClick={action}
        style={{ width: `${size / 10}rem`, height: `${size / 10}rem` }}
      >
        {isChecked &&
          <i className={styles["icon-check"]}></i>
        }
      </div>

      {/* Just to load SVG font: */}
      <i className={styles["icon-check"]} style={{ opacity: 0, position: "absolute", zIndex: -1 }} ></i>
    </>
  )
}
