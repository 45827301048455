import styles from "./ModalPrice.module.scss";

interface IModalPrice {
  price: number;
};

export function ModalPrice({ price }: IModalPrice) {
  return (
    <p className={styles['modal-price']}>{price}&nbsp;H2Q Tokens</p>
  )
};