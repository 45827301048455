import { Sprite } from "@saitonakamura/react-pixi"
import { canvasInitialWidth, canvasInitialHeight } from "entities/generator";

interface IGeneratorBg {
  avatarId: number;
};

export const GeneratorBg = ({ avatarId }: IGeneratorBg) => {
  const bgAnimal = "/res/Generator/bg_animal_horizontal.png";
  const bgHumanoid = "/res/Generator/bg_humaniod_horizontal.png";

  return (
    <Sprite
      image={avatarId <= 16
        ? bgAnimal
        : avatarId > 16 && avatarId <= 32
          ? bgHumanoid
          : ""
      }
      width={canvasInitialWidth}
      height={canvasInitialHeight}
    />
  )
}
