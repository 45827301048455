import { Tuple } from "shared/lib";
import { itemsData } from "shared/model";

export type RarityValue = "0" | "1" | "2" | "3";

export interface InitialItemElement {
  genId: number | null;
  itemId: number;
  categoryId: number;
  iconLink: string;
  iconThumbLink: string;
  imageLink?: string;
  imageThumbLink?: string;
  imagePos?: {
    X: number;
    Y: number;
  };
  imageSize?: {
    width: number;
    height: number;
  };
  imageThumbSize?: {
    width: number;
    height: number;
  };
};

export interface ItemElement extends InitialItemElement {
  amount: number;
};

export const parts = [
  "Legs",
  "Hip",
  "Body",
  "Head",
  "Accessory1",
  "Accessory2",
  "Accessory3",
] as const;

// TODO make it [gen][type][item]
export const itemList: InitialItemElement[][] = Object.entries(itemsData.inventories)
  .map(([genKey, genValue]) => {
    return Object.entries(genValue).map(([typeKey, typeValue]) => {
      return Object.entries(typeValue).map(([itemKey, itemValue]) => {

        const newItem: InitialItemElement = {
          genId: Number(itemValue.gen_id),
          iconLink: itemValue.slot_icon,
          iconThumbLink: itemValue.thumb_slot_icon,
          categoryId: +typeKey,
          itemId: +itemKey,
          imageLink: itemValue.image_file,
          imageThumbLink: itemValue.thumb_image_file,
          imagePos: {
            X: itemValue.coords.Pos.X,
            Y: itemValue.coords.Pos.Y,
          },
          imageSize: {
            width: itemValue.image_file_size.width,
            height: itemValue.image_file_size.height,
          },
          imageThumbSize: {
            width: itemValue.thumb_image_file_size.width,
            height: itemValue.thumb_image_file_size.height,
          },
        };

        return newItem
      });
    })
  }).flat();

export const makeInitialItemElement = (itemNumber: number, categoryId: number): InitialItemElement | null => {
  const maxItemNumber = itemList[0].length - 1;
  if (itemNumber > maxItemNumber || categoryId > 6) return null;

  const newItem: InitialItemElement = {
    imageLink: itemList[categoryId][itemNumber].imageLink,
    imageThumbLink: itemList[categoryId][itemNumber].imageThumbLink,
    imagePos: itemList[categoryId][itemNumber].imagePos,
    imageSize: itemList[categoryId][itemNumber].imageSize,
    imageThumbSize: itemList[categoryId][itemNumber].imageThumbSize,
    iconLink: itemList[categoryId][itemNumber].iconLink,
    iconThumbLink: itemList[categoryId][itemNumber].iconThumbLink,
    itemId: itemNumber,
    categoryId: categoryId,
    genId: itemList[categoryId][itemNumber].genId,
  };

  return newItem;
};

export const getItemsFromArray = (arr: number[][] | Tuple<Tuple<number, 64>, 7>): ItemElement[] => {
  if (arr && arr.length) {
    const itemsData = arr.map((item, categoryId) => {
      return item.map((i, indexId) => {
        const itemObject: ItemElement = {
          genId: itemList[categoryId][indexId]?.genId ? itemList[categoryId][indexId]?.genId : null,
          iconLink: itemList[categoryId][indexId]?.iconLink ? itemList[categoryId][indexId].iconLink : "",
          iconThumbLink: itemList[categoryId][indexId]?.iconThumbLink ? itemList[categoryId][indexId].iconThumbLink : "",
          itemId: indexId, categoryId: categoryId, amount: i,
          imageSize: itemList[categoryId][indexId]?.imageSize ? itemList[categoryId][indexId].imageSize : { width: 0, height: 0 },
          imageThumbSize: itemList[categoryId][indexId]?.imageThumbSize ? itemList[categoryId][indexId].imageThumbSize : { width: 0, height: 0 },
        };

        if (!itemObject.iconLink && i) {
          console.log(categoryId, indexId, "missed");
        }
        return itemObject;
      })
        .filter((item) => item.amount !== 0);
    }
    );
    return itemsData.flat(2);
  } else {
    return [];
  }
};

export const rarity = [
  "Regular",
  "Rare",
  "Epic",
  "Legendary"
] as const;

export const getRarityName = (rarityValue: RarityValue): string => {
  const rarityName = rarity[Number(rarityValue)];

  return rarityName;
};