import { Tuple } from "shared/lib";
import { itemsData } from "shared/model";

export interface InitialAvatarElement {
  itemId: number;
  genId: number | null;
  avatarName: string;
  imageLink: string;
  imageThumbLink: string;
  imageWidth: number;
  imageHeight: number;
  imageThumbWidth: number;
  imageThumbHeight: number;
  headLink: string | null;
  headThumbLink: string | null;
  headPos: {
    X: number;
    Y: number;
  };
  headSize: {
    width: string;
  };
  headThumbSize: {
    width: string;
  };
};

export interface AvatarElement extends InitialAvatarElement {
  amount: number;
};

export const avatarsList: InitialAvatarElement[] = Object.entries(itemsData.avatars)
  .map(([genKey, genValue]) => {
    return Object.entries(genValue).map(([typeKey, typeValue]) => {
      return {
        imageLink: typeValue.body,
        imageThumbLink: typeValue.thumb_body,
        imageWidth: typeValue.body_size.width,
        imageHeight: typeValue.body_size.height,
        imageThumbWidth: typeValue.thumb_body_size.width,
        imageThumbHeight: typeValue.thumb_body_size.height,
        itemId: +typeKey,
        headLink: typeValue.heads.happy === "none" ? null : typeValue.heads.happy,
        headThumbLink: typeValue.heads.thumb_happy === "none" ? null : typeValue.heads.thumb_happy,
        headPos: typeValue.coords,
        headSize: {
          width: typeValue.heads.happy_size.width
        },
        headThumbSize: {
          width: typeValue.heads.thumb_happy_size.width
        },
        genId: Number(typeValue.gen_id),
        avatarName: typeValue.avatar_name,
      };
    });
  }).flat(2);

export const avatarNames = avatarsList.map(a => a.avatarName);

export const makeInitialAvatarElement = (avatarId: number): InitialAvatarElement => {
  const id = avatarsList.indexOf(avatarsList.filter(a => a.itemId === avatarId)[0]);

  const initialAvatarElement: InitialAvatarElement = {
    genId: avatarsList[id]?.genId ? Number(avatarsList[id].genId) : null,
    imageLink: avatarsList[id]?.imageLink ? avatarsList[id].imageLink : "null",
    imageThumbLink: avatarsList[id]?.imageThumbLink ? avatarsList[id].imageThumbLink : "null",
    imageWidth: avatarsList[id]?.imageWidth ? avatarsList[id].imageWidth : 1024,
    imageThumbWidth: avatarsList[id]?.imageThumbWidth ? avatarsList[id].imageThumbWidth : 512,
    imageHeight: avatarsList[id]?.imageHeight ? avatarsList[id].imageHeight : 1024,
    imageThumbHeight: avatarsList[id]?.imageThumbHeight ? avatarsList[id].imageThumbHeight : 512,
    itemId: avatarId,
    headLink: avatarsList[id]?.headLink ? avatarsList[id].headLink : "null",
    headThumbLink: avatarsList[id]?.headThumbLink ? avatarsList[id].headThumbLink : "null",
    headPos: avatarsList[id]?.headPos ? avatarsList[id].headPos : { X: 0, Y: 0 },
    headSize: avatarsList[id]?.headSize ? avatarsList[id]?.headSize : { width: "auto" },
    headThumbSize: avatarsList[id]?.headThumbSize ? avatarsList[id]?.headThumbSize : { width: "auto" },
    avatarName: avatarsList[id]?.avatarName ? avatarsList[id].avatarName : "null",
  };

  return initialAvatarElement;
};

export const makeAvatarElement = (amount: number, avatarId: number): AvatarElement => {
  const avatarElement = makeInitialAvatarElement(avatarId) as AvatarElement;
  avatarElement.amount = amount;
  return avatarElement;
};

export const getAvatarsFromArray = (array: number[] | Tuple<number, 64>): AvatarElement[] => {
  if (array.length) {

    if (array.length > 64) {
      array.slice(0, 63);
    }

    return array.map((av, indexId) => {
      const avatarObject = makeAvatarElement(av, indexId);
      return avatarObject;
    })
      .filter((item) => item.imageLink !== "null") // remove in a future when we will got all items in the initial json file
      .filter((item) => item.amount !== 0);
  }
  else {
    return [];
  }
};