import { SideBarUserLink, userSidebarData } from "entities/sideBar";

interface ISideBarUserLinks {
  isOpen: boolean;
};

export const SideBarUserLinks = ({ isOpen }: ISideBarUserLinks) => {
  const menuItems = [...userSidebarData];
  menuItems.pop();


  return (
    <>
      {menuItems.map(item => {
        return (
          <SideBarUserLink
            key={item.id}
            isOpen={isOpen}
            menuItem={item}
          />
        )
      })}
    </>
  )
};